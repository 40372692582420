import React, { useState, useEffect } from "react";
import "./JDEditableModal.css";
import { jsPDF } from "jspdf";
import useAuthService from  '../../services/AuthService'

import html2canvas from "html2canvas";
import { useAuthContext } from "../Auth/AuthContext";

const JDEditableModal = ({ isOpen, closeModal,refreshJDList, jdId, jdData }) => {
  const authService = useAuthService();
    const [editableData, setEditableData] = useState(null);
    const[errorMessage,setErrorMessage]=useState({})
    const [fieldErrors, setFieldErrors] = useState({});
    const [aiGenerating, setAiGenerating] = useState(false); // State for AI generation loading
    const [aiGenerateError, setAiGenerateError]=useState("");

    useEffect(()=>{
        let timer;
        if(aiGenerateError.length){
            timer=setTimeout(()=>{
                setAiGenerateError("");
            }, 5000);
        }
        return () => clearTimeout(timer);
    }, [aiGenerateError])

    const {userObjId}=useAuthContext();
    useEffect(() => {
        if (!isOpen) {
            // Clear field errors when the modal is closed
            setFieldErrors({});
        }
    }, [isOpen]);

    const websocketUrlForJDGen = "wss://o834xau6qe.execute-api.us-west-1.amazonaws.com/production/"; // WebSocket for Job Description generation


    useEffect(() => {
      if (isOpen && jdId) {
          const jobDetails = jdData.find((jd) => jd._id === jdId);
          console.log("Found job details with _id:", jobDetails?._id); // Debug log for _id
          console.log("Complete job details:", jobDetails); // Debug log for all data
  
          if (jobDetails) {
              setEditableData({
                  ...jobDetails,
                  _id: jobDetails._id, // Explicitly set _id
                  about_us: jobDetails.job_description?.about_us || '',
                  job_overview: jobDetails.job_description?.job_overview || '',
                  preferred_skills: jobDetails.job_description?.preferred_skills || '',
                  key_responsibilities: jobDetails.job_description?.key_responsibilities || '',
                  required_qualification_experience: jobDetails.job_description?.required_qualification_experience || ''
              });
          }
      }
  }, [isOpen, jdId, jdData]);


    // const validateForSave = () => {
    //   // Only check for key_responsibilities as it's mandatory
    //   if (!editableData?.job_description?.key_responsibilities || 
    //       !editableData.job_description.key_responsibilities.toString().trim()) {
    //       setErrorMessage("Please fill in Key Responsibilities.");
    //       return false;
    //   }
      
    //   setErrorMessage("");
    //   return true;
    // };

    
    const validateForSave = () => {
        const {job_description } = editableData;
        let errors = {};

        if (!job_description.key_responsibilities || !job_description.key_responsibilities.toString().trim()) {
            errors.keyResponsibilities = "Enter Key Responsibilities";
        }
        else if (job_description.key_responsibilities.length > 3500) {
            errors.keyResponsibilities = "Key Responsibilities cannot exceed 3500 characters";
        }

        if (job_description.about_us && job_description.about_us.length > 3500) {
            errors.aboutUs = "About Us cannot exceed 3500 characters";
        }
    
        if (job_description.job_overview && job_description.job_overview.length > 3500) {
            errors.jobOverview = "Job Overview cannot exceed 3500 characters";
        }
    
        if (job_description.preferred_skills && job_description.preferred_skills.length > 3500) {
            errors.preferredSkills = "Preferred Skills cannot exceed 3500 characters";
        }
    
        if (job_description.required_qualification_experience && job_description.required_qualification_experience.length > 3500) {
            errors.requiredQualificationExperience = "Required Qualifications/Experience cannot exceed 3500 characters";
        }    
    
        setFieldErrors(errors);
    
        return Object.keys(errors).length === 0;
    };

    const handleSubmit = async () => {
      if (!validateForSave()) {
          return;
      }
      try {
          if (!editableData._id) {
              console.error("No _id found - this would create a new document");
              return;
          }

          const payload = {
              jd_obj_id: editableData._id,  // This should be the MongoDB ObjectId
              user_id: editableData.user_id,
              job_role: editableData.job_role,
              company: editableData.company,
              job_type: editableData.job_type,
              job_description: {
                  about_us: editableData.about_us,
                  job_overview: editableData.job_overview,
                  preferred_skills: editableData.preferred_skills,
                  key_responsibilities: editableData.key_responsibilities,
                  required_qualification_experience: editableData.required_qualification_experience
              },
              date: editableData.date,
              time: editableData.time,
              experience: editableData.experience,
              salary: editableData.salary,
              location:editableData.location,
              jd_id: editableData.jd_id
          };

          console.log("Payload for update:", payload);
          console.log("_id type:", typeof payload._id);
          console.log("_id value:", payload._id);

          const response = await authService.saveJobDetails(payload);
          console.log("API Response:", response);

          if (response.error) {
              console.error("Error updating job details:", response.error);
              alert("Failed to update job details. Please try again.");
          } else {
              alert("Job details updated successfully.");
              if (refreshJDList) {
                  await refreshJDList();
              }
              closeModal();
          }
      } catch (error) {
          console.error("Error updating job details:", error);
          alert("An error occurred. Please try again.");
      }
  };

    const handleInputChange = (field, value) => {
      setEditableData(prevData => {
          if (['about_us', 'job_overview', 'preferred_skills', 'key_responsibilities', 'required_qualification_experience'].includes(field)) {
              return {
                  ...prevData,
                  [field]: value,
                  job_description: {
                      ...prevData.job_description,
                      [field]: value
                  }
              };
          }
          return {
              ...prevData,
              [field]: value
          };
      });
  };

    const handleDownloadPDF = () => {
        if (!editableData) return;
        const pdf = new jsPDF();
        
        const cleanText = (text) => {
            if (!text) return '';
            // Remove numbers followed by dot/bracket at start of line
            return text.replace(/^\d+[\.)]\s*/gm, '');
        };

        // Set up PDF styling
        pdf.setTextColor(0, 0, 0); // Black text
        pdf.setFillColor(255, 255, 255); // White background
        
        // Company Name - Centered, Bold, Underlined
        pdf.setFontSize(18);
        pdf.setFont(undefined, 'bold');
        const companyName = editableData.company;
        const companyNameWidth = pdf.getStringUnitWidth(companyName) * pdf.internal.getFontSize() / pdf.internal.scaleFactor;
        const centerX = (pdf.internal.pageSize.width - companyNameWidth) / 2;
        pdf.text(companyName, centerX, 20);
        pdf.setFont(undefined, 'normal');
        pdf.setLineWidth(0.5);
        pdf.line(centerX, 22, centerX + companyNameWidth, 22); // Underline
        pdf.setFontSize(12);
        
        // Date - Right Aligned
        const currentDate = new Date().toLocaleDateString();
        pdf.text(`Date: ${currentDate}`, pdf.internal.pageSize.width - 20, 30, { align: 'right' });

        // Job Details
        pdf.setFontSize(12);
        const titleX = 20;
        const valueX = 70;
        
        // Helper function to ensure string values
        const toString = (value) => {
            if (value === null || value === undefined) return 'Not Specified';
            return String(value); // Convert any value to string
        };

        // Helper function for adding fields
        const addField = (label, value, y) => {
            pdf.setFont(undefined, 'bold');
            pdf.setFontSize(12);
            pdf.text(label, titleX, y);
            pdf.setFont(undefined, 'normal');
            pdf.setFontSize(10);
            pdf.text(toString(value), valueX, y);
        };

        // Add job details fields
        addField('Job Role:', editableData.job_role, 40);
        addField('Location:', editableData.location, 50);
        addField('Experience:', editableData.experience, 60);
        addField('Salary:', editableData.salary, 70);
        addField('Job Type:', editableData.job_type, 80);
        addField('JD ID:', editableData.jd_id, 90);

        // Job Description Sections
        let yPos = 110;
        const pageHeight = pdf.internal.pageSize.height;

        // Function to check for page break
        const checkPageBreak = () => {
            if (yPos >= pageHeight - 20) {
                pdf.addPage();
                yPos = 20;
            }
        };

        // Helper function for adding sections
        const addSection = (title, content) => {
            if (content) {
                checkPageBreak();
                pdf.setFontSize(14);
                pdf.setFont(undefined, 'bold');
                pdf.setFontSize(12);
                pdf.text(title, 20, yPos);
                pdf.setFont(undefined, 'normal');
                pdf.setFontSize(10);
                yPos += 10;

                const lines = pdf.splitTextToSize(toString(content), 170);
                lines.forEach(line => {
                    checkPageBreak();
                    pdf.text(line, 20, yPos);
                    yPos += 6;
                });
                yPos += 10;
            }
        };

        // Add job description sections
        if (editableData.job_description) {
            addSection('About Us:', editableData.job_description.about_us);
            addSection('Job Overview:', editableData.job_description.job_overview);
            
                    // Key Responsibilities
        if (editableData.key_responsibilities) {
        checkPageBreak();
        pdf.setFontSize(12);
        pdf.setFont(undefined, 'bold');
        pdf.text('Key Responsibilities:', 20, yPos);
        pdf.setFont(undefined, 'normal');
        pdf.setFontSize(10);
        yPos += 10;

        const responsibilities = cleanText(editableData.job_description.key_responsibilities).split('\n');
        responsibilities.forEach(resp => {
            if (resp.trim()) {
                const lines = pdf.splitTextToSize(resp.trim(), 160);
                lines.forEach((line, index) => {
                    checkPageBreak();
                    pdf.text(line, 25, yPos);
                    yPos += 6;
                });
            }
        });
        yPos += 10;
    }

        // Qualifications
        if (editableData.job_description.required_qualification_experience) {
            checkPageBreak();
            pdf.setFontSize(12);
            pdf.setFont(undefined, 'bold');
            pdf.text('Required Qualifications/Experience:', 20, yPos);
            pdf.setFont(undefined, 'normal');
            pdf.setFontSize(10);
            yPos += 10;

            const qualifications = cleanText(editableData.job_description.required_qualification_experience).split('\n');
            qualifications.forEach(qual => {
                if (qual.trim()) {
                    const lines = pdf.splitTextToSize(qual.trim(), 160);
                    lines.forEach((line, index) => {
                        checkPageBreak();
                        pdf.text(line, 25, yPos);
                        yPos += 6;
                    });
                }
            });
            yPos += 10;
        }

        // Preferred Skills
        if (editableData.preferred_skills) {
            checkPageBreak();
            pdf.setFontSize(12);
            pdf.setFont(undefined, 'bold');
            pdf.text('Preferred Skills:', 20, yPos);
            pdf.setFont(undefined, 'normal');
            pdf.setFontSize(10);
            yPos += 10;

            const skills = cleanText(editableData.job_description.preferred_skills).split('\n');
            skills.forEach(skill => {
            if (skill.trim()) {
                const lines = pdf.splitTextToSize(skill.trim(), 160);
                lines.forEach((line, index) => {
                    checkPageBreak();
                    pdf.text(line, 25, yPos);
                    yPos += 6;
                });
            }
            });
        }
    }
        // Footer
        pdf.setFontSize(10);
        pdf.setTextColor(128, 128, 128);
        pdf.text('Generated by BotGuru', 20, pdf.internal.pageSize.height - 10);
        
        // Save the PDF
        const fileName = `JD_${editableData.job_role.replace(/\s+/g, '_')}_${new Date().toISOString().split('T')[0]}.pdf`;
        pdf.save(fileName);
    };

    const handleWebSocketMessageForJDGen = async (event) => {
        try {
            const data = JSON.parse(event.data);
            console.log("Message received:", data);

                 // Helper function to format text with bullets
            const formatWithBullets = (text) => {
                if (!text) return '';
                return text
                    .split('\n')
                    .map(line => line.trim())
                    .map(line => line.replace(/^[-–—]+\s*/, '• ')) // Replace hyphens with bullets
                    .join('\n');
            };


            if (data.connectionid) {
                // Send connection ID to the backend
                await authService.JdAiGenerate({
                    job_role: editableData["job_role"],
                    company_name: editableData.company,
                    connectionId: data.connectionid,
                    bot_type:"jd_ai_generate",
                    job_details: {
                        about_us: editableData.about_us || '',
                        job_overview: editableData.job_overview || '',
                        preferred_skills: editableData.preferred_skills || '',
                        key_responsibilities: editableData.key_responsibilities || '',
                        required_qualification_experience: editableData.required_qualification_experience || ''
                    },
                    user_obj_id: userObjId,
                }).then(()=>{
                    console.log("Job description generated");
                })
                .catch(()=>{
                    console.log("Job description not generated");
                    setAiGenerating(false);
                    setAiGenerateError("AI JD Generate failed, Re-try!")
                })
            }


            // Extract specific fields and ensure they are in the right format
            if (data.message) {
                console.log("Message content:", data.message);

                const { about_us, job_overview, key_responsibilities, required_qualification_experience,preferred_skills } = data.message;
// Update the state with individual fields
                setEditableData(prevData => ({
                    ...prevData,
                    about_us: about_us || prevData.about_us || '',
                    job_overview: job_overview || prevData.job_overview || '',
                    preferred_skills: preferred_skills || prevData.preferred_skills || '',
                    key_responsibilities: key_responsibilities || prevData.key_responsibilities || '',
                    required_qualification_experience: required_qualification_experience || prevData.required_qualification_experience || '',
                    job_description: {
                        ...prevData.job_description,
                        about_us: about_us || prevData.job_description?.about_us || '',
                        job_overview: job_overview || prevData.job_description?.job_overview || '',
                        preferred_skills: formatWithBullets(preferred_skills) || prevData.job_description?.preferred_skills || '',
                        key_responsibilities: formatWithBullets(key_responsibilities) || prevData.job_description?.key_responsibilities || '',
                        required_qualification_experience: formatWithBullets(required_qualification_experience) || prevData.job_description?.required_qualification_experience || ''
                    }
                }));
                setAiGenerating(false); // Start AI generation loading

            }

        } catch (error) {
            console.error("Error processing Job Description WebSocket message:", error);
        }
    };

    const handleAIGenerate = () => {
        if (!validateForAIGenerate()) {
            return;
        }
        
        console.log("AI Generate button clicked");
        setAiGenerating(true); // Start AI generation loading
       
        console.log("Establishing a new WebSocket connection for Job Description Generation.");
        const websocketInstance = new WebSocket(websocketUrlForJDGen);

        websocketInstance.onopen = () => {
            console.log("WebSocket connection established for Job Description Generation");

            const initialMessage = { action: "botguru_sendmessage", message: "get data" };
            websocketInstance.send(JSON.stringify(initialMessage));
            console.log("Sent initial message for Job Description Generation:", initialMessage);
        };

        websocketInstance.onmessage = (event) => {
            handleWebSocketMessageForJDGen(event);
        };

        websocketInstance.onerror = (error) => {
            console.error("WebSocket error for Job Description Generation:", error);
            setAiGenerating(false); // Stop AI generation loading
        };

        websocketInstance.onclose = () => {
            console.log("WebSocket connection for Job Description Generation closed");
        };

    };

    
    const validateForAIGenerate = () => {
        const { "job_role": jobRole, company: company, job_type: jobType } = editableData;
        const missingFields = [];

        if (!jobRole) missingFields.push("Job/Role");
        if (!company) missingFields.push("Company");
        if (!jobType || jobType === "Select Job Type") missingFields.push("Job Type");

        if (missingFields.length > 0) {
            setErrorMessage(`Please fill in ${missingFields.join(" and ")}.`);
            return false;
        }
        setErrorMessage("");
        return true;
    };

    // Render nothing if modal is not open or no valid job details
    if (!isOpen || !editableData) return null;

  return (
    <div className="modal-overlay">
      <div className="jd-editable-modal-content">
        <button className="jd-close-icon" onClick={closeModal}>
          &times;
        </button>
        <div className="split-container">
          <div className="form-side">
            <div className="createjd-header">
              <h2>Edit Job/Role</h2>
            </div>
            <div className="jd-editable-modal-details">
              <div className="side-by-side-fields">
                <div className="field-container">
                  <strong>Job/Role:</strong>
                  <div className="non-editable-field">
                  {editableData.job_role || ""}
                  </div>
                </div>
                <div className="field-container">
                  <h3>Job Type:</h3>
                  <div className="non-editable-field">
                    {editableData.job_type || ""}
                  </div>
                </div>
                <div className="field-container">
                  <strong>Company:</strong>
                  <div className="non-editable-field">
                    {editableData.company || ""}
                  </div>
                </div>
                <div className="field-container">
                  <strong>Experience:</strong>
                  <input
                        type="text"
                        className="editable-field"
                        value={editableData.experience || ""}
                        onChange={(e) => handleInputChange("experience", e.target.value)}
                        placeholder="Enter experience"
                  />
                </div>
                <div className="field-container">
                  <strong>Salary:</strong>
                  <div className="input-with-dropdown">
                  <input
                        type="text"
                        className="editable-field"
                        value={editableData.salary || ""}
                        onChange={(e) => handleInputChange("salary", e.target.value)}
                        placeholder="Enter salary"
                    />
                    <select
                        value={editableData.currency || "USD"} // Default to USD
                        onChange={(e) => handleInputChange("currency", e.target.value)}
                        className="currency-dropdown"
                        >
                        <option value="USD">USD</option>
                        <option value="EUR">EUR</option>
                        <option value="GBP">GBP</option>
                        <option value="INR">INR</option>
                        <option value="AUD">AUD</option>
                    </select>
                    </div>
                </div>
                <div className="field-container">
                  <strong>Location:</strong>
                  <input
                    type="text"
                    className="editable-field"
                    value={editableData.location || ""}
                    onChange={(e) => handleInputChange("location", e.target.value)}
                    placeholder="Enter location"
                  />
                </div>
              </div>
              <div className="jd-sections-container">
                            <div className="jd-section">
                                <h3>
                                    <span>About Us:</span>
                                    {aiGenerateError.length ? <span className="ai-generate-error">{aiGenerateError}</span> :""}
                                    <button className="ai-generate-button" onClick={handleAIGenerate} disabled={aiGenerating}>
                                    {aiGenerating ? (
                                    <>
                                       AI Generating <i className="fas fa-spinner fa-spin"></i>
                                    </>
                                ) : (
                                    <>
                                        <i className="fas fa-wand-magic-sparkles"></i> AI Generate
                                    </>
                                )}
                                    </button>
                                </h3>
                                <textarea
                                    value={editableData.about_us || ""}
                                    onChange={(e) => handleInputChange("about_us", e.target.value)}
                                    placeholder="Enter company description..."
                                    className={fieldErrors.aboutUs ? 'error-border' : ''} 
                                />
                                {fieldErrors.aboutUs && 
                                <span className="jd-error-message">{fieldErrors.aboutUs}</span>}
                            </div>
                            <div className="jd-section">
                                <h3>Job Overview:</h3>
                                <textarea
                                    value={editableData.job_overview || ""}
                                    onChange={(e) => handleInputChange("job_overview", e.target.value)}
                                    placeholder="Enter job overview..."
                                    className={fieldErrors.jobOverview ? 'error-border' : ''}
                                />
                                {fieldErrors.jobOverview && 
                                <span className="jd-error-message">{fieldErrors.jobOverview}</span>}
                            </div>
                            <div className="jd-section">
                            <div className="job-label">
                                <h3>Key Responsibilities:</h3>
                                <span className={`required-asterisk ${fieldErrors.keyResponsibilities ? 'error-text' : ''}`}>*</span>

                            </div>
                                <textarea
                                    value={editableData.key_responsibilities || ""}
                                    onChange={(e) => handleInputChange("key_responsibilities", e.target.value)}
                                    placeholder="Enter key responsibilities..." 
                                    className={fieldErrors.keyResponsibilities ? 'error-border' : ''}
                                    />
                                    {fieldErrors.keyResponsibilities && 
                                    <span className="jd-error-message">{fieldErrors.keyResponsibilities}</span>}
                            </div>
                            <div className="jd-section">
                                <h3>Required Qualifications/Experience:</h3>
                                <textarea
                                    value={editableData.required_qualification_experience || ""}
                                    onChange={(e) => handleInputChange("required_qualification_experience", e.target.value)}
                                    placeholder="Enter required qualifications..."
                                    className={fieldErrors.requiredQualificationExperience ? 'error-border' : ''}
                                    />
                                    {fieldErrors.requiredQualificationExperience && 
                                    <span className="jd-error-message">{fieldErrors.requiredQualificationExperience}</span>}
                            </div>
                            <div className="jd-section">
                                <h3>Preferred Skills:</h3>
                                <textarea
                                    value={editableData.preferred_skills || ""}
                                    onChange={(e) => handleInputChange("preferred_skills", e.target.value)}
                                    placeholder="Enter preferred skills..."
                                    className={fieldErrors.preferredSkills ? 'error-border' : ''}
                                />
                                 {fieldErrors.preferredSkills && 
                                <span className="jd-error-message">{fieldErrors.preferredSkills}</span>}
                            </div>
                        </div>
              </div>
            <div className="jd-save-btn-container">
              <button className="jd-save-btn" onClick={handleSubmit}>
                Save
              </button>
            </div>
          </div>
          <div className="preview-side">
            <div className="preview-header">
              <h2>Preview</h2>
              <button className="download-pdf-btn" onClick={handleDownloadPDF}>
                <i className="fas fa-download"></i>
                Download PDF
              </button>
            </div>
            <div className="preview-content">
                <div className="preview-company-header">
                    <h1 className="company-name">{editableData.company}</h1>
                    <div className="current-date">Date: {new Date().toLocaleDateString()}</div>
                </div>
                <div className="preview-row">
                    {editableData?.job_role && (
                        <div className="preview-item">
                            <h3>Job/Role</h3>
                            <p>{editableData.job_role}</p>
                        </div>
                    )}
                    {editableData?.job_type && (
                        <div className="preview-item">
                            <h3>Job Type</h3>
                            <p>{editableData.job_type}</p>
                        </div>
                    )}
                    {editableData?.experience && (
                        <div className="preview-item">
                            <h3>Experience</h3>
                            <p>{editableData.experience}</p>
                        </div>
                    )}  
                    {editableData?.salary && (
                        <div className="preview-item">
                            <h3>Salary</h3>
                            <p>{editableData.salary}</p>
                        </div>
                    )}  
                    {editableData?.location && (
                        <div className="preview-item">
                            <h3>Location</h3>
                            <p>{editableData.location}</p>
                        </div>
                    )}
                </div>

                {/* Job Description Sections */}
                {editableData?.about_us && (
                    <div className="preview-item">
                        <h3>About Us</h3>
                        <p>{editableData.about_us}</p>
                    </div>
                )}
                
                {editableData?.job_overview && (
                    <div className="preview-item">
                        <h3>Job Overview</h3>
                        <p>{editableData.job_overview}</p>
                    </div>
                )}
                
                {editableData?.key_responsibilities && (
                    <div className="preview-item">
                        <h3>Key Responsibilities</h3>
                        <p>{editableData.key_responsibilities}</p>
                    </div>
                )}
                
                {editableData?.required_qualification_experience && (
                    <div className="preview-item">
                        <h3>Required Qualifications/Experience</h3>
                        <p>{editableData.required_qualification_experience}</p>
                    </div>
                )}
                
                {editableData?.preferred_skills && (
                    <div className="preview-item">
                        <h3>Preferred Skills</h3>
                        <p>{editableData.preferred_skills}</p>
                    </div>
                )}
            </div>
        </div>
     </div>
    </div>
  </div>
    );
};

export default JDEditableModal;
