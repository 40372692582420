import React from 'react';
import './ResumePreview.css';
import { jsPDF } from 'jspdf';


const ResumePreview = ({ resumeData, closeModal}) => {
    const handleDownloadPDF = () => {
        if (!resumeData?.message) return;
        
        const pdf = new jsPDF();
        let yPos = 20;
        const margin = 20;
        const pageWidth = pdf.internal.pageSize.width;

        // Helper function for page breaks
        const checkPageBreak = (requiredSpace = 40) => {
            if (yPos > pdf.internal.pageSize.height - requiredSpace) {
                pdf.addPage();
                yPos = 20;
            }
        };

        const addHorizontalLine = () => {
            pdf.setDrawColor(200, 200, 200);
            pdf.setLineWidth(0.3);
            pdf.line(margin, yPos, pageWidth - margin, yPos);
            yPos += 10;
        };

        // Personal Details Section
        pdf.setFont("helvetica", "bold");
        pdf.setFontSize(24);
        const fullName = `${resumeData.message.first_name} ${resumeData.message.last_name}`;
        const nameWidth = pdf.getStringUnitWidth(fullName) * pdf.getFontSize() / pdf.internal.scaleFactor;
        pdf.text(fullName, (pageWidth - nameWidth) / 2, yPos);
        yPos += 8;

        // Contact Info
        pdf.setFontSize(11);
        pdf.setFont("helvetica", "normal");
        const contactInfo = [];
        if (resumeData.message.email) contactInfo.push(resumeData.message.email);
        if (resumeData.message.phone_number) contactInfo.push(resumeData.message.phone_number);
        const contactText = contactInfo.join(" | ");
        const contactWidth = pdf.getStringUnitWidth(contactText) * pdf.getFontSize() / pdf.internal.scaleFactor;
        pdf.text(contactText, (pageWidth - contactWidth) / 2, yPos);
        yPos += 5;

        // Social Links
        if (resumeData.message.linkedin_url || resumeData.message.website_url) {
            const socialLinks = [];
            if (resumeData.message.linkedin_url) socialLinks.push(resumeData.message.linkedin_url);
            if (resumeData.message.website_url) socialLinks.push(resumeData.message.website_url);
            const socialText = socialLinks.join(" | ");
            const socialWidth = pdf.getStringUnitWidth(socialText) * pdf.getFontSize() / pdf.internal.scaleFactor;
            pdf.text(socialText, (pageWidth - socialWidth) / 2, yPos);
            yPos +=15;
        }

        yPos += 5;
        // Professional Summary
        if (resumeData.message.professional_summary) {
            pdf.setFont("helvetica", "bold");
            pdf.setFontSize(12);
            pdf.text("PROFESSIONAL SUMMARY", margin, yPos);
            yPos += 8;

            pdf.setFont("helvetica", "normal");
            pdf.setFontSize(11);
            const summaryLines = pdf.splitTextToSize(resumeData.message.professional_summary, pageWidth - 2 * margin);
            summaryLines.forEach(line => {
                checkPageBreak();
                pdf.text(line, margin, yPos);
                yPos += 6;
            });
            // yPos += 15;
            addHorizontalLine();
        }

        // Experience Section
        if (resumeData.message.experience?.length) {
            checkPageBreak();
            pdf.setFont("helvetica", "bold");
            pdf.setFontSize(12);
            pdf.text("EXPERIENCE", margin, yPos);
            yPos += 6;

            resumeData.message.experience.forEach(exp => {
                checkPageBreak();
                yPos += 3;
                if (exp.company_name ||exp.start_date || exp.end_date) {
                    pdf.setFont("helvetica", "bold");
                    pdf.setFontSize(11);
                    if(exp.company_name){
                    pdf.text(exp.company_name, margin, yPos);
                    }
                    if (exp.start_date || exp.end_date) {
                        const dateText = `${exp.start_date} - ${exp.end_date}`;
                        const dateWidth = pdf.getTextWidth(dateText);
                        pdf.text(dateText, pageWidth - margin - dateWidth, yPos);
                    }
                    yPos += 6;
                }
    
                // Job Title and Location
                if (exp.job_role || exp.location || exp.country) {
                    pdf.setFont("helvetica", "italic");
                    if (exp.job_role) {
                        pdf.text(exp.job_role, margin, yPos);
                    }
    
                    if (exp.city_state || exp.country) {
                        const locationText = [exp.city_state, exp.country].filter(Boolean).join(", ");
                        const locationWidth = pdf.getTextWidth(locationText);
                        pdf.text(locationText, pageWidth - margin - locationWidth, yPos);
                    }
                    yPos += 6;
                }

                if (exp.responsibilities?.length) {
                    pdf.setFont("helvetica", "normal");
                    yPos += 4;
                    exp.responsibilities.forEach(resp => {
                        checkPageBreak();
                        const formattedResp = resp.startsWith('•') ? resp : `• ${resp}`;
                        const lines = pdf.splitTextToSize(formattedResp, pageWidth - 2 * margin - 5);                        lines.forEach(line => {
                            pdf.text(line, margin + 2, yPos);
                            yPos += 6;
                        });
                    });
                }
                // yPos += 10;
            });
            addHorizontalLine();
        }

        // Skills Section
        if (resumeData.message.skills?.length) {
            checkPageBreak();
            pdf.setFont("helvetica", "bold");
            pdf.setFontSize(12);
            pdf.text("SKILLS", margin, yPos);
            yPos += 10;

            pdf.setFont("helvetica", "normal");
            pdf.setFontSize(11);
            
            const skillsPerRow = 3;
            const skillWidth = (pageWidth - 2 * margin) / skillsPerRow;
            
            for (let i = 0; i < resumeData.message.skills.length; i += skillsPerRow) {
                checkPageBreak();
                const rowSkills = resumeData.message.skills.slice(i, i + skillsPerRow);
                rowSkills.forEach((skill, index) => {
                    pdf.text(`• ${skill}`, margin + (index * skillWidth), yPos);
                });
                yPos += 8;
            }
            // yPos += 10;
            addHorizontalLine();
        }

        // Education Section
        if (resumeData.message.education?.length) {
            checkPageBreak();
            pdf.setFont("helvetica", "bold");
            pdf.setFontSize(12);
            pdf.text("EDUCATION", margin, yPos);
            yPos += 10;

            resumeData.message.education.forEach(edu => {
                checkPageBreak();
                pdf.setFont("helvetica", "bold");
                pdf.setFontSize(12);
                pdf.text(edu.school_university, margin, yPos);
                yPos += 6;

                pdf.setFont("helvetica", "italic");
                pdf.setFontSize(11);
                pdf.text(edu.degree, margin, yPos);

                const dateText = `${edu.start_date} - ${edu.end_date}`;
                const dateWidth = pdf.getStringUnitWidth(dateText) * pdf.getFontSize() / pdf.internal.scaleFactor;
                pdf.text(dateText, pageWidth - margin - dateWidth, yPos);
                
                if (edu.gpa) {
                    yPos += 6;
                    pdf.setFont("helvetica", "normal");
                    pdf.text(`GPA: ${edu.gpa}`, margin, yPos);
                }

                if (edu.additionalDetails) {
                    yPos += 6;
                    pdf.setFont("helvetica", "normal");
                    const detailLines = pdf.splitTextToSize(edu.additionalDetails, pageWidth - 2 * margin);
                    detailLines.forEach(line => {
                        checkPageBreak();
                        pdf.text(line, margin, yPos);
                        yPos += 6;
                    });
                }
                
                yPos += 15;
            });
        }

        // Save the PDF
        const fileName = `${resumeData.message.first_name}_${resumeData.message.last_name}_Resume.pdf`;
        console.log(fileName)
        pdf.save(fileName);
    };

    return (
        <div className="preview-modal-overlay">
            <div className="preview-modal-content">

                <div className="resume-preview-header">
                    
                    <h2>Resume Preview</h2>
                  
                    <button onClick={handleDownloadPDF} className="preview-download-btn">
                        <i className="fas fa-download"></i> Download PDF
                    </button>
                    
                    <button className="resumeview-close-button" onClick={closeModal}>
                &times;
                </button>
                </div>
                
                <div className="preview-body">
                    <div className="resume-preview-template">
                        {/* Personal Details Section */}
                        <div className="preview-section personal-details">
                            <h1 className="preview-name">
                                {resumeData?.message?.first_name} {resumeData?.message?.last_name}
                            </h1>
                            <div className="preview-contact-info">
                                {resumeData?.message?.email && (
                                    <span><i className="far fa-envelope"></i> {resumeData.message.email}</span>
                                )}
                                {resumeData?.message?.phone_number && (
                                    <span><i className="fas fa-phone"></i> {resumeData.message.phone_number}</span>
                                )}
                            </div>
                            <div className="preview-social-links">
                                {resumeData?.message?.linkedin_url && (
                                    <span><i className="fab fa-linkedin"></i> {resumeData.message.linkedin_url}</span>
                                )}
                                {resumeData?.message?.website_url && (
                                    <span><i className="fas fa-globe"></i> {resumeData.message.website_url}</span>
                                )}
                            </div>
                        </div>

                        {/* Professional Summary Section */}
                        <div className="preview-section">
                            <h3 className="preview-section-title">Professional Summary</h3>
                            <div className="preview-section-content">
                                <p>{resumeData?.message?.professional_summary}</p>
                            </div>
                        </div>

                        {/* Experience Section */}
                        <div className="preview-section">
                            <h3 className="preview-section-title">Experience</h3>
                            <div className="preview-section-content">
                                {resumeData?.message?.experience?.map((exp, index) => (
                                    <div key={index} className="preview-experience-item">
                                        <div className="preview-experience-header">
                                            <div className="preview-experience-title">
                                                <h4>{exp.job_role}</h4>
                                                <span className="preview-company">{exp.company_name}</span>
                                            </div>
                                            <div className="preview-experience-period">
                                                <span>{exp.start_date} - {exp.end_date}</span>
                                                {(exp.city_state || exp.country) && (
                                                    <span className="preview-location">
                                                        <i className="fas fa-map-marker-alt"></i>
                                                        {exp.city_state && exp.country
                                                            ? `${exp.city_state}, ${exp.country}`
                                                            : exp.city_state || exp.country}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                        <ul className="preview-responsibilities">
                                        {Array.isArray(exp.responsibilities) ? (
                                            exp.responsibilities.map((resp, idx) => (
                                                <li key={idx}>{resp.replace(/^[•\s]+/, '')}</li> // Add bullet point for each responsibility
                                            ))
                                        ) : (
                                            // If responsibilities is a string, split it into an array by line breaks
                                            exp.responsibilities?.split('\n')
                                            .filter(line => line.trim()) // Remove empty lines
                                            .map((resp, idx) => (
                                                <li key={idx}>{resp.replace(/^[•\s]+/, '')}</li> // Add bullet point for each line
                                            ))
                                        )}  
                                        </ul>
                                    </div>
                                ))}
                            </div>
                        </div>

                        {/* Skills Section */}
                        <div className="preview-section">
                            <h3 className="preview-section-title">Skills</h3>
                            <div className="preview-skills-container">
                                {resumeData?.message?.skills?.map((skill, index) => (
                                    <span key={index} className="preview-skill-tag">
                                        {skill}
                                    </span>
                                ))}
                            </div>
                        </div>

                        {/* Education Section */}
                        <div className="preview-section">
                            <h3 className="preview-section-title">Education</h3>
                            <div className="preview-section-content">
                                {resumeData?.message?.education?.map((edu, index) => (
                                    <div key={index} className="preview-education-item">
                                        <div className="preview-education-header">
                                            <div className="preview-education-title">
                                                <h4>{edu.school_university}</h4>
                                                <span className="preview-degree">{edu.degree}</span>
                                            </div>
                                            <div className="preview-education-period">
                                                <span>{edu.start_date} - {edu.end_date}</span>
                                                {edu.gpa && (
                                                    <span className="preview-gpa">GPA: {edu.gpa}</span>
                                                )}
                                            </div>
                                        </div>
                                        {edu.additionalDetails && (
                                            <p className="preview-education-details">
                                                {edu.additionalDetails}
                                            </p>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ResumePreview;