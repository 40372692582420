import React, { useEffect, useRef, useState } from 'react';
import './Component.css';
import config from '../config.json';
import { ProgressBar } from './Utils/ProgressBar';
import { ArrowRight, CheckCircle2 } from 'lucide-react';
 

const features = [
    {
      id: 1,
      title: 'AI Interview PrepBot',
      description: 'Your personal coach to enhance skills, sharpen responses, and boost confidence for any job!'
    },
    {
      id: 2,
      title: 'Interactive Coaching',
      description: 'Experience real-time, simulated interactive interview coaching for dynamic preparation.'
    },
    {
      id: 3,
      title: 'Response Refinement',
      description: 'Master the art of structured answers with PrepBot’s guidance on enhancing your responses. '
    },
    {
      id: 4,
      title: 'Instant Feedback',
      description: 'Get constructive insights and actionable tips after each session to elevate your performance.'
    },
    {
      id:5,
      title:"Success Through Preparation",
      description:"Reinforce your learning with repeat sessions and track your progress toward success!"
    }
  ];

const FeatureItem = ({ title, description }) => (
    <div className="feature-item">
        <CheckCircle2 className='feature-logo'/>
      <div className="feature-content">
        <h3 className="feature-title">{title}</h3>
        <p className="feature-description">{description}</p>
      </div>
    </div>
  );
 
const PrepGuru = ({isPrepGuruVisible, getStarted}) => {
  const videoRef = useRef(null);
//   Custom Coaching for Your Success!
  return (
    <div className="page">
        <div className='row-1'>
            <div className='row-1-container'>
                <h1>PrepBot</h1>
                <h2>Custom Coaching for Your Success!</h2>
            </div>
        </div>
        <div className='row-2'>
            <div className='row-2-container'>
                <div className="feature-list">
                {features.map((feature) => (
                    <FeatureItem
                      key={feature.id}
                      title={feature.title}
                      description={feature.description}
                    />
                ))}
                </div>  
                <div className="videoPlayer">
                    <div>
                        <video
                        ref={videoRef}
                        src={`${config.backendAPI}PrepGuru.mp4`}
                        autoPlay
                        loop
                        muted
                        playsInline
                        controls
                        style={{ borderRadius: '10px' }}
                        loading="lazy"
                    ></video>
                    </div>
                        <button className="subscribe-btn" onClick={()=>getStarted('/landingPage')}>Get started</button>
                </div>
            </div>
        </div>
        <div className='row-3'>
            {/* <p className='steps-title'>Steps To Start <ArrowRight /></p> */}
            {isPrepGuruVisible && <ProgressBar stepsData="prepSteps"/>}
        </div>
    </div>
 
  );
};
 
export default PrepGuru;