// useAuth.js
import { useState, useEffect } from 'react';
import config from './config.json';
import { useAuthContext } from './Components/Auth/AuthContext';

export const useAuth = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const {userObjId, setUserObjId}=useAuthContext();
  
  useEffect(() => {
    console.log("triggered authapi")
    const checkAuth = async () => {
      try {
        const response = await fetch(`${config.restAPI}/authApi/`, {
          method: 'GET',
          credentials: 'include',
        });
        if (response.ok) {
          let data = await response.json();
          setIsAuthenticated(true);
          if(!userObjId){
            localStorage.setItem("userObjId",data?.user_obj_id);
            setUserObjId(data?.userObjId);
          }
        } else {
          setIsAuthenticated(false);
        }
      } catch (error) {
        console.error('Auth check failed:', error);
        setIsAuthenticated(false);
      } finally {
        setIsLoading(false);
      }
    };
    checkAuth();
  }, []);
  
  return { isAuthenticated, isLoading, setIsAuthenticated };  
};