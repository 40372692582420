import React, { useState } from 'react';
import './Analytics.css';
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faClock,
    faUser,
    faBullseye,
    faCheckCircle,
    faListCheck,
    faHourglassHalf,
    faThumbsUp
} from '@fortawesome/free-solid-svg-icons';
import {Sparkles } from "lucide-react";
import SessionScoreChart from './SessionScoreChart';
import PrepLaunchPod from './PrepLaunchPod';
import MockLaunchPod from './MockLaunchPod';
import { useNavigate } from "react-router-dom";
 
const Analytics = ({analyticsData}) => {
    const location = useLocation();
    const navigate=useNavigate();
    const [activeBot, setActiveBot] = useState(location.state?.activeBot || 'prep');
    const [isPrepLaunchPodOpen, setIsPrepLaunchPodOpen] = useState(false);
    const [isMockLaunchPodOpen, setIsMockLaunchPodOpen] = useState(false);

    const openPrepLaunchPod = () => setIsPrepLaunchPodOpen(true);
    const closePrepLaunchPod = () => setIsPrepLaunchPodOpen(false);

    const openMockLaunchPod=()=> setIsMockLaunchPodOpen(true);
    const closeMockLaunchPod = () => setIsMockLaunchPodOpen(false);

    
    // Safely extract strengths and other data from analyticsData
    const strengths = analyticsData?.strengths || [];
    const weaknesses = analyticsData?.weekness || [];
    const recommendedRoles = analyticsData?.target_roles || [];

    // Calculate remaining sessions
    const prepbotTotal = analyticsData?.prepbot_total || 0;
    const prepbotUsed = analyticsData?.prepbot_used || 0;
    const remainingPBSessions = prepbotTotal - prepbotUsed;
    const remaningPrepSessions = remainingPBSessions<0 ? 0 : remainingPBSessions

    const mockbotTotal = analyticsData?.mockbot_total || 0;
    const mockbotUsed = analyticsData?.mockbot_used || 0;
    const remainingMBSessions = mockbotTotal - mockbotUsed;
    const remaningMockSessions =remainingMBSessions<0 ? 0 : remainingMBSessions

    const averagePrepDuration=analyticsData?.average_prep_duration ||0;
    const averageMockDuration=analyticsData?.average_mock_duration ||0;

    const handleSubscribeClick = (botType) => {
        const tabName = botType === 'mockbot' ? 'Mock Bot' : 'Prep Bot'; // Use the exact tab names
        navigate('/pricing', { state: { activeTabName: tabName } });
      };
 
    return (
        <div className="analytics-container">
            <div className="dashboard-header">
                <h1 className="dashboard-title">Interview Preparation Dashboard</h1>
            </div>
 
            <nav className="dashboard-nav">
                <button
                    className={`nav-tab ${activeBot === 'prep' ? 'active' : ''}`}
                    onClick={() => setActiveBot('prep')}
                >
                    <i><FontAwesomeIcon icon={faClock} className="nav-icon" /></i>
                    Prep Bot
                </button>
 
                <button
                    className={`nav-tab ${activeBot === 'mock' ? 'active' : ''}`}
                    onClick={() => setActiveBot('mock')}
                >
                     <i><FontAwesomeIcon icon={faUser} className="nav-icon" /></i>
                    Mock Bot
                </button>
            </nav>
 
            {activeBot === 'prep' && (
                <div className="dashboard-content">
                    <div className="sessions-overview">
                        <div className="kpi-grid">
                            <div className="kpi-card total">
                                <div className="kpi-icon">
                                    <FontAwesomeIcon icon={faListCheck} />
                                </div>
                                <div className="kpi-content">
                                    <span className="kpi-label">Total Sessions</span>
                                    <div className="kpi-value-wrapper">
                                        <span className="kpi-value">{prepbotTotal}</span>
                                        <span className="kpi-badge">All Time</span>
                                    </div>
                                </div>
                            </div>
 
                            <div className="kpi-card completed">
                                <div className="kpi-icon">
                                    <FontAwesomeIcon icon={faCheckCircle} />
                                </div>
                                <div className="kpi-content">
                                    <span className="kpi-label">Completed Sessions</span>
                                    <div className="kpi-value-wrapper">
                                        <span className="kpi-value">{prepbotUsed}</span>
                                        <div className="progress-mini">
                                            <div className="progress-bar"  style={{
                                                width: prepbotTotal > 0 ? `${(prepbotUsed / prepbotTotal) * 100}%` : '0%'
                                            }}>                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
 
                            <div className="kpi-card remaining">
                                <div className="kpi-icon">
                                    <FontAwesomeIcon icon={faHourglassHalf} />
                                </div>
                                <div className="kpi-content">
                                    <span className="kpi-label">Remaining Sessions</span>
                                    <div className="kpi-value-wrapper">
                                        <span className="kpi-value">{remaningPrepSessions}</span>
                                        <span className="kpi-badge">To Go</span>
                                    </div>
                                </div>
                            </div>
 
                            <div className="kpi-card duration">
                                <div className="kpi-icon">
                                    <FontAwesomeIcon icon={faClock} />
                                </div>
                                <div className="kpi-content">
                                    <span className="kpi-label">Avg Session Duration</span>
                                    <div className="kpi-value-wrapper">
                                        <span className="kpi-value">{averagePrepDuration}</span>
                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {prepbotUsed === 0 && prepbotTotal >0 &&(
                        <div className="mock-interview-prompt">
                            {/* <h2>Complete Your First Interview</h2> */}
                            <div className="mock-session-box">
                                <div className="mock-session-content">
                                    <span className="mock-icon">  <i className="fas fa-rocket launch-icon"></i></span>
                                    <h3>Start Prep Interview Session</h3>
                                    <p>Begin your journey with a practice interview  and receive insightful, personalized feedback.</p>
                                    <button onClick={openPrepLaunchPod} className="mock-session-button">Start Session</button>
                                    <PrepLaunchPod isOpen={isPrepLaunchPodOpen} closeModal={() => setIsPrepLaunchPodOpen(false)} />
                                </div>
                            </div>
                        </div>
                    )}

                    {prepbotUsed === 0 && prepbotTotal ===0 &&(
                        <div className="mock-interview-prompt">
                            {/* <h2>Complete Your First Interview</h2> */}
                            <div className="mock-session-box">
                                <div className="mock-session-content">
                                    <Sparkles color='#BE05FA'/>
                                    <h3>Unlock Your Potential</h3>
                                    <p>Begin your journey with a practice interview  and receive insightful, personalized feedback.</p>
                                    <button onClick={() => handleSubscribeClick('prepbot')} className="mock-session-button">Subscribe for PrepBot</button>
                                </div>
                            </div>
                        </div>
                    )}

                    
                     {prepbotUsed > 0 &&(
                    <div className="chart-section">
                        <div className="score-chart-container">
                            {/* <h3>Interview Performance Scores</h3> */}
                            <div className="score-chart">
                                <SessionScoreChart analyticsData={analyticsData} botType="prepbot"/>
                            </div>
                        </div>

                        <div className="focus-card combined">
                        <div className="focus-header">
                            <FontAwesomeIcon icon={faBullseye} className="focus-icon" />
                            <h3>Focus Areas</h3>
                        </div>
                        <div className='analytics-focus-sections'>
                        <div className="focus-section">
                            <h4>Strengths</h4>
                            <ul className="focus-list">
                            {strengths.map((strength, index) => (
                                <li key={index}>
                                {strength}
                                </li>
                            ))}
                            </ul>
                        </div>

                        <div className="focus-section">
                            <h4>Needs Improvement</h4>
                            <ul className="focus-list">
                            {weaknesses.map((item, index) => (
                                <li key={index}>
                                {item}
                                </li>
                            ))}
                            </ul>
                        </div>
                        </div>
                        </div>

                        <div className="focus-card roles">
                            <div className="focus-header">
                            <FontAwesomeIcon icon={faThumbsUp} className="focus-icon" />
                            <h3>Recommended Roles</h3>
                            </div>
                            <div className='analytics-focus-sections'>
                            <ul className="focus-roles-list">
                                {recommendedRoles.map((role, index) => (
                                    <li key={index}>
                                        {role}
                                    </li>
                                ))}
                            </ul>
                           </div>
                        </div>

                    </div>
                )}
                </div>
            )}
 
            {activeBot === 'mock' && (
                <div className="dashboard-content">
                    <div className="sessions-overview">
                        <div className="kpi-grid">
                            <div className="kpi-card total">
                                <div className="kpi-icon">
                                    <FontAwesomeIcon icon={faListCheck} />
                                </div>
                                <div className="kpi-content">
                                    <span className="kpi-label">Total Sessions</span>
                                    <div className="kpi-value-wrapper">
                                        <span className="kpi-value">{mockbotTotal}</span>
                                        <span className="kpi-badge">All Time</span>
                                    </div>
                                </div>
                            </div>
             
                            <div className="kpi-card completed">
                                <div className="kpi-icon">
                                    <FontAwesomeIcon icon={faCheckCircle} />
                                </div>
                                <div className="kpi-content">
                                    <span className="kpi-label">Completed Sessions</span>
                                        <div className="kpi-value-wrapper">
                                            <span className="kpi-value">{mockbotUsed}</span>
                                            <div className="progress-mini">
                                                <div className="progress-bar"  style={{
                                                    width: mockbotTotal > 0 ? `${(mockbotUsed / mockbotTotal) * 100}%` : '0%'
                                                }}>
                                                </div>
                                            </div>
                                        </div>
                                </div>
                            </div>
             
                            <div className="kpi-card remaining">
                                <div className="kpi-icon">
                                    <FontAwesomeIcon icon={faHourglassHalf} />
                                </div>
                                <div className="kpi-content">
                                    <span className="kpi-label">Remaining Sessions</span>
                                        <div className="kpi-value-wrapper">
                                            <span className="kpi-value">{remaningMockSessions}</span>
                                            <span className="kpi-badge">To Go</span>
                                        </div>
                                </div>
                            </div>
             
                            <div className="kpi-card duration">
                                <div className="kpi-icon">
                                    <FontAwesomeIcon icon={faClock} />
                                </div>
                                <div className="kpi-content">
                                    <span className="kpi-label">Avg Session Duration</span>
                                        <div className="kpi-value-wrapper">
                                            <span className="kpi-value">{averageMockDuration}</span>
                                                   
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {mockbotUsed === 0 && mockbotTotal>0 &&(
                    <div className="mock-interview-prompt">
                        {/* <h2>Complete Your First Interview</h2> */}
                        <div className="mock-session-box">
                            <div className="mock-session-content">
                                <span className="mock-icon">  <i className="fas fa-rocket launch-icon"></i></span>
                                <h3>Start Mock Interview Session</h3>
                                <p>Begin your journey with a mock interview  and receive insightful, personalized feedback.</p>
                                <button className="mock-session-button" onClick={openMockLaunchPod} >Start Session</button>
                                    <MockLaunchPod isOpen={isMockLaunchPodOpen} closeModal={closeMockLaunchPod} />
                            </div>
                        </div>
                    </div>
                    )}

                    {mockbotUsed === 0 && mockbotTotal===0 &&(
                    <div className="mock-interview-prompt">
                        {/* <h2>Complete Your First Interview</h2> */}
                        <div className="mock-session-box">
                            <div className="mock-session-content">
                            <Sparkles color='#BE05FA'/>
                                <h3>Unlock Your Potential</h3>
                                <p>Begin your journey with a mock interview  and receive insightful, personalized feedback.</p>
                                <button className="mock-session-button" onClick={() => handleSubscribeClick('mockbot')} >Subscribe for MockBot</button>
                            </div>
                        </div>
                    </div>
                    )}



                    {mockbotUsed > 0 &&(
                    <div className="chart-section">
                        <div className="score-chart-container">
                            {/* <h3>Interview Performance Scores</h3> */}
                            <div className="score-chart">
                                <SessionScoreChart analyticsData={analyticsData} botType="mockbot" />
                            </div>
                        </div>
            
                        <div className="focus-card combined">
                            <div className="focus-header">
                                <FontAwesomeIcon icon={faBullseye} className="focus-icon" />
                                <h3>Focus Areas</h3>
                            </div>
                            <div className='analytics-focus-sections'>
                                <div className="focus-section">
                                    <h4>Strengths</h4>
                                    <ul className="focus-list">
                                    {strengths.map((strength, index) => (
                                        <li key={index}>
                                        {strength}
                                        </li>
                                    ))}
                                    </ul>
                                </div>
            
                                <div className="focus-section">
                                    <h4>Needs Improvement</h4>
                                    <ul className="focus-list">
                                        {weaknesses.map((item, index) => (
                                            <li key={index}>
                                            {item}
                                            </li>
                                        ))}
                                    </ul>
                                </div>

                            </div>
                        </div>
            
                        <div className="focus-card roles">
                            <div className="focus-header">
                                <FontAwesomeIcon icon={faThumbsUp} className="focus-icon" />
                                <h3>Recommended Roles</h3>
                            </div>
                            <div className='analytics-focus-sections'>
                                <ul className="focus-roles-list">
                                    {recommendedRoles.map((role, index) => (
                                        <li key={index}>
                                            {role}
                                        </li>
                                        ))}
                                </ul>
                            </div>
                        </div>
            
                    </div>
                )}
            </div>
            )}
        </div>
    );
};
export default Analytics;