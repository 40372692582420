import React, { useState, useRef, useEffect } from 'react';
import './ResumeGuru.css';
import config from '../config.json';
import YouTube from 'react-youtube';
import { useAuth } from '../useAuth';
import { useNavigate } from 'react-router-dom';
import LoginModal from './LoginModal';
import { FileUser } from 'lucide-react';
import ResumeIcon from './resumeLogo.jsx';

const ResumeGuru = () => {
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const [isLogin, setIsLogin] = useState(false);
  const [navigateToHolder, setNavigateToHolder] = useState({path:"/landing", props:{}});
  const videoRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const playerRef = useRef(null);
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);
  
  // Minimum swipe distance (in px)
  const minSwipeDistance = 50;

  const cards = [
    {
      title: 'ResumeExpert',
      subtitle: 'Craft your perfect resume in minutes',
      description: 'ResumeExpert leverages AI to craft resumes that stand out from the crowd.',
      buttonText: 'Subscribe',
      time: 0, // time in seconds for this card
    },
    {
      title: 'JDExpert',
      subtitle: 'Create engaging job descriptions',
      description: 'JDExpert uses AI to generate job descriptions that are engaging and relevant to the target audience.',
      buttonText: 'Subscribe',
      time: 40, // time in seconds for this card
    },
    {
      title: 'CareerExpert',
      subtitle: 'Navigate your career path with confidence',
      description: 'CareerExpert provides personalized AI-driven guidance in helping you discover your skills and resources needed to achieve your career goals.',
      buttonText: 'Subscribe',
      time: 80, // time in seconds for this card
    },
  ];

  const nextSlide = () => {
    setActiveIndex((prevIndex) => (prevIndex + 1) % cards.length);
  };

  const prevSlide = () => {
    setActiveIndex((prevIndex) => (prevIndex - 1 + cards.length) % cards.length);
  };

  const handleDotClick = (index) => {
    setActiveIndex(index);
  };

  useEffect(() => {
    if (playerRef.current && cards[activeIndex]) {
      playerRef.current.seekTo(cards[activeIndex].time, true);
    }
  }, [activeIndex]);
 
  const onPlayerReady = (event) => {
    playerRef.current = event.target; // Store the player instance
  };

  const onTouchStart = (e) => {
    setTouchEnd(null);
    setTouchStart(e.targetTouches[0].clientX);
  };

  const onTouchMove = (e) => {
    setTouchEnd(e.targetTouches[0].clientX);
  };

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;
    
    if (isLeftSwipe) {
      nextSlide();
    } else if (isRightSwipe) {
      prevSlide();
    }
  };

  const openModal = (path,props={}) => {
    if(isAuthenticated){
      navigate(path ?? '/landingPage',props);
    }else{
      setNavigateToHolder({path:path, props:props ?? {}}); //saving path to reroute after login
      setIsLogin(true);
    }
  };

  const ResumeGuruPanel = () => (
    <div className="content-panel">
      <h2>
        Build Your Resume with <span>ResumeExpert!</span>
      </h2>
      <p className="description">
      Transform Your Job Applications: Leverage BotGuru's AI-Powered Resume Building Services to Stand Out in a Competitive Job Market!
      </p>
      <div className="features-list">
        {[
          { icon: <ResumeIcon />, title: 'Personalized Templates', desc: 'Choose from modern designs tailored to your style.' },
          { icon: '🔄', title: 'Real-Time Feedback', desc: 'Get AI-powered suggestions to optimize your content.' },
          { icon: '🔑', title: 'Keyword Optimization', desc: 'Enhance visibility with essential keywords for ATS.' },
          { icon: '✏️', title: 'Easy Editing', desc: 'Effortlessly update your resume for different roles.' }
        ].map((feature, index) => (
          <div key={index}>
            <div className="icon">{ feature.icon}</div>
            <div className="text-content">
              <strong>{feature.title}</strong>
              <p>{feature.desc}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );

  const JDGuruPanel = () => (
    <div className="content-panel">
      <h2>
        AI Job Description Generator with <span>JDExpert!</span>
      </h2>
      <p className="description">
      Prepare Effectively: Generate Professional Job Descriptions for Interview Readiness with BotGuru's AI Job Description Generator!
      </p>
      <div className="features-list">
        {[
          { icon: <ResumeIcon />, title: 'Customizable Templates', desc: 'Choose from a variety of professional designs.' },
          { icon: '🔄', title: 'AI-Driven Insights', desc: 'Generate tailored descriptions with relevant skills and responsibilities.' },
          { icon: '🔑', title: 'Keyword Optimization', desc: 'Attract top talent with industry-specific keywords.' },
          { icon: '👥', title: 'Collaboration Tools', desc: 'Easily share and refine job postings with your team.' },
        ].map((feature, index) => (
          <div key={index}>
            <div className="icon">{feature.icon}</div>
            <div className="text-content">
              <strong>{feature.title}</strong>
              <p>{feature.desc}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );

  const CareerGuruPanel = () => (
    <div className="content-panel">
      <h2>
        Career Path Design with <span>CareerExpert!</span>
      </h2>
      <p className="description">
         Empower Your Future: Navigate Your Career Journey with Tailored Insights from CareerExpert!
      </p>
      <div className="features-list" key={`features-career`}>
        {[
          { icon: '🎯', title: 'Personalized AI Guidance', desc: 'Get tailored advice based solely on your interests and career aspirations.' },
          { icon: '📊', title: 'Skill Assessment Tools', desc: 'Identify essential skills needed for your desired profession through AI analysis.' },
          { icon: '🗺️', title: 'Dynamic Roadmaps', desc: 'Receive AI-generated step-by-step plans for achieving your career objectives.' },
          { icon: '💬', title: 'Interactive Q&A', desc: 'Pose specific career questions and receive immediate AI-generated responses.' },
          
        ].map((feature, index) => (
          <div key={index}>
            <div className="icon">{feature.icon}</div>
            <div className="text-content">
              <strong>{feature.title}</strong>
              <p>{feature.desc}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <div className="resume-guru">
      {isLogin && <LoginModal isLogin={isLogin} closeModal={() => setIsLogin(false)} nextPath={navigateToHolder}/>}
      
      <div 
        className="fancy-carousel"
        onTouchStart={onTouchStart}
        onTouchMove={onTouchMove}
        onTouchEnd={onTouchEnd}
      >
        <button className="carousel-button prev" onClick={prevSlide}>
          &lt;
        </button>
        <button className="carousel-button next" onClick={nextSlide}>
          &gt;
        </button>

        <div className="carousel-dots">
          <div
            className="carousel-dot"
            onClick={prevSlide}
          />
          <div
            className={`carousel-dot ${
              activeIndex === 0 ? 'active-first' : 
              activeIndex === 1 ? 'active-middle' : 
              'active-last'
            }`}
          />
          <div
            className="carousel-dot"
            onClick={nextSlide}
          />
        </div>

        {cards.map((card, index) => {
          const position =
            index === activeIndex
              ? 'fancy-carousel__item--active'
              : index === (activeIndex + 1) % cards.length
              ? 'fancy-carousel__item--next'
              : 'fancy-carousel__item--prev';
          return (
            <div
              key={index}
              className={`fancy-carousel__item card${index} ${position}`}
            >
              <h1>{card.title}</h1>
              <img src={`card${index + 4}logo.webp`} alt="Logo" />
              <h4>{card.subtitle}</h4>
              <p>{card.description}</p>
              
              <button 
                className="carousel-subscribe-btn"
                onClick={() => openModal('/landingPage')}
              >
                Get Started
              </button>
            </div>
          );
        })}
      </div>
      <div className="fancy-carousel-right-panel">
        {activeIndex === 0 && <ResumeGuruPanel />}
        {activeIndex === 1 && <JDGuruPanel />}
        {activeIndex === 2 && <CareerGuruPanel />}
      </div>
    </div>
  );
};

export default ResumeGuru;
