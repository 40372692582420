import React,{useState, useMemo} from 'react';
import Modal from 'react-modal';
import './JDDetailsModal.css';
import jsPDF from 'jspdf';
import JDEditableModal from './JDEditableModal';

const JDDetailsModal = ({ isOpen, closeModal, jdId, jdData,showEditButton = true,refreshJDList }) => {
    const [showEditModal, setShowEditModal] = useState(false);
    const selectedJD = useMemo(() => {
        if (!jdData || !Array.isArray(jdData) || !jdId) {
            return null;
        }
        return jdData.find(jd => jd._id === jdId) || null;
    }, [jdData, jdId]);
    
    // Add early return if no data
    if (!selectedJD) {
        return null;
    }

    const handleEditModalClose = async () => {
        console.log("Closing edit modal");
        setShowEditModal(false);
        // Instead of closing the details modal, refresh the data and reopen details
        if (refreshJDList) {
            await refreshJDList();
            // This will ensure the details modal shows updated data
            closeModal();
        }
    };

    const handleEditClick = () => {
        console.log("Edit clicked for JD:", selectedJD);
        setShowEditModal(true);
    };


    const downloadPDF = () => {
        if (!selectedJD) return; // Exit if no data
        const pdf = new jsPDF();

        const cleanText = (text) => {
            if (!text) return '';
            // Remove numbers followed by dot/bracket at start of line
            return text.replace(/^\d+[\.)]\s*/gm, '');
        };
        
        // Set up PDF styling
        pdf.setTextColor(0, 0, 0); // Black text
        pdf.setFillColor(255, 255, 255); // White background
        
        const toString = (value) => {
            if (value === null || value === undefined) return 'Not Specified';
            return String(value);
        };

        // Company Name - Centered, Bold, Underlined
        pdf.setFontSize(18); // Increased font size for company name
        pdf.setFont(undefined, 'bold');
        const companyName = selectedJD.company;
        const companyNameWidth = pdf.getStringUnitWidth(companyName) * pdf.internal.getFontSize() / pdf.internal.scaleFactor;
        const centerX = (pdf.internal.pageSize.width - companyNameWidth) / 2;
        pdf.text(companyName, centerX, 20);
        pdf.setFont(undefined, 'normal');
        pdf.setLineWidth(0.5);
        pdf.line(centerX, 22, centerX + companyNameWidth, 22); // Underline
        pdf.setFontSize(12);
        
        // Date - Right Aligned
        const currentDate = new Date().toLocaleDateString(); // Get current date
        pdf.text(`Date: ${currentDate}`, pdf.internal.pageSize.width - 20, 30, { align: 'right' }); // Print date at the top right

        // // Job Role - Left Aligned
        // pdf.setFont(undefined, 'bold');
        // pdf.text(`Job Role: ${selectedJD["job_role"]}`, 20, 40);
        // pdf.setFont(undefined, 'normal');
        // pdf.text('', 20, 45); // Add space after Job Role

        // Job Details
        pdf.setFontSize(12);
        const titleX = 20; // X position for titles
        const valueX = 70; // X position for values (adjust as needed)
        
        pdf.setFont(undefined, 'bold');
        pdf.setFontSize(12);
        pdf.text('Job Role:', titleX, 40);
        pdf.setFont(undefined, 'normal');
        pdf.setFontSize(10);
        pdf.text(` ${selectedJD["job_role"]}`, valueX, 40);
        pdf.text('', 20, 50); // Add space after JoB Role

        pdf.setFont(undefined, 'bold');
        pdf.setFontSize(12);
        pdf.text('Location:', titleX, 50);
        pdf.setFont(undefined, 'normal');
        pdf.setFontSize(10);
        pdf.text(`${selectedJD.location || '-'}`, valueX, 50);
        pdf.text('', 20, 50); // Add space after Location

        pdf.setFont(undefined, 'bold');
        pdf.setFontSize(12);
        pdf.text('Experience:', titleX, 60);
        pdf.setFont(undefined, 'normal');
        pdf.setFontSize(10);
        pdf.text(`${selectedJD.experience || '-'}`, valueX, 60);
        pdf.text('', 20, 65); // Add space after Experience

        pdf.setFont(undefined, 'bold');
        pdf.setFontSize(12);
        pdf.text('Salary:', titleX, 70);
        pdf.setFont(undefined, 'normal');
        pdf.setFontSize(10);
        pdf.text(`${selectedJD.salary || '-'}`, valueX, 70);
        pdf.text('', 20, 75); // Add space after Salary

        pdf.setFont(undefined, 'bold');
        pdf.setFontSize(12);
        pdf.text('Job Type:', titleX, 80);
        pdf.setFont(undefined, 'normal');
        pdf.setFontSize(10);
        pdf.text(`${selectedJD.job_type || '-'}`, valueX, 80);
        pdf.text('', 20, 85); // Add space after Job Type

        pdf.setFont(undefined, 'bold');
        pdf.setFontSize(12);
        pdf.text('JD ID:', titleX, 90);
        pdf.setFont(undefined, 'normal');
        pdf.setFontSize(10);
        pdf.text(`${selectedJD.jd_id}`, valueX, 90);
        pdf.text('', 20, 95); // Add space after JD ID

        // Add more space before About Us heading
        pdf.text('', 20, 105); // Additional space before About Us

        // Job Description Sections
        let yPos = 110; // Starting position for the content
        const pageHeight = pdf.internal.pageSize.height; // Get the height of the page

        // Function to check if we need to add a new page
        const checkPageBreak = () => {
            if (yPos >= pageHeight - 20) { // Leave some space for footer
                pdf.addPage();
                yPos = 10; // Reset yPos for new page
            }
        };

        // Helper function for adding sections
        const addSection = (title, content) => {
            if (content) {
                checkPageBreak();
                pdf.setFontSize(14);
                pdf.setFont(undefined, 'bold');
                pdf.setFontSize(12);
                pdf.text(title, 20, yPos);
                pdf.setFont(undefined, 'normal');
                pdf.setFontSize(10);
                yPos += 10;

                const lines = pdf.splitTextToSize(toString(content), 170);
                lines.forEach(line => {
                    checkPageBreak();
                    pdf.text(line, 20, yPos);
                    yPos += 6;
                });
                yPos += 10;
            }
        };

        // Add job description sections
        if (selectedJD.job_description) {
            addSection('About Us:', selectedJD.job_description.about_us);
            addSection('Job Overview:', selectedJD.job_description.job_overview);
            
            // Key Responsibilities with bullet points
            // Key Responsibilities
        if (selectedJD.job_description.key_responsibilities) {
        checkPageBreak();
        pdf.setFontSize(12);
        pdf.setFont(undefined, 'bold');
        pdf.text('Key Responsibilities:', 20, yPos);
        pdf.setFont(undefined, 'normal');
        pdf.setFontSize(10);
        yPos += 10;

        const responsibilities = cleanText(selectedJD.job_description.key_responsibilities).split('\n');
        responsibilities.forEach(resp => {
            if (resp.trim()) {
                const lines = pdf.splitTextToSize(resp.trim(), 160);
                lines.forEach((line, index) => {
                    checkPageBreak();
                    pdf.text(line, 25, yPos);
                    yPos += 6;
                });
            }
        });
        yPos += 10;
        }

        // Qualifications
        if (selectedJD.job_description.required_qualification_experience) {
        checkPageBreak();
        pdf.setFontSize(12);
        pdf.setFont(undefined, 'bold');
        pdf.text('Required Qualifications/Experience:', 20, yPos);
        pdf.setFont(undefined, 'normal');
        pdf.setFontSize(10);
        yPos += 10;

        const qualifications = cleanText(selectedJD.job_description.required_qualification_experience).split('\n');
        qualifications.forEach(qual => {
            if (qual.trim()) {
                const lines = pdf.splitTextToSize(qual.trim(), 160);
                lines.forEach((line, index) => {
                    checkPageBreak();
                    pdf.text(line, 25, yPos);
                    yPos += 6;
                });
            }
        });
        yPos += 10;
        }

        // Preferred Skills
        if (selectedJD.job_description.preferred_skills) {
        checkPageBreak();
        pdf.setFontSize(12);
        pdf.setFont(undefined, 'bold');
        pdf.text('Preferred Skills:', 20, yPos);
        pdf.setFont(undefined, 'normal');
        pdf.setFontSize(10);
        yPos += 10;

        const skills = cleanText(selectedJD.job_description.preferred_skills).split('\n');
        skills.forEach(skill => {
            if (skill.trim()) {
                const lines = pdf.splitTextToSize(skill.trim(), 160);
                lines.forEach((line, index) => {
                    checkPageBreak();
                    pdf.text(line, 25, yPos);
                    yPos += 6;
                });
            }
        });
        }

        }

        // Footer
        pdf.setFontSize(10);
        pdf.setTextColor(128, 128, 128);
        pdf.text('Generated by BotGuru', 20, pdf.internal.pageSize.height - 10);
        
        // Save the PDF
        pdf.save(`JD_${selectedJD.job_role.replace(/\s+/g, '_')}.pdf`);
    };

    // Add this function at the top of your component
const formatDateTime = (utcTimestamp) => {
    if (!utcTimestamp) return { date: 'Not Available', time: 'Not Available' };

    try {
        // Create a date object from UTC timestamp and convert to local timezone
        const utcDate = new Date(utcTimestamp);
        
        // Get local timezone offset in minutes
        const timezoneOffset = utcDate.getTimezoneOffset();
        
        // Convert UTC to local time by subtracting the offset
        const localDate = new Date(utcDate.getTime() - (timezoneOffset * 60000));
        
        // Format date in local timezone
        const formattedDate = localDate.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
        });

        // Format time in local timezone
        const formattedTime = localDate.toLocaleTimeString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
        });

        // Get timezone name
        const timezoneName = Intl.DateTimeFormat().resolvedOptions().timeZone;
        return {
            date: formattedDate,
            time: formattedTime,
            timezone: timezoneName
        };
    } catch (error) {
        console.error("Error formatting date:", error);
        return { 
            date: 'Invalid Date', 
            time: 'Invalid Time',
            timezone: 'Unknown'
        };
    }
};

    // Add this helper function at the top of your component
    const cleanText = (text) => {
        if (!text) return '';
        return text
            .split('\n')
            .map(line => 
                line
                    .replace(/^[-–—•]\s*/g, '') // Remove any type of dash or bullet at start
                    .trim()
            )
            .filter(line => line) // Remove empty lines
            .join('\n');
    };



    return (
        <>
        <Modal
            isOpen={isOpen && !showEditModal}
            onRequestClose={closeModal}
            className="jdguru-modal"
            overlayClassName="jdguru-modal-overlay"
            ariaHideApp={false}
        >
            {selectedJD && (
                <div className="jdguru-modal-content">
                    {/* Updated Header with icons */}
                    <div className="jdguru-modal-header">
                        <div className="jdguru-header-content">
                            <div className="jdguru-role-container">
                                <i className="fas fa-laptop-code"></i>
                                <h2 className="jdguru-title">{selectedJD["job_role"]}</h2>
                            </div>
                            <div className="jdguru-company-container">
                                <i className="fas fa-building"></i>
                                <div className="jdguru-company">{selectedJD.company}</div>
                            </div>
                        </div>
                        <button className="jdguru-close-btn" onClick={closeModal}>
                            <i className="fas fa-times"></i>
                        </button>
                    </div>

                    <div className="jdguru-modal-body">
                        {/* Info Grid */}
                        <div className="jdguru-info-grid">
                            <div className="jdguru-info-item">
                                <i className="fas fa-briefcase"></i>
                                <div>
                                    <label>Job Type</label>
                                    <span>{selectedJD.job_type || 'Full Time'}</span>
                                </div>
                            </div>
                            <div className="jdguru-info-item">
                                <i className="fas fa-calendar"></i>
                                <div>
                                    <label>Created Date</label>
                                    <span>{formatDateTime(selectedJD.created_at).date}</span>
                                </div>
                            </div>
                            <div className="jdguru-info-item">
                                <i className="fas fa-clock"></i>
                                <div>
                                    <label>Created Time</label>
                                    <span>{formatDateTime(selectedJD.created_at).time}</span>
                                </div>
                            </div>
                            <div className="jdguru-info-item">
                                <i className="fas fa-id-badge"></i>
                                <div>
                                    <label>JD ID</label>
                                    <span>{selectedJD.jd_id}</span>
                                </div>
                            </div>
                        </div>

                        {/* Additional Details Grid */}
                        <div className="jdguru-details-grid">
                            <div className="jdguru-detail-item">
                                <i className="fas fa-map-marker-alt"></i>
                                <div>
                                    <label>Location</label>
                                    <span>{selectedJD.location || '-'}</span>
                                </div>
                            </div>
                            <div className="jdguru-detail-item">
                                <i className="fas fa-user-clock"></i>
                                <div>
                                    <label>Experience</label>
                                    <span>{selectedJD.experience || '-'}</span>
                                    </div>
                            </div>
                            <div className="jdguru-detail-item">
                                <i className="fas fa-money-bill-wave"></i>
                                <div>
                                    <label>Salary Range</label>
                                    <span>{selectedJD.salary || '-'}</span>
                                </div>
                            </div>
                        </div>

                        {/* Job Description Section */}
                        <div className="jdguru-description-section">
                            <h3 className="jdguru-main-heading">
                                <i className="fas fa-file-alt"></i>
                                Job Description
                            </h3>
                            
                            {/* About Us */}
                            {selectedJD.job_description?.about_us && (
                            <div className="jdguru-subsection">
                                <h4 className="jdguru-subheading">About Us</h4>
                                <div className="jdguru-content">
                                    {selectedJD.job_description.about_us}
                                </div>
                                </div>
                            )}
                            
                            {/* Job Overview */}
                            {selectedJD.job_description?.job_overview && (
                            <div className="jdguru-subsection">
                                <h4 className="jdguru-subheading">Job Overview</h4>
                                <div className="jdguru-content">
                                {selectedJD.job_description.job_overview}
                                </div>
                                </div>
                            )}
    
                                                        {/* Key Responsibilities */}
                            {selectedJD.job_description?.key_responsibilities && (
                                <div className="jdguru-subsection">
                                    <h4 className="jdguru-subheading">Key Responsibilities:</h4>
                                    <div className="jdguru-content">
                                        {cleanText(selectedJD.job_description.key_responsibilities)
                                            .split('\n')
                                            .map((item, index) => (
                                                <div key={index} className="jdguru-list-item">
                                                    <span className="bullet">•</span>
                                                    <span className="list-text">{item.trim()}</span>
                                                </div>
                                            ))}
                                    </div>
                                </div>
                            )}

                            {/* Qualifications */}
                            {selectedJD.job_description?.required_qualification_experience && (
                                <div className="jdguru-subsection">
                                    <h4 className="jdguru-subheading">Qualifications/Experience:</h4>
                                    <div className="jdguru-content">
                                        {cleanText(selectedJD.job_description.required_qualification_experience)
                                            .split('\n')
                                            .map((item, index) => (
                                                <div key={index} className="jdguru-list-item">
                                                    <span className="bullet">•</span>
                                                    <span className="list-text">{item.trim()}</span>
                                                </div>
                                            ))}
                                    </div>
                                </div>
                            )}

                            {/* Preferred Skills */}
                            {selectedJD.job_description?.preferred_skills && (
                                <div className="jdguru-subsection">
                                    <h4 className="jdguru-subheading">Preferred Skills</h4>
                                    <div className="jdguru-content">
                                        {cleanText(selectedJD.job_description.preferred_skills)
                                            .split('\n')
                                            .map((item, index) => (
                                                <div key={index} className="jdguru-list-item">
                                                    <span className="bullet">•</span>
                                                    <span className="list-text">{item.trim()}</span>
                                                </div>
                                            ))}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                    {/* Footer */}
                    <div className="jdguru-modal-footer">
                    {showEditButton && (  // Use the prop here
                                <button 
                                    className="jdguru-btn jdguru-btn-secondary" 
                                    onClick={handleEditClick}
                                >
                                    <i className="fas fa-edit"></i>
                                    Edit JD
                                </button>
                            )}
                        <button className="jdguru-btn jdguru-btn-primary" onClick={downloadPDF}>
                            <i className="fas fa-download"></i>
                            Download JD
                        </button>
                    </div>
                </div>
            )}
        </Modal>
        
            {/* Edit Modal */}
            {showEditModal && selectedJD && (
                <JDEditableModal
                    isOpen={showEditModal}
                    closeModal={handleEditModalClose}
                    refreshJDList={refreshJDList}
                    jdId={jdId}
                    jdData={[selectedJD]}
                />
            )}
        </>
    );
};

export default JDDetailsModal;