import React, { useState, useEffect } from "react";
import './PrepGuruPage.css';
import CompletedDetailsModal from './CompletedDetailsModal';
import MockLaunchPod from "./MockLaunchPod";
import JdDetailsModal from './JDDetailsModal';
import LoadTransitionPage from "./loadingpage/LoadTransitionPage";
import ResumePreview from './ResumePreview';
import { useAuthContext } from "../Auth/AuthContext";
import Tour from 'reactour'; // Import the Tour component
import { Info, MoveRight, Sparkles, Trophy, Zap } from "lucide-react";
import { useNavigate } from "react-router-dom";
import config from "../../config.json";


const MockGuruPage = () => {
    const { userObjId, newUser , profileData, userAnalytics, setUserAnalytics} = useAuthContext(); // Get userObjId and newUser from context
    const [sessions, setSessions] = useState([]);
    const [isModalOpen, setModalOpen] = useState(false);
    const [selectedCard, setSelectedCard] = useState(null);
    const [launchPod, setLaunchPod] = useState(false);
    const [isJdModalOpen, setJdModalOpen] = useState(false);
    const [selectedJdId, setSelectedJdId] = useState(null);
    const [jdData, setJdData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [sessionUrl, setSessionUrl] = useState(null);
    const [isIframeOpen, setIframeOpen] = useState(false);
    const [selectedResume, setSelectedResume] = useState(null);
    const [resumes, setResumes] = useState([]);
    const [showPreview, setShowPreview] = useState(false);
    const [isTourOpen, setIsTourOpen] = useState(false); // State to control the tour
    const [showTourBtn, setShowTourBtn]=useState(false);
    const [currentStep, setCurrentStep] = useState(0); // State to track the current step
    const [reigniteSessionId, setReigniteSessionId] = useState(null);

    const navigate=useNavigate();


    const tourSteps = [
        {
            selector: '.redo-button.create-session-button',
            content: 'Click on "Create New Session" to create a new mock session and start practicing!',
        },
    ];
    
        // Fetch data from the API
        const fetchSessions = async () => {
            try {
                const response = await fetch(`${config.restAPI}/getSessions/`, {
                    method: "POST",
                    credentials: 'include',
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        user_obj_id: userObjId,
                        session_type: "mockbot",
                    }),
                });
                const data = await response.json();
                console.log(data);
                const sortedSessions = data.sessions.sort((a, b) => {
                    const aDate = new Date(a.created_on);
                    const bDate = new Date(b.created_on);
                    return bDate - aDate; // Latest first
                });
                
                setSessions(sortedSessions || []);
                // setSessions(data.sessions || []);
            } catch (error) {
                console.error("Error fetching sessions:", error);
            }
        };

       // Fetch JD data
       const fetchJdData = async () => {
        try {
            const response = await fetch(`${config.restAPI}/getJobs/`, {
                method: "POST",
                credentials: 'include',
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    user_obj_id: userObjId,
                }),
            });

            if (!response.ok) {
                throw new Error('Failed to fetch JD data');
            }

            const data = await response.json();
            console.log("Fetched JD data:", data); 
            // Debug log
            setJdData(data.job_descriptions || []);
        } catch (error) {
            console.error("Error fetching JD data:", error);
        }
    };

    const fetchResumes = async () => {
        try {
            const response = await fetch(`${config.restAPI}/getResumes/`, {
                method: "POST",
                credentials: 'include',
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    user_obj_id: userObjId,
                }),
            });
    
            if (!response.ok) {
                throw new Error(`Failed to fetch resumes. Status: ${response.status}`);
            }
    
            const data = await response.json();
            console.log("Fetched Resumes:", data.resumes);      
            setResumes(data.resumes);
        } catch (error) {
            console.error("Error fetching resumes:", error);
        }
    };

    // Fetch both sessions and JD data on component mount
    useEffect(() => {
        const fetchAllData = async () => {
            try {
                await Promise.all([
                    fetchSessions(),
                    fetchJdData(),
                    fetchResumes()
                ]);
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchAllData();
    }, []);

    useEffect(() => {
        if (newUser === 'Y') {
            console.log('New user detected, showing initial tour guide');
            // setIsTourOpen(true);
            setShowTourBtn(true);
        } else {
            console.log('Tour guide disabled because:', {
                newUser
            });
            setIsTourOpen(false);
        }
    }, [newUser]);

    const openModal = (card) => {
        setSelectedCard(card);
        setModalOpen(true);
    };

    const openJdModal = (jdId) => {
        console.log("Opening JD modal with ID:", jdId);
        setSelectedJdId(jdId);
        setJdModalOpen(true);
    };

    const closeModal = () => setModalOpen(false);
    const closeLaunchModal = () => setLaunchPod(false);

    const closeIframe = () => {
        setIframeOpen(false);
        setSessionUrl(null);
    };

    const handleViewResume = (resumeId) => {
        const resumeData = resumes.find(resume => resume._id === resumeId);

        if (!resumeData) {
            console.error('Resume not found');
            return;
        }
        const formattedResume = {
            message: {
                first_name: resumeData.resume.personalDetails.firstName,
                last_name: resumeData.resume.personalDetails.lastName,
                email: resumeData.resume.personalDetails.email,
                phone_number: resumeData.resume.personalDetails.phoneNumber,
                linkedin_url: resumeData.resume.personalDetails.linkedIn,
                website_url: resumeData.resume.personalDetails.website,
                professional_summary: resumeData.resume.personalDetails.summary,
                skills: resumeData.resume.skills,
                experience: resumeData.resume.experiences.map(exp => ({
                    job_role: exp.jobTitle,
                    company_name: exp.companyName,
                    start_date: exp.startDate,
                    end_date: exp.endDate,
                    responsibilities: exp.description ? exp.description.split('\n') : [],
                    city_state: exp.location,
                    country: exp.country,
                    skills: exp.skills || [],
                    domain: exp.domain
                })),
                education: resumeData.resume.education.map(edu => ({
                    school_university: edu.school,
                    degree: edu.degree,
                    start_date: edu.startDate,
                    end_date: edu.endDate,
                    gpa: edu.gpa,
                    city_state: edu.city_state,
                    country: edu.country,
                    additionalDetails: edu.additionalDetails
                }))
            }
        };
        setSelectedResume(formattedResume);
        setShowPreview(true);
    };

    const handleRedoClick = (session) => {
        const latestSession = session.sessions[0];
        if (!latestSession) {
            alert("No session data found.");
            return;
        }

        setIsLoading(true);
        setReigniteSessionId(session._id);
        const websocketUrl = "wss://o834xau6qe.execute-api.us-west-1.amazonaws.com/production/";
        const websocket = new WebSocket(websocketUrl);

        websocket.onopen = () => {
            console.log("WebSocket connection established.");
            const message = { action: "botguru_sendmessage", message: "get data" };
            websocket.send(JSON.stringify(message));
        };

        websocket.onmessage = async (event) => {
            if (!event.data || event.data.trim() === "") {
                console.warn("Received empty WebSocket message.");
                return;
            }

            const data = JSON.parse(event.data);

            if (data.connectionid) {
                console.log("Connection ID received:", data.connectionid);

                const selectedData = {
                    user_id: userObjId,
                    user_obj_id: userObjId,
                    userfirstname: profileData?.name,
                    user_name: profileData?.name,
                    local_time: new Date().toLocaleString(),
                    user_email: session.email,
                    session_id: session._id,
                    resume_obj_id: session.resume_obj_id,
                    job_role: session.job_role,
                    job_description: session.job_description,
                    resumeId: session.resume_id,
                    persona: session.persona || "f",
                    interviewType: "technical",
                    connectionId: data.connectionid,
                    region: "us-west-1",
                    bot_type: "mockbot",
                };
                console.log(selectedData);

                try {
                    const response = await fetch(`${config.restAPI}/reigniteSession/`, {
                        method: "POST",
                        credentials: 'include',
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify(selectedData),
                    });

                    if (!response.ok) {
                        throw new Error(`Failed to send data. Status: ${response.status}`);
                    }

                    console.log("Data sent successfully. Waiting for URL...");
                } catch (error) {
                    console.error("Error sending data to API:", error.message);
                    setIsLoading(false);
                }
            } else if (data.url) {
                console.log("Session URL received:", data.url);
                setSessionUrl(data.url);
                setIframeOpen(true);
                setIsLoading(false);
            } else {
                console.warn("No connection ID or URL received in WebSocket message.");
                setIsLoading(false);
            }
        };

        websocket.onerror = (error) => {
            console.error("WebSocket error:", error);
            setIsLoading(false);
        };

        websocket.onclose = () => {
            console.log("WebSocket connection closed.");
        };
    };

    const truncateJobRole = (jobRole) => {
        const maxLength = 18;
        const words = jobRole.split(' ');
    
        if (jobRole.length > maxLength || words.length > 2) {
            return `${jobRole.substring(0, maxLength - 3)}...`; // Truncate and add ellipsis
        }
        return jobRole; // Return original if within limits
    };

    const handleNext = () => {
        setCurrentStep((prev) => Math.min(prev + 1, tourSteps.length - 1));
    };

    const handlePrev = () => {
        setCurrentStep((prev) => Math.max(prev - 1, 0));
    };

    const handleElementClick = (e, normalFunction) => {
        if (isTourOpen) {
            if (e) e.preventDefault();
            handleNext();
        } else {
            normalFunction();
        }
    };

    const handleNewSessionClick = (e) => {
        handleElementClick(e, () => {
            setLaunchPod(true);
            if (newUser === 'Y') {
                setIsTourOpen(false); // Only close tour if it was open (new user)
            }
        });
    };

    const renderNewPrepCard = () => {
        var hasSessions=userAnalytics?.mockbot_total>userAnalytics?.mockbot_used;
        return (
            <>
            {
                hasSessions ? (
                        <div className="session-card new-session-card" onClick={handleNewSessionClick}>
                            <div className="session-card-header">
                                <div className="role-icon">
                                    <i className="fas fa-plus"></i>
                                </div>
                                <div className="role-info">
                                    <h3>New Mock Session</h3>
                                    <span>Start practicing</span>
                                </div>
                            </div>
                            
                            <div className="new-session-content">
                                <div className="new-session-circle">
                                    <i className="fas fa-plus-circle"></i>
                                </div>
                            </div>

                            <button 
                                className="redo-button create-session-button" 
                                onClick={(e) => handleNewSessionClick(e)}
                            >
                                Create New Session
                            </button>
                        </div>
                ) : (
                    <div className="session-card invalid-card new-session-card">
                    <div className="prep-session-card-header">New Mock session</div>
                    <div className="prep-session-card-logo">
                        <div className="sub-container">
                            <Sparkles />
                            <p>Unlock Your Potential</p>
                        </div>
                    </div>
                    <div className="prep-session-card-features">
                        <p className="prep-session-card-feature">
                            <Zap className="prep-session-card-icon"/>
                            Realistic Interviews</p>
                        <p className="prep-session-card-feature">
                            <Trophy className="prep-session-card-icon"/>
                            Personalized feedbacks</p>
                    </div>
                    <button 
                    className="create-session-button" 
                    onClick={() => navigate("/pricing", { state: { activeTabName: 'Mock Bot' } }) }
                >
                    Unlock Premium
                    <MoveRight />
                </button>
                </div>

                )
            }
            </>
        )
    };

    
    const renderCard = (session) => {
        if (!session.sessions[0].duration || session.sessions[0].duration == 0) {
            return null; 
        }    
        const latestSession = session.sessions[0] || {};
        const score = latestSession.score || 0;
        const circumference = 2 * Math.PI * 54;
        
        const achievedDash = (session.latest_score / 100) * circumference;
        const remainingDash = ((100 - session.latest_score) / 100) * circumference;

        const improvementScore = session.improvement_score || 0;
        const improvementClass = improvementScore < 0 ? 'improvement-negative' : 'improvement-positive';
        
        var hasSessions = userAnalytics?.mockbot_total > userAnalytics?.mockbot_used;

        return (
            <div 
                className="session-card" 
                key={session._id}
                onClick={() => openModal(session)}
            >
                <div className="session-card-header">
                    <div className="role-icon">
                        <i className="fas fa-briefcase"></i>
                    </div>
                    <div className="role-info">
                    <h3>{truncateJobRole(session.job_role)}</h3>
                        <span>{session.sessions.filter(session => session.duration !== undefined && session.duration !== null).length} Sessions Completed</span>
                    </div>
                </div>
                
                <div className="donut-wrapper">
                    <div className="donut-chart">
                        <svg width="120" height="120" viewBox="0 0 120 120">
                            {/* Base circle */}
                            <circle
                                cx="60"
                                cy="60"
                                r="54"
                                fill="none"
                                stroke="#2A2A3C"
                                strokeWidth="8"
                            />
                            {/* Remaining score */}
                            <circle
                                cx="60"
                                cy="60"
                                r="54"
                                fill="none"
                                stroke="rgba(190, 5, 250, 0.1)"
                                strokeWidth="8"
                                strokeDasharray={`${remainingDash} ${circumference}`}
                                strokeDashoffset={-achievedDash}
                                transform="rotate(-90 60 60)"
                                className="remaining-score"
                            />
                            {/* Achieved score */}
                            <circle
                                cx="60"
                                cy="60"
                                r="54"
                                fill="none"
                                stroke="#BE05FA"
                                strokeWidth="8"
                                strokeDasharray={`${achievedDash} ${circumference}`}
                                strokeDashoffset="0"
                                transform="rotate(-90 60 60)"
                                className="achieved-score"
                            />
                            <text
                                x="60"
                                y="55"
                                textAnchor="middle"
                                dominantBaseline="middle"
                                className="score-text"
                                fill="white"
                                fontSize="28"
                                fontWeight="bold"
                            >
                                {session.latest_score || "0"}%
                            </text>
                            <text
                                x="60"
                                y="75"
                                textAnchor="middle"
                                dominantBaseline="middle"
                                className="score-label"
                                fill="#7B7B8F"
                                fontSize="12"
                            >
                                Latest Score
                            </text>
                        </svg>
                    </div>
                </div>

                {latestSession.improvement && (
                    <div className={`improvement ${improvementClass}`}>
                        {improvementScore > 0 ? `+${improvementScore}` : improvementScore} Improvement
                    </div>
                )}
 
                {session.total_sessions >= 2 ? (
                    <div className={`improvement-indicator ${improvementScore < 0 ? 'improvement-negative' : 'improvement-positive'}`}>
                        <span>{improvementScore > 0 ? `+${improvementScore}` : improvementScore}% Improvement</span>
                    </div>
                ) : (
                    <div className="empty-indicator">
                        
                    </div>
                )}
                <div className="tooltip">Click here to open session details</div>


                <div className="card-actions">
                    <button className="action-link" onClick={(e) => {
                            e.stopPropagation(); // Prevent modal from opening
                            openJdModal(session.job_obj_id);
                        }}>
                        <i className="far fa-file-text"></i> View Job Description
                        <i className="fas fa-external-link-alt link-icon"></i>
                    </button>
                    <button className="action-link" onClick={(e) => {
                            e.stopPropagation(); 
                            handleViewResume(session.resume_obj_id);
                        }}>
                        <i className="far fa-file-alt"></i> View Resume
                        <i className="fas fa-external-link-alt link-icon"></i>
                    </button>
                </div>

                <button 
                    className="redo-button" 
                    disabled={!hasSessions}
                    onClick={(e) =>  {
                        e.stopPropagation();
                         handleRedoClick(session);
                        }}
                >
                    Reignite
                </button>
            </div>
        );
    };

    return (
        <>
         {isLoading && <LoadTransitionPage   url={`https://botguru.ai:5174?session_id=${reigniteSessionId || ''}`}  setIsLoading={setIsLoading} />}
            <div className="mock-guru-page" style={{position:"relative"}}>
                <div className="hero-section">
                    <div className="hero-content">
                        <div className="hero-left">
                            <h1 className="hero-title">
                                Your Personalized Interview Coach
                            </h1>
                            <div className="hero-description">
                            <span>Practice for success with real-time interview simulations! Upload your job description and resume to customize your practice sessions.
                                After each practice, receive a detailed report with actionable insights. Ace your interview with confidence using MockBot!</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mock-guru-status-tabs" style={{position:"relative"}}>
                    <div className="card-container">
                    {[renderNewPrepCard(), ...sessions.map((session) => renderCard(session))]}
                    </div>
                {showTourBtn && <div className="show-tour-btn" onClick={()=>setIsTourOpen(true)}>
                    <Info />
                    <span>View Tour</span>
                </div>}
                </div>
            </div>
            <CompletedDetailsModal isOpen={isModalOpen} closeModal={closeModal} cardData={selectedCard} />
            <MockLaunchPod isOpen={launchPod} closeModal={closeLaunchModal} />
            <JdDetailsModal 
                isOpen={isJdModalOpen}
                closeModal={() => setJdModalOpen(false)}
                jdId={selectedJdId}
                jdData={jdData}
            />
            {showPreview && (
                <ResumePreview 
                    resumeData={selectedResume}
                    closeModal={() => {
                        setShowPreview(false);
                        setSelectedResume(null);
                    }}
                />
            )}
            
            {isIframeOpen && sessionUrl && (
                <div className="iframe-overlay">
                    <iframe
                        src={sessionUrl}
                        className="session-iframe"
                        allow="camera; microphone; fullscreen"
                        title="Session Iframe"
                    ></iframe>
                    <button className="iframe-close-button" onClick={closeIframe}>Close</button>
                </div>
            )}
            <Tour
                steps={tourSteps}
                isOpen={isTourOpen}
                onRequestClose={() => setIsTourOpen(false)}
                getCurrentStep={(curr) => setCurrentStep(curr)}
                showNumber={true}
                showButtons={true}
                showNavigation={true}
                disableInteraction={false}
                disableKeyboardNavigation={false}
                showNavigationNumber={true}
                badgeContent={(curr, tot) => `${curr} of ${tot}`}
                accentColor="#BE05FA"
                goToStep={currentStep}
                lastStepNextButton={
                    <button 
                        className="redo-button create-session-button"
                        onClick={(e) => {
                            setLaunchPod(true);
                            setIsTourOpen(false);
                        }}
                        style={{ borderRadius: '20px', cursor: 'pointer' }} 
                    >
                        Next
                    </button>
                }
                nextButton={
                    <button 
                        className="redo-button create-session-button"
                        style={{ borderRadius: '20px', cursor: 'pointer' }} 
                    >
                        Next
                    </button>
                }
                prevButton={
                    <button 
                        className="redo-button create-session-button"
                        onClick={handlePrev}
                        style={{ 
                            cursor: currentStep === 0 ? 'not-allowed' : 'pointer',
                            opacity: currentStep === 0 ? '0.5' : '1'
                        }}
                        disabled={currentStep === 0}
                    >
                        Previous
                    </button>
                }
            />
        </>
    );
};

export default MockGuruPage;
