// import React, { useEffect } from "react";
// import "./Loader.css";
// import { gsap } from "gsap";
// import DataCard from "./DataCard"; // Import the DataCard component
//
// const Loader = () => {
//     useEffect(() => {
//         let width, height, canvas, ctx, points, target, animateHeader = true;
//
//         const generateStars = (containerId, starCount, size, animationDuration) => {
//             const container = document.getElementById(containerId);
//             if (!container) return;
//
//             for (let i = 0; i < starCount; i++) {
//                 const star = document.createElement("div");
//                 star.classList.add("loader-star");
//
//                 star.style.left = `${Math.random() * 100}%`;
//                 star.style.top = `${Math.random() * 2000}px`;
//
//                 const starSize = Math.random() * size + 1;
//                 star.style.width = `${starSize}px`;
//                 star.style.height = `${starSize}px`;
//
//                 star.style.animationDuration = `${animationDuration}s`;
//
//                 container.appendChild(star);
//             }
//         };
//
//         const initHeader = () => {
//             width = window.innerWidth;
//             height = window.innerHeight;
//             target = { x: width / 2, y: height / 2 };
//
//             const largeHeader = document.getElementById("loader-large-header");
//             largeHeader.style.height = `${height}px`;
//
//             canvas = document.getElementById("loader-demo-canvas");
//             canvas.width = width;
//             canvas.height = height;
//             ctx = canvas.getContext("2d");
//
//             points = [];
//             for (let x = 0; x < width; x += width / 20) {
//                 for (let y = 0; y < height; y += height / 20) {
//                     const px = x + Math.random() * width / 20;
//                     const py = y + Math.random() * height / 20;
//                     const p = { x: px, originX: px, y: py, originY: py, active: 0, circle: null };
//                     points.push(p);
//                 }
//             }
//
//             points.forEach(p1 => {
//                 const closest = [];
//                 points.forEach(p2 => {
//                     if (p1 !== p2) {
//                         const distance = getDistance(p1, p2);
//                         if (closest.length < 5) {
//                             closest.push({ point: p2, distance });
//                         } else {
//                             closest.sort((a, b) => b.distance - a.distance);
//                             if (distance < closest[0].distance) {
//                                 closest[0] = { point: p2, distance };
//                             }
//                         }
//                     }
//                 });
//                 p1.closest = closest.map(c => c.point);
//             });
//
//             points.forEach(p => {
//                 p.circle = new Circle(p, 2 + Math.random() * 2, "rgba(206,26,255,0.3)");
//             });
//
//             points.forEach(p => shiftPoint(p));
//         };
//
//         const getDistance = (p1, p2) =>
//             Math.pow(p1.x - p2.x, 2) + Math.pow(p1.y - p2.y, 2);
//
//         const Circle = function (pos, rad, color) {
//             this.pos = pos;
//             this.radius = rad;
//             this.color = color;
//
//             this.draw = function () {
//                 if (!this.active) return;
//                 ctx.beginPath();
//                 ctx.arc(this.pos.x, this.pos.y, this.radius, 0, 2 * Math.PI, false);
//                 ctx.fillStyle = `rgba(206,26,255,${this.active})`;
//                 ctx.fill();
//             };
//         };
//
//         const drawLines = p => {
//             if (!p.active) return;
//             p.closest.forEach(p2 => {
//                 ctx.beginPath();
//                 ctx.moveTo(p.x, p.y);
//                 ctx.lineTo(p2.x, p2.y);
//                 ctx.strokeStyle = `rgba(206,26,255,${p.active})`;
//                 ctx.stroke();
//             });
//         };
//
//         const shiftPoint = p => {
//             gsap.to(p, {
//                 duration: 0.3 + 1 * Math.random(),
//                 x: p.originX - 75 + Math.random() * 120,
//                 y: p.originY - 75 + Math.random() * 120,
//                 ease: "circ.inOut",
//                 onComplete: function () {
//                     shiftPoint(p);
//                 },
//             });
//         };
//
//         const animatePoints = () => {
//             if (animateHeader) {
//                 ctx.clearRect(0, 0, width, height);
//                 points.forEach(p => {
//                     const distance = Math.abs(getDistance(target, p));
//                     if (distance < 4000) {
//                         p.active = 0.6;
//                         p.circle.active = 1.0;
//                     } else if (distance < 20000) {
//                         p.active = 0.2;
//                         p.circle.active = 0.4;
//                     } else if (distance < 40000) {
//                         p.active = 0.02;
//                         p.circle.active = 0.1;
//                     } else {
//                         p.active = 0;
//                         p.circle.active = 0;
//                     }
//
//                     drawLines(p);
//                     p.circle.draw();
//                 });
//             }
//             requestAnimationFrame(animatePoints);
//         };
//
//         const handleMouseMove = e => {
//             target.x = e.clientX || (e.touches && e.touches[0].clientX);
//             target.y = e.clientY || (e.touches && e.touches[0].clientY);
//         };
//
//         const handleResize = () => {
//             width = window.innerWidth;
//             height = window.innerHeight;
//             canvas.width = width;
//             canvas.height = height;
//             initHeader();
//         };
//
//         generateStars("loader-stars", 2000, 2, 50);
//         generateStars("loader-stars2", 200, 3, 100);
//         generateStars("loader-stars3", 150, 5, 150);
//         initHeader();
//         animatePoints();
//
//         window.addEventListener("mousemove", handleMouseMove);
//         window.addEventListener("resize", handleResize);
//
//         return () => {
//             window.removeEventListener("mousemove", handleMouseMove);
//             window.removeEventListener("resize", handleResize);
//         };
//     }, []);
//
//     useEffect(() => {
//         const progressBar = document.getElementById("loader-progress");
//         const progressPercentage = document.getElementById("loader-percentage");
//         let progress = 0;
//         const duration = 120000; // 2 minutes in milliseconds
//         const interval = duration / 100; // Time per increment (2 minutes / 100 steps)
//
//         const progressInterval = setInterval(() => {
//             if (progress < 100) {
//                 progress += 1; // Increment by 1%
//                 progressBar.style.width = `${progress}%`;
//                 progressPercentage.textContent = `${progress}%`; // Update percentage text
//             } else {
//                 clearInterval(progressInterval); // Stop when 100% is reached
//             }
//         }, interval);
//
//         return () => {
//             clearInterval(progressInterval); // Clean up interval on component unmount
//         };
//     }, []);
//
//
//
//     return (
//         <div id="loader-large-header" className="loader-large-header">
//
//             <canvas id="loader-demo-canvas"></canvas>
//             <div id="loader-stars" className="loader-star-layer"></div>
//             <div id="loader-stars2" className="loader-star-layer"></div>
//             <div id="loader-stars3" className="loader-star-layer"></div>
//             {/* Add DataCard below */}
//             <div className="datacard-container">
//                 <DataCard />
//             </div>
//             <div className="youtube-container">
//                 <iframe
//                     width="600"
//                     height="400"
//                     src="https://www.youtube.com/embed/RR2EI8EEOOw?autohide=1&showinfo=0&controls=0&autoplay=1"
//                     title="YouTube video player"
//                     frameBorder="0"
//                     allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
//                     allowFullScreen
//                 ></iframe>
//             </div>
//             {/* 3D Loader Progress Bar */}
//             <div class="loader-progress-bar">
//                 <div class="progress" id="loader-progress"></div>
//                 <span class="progress-percentage" id="loader-percentage">0%</span>
//             </div>
//
//         </div>
//     );
// };
//
// export default Loader;
import React, { useEffect } from "react";
import "./Loader.css";
import { gsap } from "gsap";
import DataCard from "./DataCard"; // Import the DataCard component

const Loader = () => {
    useEffect(() => {
        let width, height, canvas, ctx, points, target, animateHeader = true;

        const generateStars = (containerId, starCount, size, animationDuration) => {
            const container = document.getElementById(containerId);
            if (!container) return;

            for (let i = 0; i < starCount; i++) {
                const star = document.createElement("div");
                star.classList.add("loader-star");

                star.style.left = `${Math.random() * 100}%`;
                star.style.top = `${Math.random() * 2000}px`;

                const starSize = Math.random() * size + 1;
                star.style.width = `${starSize}px`;
                star.style.height = `${starSize}px`;

                star.style.animationDuration = `${animationDuration}s`;

                container.appendChild(star);
            }
        };

        const initHeader = () => {
            width = window.innerWidth;
            height = window.innerHeight;
            target = { x: width / 2, y: height / 2 };

            const largeHeader = document.getElementById("loader-large-header");
            largeHeader.style.height = `${height}px`;

            canvas = document.getElementById("loader-demo-canvas");
            canvas.width = width;
            canvas.height = height;
            ctx = canvas.getContext("2d");

            points = [];
            for (let x = 0; x < width; x += width / 20) {
                for (let y = 0; y < height; y += height / 20) {
                    const px = x + Math.random() * (width / 20);
                    const py = y + Math.random() * (height / 20);
                    const p = {
                        x: px,
                        originX: px,
                        y: py,
                        originY: py,
                        active: 0,
                        circle: null
                    };
                    points.push(p);
                }
            }

            // For each point, find the 5 closest points
            points.forEach(p1 => {
                const closest = [];
                points.forEach(p2 => {
                    if (p1 !== p2) {
                        const distance = getDistance(p1, p2);
                        if (closest.length < 5) {
                            closest.push({ point: p2, distance });
                        } else {
                            closest.sort((a, b) => b.distance - a.distance);
                            if (distance < closest[0].distance) {
                                closest[0] = { point: p2, distance };
                            }
                        }
                    }
                });
                p1.closest = closest.map(c => c.point);
            });

            // Assign a circle to each point
            points.forEach(p => {
                p.circle = new Circle(p, 2 + Math.random() * 2, "rgba(206,26,255,0.3)");
            });

            // Start animations
            points.forEach(p => shiftPoint(p));
        };

        const getDistance = (p1, p2) =>
            Math.pow(p1.x - p2.x, 2) + Math.pow(p1.y - p2.y, 2);

        const Circle = function (pos, rad, color) {
            this.pos = pos;
            this.radius = rad;
            this.color = color;

            this.draw = function () {
                if (!this.active) return;
                ctx.beginPath();
                ctx.arc(this.pos.x, this.pos.y, this.radius, 0, 2 * Math.PI, false);
                ctx.fillStyle = `rgba(206,26,255,${this.active})`;
                ctx.fill();
            };
        };

        const drawLines = p => {
            if (!p.active) return;
            p.closest.forEach(p2 => {
                ctx.beginPath();
                ctx.moveTo(p.x, p.y);
                ctx.lineTo(p2.x, p2.y);
                ctx.strokeStyle = `rgba(206,26,255,${p.active})`;
                ctx.stroke();
            });
        };

        const shiftPoint = p => {
            gsap.to(p, {
                duration: 0.3 + 1 * Math.random(),
                x: p.originX - 75 + Math.random() * 120,
                y: p.originY - 75 + Math.random() * 120,
                ease: "circ.inOut",
                onComplete: function () {
                    shiftPoint(p);
                },
            });
        };

        const animatePoints = () => {
            if (animateHeader) {
                ctx.clearRect(0, 0, width, height);
                points.forEach(p => {
                    const distance = Math.abs(getDistance(target, p));
                    if (distance < 4000) {
                        p.active = 0.6;
                        p.circle.active = 1.0;
                    } else if (distance < 20000) {
                        p.active = 0.2;
                        p.circle.active = 0.4;
                    } else if (distance < 40000) {
                        p.active = 0.02;
                        p.circle.active = 0.1;
                    } else {
                        p.active = 0;
                        p.circle.active = 0;
                    }

                    drawLines(p);
                    p.circle.draw();
                });
            }
            requestAnimationFrame(animatePoints);
        };

        const handleMouseMove = e => {
            target.x = e.clientX || (e.touches && e.touches[0].clientX);
            target.y = e.clientY || (e.touches && e.touches[0].clientY);
        };

        const handleResize = () => {
            width = window.innerWidth;
            height = window.innerHeight;
            canvas.width = width;
            canvas.height = height;
            initHeader();
        };

        // Generate stars
        generateStars("loader-stars", 2000, 2, 50);
        generateStars("loader-stars2", 200, 3, 100);
        generateStars("loader-stars3", 150, 5, 150);

        // Initialize and animate
        initHeader();
        animatePoints();

        window.addEventListener("mousemove", handleMouseMove);
        window.addEventListener("resize", handleResize);

        // Cleanup
        return () => {
            window.removeEventListener("mousemove", handleMouseMove);
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <div id="loader-large-header" className="loader-large-header">
            <canvas id="loader-demo-canvas"></canvas>
            <div id="loader-stars" className="loader-star-layer"></div>
            <div id="loader-stars2" className="loader-star-layer"></div>
            <div id="loader-stars3" className="loader-star-layer"></div>

            {/* DataCard Component */}
            <div className="datacard-container">
                <DataCard />
            </div>

            {/* YouTube Embed */}
            <div className="youtube-container">
                <iframe
                    width="600"
                    height="400"
                    src="https://www.youtube.com/embed/RR2EI8EEOOw?autohide=1&showinfo=0&controls=0&autoplay=1"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                ></iframe>
            </div>

            {/* Exit Button at the bottom center */}
            <button
                className="exit-button"
                onClick={() => window.location.href = 'https://botguru.ai/landingPage'}
            >
                Exit
            </button>
        </div>
    );
};

export default Loader;
