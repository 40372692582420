import config from "../../config.json";
import React, { createContext, useContext, useState,useEffect } from "react";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
       const [userObjId, setUserObjId] = useState(localStorage.getItem("userObjId") || null);
       const [newUser, setNewUser] = useState(null);
       const [userEmail, setUserEmail] = useState(null);
       const [pricingPlans, setPricingPlans]=useState(null);
       const [profileData, setProfileData] = useState({});
       const [userAnalytics, setUserAnalytics]= useState({})
      
       const fetchPricingData= async()=>{
        try{
          let response= await fetch(`${config.restAPI}/getPricing/`, {
            method:"GET",
            credentials:"include",
          })
          let data=await response.json();
          setPricingPlans(data);
        }
        catch(error){
          console.log("error in fetching pricing data:", error);
        }
      }

    useEffect(() => {
        if (userObjId) {
            localStorage.setItem("userObjId", userObjId);
        }
    }, [userObjId]);  

    const onLogout=()=>{
      setUserObjId(null);
      setUserAnalytics({});
      setUserEmail(null);
      setProfileData({});
    }

    return (
        <AuthContext.Provider value={{ 
          userObjId, setUserObjId, newUser ,setNewUser , userEmail, setUserEmail, pricingPlans, setPricingPlans, 
          fetchPricingData,
          profileData, 
          setProfileData,
          userAnalytics, 
          setUserAnalytics,
          onLogout
          }}>
            {children}
        </AuthContext.Provider>
    );
};

// Custom hook to use the AuthContext
export const useAuthContext = () => useContext(AuthContext);