import React from 'react';
import './ScrollingBanner.css';
import {
  Stethoscope, Building2, Scale, GraduationCap, Wrench,
  Banknote, Target, Brain, Palette, Newspaper,
  Wheat, Hotel, Plane, Shirt, Film, ClipboardList,
  Pen, MonitorSmartphone, Cloud, PieChart,
  Package,
  Shield,
  Megaphone,
  HeartPulse,
  Code,
  Pill,
  BarChart,
  Book,
  Cpu,
  Search,
  ShoppingCart,
  Briefcase,
  Handshake,
  Paintbrush,
  Image,
  FlaskConical,
  Factory,
  Microscope,
  HardHat
} from 'lucide-react';

const domains = [
  { text: 'Information Technology (IT)', icon: <Cpu  /> },
  { text: 'Healthcare', icon: <HeartPulse  /> }, 
  { text: 'Finance', icon: <Banknote /> },
  { text: 'Education', icon: <GraduationCap  /> },
  { text: 'Engineering', icon: <Wrench  /> }, 
  { text: 'Marketing', icon: <Megaphone  /> }, 
  { text: 'Sales', icon: <ShoppingCart  /> },
  { text: 'Creative Arts', icon: <Palette  /> }, 
  { text: 'Research & Development (R&D)', icon: <FlaskConical  /> } 
];


const skills = [
  { text: 'Software Development', icon: <Code   /> }, 
  { text: 'Cloud/Devops Engineering', icon: <Cloud /> }, 
  { text: 'AI & Machine Learning', icon: <Brain /> }, 

  { text: 'Pharmaceutical Science', icon: <Pill /> },
  { text: 'Medical Practice', icon: <Stethoscope  /> },
  { text: 'Patient Care & Nursing', icon: <HeartPulse  /> }, 
  
  { text: 'Data Analysis', icon: <BarChart /> },
  { text: 'Data Science', icon: <PieChart /> },
  { text: 'Data Visualization', icon: <MonitorSmartphone /> },
  { text: 'Law', icon: <Scale /> },

  { text: 'Risk Management', icon: <Shield /> }, 

  { text: 'Teaching & Education', icon: <Book /> }, 
  { text: 'Software Engineering', icon: <Cpu /> },
  { text: 'Construction', icon: <HardHat /> },
  { text: 'Aerospace Engineering', icon: <Plane /> },
  { text: 'Project Engineering', icon: <Wrench /> },

  { text: 'Sales & Negotiation', icon: <ShoppingCart /> }, 
  { text: 'Sales Leadership', icon: <Briefcase /> }, 

  { text: 'UX/UI Design', icon: <Paintbrush /> },
  { text: 'Graphic Design', icon: <Image /> }, 
  { text: 'Art Direction', icon: <Palette /> }, 

  { text: 'Scientific Research', icon: <FlaskConical /> }, 
  { text: 'Clinical Research', icon: <Microscope /> },
];

const professionIcons=[
  "software-engineer.png",
  "plumber.png",
  "doctor.png",
  "geologist.png",
  "civil.png",
  "nurse.png",
  "pilot.png",
  "lawyer.png",
  "electrical-engineer.png",
  "software-engineer.png",
  "plumber.png",
  "doctor.png",
  "geologist.png",
  "civil.png",
  "nurse.png",
  "pilot.png",
  "lawyer.png",
  "electrical-engineer.png",
]


const ScrollingBanner = () => {
  return (
    <div className="screen1-scrolling-banner">
      <div className="screen1-scroll-container">
        <div className="scroll-row screen1-scroll-left">
          {[...skills].map((domain, index) => (
            <span key={index} className="scroll-item">
              <span className="icon">{domain.icon}</span>
              {domain.text}
            </span>
          ))}
        </div>
      </div>
      {/* <div className='screen1-scroll-holder'>
        <div className='scroll-row screen1-profession-scroll-left'>
          {
            professionIcons.map((value, index)=> <img src={`/proffesions/${value}`} alt='profession-img' key={index} className='profession-logo'/>)
          }
        </div>
      </div> */}

      {/* <div className="screen1-scroll-container">
        <div className="scroll-row screen1-scroll-right">
          {[...skills].map((skill, index) => (
            <span key={index} className="scroll-item">
              <span className="icon">{skill.icon}</span>
              {skill.text}
            </span>
          ))}
        </div>
      </div> */}
    </div>
  );
};

export default ScrollingBanner;