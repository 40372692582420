import React,{useState} from "react";
import './CreateSessionModal.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { Tabs, Tab, TabList } from 'react-tabs';  
import CreateJDModal from './CreateJDModal';
import TimePicker from "../LandingPages/TimePicker";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTimezoneSelect, allTimezones } from "react-timezone-select"




const CreateSessionModal = ({isOpen, closeModal})=>{
  const labelStyle = "original"
const timezones = {
  ...allTimezones,
  "Europe/Berlin": "Frankfurt",
}
const { options, parseTimezone } = useTimezoneSelect({ labelStyle, timezones })

    const [activeTab, setActiveTab] = useState(0);
    const [isJDModalOpen, setJDModalOpen] = useState(false);
    const [startDate, setStartDate] = useState(new Date());

    const openJDModal = () => setJDModalOpen(true);
    const closeJDModal = () => setJDModalOpen(false);
    const resumeFiles = [
      { label: "FrontendDeveloper_resume", value: "/files/frontend_developer_resume.pdf" },
      { label: "FrontendDeveloper_resume", value: "/files/frontend_developer_resume.docx" },
    ];
    const [selectedResume, setSelectedResume] = useState("");

    const jdFiles = [
      { label: "FrontendDeveloper_JD", value: "/files/frontend_developer_JD.pdf" },
      { label: "FrontendDeveloper_JD", value: "/files/frontend_developer_JD.pdf" },
    ];
    const [selectedJD, setSelectedJD] = useState("");

    const handleTabSelect = (index) => {
        setActiveTab(index === 0 ? 0 : 1);
      };

    const handleResumeChange = (event) => {
        setSelectedResume(event.target.value);
      };
    const handleJDChange = (event) => {
        setSelectedJD(event.target.value);
      };

    if (!isOpen) return null;
    return(
      <>
        <div className="modal-overlay"  onClick={closeModal}>
          <div className="cs-modal-content" onClick={(e) => e.stopPropagation()}>
            <button className="cs-close-button" onClick={closeModal}>&times;</button>
            <h2>Start Your Next Interview</h2>
            <div>
            <div className="upload-resume-div">
                    <label className='resume'>Job/Role</label>
                    <div className="upload-resume-buttons">
                        <button className='button new' onClick={openJDModal} >+ Create new</button>
                        <input
                            id="fileInput"
                            type="file"
                            accept=".docx,.pdf"
                            style={{ display: 'none' }}
                        />
                    </div>
              </div>
              <select value={selectedJD} onChange={handleJDChange}>
              <option value="">Select Job/Role</option>
              {jdFiles.map((file, index) => (
                <option key={index} value={file.value}>
                  {file.label}
                </option>
              ))}
            </select>
            </div>

            <div>
                <div className="upload-resume-div">
                    <label className='resume'>Resume</label>
                    <div className="upload-resume-buttons">
                        <button className='button new' >+ Create new</button>
                        <button className="button upload" onClick={() => document.getElementById('fileInput').click()}> <i class="fas fa-arrow-up"></i> Upload</button>
                        <input
                            id="fileInput"
                            type="file"
                            accept=".docx,.pdf"
                            style={{ display: 'none' }}
                        />
                    </div>
                </div>
                <select value={selectedResume} onChange={handleResumeChange}>
              <option value="">Select Resume</option>
              {resumeFiles.map((file, index) => (
                <option key={index} value={file.value}>
                  {file.label}
                </option>
              ))}
            </select>
            </div>

            <div>
            <label>Interview Type</label>
            <select>
              <option>Select Interview Type</option>
              <option>Techincal</option>
              <option>Non-Techincal</option>
            </select>
            </div>
 
          <div>
            <label>
              Schedule your Interview 
            </label>
            <div className="prep-bot-status-tabs">
                <Tabs onSelect={handleTabSelect}>
                <TabList>
                    <Tab>Instant</Tab>
                    <Tab>Schedule</Tab>
                </TabList>
                </Tabs>
            </div>
          </div>
              {activeTab !== 0 && <div className="schedule-time">
                  <div className="datepicker-container">
                    <div className="datepicker-wrapper">
                      <label className="label">Date</label>
                        <i className="fas fa-calendar calendar-icon"></i> 
                        <DatePicker
                          className="datepicker"
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                          placeholderText="Select a date"
                        />
                    </div>
                      <TimePicker></TimePicker>
                      </div>
                      <label  className="label">Timezone</label>
                      <select >
                        {options.map((option) => (
                          <option value={option.value}>{option.label}</option>
                        ))}
                      </select>
                    </div>
                    }

                <div className="footer">
                  <button className="footer-button submit">Start Session</button>
                  <button className="footer-button cancel">Cancel</button>
              </div>
          </div>
        </div>
      <CreateJDModal isOpen={isJDModalOpen} closeModal={closeJDModal} />
      </>
    )

}
export default CreateSessionModal;