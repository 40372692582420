import { Check } from "lucide-react"
import '../Pricing.css';

export const StarterPlan = ({data, selectPlan})=>{
    return (
        <div className="tabData">
            <div className="content">
                <div className="pricing-section">
                    <h5 className="plan-title">Starter Plan</h5>
                    <span className="price">${data.price}</span>
                    <div className="session-info">
                        {data.session_type==="mockbot" || data.session_type==="prepbot" ?
                                <span>1 session</span>
                            : data.session_type==="jd" ?
                            <span>{data.jd} Job descriptions</span>
                            : <span>{data.resume} Resumes</span> 
                        }
                    </div>
                </div>
                <div className="session-features starter">
                    {data.hasOwnProperty("features") && data.features.map((feature, index) => {
                        if(index<=4){
                            return (
                                <div>
                                    <Check className="check" strokeWidth={3}/>
                                    <span>{feature}</span>
                                </div>
                            )
                        }
                    })}
                </div>
            </div>
            <button 
                className="choose-planBtn"
                onClick={() => selectPlan(data.price, {"plan_type":"Starter", "id":data._id, "session_type":data.session_type})}
            >Choose Plan</button>
        </div>
    )
}