import React, { useState, useEffect } from "react";
import './prepLaunchPod.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import TimePicker from "./TimePicker";
import { useTimezoneSelect, allTimezones } from "react-timezone-select";
import JDCreateModal from "./JDCreateModal";
import ResumeModal from "./ResumeModal";
import JdDetailsModal from "./JDDetailsModal";
import useAuthService from '../../services/AuthService';
import LoadTransitionPage from "./loadingpage/LoadTransitionPage";
import { jsPDF } from "jspdf";
import { useAuthContext } from "../Auth/AuthContext"; 
import Tour from 'reactour';
import ResumePreview from './ResumePreview';
import config from "../../config.json";
import { TriangleAlert } from "lucide-react";
import Notification from "./Notification";



const PrepLaunchPod = ({ isOpen, closeModal }) => {
    const { userObjId, profileData, newUser,setNewUser, userAnalytics } = useAuthContext(); // Get userObjId from context
    const [activeButton, setActiveButton] = useState('Job/Role');
    const [activePersona, setActivePersona] = useState(null)
    const [selectedSchedule, setSelectedSchedule] = useState("immediate"); // Default selection4
    const [selectedType, setSelectedType] = useState("technical"); // New state for interview type
    const [startDate, setStartDate] = useState(new Date());
    const [isCreateModalOpen, setCreateModalOpen] = useState(false); // New JDCreateModal state
    const [resumeModal,setresumeModal] = useState(false);
    const closeresumeModal = () => setresumeModal(false);
    const [isJDDetailsModalOpen, setJDDetailsModalOpen] = useState(false);
    const [selectedJDId, setSelectedJDId] = useState(null);
    const [selectedCard, setSelectedCard] = useState({});
    const authService = useAuthService();
    const [resumeData, setResumeData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [jobRoles, setJobRoles] = useState([]); // State to store fetched job roles
    const [jdData, setJdData] = useState(null);
    const [resumes, setResumes] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [showPreview, setShowPreview] = useState(false);
    const [previewData, setPreviewData] = useState(null);

    const [uploadError, setUploadError]= useState({"resumeUploadError":"", "JDUploadError":""});
    const [showNotification, setShowNotification] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState('');
    const [notificationDismissed, setNotificationDismissed] = useState(false);

    useEffect(() => {
        if (isOpen && showNotification && !notificationDismissed) {
            setShowNotification(true);
        } else {
            setShowNotification(false);
        }
    }, [isOpen]);

    const handleCloseNotification = () => {
        setNotificationDismissed(true);  // Mark it as dismissed
        setShowNotification(false);
    };
    
    

    useEffect(() => {
        console.log("Updated userObjId in PrepLaunchPod:", userObjId);
      }, [userObjId]);

    useEffect(() => {
        if (isOpen && newUser === 'Y') {
            console.log('New user detected, showing initial tour guide');
            setIsTourOpen(true);
        } else {
            console.log('Tour guide disabled because:', {
                isOpen,
                newUser
            });
            setIsTourOpen(false);
        }
    }, [isOpen, newUser]);

    // useEffect(()=>{
    //     let timer;
    //     if(uploadError?.JDUploadError.length){
    //         timer=setTimeout(() => {
    //             setUploadError({...uploadError, JDUploadError: ""});
    //         }, 10000);
    //     }
    //     else if(uploadError?.resumeUploadError.length){
    //         timer=setTimeout(() => {
    //             setUploadError({...uploadError, resumeUploadError: ""});
    //         }, 10000);
    //     }
    //     return () => clearTimeout(timer);
    // }, [uploadError])


    useEffect(() => {
        if (activeButton === 'Resume' && newUser === 'Y') {
            console.log('Switching to Resume tour');
            setIsTourOpen(false);
            setShowResumeTour(true);
            setCurrentStep(0);
        }
    }, [activeButton, newUser]);

    useEffect(() => {
        if (activeButton === 'Persona' && newUser === 'Y') {
            console.log('Switching to Persona tour');
            setShowPersonaTour(true);
        }
    }, [activeButton, newUser]);

    useEffect(() => {
        if (activeButton === 'Launch' && newUser === 'Y') {
            console.log('Switching to Launch tour');
            setShowLaunchTour(true);
            setShowTourGuideInfo(true); // Show the info container when Launch tour starts
        }
    }, [activeButton, newUser]);

    const [isTourOpen, setIsTourOpen] = useState(false); // State to control the tour
    const [currentStep, setCurrentStep] = useState(0); // State to track the current step
    const [showResumeTour, setShowResumeTour] = useState(false); // State to control the resume tour
    const [showPersonaTour, setShowPersonaTour] = useState(false); // Add state for Persona tour
    const [showLaunchTour, setShowLaunchTour] = useState(false); // Add state for Launch tour

    // Add new state near the top with other state declarations
    const [showTourGuideInfo, setShowTourGuideInfo] = useState(false);

    // Add this state
    const [isMenuOpen, setIsMenuOpen] = useState(false);


    // Add this handler
    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const tourSteps = [
        {
            selector: '.upload-jd-button',
            content: 'Click here to upload your job description.',
        },
        {
            selector: '.create-jd-button',
            content: 'Click here to create a new job description.',
        }
    ];

    const resumeTourSteps = [
        {
            selector: '.upload-card button',
            content: 'Upload your existing resume in PDF or DOCX format',
        },
        {
            selector: '.create-card button',
            content: 'Or create a new resume using our templates',
        }
    ];

    const personaTourSteps = [
        {
            selector: '.persona-container .persona-name:first-child',
            content: 'Meet Maya, your female AI interview trainer',
        },
        {
            selector: '.persona-container .persona-name:last-child',
            content: 'Meet Leo, your male AI interview trainer',
        }
    ];

    const launchTourSteps = [
        {
            selector: '.launch-button',
            content: 'Click here to review your profile and start your interview preparation session with your Trainer',
        }
    ];

    
    // const handleTourClose = () => {
    //     setIsTourOpen(false);
    //     setNewUser('N')
    // };

    const handleTourClose = (tourType) => {
        switch (tourType) {
            case 'JobRole':
                setIsTourOpen(false);
                break;
            case 'Resume':
                setShowResumeTour(false);
                break;
            case 'Persona':
                setShowPersonaTour(false);
                break;
            case 'Launch':
                setShowLaunchTour(false);
                setShowTourGuideInfo(false);
                setNewUser('N') // Hide the info container when tour ends
                break;
            default:
                break;
        }
    };

    const now = new Date();
    const date = now.toISOString().split("T")[0]; // Extract date in YYYY-MM-DD format
    const time = now.toTimeString().split(" ")[0]; // Extract time in HH:MM:SS format


    const generateRandom5DigitNumber = () => {
        return Math.floor(10000 + Math.random() * 90000);
    };

    const [formData, setFormData] = useState({
        "job_role": "",
        company: "",
        job_type: "",
        job_description: {
            about_us: "",
            job_overview: "",
            key_responsibilities: "",
            required_qualification_experience: "",
            preferred_skills: ""
        },
        experience: "",
        salary: "",
        location: "",
        date: date,
        time: time,
        jd_id: generateRandom5DigitNumber()
    });

    const [disabledButtons, setDisabledButtons] = useState({
        'Job/Role': false,
        'Resume': true,
        'Persona': true,
        'Launch': true
    });



    const sectionKeys = {
        'Job/Role': 'jobRole',
        'Resume': 'resume',
        'Persona': 'persona',
        'Launch': null, // No selection needed for Launch
    };

    const handleCloseModal = () => {
        // Reset state
        setSelectedCard({});
        setActiveButton('Job/Role');
        // Add other state resets as needed
    
        // Close modal logic
        closeModal();
    };

    const websocketUrlForFileUpload = "wss://o834xau6qe.execute-api.us-west-1.amazonaws.com/production/"; // WebSocket for File upload

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (!file) {
            console.error("No file selected.");
            return;
        }

        console.log("File selected:", file);
        handleFileUpload(file);
        event.target.value = null; // Clear the input after handling
    };

    const handleFileUpload = (file) => {
        setLoading(true);
        setresumeModal(true);
        console.log("Initializing WebSocket for Resume Upload...");


        const websocketInstance = new WebSocket(websocketUrlForFileUpload);

        websocketInstance.onopen = () => {
            console.log("WebSocket connection established for Resume Upload.");
            const message = { action: "botguru_sendmessage", message: "get data" };
            sendWebSocketMessageWithRetry(websocketInstance, message);
        };

        websocketInstance.onmessage = (event) => handleWebSocketMessage(event, file);

        websocketInstance.onerror = (error) => {
            console.error("WebSocket error for Resume Upload:", error);
        };

        websocketInstance.onclose = () => {
            console.log("WebSocket connection closed for Resume Upload.");
        };
    };

    const handleWebSocketMessage = async (event, file) => {
        if (!event.data || event.data.trim() === "") {
            console.warn("Empty WebSocket message received.");
            return;
        }

        try {
            const data = JSON.parse(event.data);
            console.log("WebSocket message received:", data);
            setLoading(true);
            if (data.connectionid) {
                // Step 1: Call authService to extract resume data
                const response = await authService
                    .resumeExtractor({
                        file,
                        connectionId: data.connectionid,
                        user_obj_id:userObjId,
                    });

                console.log("Resume extraction initiated successfully:", response);

                // Wait for the full resume data in subsequent WebSocket messages
            } else if (data.message) {
                // Step 2: Full resume data received
                const messageData = data.message;
                console.log("Complete resume data received:", messageData);

                // Step 3: Set the parsed resume data
                setLoading(false); // Stop loading on error

                setResumeData(data);

                // Step 4: Open the modal
                setresumeModal(true);
            }
        } catch (error) {
            console.log("called catch block")
            console.error("Error handling WebSocket message:", error?.data.error);
            setNotificationMessage(error?.data.error);
            setShowNotification(true);
            setLoading(false);
            setresumeModal(false);
            // setUploadError({...uploadError, resumeUploadError:"Your uploaded resume document exceeded size limit"});
        }
    };


    const sendWebSocketMessageWithRetry = (websocketInstance, message, retries = 3, interval = 1000) => {
        let attempts = 0;

        const sendMessage = () => {
            if (websocketInstance.readyState === WebSocket.OPEN) {
                websocketInstance.send(JSON.stringify(message));
                console.log("WebSocket message sent:", message);
            } else if (attempts < retries) {
                attempts++;
                console.log(`WebSocket not ready. Retrying (${attempts}/${retries})...`);
                setTimeout(sendMessage, interval);
            } else {
                console.error("Failed to send WebSocket message after multiple attempts.");
            }
        };

        sendMessage();
    };

    const handleJDFileChange = (event) => {
        const file = event.target.files[0];
        if (!file) {
            console.error("No file selected.");
            return;
        }

        console.log("File selected:", file);

        // Trigger the upload process with the selected file
        handleJDFileUpload(file);
        event.target.value = null;
        setIsTourOpen(false); // Close the tour when the user clicks on the upload JD button
    };

      const handleJDFileUpload = (file) => {
        if (!file) {
            console.error("No file selected. Aborting upload.");
            return;
        }
        setLoading(true);
        setCreateModalOpen(true)
        console.log("Initializing WebSocket for File Upload...");
        const websocketInstance = new WebSocket(websocketUrlForFileUpload);

        websocketInstance.onopen = () => {
            console.log("WebSocket connection established for File Upload.");
            const message = { action: "botguru_sendmessage", message: "get data" };
            sendWebSocketMessageWithRetry(websocketInstance, message);
        };

        websocketInstance.onmessage = (event) => handleWebSocketMessageForFileUpload(event, file);

        websocketInstance.onerror = (error) => {
            console.error("WebSocket error for File Upload:", error);
        };

        websocketInstance.onclose = () => {
            console.log("WebSocket connection closed for File Upload.");
        };
    };

    const handleWebSocketMessageForFileUpload = async (event, file) => {
      // if (!event.data || event.data.trim() === "") {
      //     console.warn("Empty WebSocket message received.");
      //     return;
      // }
      try {
          const data = JSON.parse(event.data);
          console.log("WebSocket message received:", data);

          if (data.connectionid) {
              const formData = new FormData();
              formData.append("file", file);
              formData.append("connectionId", data.connectionid);
              formData.append("user_obj_id", userObjId);

              console.log("Sending file data via API...");
              authService.jobDescriptionExtractor({
                      file,
                      connectionId: data.connectionid,
                      user_obj_id:userObjId,
                  })
                  .then((response) => {
                      console.log("API Response from jobDescriptionExtractor:", response);
                  })
                  .catch((error) => {
                      console.error("Error uploading file to /jobDescriptionExtractor:", error?.data.error);
                      setNotificationMessage(error?.data.error);
                      setShowNotification(true);
                      setLoading(false);
                      setCreateModalOpen(false);
                    //   setUploadError({...uploadError, JDUploadError:"Your uploaded JD document exceeded size limit"});
                  });
          }

          if (data.message) {
            try {
                const parsedMessage = JSON.parse(data.message);
                console.log("Parsed Message:", parsedMessage);
                // Update form data
                setFormData(prev => ({
                    ...prev,
                    "job_role": parsedMessage.job_role || prev["job_role"],
                    company: parsedMessage.company || prev.company,
                    job_type: parsedMessage.job_type || prev.job_type,
                    experience: parsedMessage.job_description.experience || prev.experience,
                    salary: parsedMessage.job_description.salary || prev.salary,
                    location: parsedMessage.job_description.location || prev.location,  
                    job_description: {
                        ...prev.job_description,
                        about_us: parsedMessage.job_description.about_us || prev.job_description.about_us,
                        job_overview: parsedMessage.job_description.job_overview || prev.job_description.job_overview,
                        key_responsibilities: parsedMessage.job_description.key_responsibilities || prev.job_description.key_responsibilities,
                        required_qualification_experience: parsedMessage.job_description.required_qualification_experience || prev.job_description.required_qualification_experience,
                        preferred_skills: parsedMessage.job_description.preferred_skills || prev.job_description.preferred_skills
                    },
                    jd_id: generateRandom5DigitNumber()
                }));
                setLoading(false);
            } catch (parseError) {
                console.error("Error parsing message:", parseError);
            }
        }
      } catch (error) {
          console.error("Error handling WebSocket message:", error);
      }
    };





    const openJDDetailsModal = (jdId) => {
        console.log('Opening JD Details Modal for ID:', jdId);
        setSelectedJDId(jdId);
        setJDDetailsModalOpen(true);
    };


    const closeJDDetailsModal = () => {
        setSelectedJDId(null);
        setJDDetailsModalOpen(false);
    };

// const handleCardSelection = (cardType, cardId) => {
//     setSelectedCard({ type: cardType, id: cardId });
//   };

    const handleCardSelection = (section, cardId) => {
        setSelectedCard((prev) => ({
            ...prev,
            [section]: cardId,
        }));
        
        if (section === 'jobRole') {
            setDisabledButtons({
                'Job/Role': false,
                'Resume': false,
                'Persona': true,
                'Launch': true
            });
        } else if (section === 'resume') {
            setDisabledButtons({
                'Job/Role': false,
                'Resume': false,
                'Persona': false,
                'Launch': true
            });
        }
    };


    const randomImages = [
        "/resumes/resume1.png",
        "/resumes/resume2.jpg"
    ];

    const fetchResumes = async () => {
        setLoading(true);
        try {
            const response = await fetch(`${config.restAPI}/getResumes/`, {
                method: "POST",
                credentials:"include",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    user_obj_id: userObjId,
                }),
            });

            if (!response.ok) {
                throw new Error(`Failed to fetch resumes. Status: ${response.status}`);
            }

            const data = await response.json();
            console.log("Fetched Resumes:", data.resumes);    
            const sortedResumes = data.resumes.sort((a, b) => {
                const aDate = new Date(a.created_at);
                const bDate = new Date(b.created_at);
                return bDate - aDate; // Latest first (descending order)
            });
    
            console.log("Sorted Resumes by created_at:", sortedResumes);
            setResumes(sortedResumes || []);
        } catch (error) {
            console.error("Error fetching resumes:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchResumes();
    }, []);


    const labelStyle = "original"
    const timezones = {
        ...allTimezones,
        "Europe/Berlin": "Frankfurt",
    }
    const { options, parseTimezone } = useTimezoneSelect({ labelStyle, timezones })
    const handleButtonClick = (buttonName) => {
        setActiveButton(buttonName);
    };

    const handlePersonClick = (personaVal) => {
        if (personaVal === 0) {
            setActivePersona(0);
            setSelectedCard((prev) => ({
                ...prev,
                persona: personaVal,
                // interview: 'prepbot'
            }));
            setDisabledButtons({
                'Job/Role': false,
                'Resume': false,
                'Persona': false,
                'Launch': false
            });

        }
        if (personaVal === 1) {
            setActivePersona(1);
            setSelectedCard((prev) => ({
                ...prev,
                persona: personaVal,
                // interview: 'prepbot'
            }));
            setDisabledButtons({
                'Job/Role': false,
                'Resume': false,
                'Persona': false,
                'Launch': false
            });
            // setActiveButton('Launch');
        }

        // Close persona tour if it's open
        // if (showPersonaTour) {
        //     setShowPersonaTour(false);
        // }
    };
    const buttons = [
        { id: 1, name: 'Job/Role', label: 'Job/Role' },
        { id: 2, name: 'Resume', label: 'Resume' },
        { id: 3, name: 'Persona', label: 'Persona' },
        { id: 4, name: 'Launch', label: 'Review & Launch' },
    ];

    const sections = ['Job/Role','Resume', 'Persona', 'Launch'];
    const currentSectionIndex = sections.indexOf(activeButton);

    const handleNextSection = () => {
        const currentSection = sections[currentSectionIndex];
        const currentSectionKey = sectionKeys[currentSection];
        
        if (currentSectionKey && selectedCard[currentSectionKey]) {
            if (currentSectionIndex < sections.length - 1) {
                setActiveButton(sections[currentSectionIndex + 1]);
            }
        }
    };

    const handlePrevious = () => {
        if (currentSectionIndex > 0) {
            setActiveButton(sections[currentSectionIndex - 1]);
        }
    };

    const renderLoading = () => {
        return (
            <div className="loading-overlay">
                <div className="spinner"></div>
                <p>Loading, please wait...</p>
            </div>
        );
    };

    const fetchJobRoles = async () => {
        setLoading(true);
        try {
            const response = await fetch(`${config.restAPI}/getJobs/`, {
                method: "POST",
                credentials:"include",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    user_obj_id: userObjId,
                }),
            });

            if (!response.ok) {
                throw new Error(`Failed to fetch job roles. Status: ${response.status}`);
            }

            const data = await response.json();
            console.log(data);
            const sortedData = data.job_descriptions.sort((a, b) => {
                const aDate = new Date(a.created_at);
                const bDate = new Date(b.created_at);
                return bDate - aDate; // Latest first
            });
    
            console.log("Sorted JDs by created_at:", sortedData);
            setJdData(sortedData || []);
        } catch (error) {
            console.error("Error fetching job roles:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchJobRoles();
    }, []);

    const handleDownloadPDF = (jdData) => {
        if (!jdData) return;
        const pdf = new jsPDF();

        const cleanText = (text) => {
            if (!text) return '';
            // Remove numbers followed by dot/bracket at start of line
            return text.replace(/^\d+[\.)]\s*/gm, '');
        };
        
        // Set up PDF styling
        pdf.setTextColor(0, 0, 0); // Black text
        pdf.setFillColor(255, 255, 255); // White background
        
        // Helper function to ensure string values
        const toString = (value) => {
            if (value === null || value === undefined) return 'Not Specified';
            return String(value);
        };
        
        // Company Name - Centered, Bold, Underlined
        pdf.setFontSize(18);
        pdf.setFont(undefined, 'bold');
        const companyName = toString(jdData.company);
        const companyNameWidth = pdf.getStringUnitWidth(companyName) * pdf.internal.getFontSize() / pdf.internal.scaleFactor;
        const centerX = (pdf.internal.pageSize.width - companyNameWidth) / 2;
        pdf.text(companyName, centerX, 20);
        pdf.setFont(undefined, 'normal');
        pdf.setLineWidth(0.5);
        pdf.line(centerX, 22, centerX + companyNameWidth, 22); // Underline
        pdf.setFontSize(12);
        
        // Date - Right Aligned
        const currentDate = new Date().toLocaleDateString();
        pdf.text(`Date: ${currentDate}`, pdf.internal.pageSize.width - 20, 30, { align: 'right' });

        // Job Details
        pdf.setFontSize(12);
        const titleX = 20;
        const valueX = 70;
        
        // Helper function for adding fields
        const addField = (label, value, y) => {
            pdf.setFont(undefined, 'bold');
            pdf.setFontSize(12);
            pdf.text(label, titleX, y);
            pdf.setFont(undefined, 'normal');
            pdf.setFontSize(10);
            pdf.text(toString(value), valueX, y);
        };

        // Add job details fields
        addField('Job Role:', jdData.job_role, 40);
        addField('Location:', jdData.location, 50);
        addField('Experience:', jdData.experience, 60);
        addField('Salary:', jdData.salary, 70);
        addField('Job Type:', jdData.job_type, 80);
        addField('JD ID:', jdData.jd_id, 90);

        // Job Description Sections
        let yPos = 110;
        const pageHeight = pdf.internal.pageSize.height;

        // Function to check for page break
        const checkPageBreak = () => {
            if (yPos >= pageHeight - 20) {
                pdf.addPage();
                yPos = 20;
            }
        };

        // Helper function for adding sections
        const addSection = (title, content) => {
            if (content) {
                checkPageBreak();
                pdf.setFontSize(14);
                pdf.setFont(undefined, 'bold');
                pdf.setFontSize(12);
                pdf.text(title, 20, yPos);
                pdf.setFont(undefined, 'normal');
                pdf.setFontSize(10);
                yPos += 10;

                const lines = pdf.splitTextToSize(toString(content), 170);
                lines.forEach(line => {
                    checkPageBreak();
                    pdf.text(line, 20, yPos);
                    yPos += 6;
                });
                yPos += 10;
            }
        };

        // Add job description sections
        if (jdData.job_description) {
            addSection('About Us:', jdData.job_description.about_us);
            addSection('Job Overview:', jdData.job_description.job_overview);
            
            // Key Responsibilities with bullet points
            // Key Responsibilities
    if (jdData.job_description.key_responsibilities) {
        checkPageBreak();
        pdf.setFontSize(12);
        pdf.setFont(undefined, 'bold');
        pdf.text('Key Responsibilities:', 20, yPos);
        pdf.setFont(undefined, 'normal');
        pdf.setFontSize(10);
        yPos += 10;

        const responsibilities = cleanText(jdData.job_description.key_responsibilities).split('\n');
        responsibilities.forEach(resp => {
            if (resp.trim()) {
                const lines = pdf.splitTextToSize(resp.trim(), 160);
                lines.forEach((line, index) => {
                    checkPageBreak();
                    pdf.text(line, 25, yPos);
                    yPos += 6;
                });
            }
        });
        yPos += 10;
    }

    // Qualifications
    if (jdData.job_description.required_qualification_experience) {
        checkPageBreak();
        pdf.setFontSize(12);
        pdf.setFont(undefined, 'bold');
        pdf.text('Required Qualifications/Experience:', 20, yPos);
        pdf.setFont(undefined, 'normal');
        pdf.setFontSize(10);
        yPos += 10;

        const qualifications = cleanText(jdData.job_description.required_qualification_experience).split('\n');
        qualifications.forEach(qual => {
            if (qual.trim()) {
                const lines = pdf.splitTextToSize(qual.trim(), 160);
                lines.forEach((line, index) => {
                    checkPageBreak();
                    pdf.text(line, 25, yPos);
                    yPos += 6;
                });
            }
        });
        yPos += 10;
    }

    // Preferred Skills
    if (jdData.job_description.preferred_skills) {
        checkPageBreak();
        pdf.setFontSize(12);
        pdf.setFont(undefined, 'bold');
        pdf.text('Preferred Skills:', 20, yPos);
        pdf.setFont(undefined, 'normal');
        pdf.setFontSize(10);
        yPos += 10;

        const skills = cleanText(jdData.job_description.preferred_skills).split('\n');
        skills.forEach(skill => {
            if (skill.trim()) {
                const lines = pdf.splitTextToSize(skill.trim(), 160);
                lines.forEach((line, index) => {
                    checkPageBreak();
                    pdf.text(line, 25, yPos);
                    yPos += 6;
                });
            }
        });
    }

        }

        // Footer
        pdf.setFontSize(10);
        pdf.setTextColor(128, 128, 128);
        pdf.text('Generated by BotGuru', 20, pdf.internal.pageSize.height - 10);
        
        // Save the PDF
        const fileName = `JD_${jdData.job_role.replace(/\s+/g, '_')}_${new Date().toISOString().split('T')[0]}.pdf`;
        pdf.save(fileName);
    };

    // const handleNext = () => {
    //     if (currentStep === 3) {  // On last step of Job/Role tour
    //         if (jdData && jdData.length > 0 && !selectedCard.jobRole) {
    //             handleCardSelection('jobRole', jdData[0]._id);
    //         }
    //         setActiveButton('Resume');  // Move to Resume section
    //         setIsTourOpen(false);       // Close Job/Role tour
    //         setShowResumeTour(true);    // Start Resume tour
    //         setCurrentStep(0);          // Reset step count for Resume tour
    //     } else {
    //         setCurrentStep((prev) => Math.min(prev + 1, tourSteps.length - 1));
    //     }
    // };
    const handleNext = () => {
        if (currentSectionIndex < sections.length - 1) {
            setActiveButton(sections[currentSectionIndex + 1]);
        }
    };

    const handlePrev = () => {
        setCurrentStep((prev) => Math.max(prev - 1, 0));
    };

    const handleElementClick = (e, normalFunction) => {
        if (isTourOpen || showResumeTour) {
            if (e) e.preventDefault();
            handleNext();
        } else {
            normalFunction();
        }
    };

    const renderNewJobRoleCard = () => {
        return (
        <div className="launch-resume-card create-card" onClick={(e) => handleElementClick(e, () => {
            setCreateModalOpen(true);
            setIsTourOpen(false); // Close the tour when the user clicks on the create new JD button
        })}>
            <div className="resume-user-info">
            <i className="fas fa-plus-circle user-icon"></i>
            <span className="name">Create New JD</span>
                    </div>
                    <div className="create-content">
                    <i className="fas fa-file-alt feature-icon"></i>
                    <div className="job-title">Create New JD</div>
                        <p className="create-description">
                        Start creating a new job description
                        </p>
                    </div>
                    <div className="launch-card-footer">
                        <button 
                    className="action-btn create-btn create-jd-button"
                    onClick={(e) => handleElementClick(e, () => {
                        setCreateModalOpen(true);
                        setIsTourOpen(false); // Close the tour when the user clicks on the create new JD button
                    })}
                >
                         <i className="fas fa-cloud-upload-alt"></i>
                    Create New JD
                </button>
            </div>
        </div>
        );
    };

    const renderJobUploadRoleCard = () => {
        return (
        <div className="launch-resume-card upload-card" onClick={(e) => handleElementClick(e, () => {
            document.getElementById("fileInput").click();
            setIsTourOpen(false); // Close the tour when the user clicks on the upload JD button
        })}>
           <div className="resume-user-info">
                        <i className="fas fa-cloud-upload-alt user-icon"></i>
                        <span className="name">Upload JD</span>
                    </div>
                    <div className="upload-content">
                        <i className="fas fa-file-upload feature-icon"></i>
                        <div className="job-title">Import existing JD</div>
                        <p className="upload-description">
                            Supported formats: PDF, DOCX (Max 5 pages)
                        </p>
                    </div>
                    <div className="launch-card-footer">
                        <input
                            id="fileInput"
                            type="file"
                            accept=".docx,.pdf"
                            style={{ display: 'none' }}
                            onChange={handleJDFileChange}
                        />
                        <button 
                            className="action-btn upload-btn upload-jd-button"
                            onChange={handleJDFileChange}
                        >
                            <i className="fas fa-cloud-upload-alt"></i>
                            Upload JD
                        </button>
             </div>
        </div>
        );
    };

    const renderUploadResumeCard = () => {
        return (
            <div className="launch-resume-card upload-card">
                    <div className="resume-user-info">
                        <i className="fas fa-cloud-upload-alt user-icon"></i>
                        <span className="name">Upload Resume</span>
                    </div>
                    <div className="upload-content">
                        <i className="fas fa-file-upload feature-icon"></i>
                        <div className="job-title">Import existing resume</div>
                        <p className="upload-description">
                            Supported formats: PDF, DOCX (Max 5 pages)
                        </p>
                    </div>
                    <div className="launch-card-footer">
                        <input
                            id="fileInput"
                            type="file"
                            accept=".docx,.pdf"
                            style={{ display: 'none' }}
                            onChange={handleFileChange}
                        />
                        <button 
                            className="action-btn upload-btn"
                            onClick={() => document.getElementById('fileInput').click()}
                        >
                            <i className="fas fa-cloud-upload-alt"></i>
                            Upload Resume
                        </button>
                    </div>
            </div>
        );
    };

    const renderNewResumeCard = () => {
        return (
            <div className="launch-resume-card create-card">
                <div className="resume-user-info">
                    <i className="fas fa-plus-circle user-icon"></i>
                    <span className="name">Create New Resume</span>
                </div>
            <div className="create-content">
                <i className="fas fa-file-alt feature-icon"></i>
                <div className="job-title">Start from scratch</div>
                <p className="create-description">
                    Choose from multiple templates and create your perfect resume
                </p>
            </div>
            <div className="launch-card-footer">
                <button 
                    className="action-btn create-btn" 
                    onClick={() => {
                        setResumeData(null);
                        setresumeModal(true);
                    }}
                >
                    <i className="fas fa-plus"></i>
                    Create New Resume
                </button>
            </div>
        </div>
        );
    };

    const truncateJobRole = (jobRole) => {
        const maxLength = 18;
        const words = jobRole.split(' ');
    
        if (jobRole.length > maxLength || words.length > 2) {
            return `${jobRole.substring(0, maxLength - 3)}...`; // Truncate and add ellipsis
        }
        return jobRole; // Return original if within limits
    };

    const truncateCompany = (company) => {
        const maxLength = 10;
        const words = company.split(' ');
    
        if (company.length > maxLength || words.length > 2) {
            return `${company.substring(0, maxLength - 3)}...`; // Truncate and add ellipsis
        }
        return company; // Return original if within limits
    };
    const truncateJobType = (job_type) => {
        const maxLength = 10;
        const words = job_type.split(' ');
    
        if (job_type.length > maxLength || words.length > 2) {
            return `${job_type.substring(0, maxLength - 3)}...`; // Truncate and add ellipsis
        }
        return job_type; // Return original if within limits
    };

    const renderJobRoleCards = () => {
        if (!jdData || jdData.length === 0) {
            return null
        }
        
        return jdData.map((jd) => (
            <div 
                className={`launch-resume-card ${selectedCard.jobRole === jd._id ? 'selected' : ''}`}
                key={jd._id}
                onClick={() => handleCardSelection('jobRole', jd._id)}>
                        <div className="jd-user-info">
                        {/* <i className="fas fa-barcode resume-icon"></i>                             */}
                        <span className="resume-id-badge">
                        {/* <i className="fas fa-bolt id-icon"></i> */}
                            <i className="fas fa-star id-icon"></i>
                            <span className="jd-id-number">{jd.jd_id}</span>
                        </span>    
                        </div>

                        <div className="jd-job-title">
                            {truncateJobRole(jd.job_role)}
                        </div>
                        <div className="jd-info-row">
                            <div className="jd-info-item">
                                <span className="jd-info-label">Last Updated</span>
                                <div className="jd-info-value">
                                    <i className="far fa-clock"></i>
                                    {new Date(jd.created_at).toLocaleDateString('en-US', { day:'numeric',year: 'numeric', month: 'short' })}
                                </div>
                            </div>
                            <div className="jd-info-item">
                                <span className="jd-info-label">Company</span>
                                <div className="jd-info-value">
                                    <i className="fas fa-briefcase"></i>
                                    {truncateCompany(jd.company)||'-'}
                                </div>
                            </div>
                            <div className="jd-info-item">
                                <span className="jd-info-label">Job Type</span>
                                <div className="jd-info-value">
                                    <i className="fas fa-briefcase"></i>
                                    {truncateJobType(jd.job_type)||'-'}
                                </div>
                            </div>
                            <div className="jd-info-item">
                                <span className="jd-info-label">Experience</span>
                                <div className="jd-info-value">
                                    <i className="fas fa-briefcase"></i>
                                    {jd.experience||'-'}
                                </div>
                            </div>

                        </div>
                        <div className="launch-card-footer">
                        {/* <div className="resume-id">ID: {resume.personalDetails.resumeId}</div> */}
                        <div className="actions">
                                <button 
                                    className="action-btn view-jd-button"
                                    onClick={(e) => handleElementClick(e, () => {
                                        e.stopPropagation();
                                        openJDDetailsModal(jd._id);
                                    })}
                                >
                                    <i className="fas fa-eye"></i>
                                    View
                                </button>
                                <button 
                                    className="action-btn download-jd-button"
                                    onClick={(e) => handleElementClick(e, () => {
                                        e.stopPropagation();
                                        handleDownloadPDF(jd);
                                    })}
                                >
                                <i className="fas fa-download"></i>
                                Download
                                </button>
                    </div>
                </div>
            </div>
        ));
    };

    const handleDownloadResume = (resumeData) => {
        // Transform the data to match ResumePreview format
        const transformedData = {
            message: {
                first_name: resumeData.resume.personalDetails.firstName,
                last_name: resumeData.resume.personalDetails.lastName,
                email: resumeData.resume.personalDetails.email,
                phone_number: resumeData.resume.personalDetails.phoneNumber,
                linkedin_url: resumeData.resume.personalDetails.linkedIn,
                website_url: resumeData.resume.personalDetails.website,
                professional_summary: resumeData.resume.personalDetails.summary,
                experience: resumeData.resume.experiences?.map(exp => ({
                    job_role: exp.jobTitle,
                    company_name: exp.companyName,
                    start_date: exp.startDate,
                    end_date: exp.endDate,
                    city_state: exp.location,
                    responsibilities: exp.description?.split('\n')
                })),
                skills: resumeData.resume.skills,
                education: resumeData.resume.education?.map(edu => ({
                    school_university: edu.school,
                    degree: edu.degree,
                    start_date: edu.startDate,
                    end_date: edu.endDate,
                    gpa: edu.gpa,
                    additionalDetails: edu.additionalDetails
                }))
            }
        };

        const pdf = new jsPDF();
        let yPos = 20;
        const margin = 20;
        const pageWidth = pdf.internal.pageSize.width;

        // Helper function for page breaks
        const checkPageBreak = (requiredSpace = 40) => {
            if (yPos > pdf.internal.pageSize.height - requiredSpace) {
                pdf.addPage();
                yPos = 20;
            }
        };

        // Personal Details Section
        pdf.setFont("helvetica", "bold");
        pdf.setFontSize(24);
        const fullName = `${transformedData.message.first_name} ${transformedData.message.last_name}`;
        const nameWidth = pdf.getStringUnitWidth(fullName) * pdf.getFontSize() / pdf.internal.scaleFactor;
        pdf.text(fullName, (pageWidth - nameWidth) / 2, yPos);
        yPos += 5;

        // Contact Info
        pdf.setFontSize(11);
        pdf.setFont("helvetica", "normal");
        const contactInfo = [];
        if (transformedData.message.email) contactInfo.push(transformedData.message.email);
        if (transformedData.message.phone_number) contactInfo.push(transformedData.message.phone_number);
        const contactText = contactInfo.join(" | ");
        const contactWidth = pdf.getStringUnitWidth(contactText) * pdf.getFontSize() / pdf.internal.scaleFactor;
        pdf.text(contactText, (pageWidth - contactWidth) / 2, yPos);
        yPos += 15;

        // Social Links
        if (transformedData.message.linkedin_url || transformedData.message.website_url) {
            const socialLinks = [];
            if (transformedData.message.linkedin_url) socialLinks.push(transformedData.message.linkedin_url);
            if (transformedData.message.website_url) socialLinks.push(transformedData.message.website_url);
            const socialText = socialLinks.join(" | ");
            const socialWidth = pdf.getStringUnitWidth(socialText) * pdf.getFontSize() / pdf.internal.scaleFactor;
            pdf.text(socialText, (pageWidth - socialWidth) / 2, yPos);
            yPos += 20;
        }

        // Professional Summary
        if (transformedData.message.professional_summary) {
            pdf.setFont("helvetica", "bold");
            pdf.setFontSize(14);
            pdf.text("Professional Summary", margin, yPos);
            yPos += 8;

            pdf.setFont("helvetica", "normal");
            pdf.setFontSize(11);
            const summaryLines = pdf.splitTextToSize(transformedData.message.professional_summary, pageWidth - 2 * margin);
            summaryLines.forEach(line => {
                checkPageBreak();
                pdf.text(line, margin, yPos);
                yPos += 6;
            });
            yPos += 15;
        }

        // Experience Section
        if (transformedData.message.experience?.length) {
            checkPageBreak();
            pdf.setFont("helvetica", "bold");
            pdf.setFontSize(14);
            pdf.text("Experience", margin, yPos);
            yPos += 10;

            transformedData.message.experience.forEach(exp => {
                checkPageBreak();
                pdf.setFont("helvetica", "bold");
                pdf.setFontSize(12);
                pdf.text(exp.job_role, margin, yPos);
                yPos += 6;

                pdf.setFont("helvetica", "italic");
                pdf.setFontSize(11);
                pdf.text(exp.company_name, margin, yPos);
                
                pdf.setFont("helvetica", "normal");
                const dateText = `${exp.start_date} - ${exp.end_date}`;
                const dateWidth = pdf.getStringUnitWidth(dateText) * pdf.getFontSize() / pdf.internal.scaleFactor;
                pdf.text(dateText, pageWidth - margin - dateWidth, yPos);
                yPos += 6;

                if (exp.city_state) {
                    pdf.text(`Location: ${exp.city_state}`, margin, yPos);
                    yPos += 6;
                }

                if (exp.responsibilities?.length) {
                    yPos += 4;
                    exp.responsibilities.forEach(resp => {
                        checkPageBreak();
                        const lines = pdf.splitTextToSize(resp, pageWidth - 2 * margin);
                        lines.forEach(line => {
                            pdf.text(line, margin, yPos);
                            yPos += 6;
                        });
                    });
                }
                yPos += 10;
            });
        }

        // Skills Section
        if (transformedData.message.skills?.length) {
            checkPageBreak();
            pdf.setFont("helvetica", "bold");
            pdf.setFontSize(14);
            pdf.text("Skills", margin, yPos);
            yPos += 10;

            pdf.setFont("helvetica", "normal");
            pdf.setFontSize(11);
            
            const skillsPerRow = 3;
            const skillWidth = (pageWidth - 2 * margin) / skillsPerRow;
            
            for (let i = 0; i < transformedData.message.skills.length; i += skillsPerRow) {
                checkPageBreak();
                const rowSkills = transformedData.message.skills.slice(i, i + skillsPerRow);
                rowSkills.forEach((skill, index) => {
                    pdf.text(skill, margin + (index * skillWidth), yPos);
                });
                yPos += 8;
            }
            yPos += 10;
        }

        // Education Section
        if (transformedData.message.education?.length) {
            checkPageBreak();
            pdf.setFont("helvetica", "bold");
            pdf.setFontSize(14);
            pdf.text("Education", margin, yPos);
            yPos += 10;

            transformedData.message.education.forEach(edu => {
                checkPageBreak();
                pdf.setFont("helvetica", "bold");
                pdf.setFontSize(12);
                pdf.text(edu.school_university, margin, yPos);
                yPos += 6;

                pdf.setFont("helvetica", "italic");
                pdf.setFontSize(11);
                pdf.text(edu.degree, margin, yPos);

                const dateText = `${edu.start_date} - ${edu.end_date}`;
                const dateWidth = pdf.getStringUnitWidth(dateText) * pdf.getFontSize() / pdf.internal.scaleFactor;
                pdf.text(dateText, pageWidth - margin - dateWidth, yPos);
                
                if (edu.gpa) {
                    yPos += 6;
                    pdf.setFont("helvetica", "normal");
                    pdf.text(`GPA: ${edu.gpa}`, margin, yPos);
                }

                if (edu.additionalDetails) {
                    yPos += 6;
                    pdf.setFont("helvetica", "normal");
                    const detailLines = pdf.splitTextToSize(edu.additionalDetails, pageWidth - 2 * margin);
                    detailLines.forEach(line => {
                        checkPageBreak();
                        pdf.text(line, margin, yPos);
                        yPos += 6;
                    });
                }
                
                yPos += 15;
            });
        }

        // Save the PDF
        const fileName = `${transformedData.message.first_name}_${transformedData.message.last_name}_Resume.pdf`;
        pdf.save(fileName);
    };

    const handleViewResume = (resumeData) => {
        console.log('Viewing Resume:', resumeData);
        // Transform the resume data to match ResumePreview's expected format
        const transformedData = {
            message: {
                first_name: resumeData.resume.personalDetails.firstName,
                last_name: resumeData.resume.personalDetails.lastName,
                email: resumeData.resume.personalDetails.email,
                phone_number: resumeData.resume.personalDetails.phoneNumber,
                linkedin_url: resumeData.resume.personalDetails.linkedIn,
                website_url: resumeData.resume.personalDetails.website,
                professional_summary: resumeData.resume.personalDetails.summary,
                experience: resumeData.resume.experiences?.map(exp => ({
                    job_role: exp.jobTitle,
                    company_name: exp.companyName,
                    start_date: exp.startDate,
                    end_date: exp.endDate,
                    city_state: exp.location,
                    responsibilities: exp.description?.split('\n')
                })),
                skills: resumeData.resume.skills,
                education: resumeData.resume.education?.map(edu => ({
                    school_university: edu.school,
                    degree: edu.degree,
                    start_date: edu.startDate,
                    end_date: edu.endDate,
                    gpa: edu.gpa,
                    additionalDetails: edu.additionalDetails
                }))
            }
        };
        
        setPreviewData(transformedData);
        setShowPreview(true);
    };


    const calculateTotalExperience = (experiences) => {
        if (!experiences || experiences.length === 0) return 'No experience';
    
        let totalMonths = 0;
        
        experiences.forEach(exp => {
            const startDate = parseDate(exp.startDate);
            const endDate = exp.endDate === 'Present' ? new Date() : parseDate(exp.endDate);
            
            if (startDate && endDate) {
                const months = (endDate.getFullYear() - startDate.getFullYear()) * 12 + 
                              (endDate.getMonth() - startDate.getMonth());
                totalMonths += months;
            }
        });
    
        const years = Math.round(totalMonths / 12);
        return `${years}+ years`;
    };
    
    const parseDate = (dateStr) => {
        if (!dateStr) return null;
        if (dateStr === 'Present') return new Date();
        
        const [month, year] = dateStr.split(' ');
        const monthIndex = new Date(Date.parse(month + " 1, 2000")).getMonth();
        return new Date(parseInt(year), monthIndex);
    };

    const truncateResumeJobTitle = (jobTitle) => {
        const maxLength = 28;
        if (jobTitle.length > maxLength) {
            return `${jobTitle.substring(0, maxLength - 3)}...`; // Truncate and add ellipsis
        }
        return jobTitle; // Return original if within limits
    };

    const renderResumeCard = (resumeData) => {
        const resume = resumeData.resume;
        const fullName = `${resume.personalDetails.firstName} ${resume.personalDetails.lastName}`.trim();
        return (
            <div 
                className={`launch-resume-card ${selectedCard.resume === resumeData._id ? 'selected' : ''}`}
                key={resumeData._id}
                onClick={() => handleCardSelection('resume', resumeData._id)}
            >
                <div className="resume-user-info">
                    <span className="resume-id-badge">
                            {/* <i className="fas fa-bolt id-icon"></i> */}
                            <i className="fas fa-star id-icon"></i>
                            <span className="id-number">{resumeData.resume_id}</span>
                    </span> 
                    {/* <span className="file-type" onClick={(e) => {
                        e.stopPropagation();
                        // handleEditResume(resumeData);
                    }}>
                        <i className="fas fa-pen"></i>
                    </span> */}
                </div>
    
                <div className="job-title">
                    {truncateResumeJobTitle(resume.experiences?.[0]?.jobTitle || 'No Job Title')}
                </div>
    
                <div className="info-row">
                    <div className="info-item">
                        <span className="info-label">Last Updated</span>
                        <div className="info-value">
                            <i className="far fa-clock"></i>
                            {new Date(resumeData.last_updated).toLocaleDateString('en-US', { day:'numeric',year: 'numeric', month: 'short' })}
                        </div>
                    </div>
                    <div className="info-item">
                        <span className="info-label">Experience</span>
                        <div className="info-value">
                            <i className="fas fa-briefcase"></i>
                            {calculateTotalExperience(resume.experiences)}
                        </div>
                    </div>
                </div>
    
                <div className="skills-section">
                    <span className="skills-label">Key Skills</span>
                    <div className="skills-list">
                        {resume.skills?.slice(0, 4).map((skill, index) => (
                            <span key={index} className="skill-tag">
                                {skill}
                            </span>
                        ))}
                    </div>
                </div>
    
                <div className="launch-card-footer">
                    {/* <div className="resume-id">ID: {resume.personalDetails.resumeId}</div> */}
                    <div className="actions">
                        <button 
                            className="action-btn"
                            onClick={(e) => {
                                e.stopPropagation();
                                handleViewResume(resumeData);
                            }}
                        >
                            <i className="far fa-eye"></i>
                            View
                        </button>
                        <button 
                            className="action-btn"
                            onClick={(e) => {
                                e.stopPropagation();
                                handleDownloadResume(resumeData);
                            }}
                        >
                            <i className="fas fa-download"></i>
                            Download
                        </button>
                    </div>
                </div>
            </div>
        );
    };


    if (!isOpen) return null;

    return (
        <>
            {isLoading && <LoadTransitionPage url="https://botguru.ai:5174/" setIsLoading={setIsLoading} />} {/* Pass setIsLoading */}
            {!isLoading && (
            <div className="modal-overlay">
                <div className="launch-modal-content">
                    <button className="completed-close-button" onClick={handleCloseModal}>
                        &times;
                    </button>
                    
                    {/* Top bar with burger menu */}
                    <div className='left-launch-page'>
                        <button className="burger-menu-btn" onClick={toggleMenu}></button>
                        
                        {/* Slide-out menu */}
                        <div className={`preplanding-services ${isMenuOpen ? 'menu-open' : ''}`}>
                            {buttons.map(button => (
                                <button
                                    key={button.id}
                                    className={`launch-service-button ${activeButton === button.name ? 'active' : ''}`}
                                    onClick={() => {
                                        handleButtonClick(button.name);
                                        setIsMenuOpen(false);
                                    }}
                                    disabled={disabledButtons[button.name]}
                                >
                                    <span className="button-serial">
                                        <strong className="circle">{button.id}</strong>
                                    </span>
                                    {button.label}
                                </button>
                            ))}
                        </div>
                    </div>

                    {/* Main content area */}
                    <div className="right-launch-page">
                        {activeButton==='Job/Role' &&
                            <div className="launch-right">
                                <h1>Select your Job/Role</h1>
                                <h4 className="pre-sub-title">Selected Job/Role would be used for your prep session</h4>
                                <div className="jdlist-card-container ">
                                {renderJobUploadRoleCard()} 
                                {renderNewJobRoleCard()}
                                {renderJobRoleCards()}
                                </div>
                            </div>
                        }
                        {activeButton==='Resume' &&
                            <div className="launch-right">
                                <h1>Select your Resume</h1>
                                <h4>Selected resume would be used for your prep session</h4>
                                <div className="resumelist-card-container ">
                                    {renderUploadResumeCard()}
                                    {renderNewResumeCard()}
                                    {resumes.map((resume, index) => renderResumeCard(resume, index))}
                                </div>
                            </div>
                        }
                        {activeButton==='Persona' &&
                            <div className="launch-right">
                                <h1>Select your Trainer</h1>
                                <h4 className="pre-sub-title">Selected Trainer would be conducting your session</h4>
                                <div className="persona-container">
                                    <div className="persona-name">
                                        <button className={`persona ${activePersona === 0 ? 'active' : ''}`}  onClick={()=>handlePersonClick(0)}>
                                            <img src="femalebot.png"></img>
                                        </button>
                                        <h1>Maya</h1>
                                    </div>

                                    <div className="persona-name">
                                        <button className={`persona ${activePersona === 1? 'active' : ''}`} onClick={()=>handlePersonClick(1)}>
                                            <img src="malebot.png"></img>
                                        </button>
                                        <h1>Leo</h1>
                                    </div>
                                </div>
                            </div>}
                        {activeButton==='Launch' &&
                            <div className="launch-right">

                        <h1>Overview of your selection</h1>
                        <h4 className="pre-sub-title">Please review the selected Job/Role, Resume and Trainer for your prep session:</h4>
                        
                        <div className="selected-cards-container">
                        {/* Selected JD Card */}
                        {selectedCard.jobRole && (
                        <div className="launch-resume-card selected">
                        <div className="jd-user-info">
                        {/* <i className="fas fa-barcode resume-icon"></i>                             */}
                        <span className="resume-id-badge">
                        {/* <i className="fas fa-bolt id-icon"></i> */}
                            <i className="fas fa-star id-icon"></i>
                            <span className="jd-id-number">{jdData.find(jd => jd._id === selectedCard.jobRole)?.jd_id || "-"}</span>
                        </span>    
                        </div>
                        <div className="jd-job-title">
                        {jdData.find(jd => jd._id === selectedCard.jobRole)?.job_role || "-"}
                        </div>

                        <div className="jd-info-row">
                            <div className="jd-info-item">
                                <span className="jd-info-label">Last Updated</span>
                                <div className="jd-info-value">
                                    <i className="far fa-clock"></i>
                                    {new Date(jdData.find(jd => jd._id === selectedCard.jobRole)?.created_at).toLocaleDateString('en-US', { day:'numeric',year: 'numeric', month: 'short' })}
                                </div>
                            </div>
                            <div className="jd-info-item">
                                <span className="jd-info-label">Company</span>
                                <div className="jd-info-value">
                                    <i className="fas fa-briefcase"></i>
                                    {truncateCompany (jdData.find(jd => jd._id === selectedCard.jobRole)?.company || "-")}
                                </div>
                            </div>
                            <div className="jd-info-item">
                                <span className="jd-info-label">Job Type</span>
                                <div className="jd-info-value">
                                    <i className="fas fa-briefcase"></i>
                                    {truncateJobType(jdData.find(jd => jd._id === selectedCard.jobRole)?.job_type || "-")}
                                </div>
                            </div>
                            <div className="jd-info-item">
                                <span className="jd-info-label">Experience</span>
                                <div className="jd-info-value">
                                    <i className="fas fa-briefcase"></i>
                                    {jdData.find(jd => jd._id === selectedCard.jobRole)?.experience || "-"}
                                </div>
                            </div>

                        </div>
                        <div className="launch-card-footer">
                        {/* <div className="resume-id">ID: {resume.personalDetails.resumeId}</div> */}
                        <div className="actions">
                                <button 
                                    className="action-btn view-jd-button"
                                    onClick={(e) => handleElementClick(e, () => {
                                        e.stopPropagation();
                                        openJDDetailsModal(selectedCard.jobRole);
                                    })}
                                >
                                    <i className="fas fa-eye"></i>
                                    View
                                </button>
                                <button 
                                    className="action-btn download-jd-button"
                                    onClick={(e) => handleElementClick(e, () => {
                                        e.stopPropagation();
                                        const selectedJD = jdData.find(jd => jd._id === selectedCard.jobRole);
                                        handleDownloadPDF(selectedJD);
                                    })}
                                >
                                <i className="fas fa-download"></i>
                                Download
                                </button>
                    </div>
                </div>

                            {/* <div className="jd-guru-top">
                                <div className="jd-guru-role">
                                    <h3>{jdData.find(jd => jd._id === selectedCard.jobRole)?.job_role || "Role Title"}</h3>
                                </div>
                                <div className="jd-guru-company">
                                    <span className="jd-guru-company-badge">
                                        {jdData.find(jd => jd._id === selectedCard.jobRole)?.company || "Company Name"}
                                    </span>
                                    <div className="jd-guru-type">
                                        {jdData.find(jd => jd._id === selectedCard.jobRole)?.job_type || "Full Time"}
                                    </div>
                                </div>
                            </div> */}

                            {/* <div className="jd-guru-details">
                                <div className="jd-guru-detail-item">
                                    <i className="fas fa-map-marker-alt"></i>
                                    {jdData.find(jd => jd._id === selectedCard.jobRole)?.location || "-"}
                                </div>
                                <div className="jd-guru-detail-item">
                                    <i className="fas fa-briefcase"></i>
                                    {jdData.find(jd => jd._id === selectedCard.jobRole)?.experience || "-"}
                                </div>
                                <div className="jd-guru-detail-item">
                                    <i className="fas fa-dollar-sign"></i>
                                    {jdData.find(jd => jd._id === selectedCard.jobRole)?.salary || "-"}
                                </div>
                            </div>

                            <div className="jd-guru-actions">
                                <button 
                                    className="jd-action-btn preview-jd"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        openJDDetailsModal(selectedCard.jobRole);
                                    }}
                                >
                                    <i className="fas fa-eye"></i>
                                    Preview
                                </button>
                                <button 
                                    className="jd-action-btn download-jd"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        const selectedJD = jdData.find(jd => jd._id === selectedCard.jobRole);
                                        handleDownloadPDF(selectedJD);
                                    }}
                                >
                                    <i className="fas fa-download"></i>
                                    Download
                                </button>
                            </div> */}
                        </div>
                        )}

                        {/* Selected Resume Card */}
                        {selectedCard.resume && (
                        <div className="launch-resume-card selected">
                            <div className="resume-user-info">
                                <span className="resume-id-badge">
                            {/* <i className="fas fa-bolt id-icon"></i> */}
                            <i className="fas fa-star id-icon"></i>
                            <span className="id-number">{resumes.find(resume => resume._id === selectedCard.resume)?.resume_id}</span>
                            </span>
                            </div>

                            <div className="job-title">
                                {resumes.find(resume => resume._id === selectedCard.resume)?.resume.experiences?.[0]?.jobTitle || 'No Job Title'}
                            </div>

                            <div className="info-row">
                                <div className="info-item">
                                    <span className="info-label">Last Updated</span>
                                    <div className="info-value">
                                        <i className="far fa-clock"></i>
                                        {new Date(resumes.find(resume => resume._id === selectedCard.resume)?.last_updated).toLocaleDateString('en-US', { day:'numeric',year: 'numeric', month: 'short' })}
                                    </div>
                                </div>
                                <div className="info-item">
                                    <span className="info-label">Experience</span>
                                    <div className="info-value">
                                        <i className="fas fa-briefcase"></i>
                                        {calculateTotalExperience(resumes.find(resume => 
                                            resume._id === selectedCard.resume)?.resume.experiences)}
                                    </div>
                                </div>
                            </div>

                            <div className="skills-section">
                                <span className="skills-label">Key Skills</span>
                                <div className="skills-list">
                                    {resumes.find(resume => 
                                        resume._id === selectedCard.resume)?.resume.skills?.slice(0, 4).map((skill, index) => (
                                        <span key={index} className="skill-tag">
                                            {skill}
                                        </span>
                                    ))}
                                </div>
                            </div>

                            <div className="launch-card-footer">
                                <div className="actions">
                                    <button 
                                        className="action-btn"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            const selectedResume = resumes.find(resume => 
                                                resume._id === selectedCard.resume);
                                            handleViewResume(selectedResume);
                                        }}
                                    >
                                        <i className="far fa-eye"></i>
                                        View
                                    </button>
                                    <button 
                                        className="action-btn"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            const selectedResume = resumes.find(resume => 
                                                resume._id === selectedCard.resume);
                                            handleDownloadResume(selectedResume);
                                        }}
                                    >
                                        <i className="fas fa-download"></i>
                                        Download
                                    </button>
                                </div>
                            </div>
                        </div>
                        )}

                        {/* Selected Persona */}
                        {activePersona !== null && (
                        <div className={`selected-cards-container ${showTourGuideInfo ? 'tour-active' : ''}`}>
                            {showTourGuideInfo && showLaunchTour && (
                                <>
                                    <div className="launch-resume-card selected tour-active">
                                        {/* First container content */}
                                        <div className="resume-user-info">
                                            <span className="resume-id-badge">
                                                <i className="fas fa-star id-icon"></i>
                                                <span className="id-number">{resumes.find(resume => resume._id === selectedCard.resume)?.resume_id}</span>
                                            </span>
                                        </div>

                                        <div className="job-title">
                                            {resumes.find(resume => resume._id === selectedCard.resume)?.resume.experiences?.[0]?.jobTitle || 'No Job Title'}
                                        </div>

                                        <div className="info-row">
                                            <div className="info-item">
                                                <span className="info-label">Last Updated</span>
                                                <div className="info-value">
                                                    <i className="far fa-clock"></i>
                                                    {new Date(resumes.find(resume => resume._id === selectedCard.resume)?.last_updated).toLocaleDateString('en-US', { day:'numeric',year: 'numeric', month: 'short' })}
                                                </div>
                                            </div>
                                            <div className="info-item">
                                                <span className="info-label">Experience</span>
                                                <div className="info-value">
                                                    <i className="fas fa-briefcase"></i>
                                                    {calculateTotalExperience(resumes.find(resume => 
                                                        resume._id === selectedCard.resume)?.resume.experiences)}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="skills-section">
                                            <span className="skills-label">Key Skills</span>
                                            <div className="skills-list">
                                                {resumes.find(resume => 
                                                    resume._id === selectedCard.resume)?.resume.skills?.slice(0, 4).map((skill, index) => (
                                                    <span key={index} className="skill-tag">
                                                        {skill}
                                                    </span>
                                                ))}
                                            </div>
                                        </div>

                                        <div className="launch-card-footer">
                                            <div className="actions">
                                                <button 
                                                    className="action-btn"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        const selectedResume = resumes.find(resume => 
                                                            resume._id === selectedCard.resume);
                                                        handleViewResume(selectedResume);
                                                    }}
                                                >
                                                    <i className="far fa-eye"></i>
                                                    View
                                                </button>
                                                <button 
                                                    className="action-btn"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        const selectedResume = resumes.find(resume => 
                                                            resume._id === selectedCard.resume);
                                                        handleDownloadResume(selectedResume);
                                                    }}
                                                >
                                                    <i className="fas fa-download"></i>
                                                    Download
                                                </button>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Second container - identical to the first */}
                                    <div className="launch-resume-card selected tour-active">
                                        {/* Same content structure as above */}
                                        <div className="resume-user-info">
                                            <span className="resume-id-badge">
                                                <i className="fas fa-star id-icon"></i>
                                                <span className="id-number">{resumes.find(resume => resume._id === selectedCard.resume)?.resume_id}</span>
                                            </span>
                                        </div>

                                        <div className="job-title">
                                            {resumes.find(resume => resume._id === selectedCard.resume)?.resume.experiences?.[0]?.jobTitle || 'No Job Title'}
                                        </div>

                                        <div className="info-row">
                                            <div className="info-item">
                                                <span className="info-label">Last Updated</span>
                                                <div className="info-value">
                                                    <i className="far fa-clock"></i>
                                                    {new Date(resumes.find(resume => resume._id === selectedCard.resume)?.last_updated).toLocaleDateString('en-US', { day:'numeric',year: 'numeric', month: 'short' })}
                                                </div>
                                            </div>
                                            <div className="info-item">
                                                <span className="info-label">Experience</span>
                                                <div className="info-value">
                                                    <i className="fas fa-briefcase"></i>
                                                    {calculateTotalExperience(resumes.find(resume => 
                                                        resume._id === selectedCard.resume)?.resume.experiences)}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="skills-section">
                                            <span className="skills-label">Key Skills</span>
                                            <div className="skills-list">
                                                {resumes.find(resume => 
                                                    resume._id === selectedCard.resume)?.resume.skills?.slice(0, 4).map((skill, index) => (
                                                    <span key={index} className="skill-tag">
                                                        {skill}
                                                    </span>
                                                ))}
                                            </div>
                                        </div>

                                        <div className="launch-card-footer">
                                            <div className="actions">
                                                <button 
                                                    className="action-btn"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        const selectedResume = resumes.find(resume => 
                                                            resume._id === selectedCard.resume);
                                                        handleViewResume(selectedResume);
                                                    }}
                                                >
                                                    <i className="far fa-eye"></i>
                                                    View
                                                </button>
                                                <button 
                                                    className="action-btn"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        const selectedResume = resumes.find(resume => 
                                                            resume._id === selectedCard.resume);
                                                        handleDownloadResume(selectedResume);
                                                    }}
                                                >
                                                    <i className="fas fa-download"></i>
                                                    Download
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                            
                            {/* Moved persona container to the end */}
                            <div className={`launch-persona-container ${showTourGuideInfo ? 'tour-active' : ''}`}>
                                <div className="persona-name">
                                    <div className={`launch-persona ${activePersona === 0 ||activePersona === 1 ? "active" : ""}`}>
                                        <img
                                            src={activePersona === 0 ? "femalebot.png" : "malebot.png"}
                                            alt={activePersona === 0 ? "Female launch-Persona" : "Male launch-Persona"}
                                        />
                                    </div>
                                    <h2>{activePersona === 0 ? "Maya" : "Leo"}</h2>
                                </div>
                            </div>
                        </div>
                        )}
                        </div>
                                <div className="launch-btn-container">
                                    <button className="prev-button button" onClick={handlePrevious}>Previous</button>
                                    <button
                                        className="launch-button button"
                                        disabled={ userAnalytics?.prepbot_total<=userAnalytics?.prepbot_used  || selectedCard.resume==null || selectedCard.jobRole==null}
                                        onClick={
                                            () => {
                                            setIsLoading(true);
                                            const websocketUrl = "wss://o834xau6qe.execute-api.us-west-1.amazonaws.com/production/";
                                            const websocket = new WebSocket(websocketUrl);
                                            let newUrl = null; // Store the new URL temporarily

                                            websocket.onopen = () => {
                                                console.log("WebSocket connection established.");
                                                const message = {action: "botguru_sendmessage", message: "get data"};
                                                websocket.send(JSON.stringify(message));
                                            };

                                            websocket.onmessage = async (event) => {
                                                try {
                                                    if (!event.data || event.data.trim() === "") {
                                                        console.warn("Received empty or irrelevant WebSocket message.");
                                                        return;
                                                    }
                                                    const data = JSON.parse(event.data);

                                                    if (data.connectionid) {
                                                        console.log("Connection ID received:", data.connectionid);

                                                        // Retrieve full content of the selected JD
                                                        const selectedJDContent = jdData.find(
                                                            jd => jd._id === selectedCard.jobRole
                                                        ) || { message: "No JD Selected" };

                                                        // Retrieve full content of the selected Resume
                                                        const selectedResumeContent = resumes.find(
                                                            resume => resume._id === selectedCard.resume
                                                        ) || {message: "No Resume Selected"};

                                                        // Extract user information from the selected resume
                                                        const userEmail = selectedResumeContent.resume?.personalDetails?.email || "email8@email.com";
                                                        const userName = selectedResumeContent.resume?.personalDetails
                                                            ? `${selectedResumeContent.resume.personalDetails.firstName} ${selectedResumeContent.resume.personalDetails.lastName}`
                                                            : "User";
                                                        const resumeId = selectedResumeContent.resume_id || "00001";

                                                        // Helper function to format JSON as resume-like text
                                                        const formatJsonAsText = (json) => {
                                                            const traverse = (obj) => {
                                                                return Object.entries(obj)
                                                                    .map(([key, value]) => {
                                                                        if (typeof value === "object" && value !== null) {
                                                                            return traverse(value); // Recursively handle objects
                                                                        } else if (Array.isArray(value)) {
                                                                            return `• ${key}: ${value.join(", ")}`;
                                                                        } else {
                                                                            return `• ${key}: ${value}`;
                                                                        }
                                                                    })
                                                                    .join("\n");
                                                            };
                                                            return traverse(json);
                                                        };

                                                        // Convert JD and Resume JSON to formatted text
                                                        
                                                        const jobDescriptionText = (selectedJDContent.job_description || {});
                                                        console.log("Job Description Text:", jobDescriptionText);
                                                        const resumeText = formatJsonAsText(selectedResumeContent.resume || {});
                                                        const resume_obj_id = (selectedResumeContent._id || {});
                                                        const job_role = (selectedJDContent.job_role || {});
                                                        const job_id = (selectedJDContent.jd_id || {});
                                                        const job_obj_id = (selectedJDContent._id || {});

                                                        // Generate a random user ID
                                                        const generateRandomUserId = () => {
                                                            const array = new Uint8Array(12); // Generate 12 random bytes
                                                            window.crypto.getRandomValues(array);
                                                            return Array.from(array, byte => byte.toString(16).padStart(2, '0')).join('');
                                                        };

                                                        // Prepare the JSON object with user details
                                                        const selectedData = {
                                                            user_id: userObjId,        //updates with login
                                                            user_obj_id: userObjId,    //updates with login
                                                            userfirstname: profileData?.name,                     //updates with login
                                                            resume_obj_id: resume_obj_id,
                                                            job_role: job_role,
                                                            user_email: userEmail,
                                                            local_time: new Date().toLocaleString(),
                                                            bot_type:'prepbot',
                                                            user_name: profileData?.name,
                                                            job_description: jobDescriptionText,
                                                            job_id: job_id,
                                                            job_obj_id: job_obj_id,
                                                            resume: resumeText,
                                                            resumeId: resumeId,
                                                            persona: activePersona !== null
                                                                ? (activePersona === 0 ? "f" : "m")
                                                                : "No Persona Selected",
                                                            interviewType: selectedCard.interview || "No Interview Type Selected",
                                                            connectionId: data.connectionid,
                                                            region: "us-west-1",
                                                        };

                                                        // Log the JSON data
                                                        console.log("Selected Data JSON with User Information:", selectedData);

                                                        // Send the JSON data as a POST request
                                                        try {
                                                            const response = await fetch(`${config.restAPI}/startInterview/`, {
                                                                method: "POST",
                                                                credentials:"include",
                                                                headers: {
                                                                    "Content-Type": "application/json",
                                                                },
                                                                body: JSON.stringify(selectedData),
                                                            });

                                                            if (!response.ok) {
                                                                throw new Error(`Failed to send data. Status: ${response.status}`);
                                                            }

                                                            const responseData = await response.json();
                                                            console.log("API response:", responseData);
                                                        } catch (apiError) {
                                                            console.error("Error sending data to API:", apiError.message);
                                                        }
                                                    } else if (data.url) {
                                                        // Handle the URL case and display it as an iframe
                                                        // setIsLoading(false)
                                                        console.log("Opening URL in iframe:", data.url);
                                                        console.log(isLoading + " isLoading")
                                                        newUrl = data.url; // Store the new URL temporarily
                                                        const handleMessage = (event) => {
                                                            if (event.data === "operationComplete") {
                                                                console.log("operationComplete message received from iframe");


                                                                // clearInterval(interval);

                                                                // Display the new URL in an iframe
                                                                const iframeContainer = document.createElement("div");
                                                                iframeContainer.style.position = "fixed";
                                                                iframeContainer.style.top = "0";
                                                                iframeContainer.style.left = "0";
                                                                iframeContainer.style.width = "100%";
                                                                iframeContainer.style.height = "100%";
                                                                iframeContainer.style.backgroundColor = "rgba(0, 0, 0, 0.5)";
                                                                iframeContainer.style.zIndex = "9999";

                                                                const iframe = document.createElement("iframe");
                                                                iframe.src = newUrl;
                                                                iframe.style.width = "100%";
                                                                iframe.style.height = "100%";
                                                                iframe.style.border = "none";
                                                                iframe.allow = "camera; microphone; fullscreen"; // Allow camera and microphone access

                                                                const closeButton = document.createElement("button");
                                                                closeButton.innerText = "Close";
                                                                closeButton.style.position = "absolute";
                                                                closeButton.style.bottom = "10px";
                                                                closeButton.style.left = "10px";
                                                                closeButton.style.zIndex = "10000";
                                                                closeButton.style.padding = "10px";
                                                                closeButton.style.backgroundColor = "#be01f9";
                                                                closeButton.style.color = "#fff";
                                                                closeButton.style.border = "none";
                                                                closeButton.style.cursor = "pointer";
                                                                closeButton.style.borderRadius = "5px";
                                                                closeButton.onclick = () => {
                                                                    document.body.removeChild(iframeContainer);
                                                                    window.location.href = "/landingPage"; // Redirect to the specified URL
                                                                };

                                                                iframeContainer.appendChild(iframe);
                                                                iframeContainer.appendChild(closeButton);
                                                                document.body.appendChild(iframeContainer);

                                                                window.removeEventListener("message", handleMessage); // Remove listener after use
                                                            }
                                                        }
                                                        // const iframeContainer = document.createElement('div');
                                                        // iframeContainer.style.position = 'fixed';
                                                        // iframeContainer.style.top = '0';
                                                        // iframeContainer.style.left = '0';
                                                        // iframeContainer.style.width = '100%';
                                                        // iframeContainer.style.height = '100%';
                                                        // iframeContainer.style.backgroundColor = 'rgba(0, 0, 0, 0.5)';
                                                        // iframeContainer.style.zIndex = '9999';
                                                        //
                                                        // const iframe = document.createElement('iframe');
                                                        // iframe.src = data.url;
                                                        // iframe.style.width = '100%';
                                                        // iframe.style.height = '100%';
                                                        // iframe.style.border = 'none';
                                                        // iframe.allow = "camera; microphone; fullscreen"; // Allow camera and microphone access
                                                        //
                                                        // const closeButton = document.createElement('button');
                                                        // closeButton.innerText = 'Close';
                                                        // closeButton.style.position = 'absolute';
                                                        // closeButton.style.bottom = '10px'; // Move to the bottom
                                                        // closeButton.style.left = '10px'; // Align to the left
                                                        // closeButton.style.zIndex = '10000';
                                                        // closeButton.style.padding = '10px';
                                                        // closeButton.style.backgroundColor = '#f00';
                                                        // closeButton.style.color = '#fff';
                                                        // closeButton.style.border = 'none';
                                                        // closeButton.style.cursor = 'pointer';
                                                        // closeButton.style.borderRadius = '5px'; // Optional: Add rounded corners
                                                        // closeButton.onclick = () => {
                                                        //     document.body.removeChild(iframeContainer);
                                                        //     window.location.href = 'https://flower.botguru.ai/landingPage'; // Redirect to the specified URL
                                                        // };
                                                        //
                                                        //
                                                        //
                                                        // iframeContainer.appendChild(iframe);
                                                        // iframeContainer.appendChild(closeButton);
                                                        // document.body.appendChild(iframeContainer);
                                                        window.addEventListener("message", handleMessage);

                                                    } else {
                                                        console.log(data)
                                                        console.warn("No connection ID or URL received in WebSocket message.");
                                                    }
                                                } catch (error) {
                                                    console.error("Error parsing WebSocket message:", error.message);
                                                }
                                            };

                                            websocket.onerror = (error) => {
                                                console.error("WebSocket error:", error);
                                            };

                                            websocket.onclose = () => {
                                                console.log("WebSocket connection closed.");
                                            };
                                            }
                                        }
                                        >
                                        <i className="fas fa-rocket launch-icon"></i>
                                        Launch
                                    </button>
                                </div>


                            </div>}
                        <div className="launch-navigation-buttons">
                            {currentSectionIndex > 0 && currentSectionIndex <= 2 && (
                                <button
                                    className="prev-button"
                                    onClick={handlePrevious}
                                >
                                    Previous
                                </button>
                            )}
                            {currentSectionIndex < sections.length - 1 && (
                                <button
                                    className="next-button"
                                    onClick={handleNext}
                                    disabled={!selectedCard.hasOwnProperty(sectionKeys[sections[currentSectionIndex]])}
                                >
                                    Next
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>)};
            <JDCreateModal
                isOpen={isCreateModalOpen}
                closeModal={() => setCreateModalOpen(false)}
                refreshJDList={fetchJobRoles} initialFormData={formData} isLoading={loading}
            />;
            {resumeModal && <ResumeModal isOpen={resumeModal} closeModal={closeresumeModal} resumeData={resumeData}
                                         onResumeSubmit={() => {
                                             fetchResumes(); // Refresh the resume list
                                             setActiveButton("Resume"); // Navigate back to "Choose your Resume" page
                                             setresumeModal(false); // Ensure the modal is closed
                                         }
                                         }
                                         isLoading={loading}
            ></ResumeModal>}
             {isJDDetailsModalOpen && (
            <JdDetailsModal
                isOpen={isJDDetailsModalOpen}
                closeModal={closeJDDetailsModal}
                jdId={selectedJDId}
                jdData={jdData}
                showEditButton={false} 
            />
        )}
            {/* Add ResumePreview modal */}
            {showPreview && (
                <ResumePreview 
                    resumeData={previewData} 
                    closeModal={() => setShowPreview(false)}
                />
            )}
             {showNotification && (
                <Notification 
                    message={notificationMessage}
                    onClose={handleCloseNotification}
                />
            )}
            {/* {loading ? renderLoading() : renderNewJobRoleCard()} */}
            <Tour
                steps={tourSteps}
                isOpen={isTourOpen}
                onRequestClose={() => setIsTourOpen(false)}
                getCurrentStep={(curr) => setCurrentStep(curr)}
                showNumber={true}
                showButtons={true}
                showNavigation={true}
                disableInteraction={true}
                disableKeyboardNavigation={true}
                showNavigationNumber={true}
                badgeContent={(curr, tot) => `${curr} of ${tot}`}
                accentColor="#BE05FA"
                styles={{
                    options: {
                        backgroundColor: '#0e1428',
                        borderColor: '#a86cda',
                        textColor: '#ffffff',
                        arrowColor: '#a86cda',
                    },
                    badge: (base) => ({
                        ...base,
                        backgroundColor: '#a86cda',
                        padding: '4px 8px',
                        borderRadius: '4px',
                        fontSize: '14px',
                        marginTop: '10px',
                        color: '#ffffff',
                    }),
                    navigation: (base) => ({
                        ...base,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        padding: '10px 20px',
                    }),
                    navigationNumber: (base) => ({
                        ...base,
                        fontSize: '14px',
                        color: '#ffffff',
                        margin: '0 10px',
                    }),
                }}
                lastStepNextButton={
                    <button 
                        onClick={() => {
                            //handleNext();
                            setIsTourOpen(false);
                            setActiveButton('Resume');
                        }}
                        style={{ borderRadius: '20px', cursor: 'pointer' }}
                    >
                        Next Section
                    </button>
                }
                nextButton={
                    <button 
                        onClick={() => {
                            const nextStep = currentStep + 1;
                            setCurrentStep(nextStep);
                        }}
                        style={{ 
                            borderRadius: '20px', 
                            cursor: 'pointer'
                        }}
                    >
                        Next
                    </button>
                }
                prevButton={
                    <button 
                        onClick={() => setCurrentStep((prev) => Math.max(prev - 1, 0))}
                        style={{ 
                            borderRadius: '20px', 
                            cursor: currentStep === 0 ? 'not-allowed' : 'pointer' 
                        }} 
                        disabled={currentStep === 0}
                    >
                        Previous
                    </button>
                }
            />
            <Tour
                steps={resumeTourSteps}
                isOpen={showResumeTour && activeButton === 'Resume'}
                onRequestClose={() => setShowResumeTour(false)}
                getCurrentStep={(curr) => setCurrentStep(curr)}
                showNumber={true}
                showButtons={true}
                showNavigation={true}
                disableInteraction={true}
                disableKeyboardNavigation={true}
                showNavigationNumber={true}
                badgeContent={(curr, tot) => `${curr} of ${tot}`}
                accentColor="#BE05FA"
                goToStep={currentStep}
                styles={{
                    options: {
                        backgroundColor: '#0e1428',
                        borderColor: '#a86cda',
                        textColor: '#ffffff',
                        arrowColor: '#a86cda',
                    },
                    badge: (base) => ({
                        ...base,
                        backgroundColor: '#a86cda',
                        padding: '4px 8px',
                        borderRadius: '4px',
                        fontSize: '14px',
                        marginTop: '10px',
                        color: '#ffffff',
                    }),
                    navigation: (base) => ({
                        ...base,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        padding: '10px 20px',
                    }),
                    navigationNumber: (base) => ({
                        ...base,
                        fontSize: '14px',
                        color: '#ffffff',
                        margin: '0 10px',
                    }),
                }}
                lastStepNextButton={
                    <button 
                        onClick={() => {
                            setShowResumeTour(false);
                            setActiveButton('Persona');
                        }}
                        style={{ borderRadius: '20px', cursor: 'pointer' }}
                    >
                        Next Section
                    </button>
                }
                nextButton={
                    <button 
                        onClick={() => {
                            const nextStep = currentStep + 1;
                            setCurrentStep(nextStep);
                        }}
                        style={{ 
                            borderRadius: '20px', 
                            cursor: 'pointer'
                        }}
                    >
                        Next
                    </button>
                }
                prevButton={
                    <button 
                        onClick={() => setCurrentStep((prev) => Math.max(prev - 1, 0))} 
                        style={{ 
                            borderRadius: '20px', 
                            cursor: currentStep === 0 ? 'not-allowed' : 'pointer' 
                        }} 
                        disabled={currentStep === 0}
                    >
                        Previous
                    </button>
                }
            />
            <Tour
                steps={personaTourSteps}
                isOpen={showPersonaTour && activeButton === 'Persona'}
                onRequestClose={() => handleTourClose('Persona')}
                getCurrentStep={(curr) => setCurrentStep(curr)}
                showNumber={true}
                showButtons={true}
                showNavigation={true}
                disableInteraction={true}
                disableKeyboardNavigation={true}
                showNavigationNumber={true}
                badgeContent={(curr, tot) => `${curr} of ${tot}`}
                accentColor="#BE05FA"
                goToStep={currentStep}
                styles={{
                    options: {
                        backgroundColor: '#0e1428',
                        borderColor: '#a86cda',
                        textColor: '#ffffff',
                        arrowColor: '#a86cda',
                    },
                    badge: (base) => ({
                        ...base,
                        backgroundColor: '#a86cda',
                        padding: '4px 8px',
                        borderRadius: '4px',
                        fontSize: '14px',
                        marginTop: '10px',
                        color: '#ffffff',
                    }),
                    navigation: (base) => ({
                        ...base,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        padding: '10px 20px',
                    }),
                    navigationNumber: (base) => ({
                        ...base,
                        fontSize: '14px',
                        color: '#ffffff',
                        margin: '0 10px',
                    }),
                }}
                lastStepNextButton={
                    <button 
                        onClick={() => {
                            if (!activePersona) {
                                handlePersonClick(0); // Select Maya by default if no persona selected
                            }
                            setActiveButton('Launch');
                            setShowPersonaTour(false);
                        }}
                        style={{ 
                            borderRadius: '20px',
                            cursor: 'pointer'
                        }}
                    >
                        Next Section
                    </button>
                }
                nextButton={
                    <button 
                        onClick={() => {
                            const nextStep = currentStep + 1;
                            setCurrentStep(nextStep);
                        }}
                        style={{ 
                            borderRadius: '20px', 
                            cursor: 'pointer'
                        }}
                    >
                        Next
                    </button>
                }
                prevButton={
                    <button 
                        onClick={() => setCurrentStep((prev) => Math.max(prev - 1, 0))} 
                        style={{ 
                            borderRadius: '20px', 
                            cursor: currentStep === 0 ? 'not-allowed' : 'pointer' 
                        }} 
                        disabled={currentStep === 0}
                    >
                        Previous
                    </button>
                }
            />
            <Tour
                steps={launchTourSteps}
                isOpen={showLaunchTour && activeButton === 'Launch'}
                onRequestClose={() => handleTourClose('Launch')}
                getCurrentStep={(curr) => setCurrentStep(curr)}
                showNumber={true}
                showButtons={true}
                showNavigation={true}
                disableInteraction={true}
                disableKeyboardNavigation={true}
                showNavigationNumber={true}
                badgeContent={(curr, tot) => `${curr} of ${tot}`}
                accentColor="#BE05FA"
                goToStep={currentStep}
                styles={{
                    options: {
                        backgroundColor: '#0e1428',
                        borderColor: '#a86cda',
                        textColor: '#ffffff',
                        arrowColor: '#a86cda',
                    },
                    badge: (base) => ({
                        ...base,
                        backgroundColor: '#a86cda',
                        padding: '4px 8px',
                        borderRadius: '4px',
                        fontSize: '14px',
                        marginTop: '10px',
                        color: '#ffffff',
                    }),
                    navigation: (base) => ({
                        ...base,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        padding: '10px 20px',
                    }),
                    navigationNumber: (base) => ({
                        ...base,
                        fontSize: '14px',
                        color: '#ffffff',
                        margin: '0 10px',
                    }),
                }}
                lastStepNextButton={
                    <button 
                        onClick={() => {
                            setShowLaunchTour(false);
                            closeModal();
                            
                        }}
                        style={{ 
                            borderRadius: '20px',
                            cursor: 'pointer'
                        }}
                    >
                        End Tour!
                    </button>
                }
                nextButton={
                    <button 
                        onClick={() => {
                            const nextStep = currentStep + 1;
                            setCurrentStep(nextStep);
                        }}
                        style={{ 
                            borderRadius: '20px', 
                            cursor: 'pointer'
                        }}
                    >
                        Next
                    </button>
                }
                prevButton={
                    <button 
                        onClick={() => setCurrentStep((prev) => Math.max(prev - 1, 0))} 
                        style={{ 
                            borderRadius: '20px', 
                            cursor: currentStep === 0 ? 'not-allowed' : 'pointer' 
                        }} 
                        disabled={currentStep === 0}
                    >
                        Previous
                    </button>
                }
            />
        </>
    );
};

export default PrepLaunchPod;