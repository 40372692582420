import { Target, Users, Award, Building2 } from 'lucide-react';

export const objectives = [
    {
      id: 'unlock-potential',
      title: 'Unlock Your Potential',
      description: 'Customized coaching to enhance your skills and confidence in job search with PrepBot.',
      Icon: Target
    },
    {
      id: 'interview-mastery',
      title: 'Interview Mastery',
      description: 'Realistic mock sessions with feedback to excel in job interviews with MockBot.',
      Icon: Users
    },
    {
      id: 'stand-out',
      title: 'Stand Out',
      description: 'Proven techniques to differentiate yourself from other candidates!',
      Icon: Award
    },
    {
      id: 'diverse-support',
      title: 'Diverse Support',
      description: 'Expert guidance for roles in tech, finance, and Healthcare industries!',
      Icon: Building2
    }
  ];


function ObjectiveCard({ title, description, Icon }) {
    return (
      <div className="objective-card">
        <Icon className="objective-icon" />
        <h3 className="objective-title">{title}</h3>
        <p className="objective-description">{description}</p>
      </div>
    );
}

export const Objectives = () => {
    return (
        <div className="objectives">
            {objectives.map((objective) => (
                <ObjectiveCard
                  key={objective.id}
                  title={objective.title}
                  description={objective.description}
                  Icon={objective.Icon}
                />
            ))}
        </div>
    )
}
