import { Minus, Plus } from "lucide-react";
import { useState } from "react"
import './FAQAccordian.css';

const FAQData=[
    {
        question:"What is BotGuru?",
        answer:"BotGuru offers personalized Interview prep and Mock interview services to help you stand out and ace your Interview."
    },
    {
        question:"Who can benefit from BotGuru?",
        answer:"Anyone looking  for help with Interview preparation and to enhance skills can benefit, including job seekers, students, and professionals."
    },
    {
        question:"Is there a free trial available?",
        answer:"Yes, we offer a free trial that allows you to explore BotGuru's PrepBot features before committing to a subscription."
    },
    {
        question:"What types of feedback does BotGuru provide?",
        answer:"BotGuru offers personalized feedback on clarity, tone, and engagement, along with actionable recommendations for improvement."
    },
    {
        question:"When will the report be generated?",
        answer:"The average time taken for the report to be generated is approximately 4 minutes after your session ends. You will receive a notification once the report is ready for you to view or download."
    },
    {
        question:"Why my report is not generated?",
        answer:"Your Report wont generate if you wont't complete your session. We suggest you to complete your session to get your report."
    },
    // {
    //     question:"Can I access BotGuru on mobile devices?",
    //     answer:"Absolutely! BotGuru is fully optimized for both desktop and mobile devices, allowing you to practice anytime, anywhere."
    // },
    // {
    //     question:"How is my data handled?",
    //     answer: "We prioritize your privacy and security. All personal data is encrypted and stored securely, in accordance with our privacy policy."
    // }
]

export const FAQAccordian = () => {
    const [openIndex, setOpenIndex] = useState(null);

    const toggleItem = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    return (
        <div className='FAQ-content'>
            {FAQData.map((item, index) => (
                <div 
                    key={index} 
                    className={`FAQ-Item ${openIndex === index ? 'open' : ''}`} 
                >
                    <div className='FAQ-Question' onClick={() => toggleItem(index)}>
                        <span>{item.question}</span>
                        <div className='FAQ-Icon'>{openIndex === index ? <Minus /> : <Plus />}</div>
                    </div>
                    <div className='FAQ-Answer'>
                        <p>{item.answer}</p>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default FAQAccordian;
