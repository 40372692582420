import React from 'react';
import { useNavigate } from 'react-router-dom';  // Import useNavigate from react-router-dom
import './SuccessModal.css';  // You can style this modal as you like.

const SuccessModal = ({navigate,planType}) => {

    const handleRedirect = () => {
      if (planType === 'Resume') {
          navigate('/landingPage', { state: { activeButton: 'ResumeGuru' } });
      } else if (planType === 'Jd') {
          navigate('/landingPage', { state: { activeButton: 'JDGuru' } });
      } else {
          const activeBot = planType === 'Prepbot' ? 'prep' : planType === 'Mockbot' ? 'mock' : null;
          if (activeBot) {
              navigate('/landingPage', { state: { activeButton: 'Analytics', activeBot } });
          } else {
              console.log("Unknown planType, defaulting to Analytics");
              navigate('/landingPage', { state: { activeButton: 'Analytics' } });
          }
      }
  };

  return (
    <div className="success-modal-overlay">
      <div className="success-modal-content">
        <div className="success-modal-header">
          <span>✔️</span>
        </div>
        <div className="success-modal-body">
          <h2>Thank You for Your Payment!</h2>
          <p>Your payment has been successfully processed.</p>
        </div>
        <div className="success-modal-footer">
          <button className='success-btn'onClick={handleRedirect}>OK</button>
        </div>
      </div>
    </div>
  );
};

export default SuccessModal;
