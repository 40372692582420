import { Check } from "lucide-react"
import '../Pricing.css';
import { useEffect, useState } from "react";

export const CustomPlan = ({data, selectPlan})=>{
    const [totalPrice, setTotalPrice] = useState(parseFloat(data.price).toFixed(2));
    const [sessionCount, setSessionCount] = useState(data.minimum_sessions);
    useEffect(()=>{
        setSessionCount(data.minimum_sessions);
        setTotalPrice(parseFloat((data.price*data.minimum_sessions)-(data?.discount/100)*(data.price*data.minimum_sessions)).toFixed(2));
    }, [data]);
    
    const changePrice = (newSessionNumber) => {
        setSessionCount(newSessionNumber);
        setTotalPrice(parseFloat((data.price*newSessionNumber)-(data?.discount/100)*(data.price*newSessionNumber)).toFixed(2));
    };

    return (
        <div className="tabData customPlan">
            <div className="content">
            <span className="tip">Recommended</span>
            <div className="pricing-section">
                <h5 className="plan-title">Custom Plan</h5>
                <span className="price">${totalPrice}</span>
                <div className="session-info">
                    <div>
                        <span className="unactive">{data.price}$ per session</span>
                        
                        <span className="discount">Save {data.discount}%</span>
                    </div>
                    <span>{data?.minimum_sessions} 
                        {(data?.session_type==="prepbot") || (data?.session_type==="mockbot") 
                            ? " sessions minimum" : data?.session_type ==="resume" 
                            ? " Resumes" : " Job Descriptions"}
                    </span>
                </div>
            </div>
            <div className="counter-container">
                <button
                    onClick={() => changePrice(Math.max(sessionCount - 1, data.minimum_sessions ))}
                    className="counter-btn decrement">
                    <span>-</span>
                </button>
                <span className="counter-display">{sessionCount}</span>
                <button
                    onClick={() => changePrice(sessionCount + 1)}
                    className="counter-btn increment">
                    <span>+</span>
                </button>
           </div>
            <div className="session-features custom">
                {data.hasOwnProperty("features") && data.features.map((feature, index) => {
                    if(index<=4){
                        return (
                        <div>
                            <Check className="check" strokeWidth={3}/>
                            <span>{feature}</span>
                        </div>
                    )}
                })}
            </div>
            </div>
            <button 
                className="choose-planBtn"
                onClick={() => selectPlan(totalPrice, {"plan_type":"Custom", "sessions":sessionCount, "id":data._id, "session_type":data.session_type})}
            >Choose Plan</button>
        </div>
    )
}
