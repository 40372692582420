import React from 'react';
import { LineChart, Bar, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ComposedChart } from 'recharts';

const SessionScoreChart = ({analyticsData,botType}) => {

    const prepbotData = analyticsData?.chart_metrics?.prepbot || [];

    const botData = analyticsData?.chart_metrics?.[botType] || [];

    // Transform the data to fit the chart's expected format
    const sessionData = botData.map((session, index) => ({
        session: index + 1,
        score: session.avg_score,
        duration: session.avg_duration
    }));

    // //Transform the data to fit the chart's expected format
    // const sessionData = prepbotData.map((session, index) => ({
    //     session: index + 1,
    //     score: session.avg_score,
    //     duration: session.avg_duration
    // }));
    // Sample data for 3 sessions with both score and duration
    // const sessionData = [
    //     { session: 1, score: 85, duration: 45 },
    //     { session: 2, score: 92, duration: 30 },
    //     { session: 3, score: 78, duration: 60 }
    // ];

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div className="bg-white p-3 rounded-lg shadow-lg border"  style={{
                    border: '1px solid rgba(200, 200, 200, 0.8)', background:'#0e1428' // Light gray border
                }}>
                    <p className="font-semibold text-white-300">Session: {label}</p>
                    {payload.map((entry, index) => (
                        <p key={index} className="tooltip-text" style={{ color: 'white' }}>
                            {entry.name}: {entry.value} {entry.name === 'Duration' ? 'min' : '%'}
                        </p>
                    ))}
                </div>
            );
        }
        return null;
    };

        // Determine the chart dimensions dynamically based on the screen width
        const getChartDimensions = () => {
            const screenWidth = window.innerWidth;
    
            let chartWidth = 500; // Default width
            let chartHeight = 300; // Default height
    
            if (screenWidth <= 1400) {
                chartHeight = 280; // Reduced height for smaller screens
            }
            if (screenWidth <= 768) {
                chartWidth = 300; // Smaller width for mobile
            }
    
            return { chartWidth, chartHeight };
        };
        

    return (
        <div className="score-chart-container">
            <h3>Interview Performance Scores & Duration</h3>
            <div style={{ width: '50%', height: 200, transform: 'translateX(-10%)' }}>
                <ComposedChart
                    width={window.innerWidth < 800 ? 300 : 500} // Dynamic width
                    height={300}
                    data={sessionData}
                    margin={{ top: 50, right: 30, left: 20, bottom: 5 }}
                >
                    {/* <CartesianGrid strokeDasharray="0.5 0.5" /> */}
                    <XAxis dataKey="session" label={{ value: 'Sessions', position: 'bottom',fill: '#ffffff' }}     stroke="#EDEADE" // Change axis line and tick color to white
   />
                    <YAxis 
                        yAxisId="left"
                        label={{ value: 'Score (%)', angle: -90, position: 'insideLeft',fill: '#ffffff' }}stroke="#EDEADE"
                    />
                    <YAxis 
                        yAxisId="right" 
                        orientation="right"
                        label={{ value: 'Duration (min)', angle: 90, position: 'insideRight' ,fill: '#ffffff'}}stroke="#EDEADE"
                    />
                    <Tooltip content={<CustomTooltip /> }  cursor={false}  />
                    <Legend 
                        verticalAlign="bottom" 
                        align="left"
                        fill= '#ffffff'
                        wrapperStyle={{ 
                            bottom: -25,
                            left: 0,
                            right: 0,
                            margin: 25,
                        }}
                        formatter={(value) => {
                            return (
                                <span 
                                    style={{ 
                                        color: value === 'Duration' ? '#ffffff' : 'inherit' // Directly add color for 'Duration'
                                    }}
                                >
                                    {value}
                                </span>
                            );
                        }}
                    />
                    <Bar
                        yAxisId="left"
                        dataKey="score"
                        name="Score"
                        fill="url(#barGradient)"
                        radius={[4, 4, 0, 0]}
                        barSize={40} 
                    />
                    <Line
                        yAxisId="right"
                        type="linear"
                        dataKey="duration"
                        name="Duration"
                        stroke="#8900ae"
                        strokeWidth={2}
                        dot={{ fill: 'white', r: 6 }}
                        activeDot={{r:8}}
                    />

                    <defs>
                        <linearGradient id="barGradient" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="100%" stopColor="#BE05FA" />
                            <stop offset="100%" stopColor="#6F00FF" />
                        </linearGradient>
                        <linearGradient id="lineGradient" x1="0" y1="0" x2="1" y2="0">
                            <stop offset="0%" stopColor="#FF5BFF" />
                            <stop offset="100%" stopColor="#9B33FF" /> 
                        </linearGradient>
                    </defs>
                </ComposedChart>
            </div>
        </div>
    );
};

export default SessionScoreChart;