import React, { useState, useEffect } from 'react';
import { FileText, Search, Database, CheckCircle } from 'lucide-react';

const JDLoadingScreen = () => {
  const [currentStep, setCurrentStep] = useState(0);
  
  const steps = [
    { icon: FileText, text: 'Reading Document', subtext: 'Extracting content from your JD' },
    { icon: Search, text: 'Analyzing Content', subtext: 'Identifying key information' },
    { icon: Database, text: 'Processing Data', subtext: 'Structuring your JD' },
    { icon: CheckCircle, text: 'Finalizing', subtext: 'Preparing results for display' }
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentStep((prev) => (prev < steps.length - 1 ? prev + 1 : prev));
    }, 600);

    return () => clearInterval(interval);
  }, []);

  const step = steps[currentStep];
  const Icon = step.icon;

  return (
    <div className="jd-loading-screen">
      <div className="jd-loading-content">
      <div className='jd-loading-data'>
        <div className="icon-container">
          <Icon size={25} className="icon" />
        </div>
        <div className="loading-text-container">
          <h3 className="loading-title">{step.text}</h3>
          <p className="loading-subtext">{step.subtext}</p>
        </div>
      </div>
        <div className="dots">
            {[...Array(3)].map((_, i) => (
              <div key={i} className="dot" />
            ))}
          </div>
      </div>
    </div>
  );
};

export default JDLoadingScreen;
