import React, { Suspense, useEffect, useState } from "react";
import './Pricing.css';
import { useNavigate,useLocation } from "react-router-dom"; // Assuming React Router is used
import { Bot, Brain, Crown, Sparkles } from "lucide-react";
import { StarterPlan } from "./PricingPlans/StarterPlan";
import { FreePlan } from "./PricingPlans/FreePlan";
import { CustomPlan } from "./PricingPlans/CustomPlan";
import { useAuthContext } from "./Auth/AuthContext";




const PricingPlan = React.memo(({showLogin}) => {
  const [currentTab, setCurrentTab] = useState(0);
  const {pricingPlans, fetchPricingData}=useAuthContext();
  const [currentTabData, setcurrentTabData] = useState(pricingPlans && pricingPlans.length ? pricingPlans?.prepbot : []);
  const navigate = useNavigate();
  const location = useLocation(); // Use useLocation to access navigation state


  useEffect(()=>{
    if(!pricingPlans){
      fetchPricingData();
    }
    setcurrentTabData(pricingPlans?.prepbot)

  }, [pricingPlans, fetchPricingData])

  useEffect(() => {
    if (pricingPlans && location.state?.activeTabName) {
      handleTabChangeByName(location.state.activeTabName);
    }
  }, [location.state, pricingPlans]); // Depend on both location.state and pricingPlans
  

  const handlePlanClick = (price,planDetails) => {
    console.log("selected plan details", planDetails);
    // Convert price to cents before navigating
    const priceInCents = Math.round(price * 100); 
    if(!showLogin){
      // Navigate to PaymentPage with price as state for new user after login
      navigate("/payment", { state: { amount: priceInCents, planDetails  } });
    }else{
      // showLogin checks if user is logged in or not, if loggedin redirect to payment page with payment details, if not show login and then
      // redirect to payment with plan details.
      showLogin('/payment', { state: { amount: priceInCents, planDetails } });
    }
  };
  
  const tabData=[
    {
      name:"Prep Bot",
      logo:Bot,
    },
    {
      name: "Mock Bot",
      logo:Brain,
    },
    {
      name:"Expert Resume Builder",
      logo: Sparkles, 
    },
    {
      name: "AI JD Generator",
      logo: Crown,
    },
  ]

  const handleTabChangeByName = (tabName) => {
    const tabIndex = tabData.findIndex(tab => tab.name === tabName);
    if (tabIndex !== -1) {
      handleTabChange(tabIndex);
    }
  };


  const handleTabChange = (tabIndex) => {
  
    let newTabData = [];
    if (tabIndex === 0) {
      newTabData = pricingPlans?.prepbot || [];
    } else if (tabIndex === 1) {
      newTabData = pricingPlans?.mockbot || [];
    } else if (tabIndex === 2) {
      newTabData = pricingPlans?.resume || [];
    } else if (tabIndex === 3) {
      newTabData = pricingPlans?.jd || [];
    }
  
    setCurrentTab(tabIndex);
    setcurrentTabData(newTabData);
  };


  return (
    <>
        <div className="pricing-container">
          <div className="section-title">
            <h2 className="text-white">Choose Your Perfect Plan</h2>
            <span>Select the perfect package that suits your needs. Unlock premium features and boost your preparation journey</span>
          </div>
          <div className="saas-pricing-tabs">
            <div className="tabList">
              {tabData.map((tab, index)=>{
                return (
                  <div 
                  className={`tab ${index === currentTab ? "active" : ""}`} 
                  key={index}
                  onClick={()=>handleTabChange(index)}>
                    <tab.logo strokeWidth={1.5} className="tab-logo"/>
                    <p>{tab?.name}</p>
                  </div>
                )
              })}
            </div>
            {
              currentTabData && (
                <div className="tabList-container">
                {currentTabData.hasOwnProperty("free-trial") && (
                    <FreePlan data={currentTabData["free-trial"]} selectPlan={handlePlanClick} />
                )}
                {currentTabData.hasOwnProperty("starter") && (
                    <StarterPlan data={currentTabData["starter"]} selectPlan={handlePlanClick} />
                )}
                {currentTabData.hasOwnProperty("custom") && (
                    <CustomPlan data={currentTabData["custom"]} selectPlan={handlePlanClick} />
                )}
              </div>
              )
            }
          </div>
        </div>
    </>
  );
});

export default PricingPlan;
