import React, { useState,useRef ,useEffect } from 'react';
import './MainLandingPage.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faUser, 
  faHeadset, 
  faSignOutAlt, 
  faCog, 
  faUserCog
} from '@fortawesome/free-solid-svg-icons';
import useAuthService from '../../services/AuthService';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../Auth/AuthContext';



const ProfileSection = ({openAccountDetails, userName, setLogoutPopUp }) => {
    const authService = useAuthService();
    const { userEmail } = useAuthContext(); 
    const [showProfilePopup, setShowProfilePopup] = useState(false);
    const profileRef = useRef(null); // Ref for detecting outside clicks

    const togglePopup = () => {
        setShowProfilePopup((prev) => !prev);
    };

    const handleClickOutside = (event) => {
        if (profileRef.current && !profileRef.current.contains(event.target)) {
            setShowProfilePopup(false);
        }
    };

    const handleMouseLeave = () => {
        setShowProfilePopup(false);
    };

    const navigate = useNavigate();

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleMyAccountClick = () => {
        openAccountDetails();
        setShowProfilePopup(false); // Close the profile dropdown when clicking "My Account"
    };
    
    const handleSupportClick = () => {
        const supportEmail = "support@example.com";
        const subject = encodeURIComponent("Support Request");
        const body = encodeURIComponent("Hello, I need help with...");
        const gmailURL = `https://mail.google.com/mail/?view=cm&fs=1&to=${supportEmail}&su=${subject}&body=${body}`;
        const mailtoURL = `mailto:${supportEmail}?subject=${subject}&body=${body}`; 
        const newWindow = window.open(gmailURL, "_blank");
         if (!newWindow || newWindow.closed || typeof newWindow.closed === "undefined") { 
            window.location.href = mailtoURL;
         } 
    };
     

    return (
        <div className="profile-container"  ref={profileRef}  onMouseLeave={handleMouseLeave}>
            <button 
                className={`profile-button ${showProfilePopup ? 'active' : ''}`} 
                onClick={togglePopup}
            >
                <div className="profile-icon-wrapper">
                    <FontAwesomeIcon icon={faUser} className="profile-icon" />
                </div>
                Profile
            </button>
            {showProfilePopup && (
                <div className="profile-dropdown">
                    <div className="profile-info">
                        <div className="profile-icon-wrapper">
                            <FontAwesomeIcon icon={faUser} className="profile-icon" />
                        </div>
                        <div className="profile-details">
                            <h4>{userName}</h4>
                            {/* <p>johndoe@gmail.com</p> */}
                        </div>
                    </div>
                    <div className="profile-actions">
                        <button onClick={handleMyAccountClick} >
                            <FontAwesomeIcon icon={faUserCog}/> My Account
                        </button>
                        <button onClick={handleSupportClick}>
                            <FontAwesomeIcon icon={faHeadset}/> Support
                        </button>
                        <button className="logout" onClick={()=>setLogoutPopUp(true)}>
                            <FontAwesomeIcon icon={faSignOutAlt}/> Logout
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};
export default ProfileSection;