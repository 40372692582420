import React,{useState,useRef, useEffect} from 'react';
import './LoginModal.css';
import PhoneInput from "react-phone-number-input";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope,fatags } from '@fortawesome/free-regular-svg-icons';
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
import { PublicClientApplication } from '@azure/msal-browser';

import 'react-phone-number-input/style.css'
import { useNavigate } from 'react-router-dom';
import useAuthService from '../services/AuthService';
import logo from '../botgurulogo.png';
import avatar from '../avatar-img.png';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { faHashtag, faBolt, faGlobe } from '@fortawesome/free-solid-svg-icons'; // Import icons
import { useAuthContext } from "../Components/Auth/AuthContext"; // Import Auth Context
import { time } from 'three/src/nodes/TSL.js';
import { Loader } from 'lucide-react';


// import axios from "axios";

// const fetchUserIP = async () => {
//   try {
//     const response = await axios.get("https://api.ipify.org?format=json");
//     return response.data.ip; // Returns the user's IP address
//   } catch (error) {
//     console.error("Error fetching IP address:", error);
//     return null; // Return null if there's an error
//   }
// };


const LoginModal = ({ isLogin, closeModal, nextPath }) => {
    const navigate = useNavigate(); // Get the navigate function
    const [email, setEmail] = useState("");
    const [showTooltip, setShowTooltip] = useState(false);
    const [shownSignIn, setShowSignIn] = useState(true)
    const [showOtpInput, setShowOtpInput] = useState(false);
    const [showDetailsForm, setShowDetailsForm] = useState(false);
    const [otp, setOtp] = useState(new Array(6).fill(""));
    const [isFocused, setIsFocused] = useState(false); 
    const [flag,setFlag]=useState(false);
    const [otpError, setOtpError] = useState('');
    const [signInError, setSignInError] = useState('');
    const [showExtraFields, setShowExtraFields] = useState(false);
    const [phoneError, setPhoneError] = useState('');
    const [extraDetails, setExtraDetails] = useState({
      field1: "",
      field2: "",
      field3: "",
      field4: "",
      field5: "",
    });
  
    const [tags, setTags] = useState({
      field1: [],
      field2: [],
      field3: [],
      field4: [],
      field5: [],
    });

    const authService = useAuthService();
    const { setUserObjId } = useAuthContext(); 
    const { setNewUser } = useAuthContext();
    const [details, setDetails] = useState({
        first_name: "",
        last_name: "",
        phone_number: "",
        email: email,
      });

      const [errors, setErrors] = useState({
        first_name: false,
        last_name: false,
        phone_number: false,
      });

      useEffect(()=>{
        if(!isLogin){
          setOtpResendCount(0);
          setSignInError("");
        }
      }, [isLogin])


      const [extraFieldsError, setExtraFieldsError] = useState({
        field1: false,
        field2: false,
        field3: false,
        // field4: false,
        // field5: false,
      });
      const [isLoading, setIsLoading] = useState(false);
      

      useEffect(() => {
        if (isLogin) {
          document.body.classList.add("no-scroll");
        } else {
          document.body.classList.remove("no-scroll");
        }
    
        // Cleanup when the component unmounts
        return () => {
          document.body.classList.remove("no-scroll");
        };
      }, [isLogin]);

    // const clientId = "583854496467-v8mtviku2cd2ffbmi29r4tbg9dnooul5.apps.googleusercontent.com";
    const clientId = "30723097180-545sij1sc7uk340kct0jac9hu3ocauef.apps.googleusercontent.com";

      
// To handle google signin
const handleLoginSuccess = (response) => {
  console.log("Google Sign-In Success:", response);
  const credential = response?.credential || null;
  const userEmail = response?.credential ? JSON.parse(atob(response.credential.split('.')[1])).email : null;
  const source ='g'
  if (userEmail) {
    console.log("Email used for login:", userEmail);

    setDetails((prevDetails) => ({
      ...prevDetails,
      email: userEmail, // Update the email field in details
    }));

    authService.googlesignup(userEmail,credential,source).then((response)=>{
      console.log(response);
      if (response && response.user_obj_id) {
        setUserObjId(response.user_obj_id); // Save in Global State
        setNewUser(response.new_user);
        // console.log("User Object ID saved:", response.new_user);
    }
      if(response.new_user=='Y'){
        setShowOtpInput(false);
        setShowSignIn(false);
        setShowDetailsForm(true);
      }
      if( response.new_user=='N'){
        navigate(nextPath.path, nextPath.props);
      }
    }).catch((err)=>{
      console.log("error:"+err)
    })
  }
};
    const handleLoginFailure = (error) => {
      console.log("Google Sign-In Failed:", error);
    };


    // const handleMicrosoftLogin = (err, data) => {
    //   if (err) {
    //     console.error("Microsoft Sign-In Failed:", err);
    //     return;
    //   }
  
    //   const userEmail = data?.account?.userName;
    //   console.log("Microsoft Sign-In Success:", userEmail);
    // };
  

    const msalConfig = {
      auth: {
          clientId: "50986f23-a91b-43fa-a22d-919bc5445601", // Replace with your Microsoft Client ID
          authority: "https://login.microsoftonline.com/common",
          redirectUri: window.location.origin,
      },
      cache: {
          cacheLocation: "sessionStorage",
          storeAuthStateInCookie: false,
      },
    };

  const msalInstance = new PublicClientApplication(msalConfig);

  // Handle Microsoft Sign-In
  const handleMicrosoftLogin = async () => {
      try {
          const loginResponse = await msalInstance.loginPopup({
              scopes: ["user.read", "email"],
              prompt: "select_account",
          });
          console.log(loginResponse)

          if (loginResponse.account) {
              const useremail = loginResponse.account.username;
              const credential= loginResponse.idToken
              const source ='m'
              console.log("Microsoft Sign-In Success:", email);

              setDetails((prevDetails) => ({
                  ...prevDetails,
                  email: useremail,
              }));
              authService.googlesignup(useremail,credential,source,{ 
            }).then((response)=>{
              console.log(response)
              if (response && response.user_obj_id) {
                setUserObjId(response.user_obj_id); 
                setNewUser(response.new_user);
                console.log("User Object ID saved:", response.first_name);
            }
                if(response.new_user==='Y'){
                  setShowOtpInput(false);
                  setShowSignIn(false)
                  setShowDetailsForm(true);
                }
                if(response.new_user==='N'){
                  navigate(nextPath.path, nextPath.props);
                }
              }).catch((err)=>{
                console.log("error:"+err)
              })
          }
      } catch (error) {
          console.log("Microsoft Sign-In Failed:", error);
      }
  };

    useEffect(() => {
      if (isLogin) {
        // Reset state whenever modal is opened
        setShowSignIn(true);
        setShowOtpInput(false);
        setShowDetailsForm(false);
        setEmail('');
        setOtp(new Array(6).fill(''));
        setOtpError('');
        setExtraFieldsError('')
        setErrors('')
        setTags({
          field1: [],
          field2: [],
          field3: [],
          field4: [],
          field5: [],
      });
      setExtraDetails({
          field1: '',
          field2: '',
          field3: '',
          field4: '',
          field5: '',
      });
      setDetails((prev) => ({
        ...prev,
        phone_number: '',  // Reset the phone number
      }));
      }
    }, [isLogin]);
    
    const [otpResendCount, setOtpResendCount] = useState(0);
    const [message, setMessage] = useState("");

    const resendOTPLimits=5;

    const handleResendOTP = () => {
      if(otpResendCount<resendOTPLimits){
        setOtpResendCount((prev)=>prev+1);
        setMessage(" Resending...");
        handleEmailSubmit();
        let timer1=setTimeout(() => {
          setMessage(" ✅OTP Resent!");
          // Reset message after 3 seconds
          let timer2=setTimeout(() => {
            clearTimeout(timer1)
            clearTimeout(timer2)
            setMessage("")
          }, 2000);
        }, 2000);
        setOtp(new Array(6).fill("")); // Reset OTP array
        setOtpError(""); 
      }else{
        setOtpError("Max-limits reached.");
      }
    };

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
        setDetails((prev)=>({
          ...prev,
          email:event.target.value
        }))
      };
      const handleEmailSubmit = () => {
        setIsLoading(true); // Start loading
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        setShowTooltip(false);
        if (!emailPattern.test(email)) {
            setShowTooltip(true);
            setIsLoading(false); // Stop loading if email is invalid
        } else {
            // Fetch the user's public IP address
            fetch('https://api.ipify.org?format=json')
                .then(response => response.json())
                .then(data => {
                    const ipAddress = data.ip;  // User's public IP address
                    // console.log('Client IP Address:', ipAddress);
    
                    // Initialize FingerprintJS to generate a unique device fingerprint
                    FingerprintJS.load().then(fp => {
                        fp.get().then(result => {
                            const fingerprint = result.visitorId;  // Unique device fingerprint
                            // console.log('Device Fingerprint:', fingerprint);
                            // Call signup with email, IP address, and fingerprint
                            authService.signup(email, ipAddress, fingerprint).then((response) => {
                                if (response.message.includes("Maximum login attempts exceeded")) {
                                    setSignInError(response.message);
                                    setShowSignIn(true);
                                    setShowTooltip(false);
                                    setShowOtpInput(false);
                                    setShowDetailsForm(false);
                                    return;
                                } else {
                                    setShowSignIn(false);
                                    setShowTooltip(false);
                                    setShowOtpInput(true);
                                    setShowDetailsForm(false);
                                    setSignInError('');
                                }
                            }).catch((err) => {
                              setSignInError("Maximum Limits Exceeded. Try again later.")
                              console.log("error:", err);
                            }).finally(()=>{
                              setIsLoading(false); // Stop loading
                            });
                        });
                    });
                })
                .catch(error => {
                    console.error("Failed to fetch IP address:", error);
                    setIsLoading(false); // Stop loading on error
                });
        }
      };
    
      const inputRefs = useRef([]);
// To handle OTP Submission
      const handleOtpSubmit = () => {
        setIsLoading(true)
        setOtpError("");
        const otpValue = otp.join("");
        const otpValueint = parseInt(otpValue, 10);
        if (!otpValue || otpValue.length !== 6) {
          setOtpError("Please enter a valid OTP");
          setIsLoading(false)
          return;
        }
        authService.verifySignin(email, otpValueint, { 
            credentials: 'include' // Add this line to include cookies
        }).then((data) => {
            // console.log(data);
            // const token = document.cookie.includes("auth_token");
            // console.log("auth_token exits", token)
            // console.log(document.cookie);
            if (data && data.user_obj_id) {
              setUserObjId(data.user_obj_id);
              setNewUser(data.new_user);
          }
            if (data.message.includes("Invalid OTP")) {
                setOtpError("The OTP you entered is invalid or expired.\nPlease try again!");
                console.log("invalid otp")
                setIsLoading(false)
                return;
            }
            if(data.new_user === 'Y') {
                setShowOtpInput(false);
                setShowSignIn(false);
                setShowDetailsForm(true);
            }
            if(data.new_user === 'N') {
              navigate(nextPath.path, nextPath.props);
            }
        }).catch((err) => {
          setOtpError("The OTP you entered is invalid or expired.\nPlease try again!");
            console.log("error:" + err);
        }).finally(()=>{
          setIsLoading(false)
        });
      };
    // for backspace and removing otp
      const handleKeyDown = (element, index) => {
        if (element.key === "Backspace" && otp[index] === "") {
          if (index > 0) {
            document.getElementById(`otp-input-${index - 1}`).focus();
          }
        }
     
        
        if (element.key === "Enter" && index === 5 && otp[index] !== "") {
          handleOtpSubmit();
        }
      };

      const handleOtpPaste = (event) => {
        const pastedValue = event.clipboardData.getData("Text").replace(/\D/g, ''); // Remove non-numeric characters
        if (pastedValue.length === 6) {
          // If the pasted value has exactly 6 digits, update the OTP fields
          setOtp(pastedValue.split("").map((char) => char));
        } else {
          setOtpError("Please enter a valid 6-digit OTP.");
        }
      };

      const handleOtpChange = (element, index) => {
        if (/^[0-9]$/.test(element.value) || element.value === "") {
          const otpCopy = [...otp];
          otpCopy[index] = element.value;
          setOtp(otpCopy);
          if (element.value !== "" && index < 5) {
            document.getElementById(`otp-input-${index + 1}`).focus();
          }
        }
      };
      
      const handleDetailsChange = (e) => {
        setDetails({
          ...details,
          [e.target.name]: e.target.value,
        });
      };
     
      

      const handlePhoneChange = (value) => {
        // Only proceed with validation if there's a value
        if (value) {
            // Remove all non-digit characters to count only numbers
            const digitsOnly = value.replace(/\D/g, '');
            
            if (digitsOnly.length >15) {
                setPhoneError('Please enter a valid phone number');
                setErrors(prev => ({
                    ...prev,
                    phone_number: true
                }));
            } else {
                setPhoneError('');
                setErrors(prev => ({
                    ...prev,
                    phone_number: false
                }));
            }
        } else {
            // Clear phone error when there's no input
            setPhoneError('');
            setErrors(prev => ({
                ...prev,
                phone_number: !value // Set to true if no value (for required field validation)
            }));
        }
    
        setDetails((prevDetails) => ({
            ...prevDetails,
            phone_number: value,
        }));
    };
      const handleDetailsSubmit = (event) => {
        event.preventDefault();

        const newErrors = {
          first_name: !details.first_name,
          last_name: !details.last_name,
          phone_number: !details.phone_number,
        };
      
        setErrors(newErrors);
      
        // Only proceed with submission if there are no errors
        if (!Object.values(newErrors).includes(true)) {
          console.log("Submitting details, showing extra fields");
          setShowExtraFields(true)
          setShowDetailsForm(false); // Add this line
        }
      };

      // const handleExtraDetailsChange = (e) => {
      //   const { name, value } = e.target;
      //   setExtraDetails((prev) => ({ ...prev, [name]: value }));
      // };
      
      // const handleNextClick = () => {
      //   console.log("Extra Details:", extraDetails); // Log the additional details
      //   navigate('/pricing'); // Navigate to pricing page
      // };
    

      const handleTagInput = (e, fieldName) => {
        if (e.key === "Enter") {
          // Prevent form submission
          e.preventDefault();
          const value = e.target.value.trim();
          if (value !== "") {
            // Add the tag
            setTags((prevTags) => ({
              ...prevTags,
              [fieldName]: [...prevTags[fieldName], value],
            }));
                  // Clear the input field
            setExtraDetails((prevDetails) => ({
              ...prevDetails,
              [fieldName]: "",
            }));

            // If there was an error on this field, clear it
            if (extraFieldsError[fieldName]) {
              setExtraFieldsError((prevErrors) => ({
                ...prevErrors,
                [fieldName]: false,
              }));
            }
          }
        }
      };
    
      const removeTag = (fieldName, indexToRemove) => {
        setTags({
          ...tags,
          [fieldName]: tags[fieldName].filter((_, index) => index !== indexToRemove),
        });
      };
    
      const handleExtraDetailsChange = (e) => {
        const { name, value } = e.target;
        setExtraDetails((prev) => ({ ...prev, [name]: value }));
         // If the field had an error and now has text, clear the error
          if (value.trim() !== "" && extraFieldsError[name]) {
            setExtraFieldsError((prevErrors) => ({
              ...prevErrors,
              [name]: false,
            }));
          }
      };
    
      const handleNextClick = (e) => {
        e.preventDefault();

          // Check if any extra field is empty
      const newExtraFieldsError = {
        field1: !tags.field1.length,
        field2: !tags.field2.length,
        field3: !tags.field3.length,
        // field4: !tags.field4.length,
        // field5: !tags.field5.length,
      };

      setExtraFieldsError(newExtraFieldsError);

      // If any field is empty, do not proceed with the form submission
      if (Object.values(newExtraFieldsError).includes(true)) {
        return;
      }


      // console.log("Form Submitted", tags);
      const body={...details, target_roles:tags.field1, skills:tags.field2, domains:tags.field3}
      authService.saveUserDetails(body).then((data) => {
        console.log(data)
          if (data.status===200) {
            navigate('/pricing');
          }
          else{
            console.log(data.status)
          }
        });
      };
      
        useEffect(() => {
          if (isLogin) {
            document.body.style.overflow = 'hidden';  // Directly set overflow to hidden
            document.body.style.position = 'fixed';   // Optionally fix the position
          } else {
            document.body.style.overflow = '';  // Reset overflow
            document.body.style.position = '';  // Reset position
          }
        
          return () => {
            document.body.style.overflow = '';  // Cleanup on unmount
            document.body.style.position = '';  // Cleanup on unmount
          };
        }, [isLogin]);
      
  if (!isLogin) return null; 

  return (
    <div className="modal">
      <div className="login-modal-content">
      <button className="close-button" onClick={()=>{
          setShowTooltip(false);
          closeModal()
        }
          }>×</button>
        <div className="left-modal-content">
                <img className='logo-image'src={logo} alt="Logo" />
                <img  className='avatar-image'src={avatar} alt="avatar" />
        </div>
        {shownSignIn && !showOtpInput && <div className="right-modal-content">
            <h1 className='sign-title'>Sign On to BotGuru</h1>
             {signInError && (
    <div className="sign-in-error" style={{ color: 'red', marginBottom: '10px' }}>
      {signInError}
    </div>
  )}
            {/* <p>Access to Mock bot, Prep Bot, Resume Builder and many other.</p> */}
            <div className='email-container'>
                <input type="email" 
                // className={showTooltip ? 'error' : ''}
                placeholder="Email address"
                value={email}
                onChange={handleEmailChange}
                onKeyDown = {
                  (event) => {
                      if (event.key === 'Enter') {
                          event.preventDefault(); 
                          handleEmailSubmit();
                      } 
                      }}
                 /> 
                <FontAwesomeIcon className="email-icon" icon={faEnvelope} />
                </div>
                {showTooltip && (
                <span className="login-tooltip">
                    <img className = "warning" src={'warning.svg'} alt="Warning"></img>
                    Please enter a valid email address.
                </span>
            )}

            <div><button className="sign-in" onClick = {handleEmailSubmit} disabled={isLoading}>
            {isLoading ? (
             <>
             <span className="loading-text">Signing In...</span>
             <div className="login-spinner"></div> 
         </>
            ) : (
                'Sign In'
            )}
            </button>

        </div>
            <hr></hr>            
           
            <br></br>
            {/* Calling google auth provider to render google sign in Change clientID wherever required */}
            <GoogleOAuthProvider clientId={clientId}>
      
            <GoogleLogin
                onSuccess={handleLoginSuccess}
                onError={handleLoginFailure}
                useOneTap
                theme="none"  
                shape="rectangular"     
                width="220px" 
                background-color="#BE05FA"
                render={(renderProps) => (
                    <button
                    className="login-with-google-btn"
                    onClick={renderProps.onClick}
                    disabled={renderProps.disabled}

                    style={{
                      width: '220px',
                      height: '50px', // Adjust the height as needed
                      backgroundColor: '#BE05FA',
                      backgroundImage: 'url(https://upload.wikimedia.org/wikipedia/commons/a/a7/Google_Logo_2013.png)', // Google logo URL
                      backgroundSize: 'contain', // Ensures the logo fits within the button
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'center',
                      border: 'none',
                    }}
                    >
                    </button>
                )}
                />
            </GoogleOAuthProvider>

            <div>
              <button
                className="login-with-microsoft-btn"
                onClick={handleMicrosoftLogin}
              >
                <img src="https://upload.wikimedia.org/wikipedia/commons/4/44/Microsoft_logo.svg" alt="Microsoft logo" />
                 Sign in with Microsoft
              </button>
            </div>
            
        </div>}
                  {/* Page to input gmail for signin */}
        {showOtpInput &&  !showDetailsForm && !shownSignIn&& (
          <div className="right-modal-content">
            <div className="otp-header">
                <div>
                    <FontAwesomeIcon className="otp-icon" icon={faEnvelope} />
                </div>
                <span>An email with the code has been sent to <strong>{email}</strong> 
                {/* <button className="change-email"  
                 onClick={() => {setShowSignIn(true); 
                                 setShowOtpInput(false); 
                                 setShowDetailsForm(false); 
                                 setEmail("");}}
                >Change email</button> */}
                </span>
                <br></br>
              
                <span>
                  {/* <Link to="/login">Change!</Link> */}
                </span>

                <span>OTP is valid for 5 minutes</span>

                {otpError && <div className="otp-error"  style={{ whiteSpace: "pre-line" }}>{otpError}</div>}
                
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "5px",
                  margin: "20px",
                }}
              >
                {otp.map((data, index) => (
                  <input
                    key={index}
                    type="text"
                    id={`otp-input-${index}`}
                    name="otp"
                    maxLength="1"
                    className={`otp-field ${otpError ? 'otp-error-border' : ''}`}
                    value={data}
                    onChange={(e) => handleOtpChange(e.target, index)}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    onFocus={(e) => e.target.select()}
                    onPaste={handleOtpPaste} 
                    ref={(el) => (inputRefs.current[index] = el)}
                    style={{
                      width: "40px",
                      height: "40px",
                      textAlign: "center",
                      fontSize: "20px",
                    }}
                  />
                ))}
              </div>
              <span>
                    <span className='resend-code' onClick={()=>{
                      if(message.length){
                        return;
                      }
                      handleResendOTP()
                    }}> Resend OTP </span>
                                 {<span>{message}</span>}
                                 </span>
              {/* <div className="otp-error">Wrong OTP! Please Try Again</div> */}
              
              <div className='confirm'>
              <button className="otp-btn" onClick={handleOtpSubmit} disabled={isLoading}>
              {isLoading ? (
             <>
             <span className="loading-text">Confirm OTP ...</span>
             <div className="login-spinner"></div> 
             </>
            ) : (
                'Confirm OTP'
            )} 
            </button>
            </div>
            </div>
          </div>
        )}

{/* For new Users registration and fill the details */}
        {showDetailsForm && !showOtpInput && !shownSignIn && (
          <div className="right-modal-content">
          <div className="details-box">
            <h1>Registration</h1>
            <form className="details-form login-form">
                <div className='email-container'>
                    <input
                    type="name" 
                    name="first_name"
                    className={errors.first_name ? 'error-fields' : ''}
                    placeholder="First Name"
                    onChange={handleDetailsChange}
                    required
                    /> 
                </div>
                <div className='email-container'>
                    <input
                    type="name" 
                    name="last_name"
                    className={errors.last_name ? 'error-fields' : ''}
                    placeholder="Last Name"
                    onChange={handleDetailsChange}
                    required
                    /> 
                </div>
              
              
                <div className='email-container'>
                    <input type="emailname" 
                    placeholder='Email address'
                    className={showTooltip ? 'error' : ''}
                    value={details.email}
                    onFocus={(e) => e.target.blur()}
                    /> 
                    <FontAwesomeIcon className="email-icon" icon={faEnvelope} />
                </div>
                <div className={`phone-container ${isFocused ? 'focused' : ''} ${errors.phone_number ? 'error-fields' : ''}`}>
                    <PhoneInput
                        type="phone"
                        placeholder="+1 123 456 6789"
                        name="Phone Number"
                        onFocus={() => setIsFocused(true)}
                        value={details.phone_number}
                        onChange={handlePhoneChange}
                        required
                        defaultCountry="US"
                    />

                </div>
                
                {phoneError && details.phone_number ? (
    <div className="register-error-message">
        {phoneError}
    </div>
) : (
    (errors.first_name || errors.last_name || errors.phone_number) && (
        <div className="register-error-message">
            Please fill in the highlighted required fields.
        </div>
    )
)}
                <div className='confirm'>
                <button
                    className="submit-btn"
                    onClick={(event)=>handleDetailsSubmit(event)}>
                    Next
                </button>
                </div>
            </form>
          </div>
          </div>
        )}

{showExtraFields && !showDetailsForm && !showOtpInput && !shownSignIn && (
  <div className="right-modal-content">
    <div className="details-box">
      <h2>Additional Information</h2>
      {/* <form className="extradetails-form" onSubmit={(e) => e.preventDefault()} noValidate>
      {(extraFieldsError.field1 || errors.field2|| errors.field3 ||errors.field4 ||errors.field5) && (
  <div className="register-error-message">
    Please fill in the highlighted required fields.
  </div>
)}
      {[
        { label: "Targeted roles", name: "field1" },
        { label: "Targeted domains", name: "field2" },
        { label: "Previously worked domains on", name: "field3" },
        { label: "Previously worked roles on", name: "field4" },
        { label: "Skills known", name: "field5" },
      ].map(({ label, name }) => (
        <div className="email-container" key={name}>
          <div className="label-text">
            <label>{label}</label>
          </div>
          <div className={`tag-input-container ${extraFieldsError[name] ? 'error-fields' : ''} `}>
            {tags[name].map((tag, index) => (
              <div key={index} className="tag">
                {tag}
                <span
                  className="close-btn"
                  onClick={() => removeTag(name, index)}
                >
                  &times;
                </span>
              </div>
            ))}
            <input
              type="text"
              name={name}
              placeholder="Type here and press Enter"
              className="question-fields"
              value={extraDetails[name]}
              onChange={handleExtraDetailsChange}
              onKeyDown={(e) => {
                // Prevent form submission on Enter
                if (e.key === 'Enter') {
                  e.preventDefault();
                  if (e.target.value.trim() !== "") {
                    handleTagInput(e, name);
                  }
                }
                
              }}
              onBlur={(e) => {
                // Handle tagging when moving out of the field
                if (e.target.value.trim() !== "") {
                  setTags({
                    ...tags,
                    [name]: [...tags[name], e.target.value.trim()],
                  });
                  setExtraDetails({ ...extraDetails, [name]: "" });
                }
              }}
            />
          </div>
        </div>
      ))}
     
    </form> */}

<form className="extradetails-form" onSubmit={(e) => e.preventDefault()} noValidate>
  {/* Error message */}
  {(extraFieldsError.field1 || extraFieldsError.field2 || extraFieldsError.field3) && (
    <div className="register-error-message">
      Please fill in the highlighted required fields.
    </div>
  )}

  {/* Fields */}
  {[
    { label: "Targeted Roles", name: "field1" ,icon:faHashtag},
    { label: "Skills Focused On", name: "field2",icon:faBolt },
    { label: "Interested Domains", name: "field3",icon: faGlobe},
    // { label: "Previously worked roles", name: "field4" },
    // { label: "Skills known", name: "field5" },
  ].map(({ label, name,icon }) => (
    <div className="form-field" key={name}>
      <label className="additional-form-label">
      <FontAwesomeIcon icon={icon} className="label-icon" /> {label}
        </label>
        <div className='tag-input-wrapper'>      
      <div className={`tag-input-container ${extraFieldsError[name] ? 'error-fields' : ''}`}>

        {/* Tags */}
        {tags[name].map((tag, index) => (
          <div key={index} className="tag">
            <span className="tag-text">{tag}</span>
            <span className="close-btn" onClick={() => removeTag(name, index)}>
              &times;
            </span>
          </div>
        ))}
        {/* Input */}
        <input
          type="text"
          name={name}
          placeholder="Type here and press Enter"
          className="tag-input"
          value={extraDetails[name]}
          onChange={handleExtraDetailsChange}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
              if (e.target.value.trim() !== "") {
                handleTagInput(e, name);
              }
            }
          }}
          onBlur={(e) => {
            if (e.target.value.trim() !== "") {
              setTags({
                ...tags,
                [name]: [...tags[name], e.target.value.trim()],
              });
              setExtraDetails({ ...extraDetails, [name]: "" });
            }
          }}
        />
      </div>
      </div>
    </div>
  ))}
</form>
  
        <button className="next-btn" onClick={handleNextClick}>
        Submit Details
        </button>

    </div>
  </div>
)}
</div>
    </div>
  );
};

export default LoginModal;
