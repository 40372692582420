    import React from 'react';
    import './PolicyModal.css';
    import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
    import { faTimes, faUserShield, faInfoCircle, faDatabase, faShareNodes, faLock, faUserCheck, faLink, faBell, faHeadset, faFileContract, faShieldHalved, faTasks, faTrashAlt, faFolderOpen, faShareAlt, faUserLock, faUserSlash, faUserEdit, faCookieBite, faUserPlus,faLayerGroup,faMapMarkerAlt,faClock,faUsers, } from '@fortawesome/free-solid-svg-icons';

    const PrivacyPolicy = ({ isOpen, onClose }) => {
        if (!isOpen) return null;

        return (
            <div className="policy-modal-overlay" onClick={onClose}>
                <div className="policy-modal-content" onClick={(e) => e.stopPropagation()}>
                    <button className="policy-close-button" onClick={onClose}>
                        <FontAwesomeIcon icon={faTimes} />
                    </button>
                    <div className="policy-modal-header">
                        <div className='policy-heading'>
                        <h2><FontAwesomeIcon icon={faUserShield} className="policy-icon" />PRIVACY POLICY</h2>
                        <h2 className='policy-company-name'>Flexon Technologies Inc.</h2>
                        </div>
                        <p className="effective-date">Last Updated: <strong>February 26, 2025</strong></p>
                    </div>
                    <div className="policy-modal-body">
                        <p>This privacy statement discloses the privacy practices for BotGuru (hereinafter “We” or “Us”). Because we are committed to your privacy, we have created the following practices to ensure the highest level of integrity and confidentiality.</p>
                        
                        <h3><FontAwesomeIcon icon={faInfoCircle} className="policy-icon" />INFORMATION COLLECTION AND US</h3>
                        <p>We the sole owner of the information collected on this site. We will not sell, share, or rent this information to others in ways different from what is disclosed in this statement. We collect information from our users at several different points on the web site.</p>
                        
                        <h3><FontAwesomeIcon icon={faUserPlus} className="policy-icon" /> REGISTRATION</h3>
                        <p>In order to obtain or provide Services (as defined in our Terms of Use), a user must first complete the registration process. A user is required to give their contact information (such as name, address, telephone number, e-mail address and other banking information). This information is used to contact the user and process payments. All registration information obtained is encrypted for security purposes and stored on a secured server.</p>
                        
                        <h3><FontAwesomeIcon icon={faCookieBite} className="policy-icon" /> COOKIES</h3>
                        <p>A cookie is a piece of data stored on the user's hard drive containing information about the user. Usage of a cookie is in no way linked to any personally identifiable information while on our site. Once the user closes their browser, the cookie simply terminates. For example, a cookie is used to store items during on-line shopping so that a list of what you are ordering is maintained. If a user rejects the cookie, they may still use the site with some drawbacks and limitations. Cookies can also enable us to track and target the interests of our users to enhance the experience on the site.</p>
                        
                        <h3><FontAwesomeIcon icon={faLock} className="policy-icon" /> LOG FILES</h3>
                        <p>We use IP addresses to analyze trends, administer the site, track user's movement, and gather broad demographic and geographic information for aggregate use. IP addresses are not linked to personally identifiable information.</p>
                        
                        <h3><FontAwesomeIcon icon={faShareNodes} className="policy-icon" /> SHARING</h3>
                        <p>We may share demographic information with our partners and advertisers. This is generally not linked to any personal information that can identify any individual person. However, we may send E-mails or mail about special offers or information that may be of interest to the user based on the information we collect. We use a credit card processing company to bill users for goods and services. These companies do not to our knowledge, retain, share, store or use personally identifiable information for any secondary purposes.</p>
                        <p>We may partner with another party to provide specific services. When the user signs up for these services, we will share names, or other contact information that is necessary for the third party to provide these services. These parties are not allowed to use personally identifiable information except for the purpose of providing these services.</p>
                        <h3><FontAwesomeIcon icon={faLink} className="policy-icon" /> LINKS</h3>
                        <p>This web site may contain links to other sites. Please be aware that we are not responsible for the privacy practices of such other sites. We encourage our users to read the privacy statements of every web site that collects personally identifiable information. This privacy statement applies solely to information collected by this web site.</p>
                        
                        <h3><FontAwesomeIcon icon={faBell} className="policy-icon" />NOTIFICATION OF CHANGES:</h3>
                        <p>If we decide to change our privacy policy, we will post those changes here so our users are always aware of what information we collect, how we use it, and under circumstances, if any, we disclose it.</p>
                        
                        <h3><FontAwesomeIcon icon={faShieldHalved} className="policy-icon" />SECURITY</h3>
                        <p>This web site takes every precaution to protect our users' information. When users submit sensitive information via the web site, their information is protected both online and off-line.</p>
                        <p>When our registration form asks users to enter sensitive information (such as credit card number and/or social security number), that information is encrypted and is protected with the best encryption software in the industry - SSL. While on a secure page, such as our registration form, the lock icon on the bottom of Web browsers such as Netscape Navigator and Microsoft Internet Explorer become locked, as opposed to unlocked, or open, when you are just "surfing".</p>
                        <p>While we use SSL encryption to protect sensitive information online, we also do everything in our power to protect user-information off-line. All of our users' information, not just the sensitive information mentioned above, has restricted access at our company. Only employees who need the information to perform a specific job are granted access to personally identifiable information. Access to the information is made via a password-protected site. Each time an employee needs to access information, they must enter their password to gain access to the information. Furthermore, employees are kept up-to-date on our security and privacy practices. Employees are periodically notified and/or reminded about the importance we place on privacy, and what they can do to ensure our customers' information is protected.</p>

                        <h3><FontAwesomeIcon icon={faLayerGroup} className="policy-icon" />SUPPLEMENTATION OF INFORMATION</h3>
                        <p>In order for this web site to properly fulfill its obligation to our customers, it may be necessary for us to supplement the information we receive with information from third party sources.</p>

                        
                        <h3><FontAwesomeIcon icon={faBell} className="policy-icon" />SITE AND SERVICE UPDATES:</h3>
                        <p>We may send the user site and service announcement updates which contain important information about the service. We communicate with the user to provide requested services and in regard to issues relating to their account via e-mail or phone (including text messages).</p>
                        <h3><FontAwesomeIcon icon={faUserEdit} className="policy-icon" />CORRECTING / UPDATING PERSONAL INFORMATION:</h3>
                        <p>If a user's personally identifiable information changes (such as address or E-mail), or if a user no longer desires to receive information from us, we will endeavor to correct, update or remove that user's personal data from our database.</p>
                        
                        <div className="policy-modal-header">
                            <h2><FontAwesomeIcon icon={faMapMarkerAlt} className="policy-icon" />Privacy Notice for California Residents</h2>    
                            <p className="effective-date">Last Updated: <strong>February 26, 2025</strong></p>
                        </div>
                        
                        <p>This Privacy Notice for California Residents supplements and is expressly made part of the information contained in the Privacy Policy and applies solely to all visitors, users, and others who reside in the State of California ("consumers" or "you"). We adopt this Notice to comply with the California Consumer Privacy Act of 2018 (CCPA). Any terms defined in the CCPA have the same meaning when used in this Notice.</p>
                        
                        <h3><FontAwesomeIcon icon={faDatabase} className="policy-icon" />Information We Collect</h3>
                        <p>Our Websites collect information that identifies, relates to, describes, references, is capable of being associated with, or could reasonably be linked, directly or indirectly, with a particular consumer or device ("personal information"). In particular, our Websites have collected the following categories of personal information from its consumers within the last twelve (12) months:</p>
                        
                         {/* New Table */}
                        <table className="policy-table">
                            <thead>
                                <tr>
                                    <th>Category</th>
                                    <th>Collected</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>A. Identifiers</td>
                                    <td>Yes</td>
                                </tr>
                                <tr>
                                    <td>B. Personal information categories listed in the California Customer Records statute (Cal. Civ. Code § 1798.80(e)).</td>
                                    <td>Yes</td>
                                </tr>
                                <tr>
                                    <td>C. Protected classification characteristics under California or federal law.</td>
                                    <td>No</td>
                                </tr>
                                <tr>
                                    <td>D. Commercial information.</td>
                                    <td>Yes</td>
                                </tr>
                                <tr>
                                    <td>E. Biometric information.</td>
                                    <td>No</td>
                                </tr>
                                <tr>
                                    <td>F. Internet or other similar network activity.</td>
                                    <td>Yes</td>
                                </tr>
                                <tr>
                                    <td>G. Geolocation data.</td>
                                    <td>Yes</td>
                                </tr>
                                <tr>
                                    <td>H. Sensory data.</td>
                                    <td>No</td>
                                </tr>
                                <tr>
                                    <td>I. Professional or employment-related information.</td>
                                    <td>No</td>
                                </tr>
                                <tr>
                                    <td>J. Non-public education information (per the Family Educational Rights and Privacy Act (20 U.S.C. Section 1232g, 34 C.F.R. Part 99)).</td>
                                    <td>No</td>
                                </tr>
                                <tr>
                                    <td>K. Inferences drawn from other personal information.</td>
                                    <td>Yes</td>
                                </tr>
                            </tbody>
                        </table> 
                        <h3><FontAwesomeIcon icon={faUserSlash} className="policy-icon" /> Personal information does not include</h3>
                        <ul className='policy-list'>
                        <li><span>Publicly available information from government records.</span></li>   
                        <li><span>Deidentified or aggregated consumer information.</span></li>
                        </ul>
                        <p>Information excluded from the CCPA’s scope, like:</p>
                        <ul className='policy-list'>
                        <li><span>health or medical information covered by the Health Insurance Portability and Accountability Act of 1996 (HIPAA) and the California Confidentiality of Medical Information Act (CMIA) or clinical trial data;</span></li>   
                        <li><span>personal information covered by certain sector-specific privacy laws, including the Fair Credit Reporting Act (FRCA), the Gramm-Leach-Bliley Act (GLBA) or California Financial Information Privacy Act (FIPA), and the Driver’s Privacy Protection Act of 1994.</span></li>
                        </ul>
                        <p>We obtain the categories of personal information listed above from the following categories of sources:</p>
                        <ul className='policy-list'>
                        <li><span>Directly from you. For example, from forms you complete or products and services you purchase.</span></li>   
                        <li><span>Indirectly from you. For example, from observing your actions on our Websites.</span></li>
                        <li><span>Indirectly from you. For example, from observing your actions on our Websites.</span></li>
                        </ul>

                        <h3><FontAwesomeIcon icon={faUserLock} className="policy-icon" /> Use of Personal Information</h3>
                        <p>We may use or disclose the personal information we collect for one or more of the following business purposes:</p>
                        <ul className='policy-list'>
                        <li><span>To fulfill or meet the reason you provided the information.  For example, if you provide your personal information to purchase a product or service, we will use that information to process your payment and facilitate the services.</span></li>
                        <li><span>To provide, support, personalize, and develop our Websites, products, and services.</span></li>
                        <li><span>To create, maintain, customize, and secure your account with us.</span></li>
                        <li><span>To process your requests, purchases, transactions, and payments and prevent transactional fraud.</span></li>
                        <li><span>To provide you with support and to respond to your inquiries, including to investigate and address your concerns and monitor and improve our responses.</span></li>
                        <li><span>To personalize your Website experience and to deliver content and product and service offerings relevant to your interests, including targeted offers and ads through our Websites, third-party sites, and via email or text message (with your consent, where required by law).</span></li>
                        <li><span>To help maintain the safety, security, and integrity of our Websites, products and services, databases and other technology assets, and business.</span></li>
                        <li><span>For testing, research, analysis, and product development, including to develop and improve our Websites, products, and services.</span></li>
                        <li><span>To respond to law enforcement requests and as required by applicable law, court order, or governmental regulations.</span></li>
                        <li><span>As described to you when collecting your personal information or as otherwise set forth in the CCPA.</span></li>
                        <li><span>To evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which personal information held by us, about our Website users is among the assets transferred.</span></li>
                        </ul>
                        <p>We will not collect additional categories of personal information or use the personal information we collected for materially different, unrelated, or incompatible purposes without providing you notice.</p>
                        <h3><FontAwesomeIcon icon={faShareAlt} className="policy-icon" />Sharing Personal Information</h3>
                        <p>We may disclose your personal information to a third party for a business purpose. When we disclose personal information for a business purpose, we enter a contract that describes the purpose and requires the recipient to both keep that personal information confidential and not use it for any purpose except performing the contract.</p>
                        <p>We share your personal information with the following categories of third parties:</p>
                        <ul className='policy-list'>
                        <li><span>Service providers.</span></li>   
                        <li><span>Data aggregators.</span></li>
                        </ul>
                        <h3><FontAwesomeIcon icon={faUserCheck} className="policy-icon" />Your Rights and Choices </h3>
                        <p>The CCPA provides consumers (California residents) with specific rights regarding their personal information. This section describes your CCPA rights and explains how to exercise those rights.</p>
                        <h3><FontAwesomeIcon icon={faFolderOpen} className="policy-icon" /> Access to Specific Information and Data Portability Rights</h3>
                        <p>You have the right to request that we disclose certain information to you about our collection and use of your personal information over the past 12 months. Once we receive and confirm your verifiable consumer request (see Exercising Access, Data Portability, and Deletion Rights), we will disclose to you:</p>
                        <ul className='policy-list'>
                        <li><span>The categories of personal information we collected about you.</span></li>   
                        <li><span>The categories of sources for the personal information we collected about you.</span></li>
                        <li><span>Our business or commercial purpose for collecting or selling that personal information.</span></li>
                        <li><span>The categories of third parties with whom we share that personal information.</span></li>
                        <li><span>The specific pieces of personal information we collected about you (also called a data portability request).</span></li>
                        </ul>
                        <p>If we sold or disclosed your personal information for a business purpose, two separate lists disclosing:</p>
                        <ul className='policy-list'>
                        <li><span>sales, identifying the personal information categories that each category of recipient purchased; and</span></li>
                        <li><span>disclosures for a business purpose, identifying the personal information categories that each category of recipient obtained.</span></li>                        
                        </ul>

                        <h3><FontAwesomeIcon icon={faTrashAlt} className="policy-icon" /> Deletion Request Rights</h3>
                        <p>You have the right to request that we delete any of your personal information that we collected from you within the last 12 months and retained, subject to certain exceptions. Once we receive and confirm your verifiable consumer request (see Exercising Access, Data Portability, and Deletion Rights), we will delete (and direct our service providers to delete) your personal information from our records, unless an exception applies.</p>
                        <p>We may deny your deletion request if retaining the information is necessary for us or our service provider(s) to:</p>
                        
                        <ul className='policy-list'>
                        <li><span>1. Complete the transaction for which we collected the personal information, provide a good or service that you requested, take actions reasonably anticipated within the context of our ongoing business relationship with you, or otherwise perform our contract with you.</span></li>
                        <li><span>2. Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity, or prosecute those responsible for such activities.</span></li> 
                        <li><span>3. Debug products to identify and repair errors that impair existing intended functionality.</span></li> 
                        <li><span>4. Exercise free speech, ensure the right of another consumer to exercise their free speech rights, or exercise another right provided for by law.</span></li>
                        <li><span>5. Comply with the California Electronic Communications Privacy Act (Cal. Penal Code § 1546 seq.).</span></li> 
                        <li><span>6. Engage in public or peer-reviewed scientific, historical, or statistical research in the public interest that adheres to all other applicable ethics and privacy laws, when the information’s deletion may likely render impossible or seriously impair the research’s achievement, if you previously provided informed consent.</span></li> 
                        <li><span>7. Enable solely internal uses that are reasonably aligned with consumer expectations based on your relationship with us.</span></li> 
                        <li><span>8. Comply with a legal obligation.</span></li>                        
                        <li><span>9. Make other internal and lawful uses of that information that are compatible with the context in which you provided it.</span></li> 
                        </ul>

                        <h3><FontAwesomeIcon icon={faTasks} className="policy-icon" />Exercising Access, Data Portability, and Deletion Rights</h3>
                        <p>To exercise the access, data portability, and deletion rights described above, please submit a verifiable consumer request to us by filling out the Consumer Privacy form.</p>
                        <p>Only you, or a person registered with the California Secretary of State that you authorize to act on your behalf, may make a verifiable consumer request related to your personal information. You may also make a verifiable consumer request on behalf of your minor child.You may only make a verifiable consumer request for access or data portability twice within a 12-month period. The verifiable consumer request must:</p>
                        <ul className='policy-list'>
                            <li><span>Provide sufficient information that allows us to reasonably verify you are the person about whom we collected personal information or an authorized representative.</span></li>
                            <li><span>•	Describe your request with sufficient detail that allows us to properly understand, evaluate, and respond to it.</span></li>
                        </ul>
                        <p>We cannot respond to your request or provide you with personal information if we cannot verify your identity or authority to make the request and confirm the personal information relates to you</p>
                        <p>Making a verifiable consumer request does not require you to create an account with us.</p>
                        <p>We will only use personal information provided in a verifiable consumer request to verify the requestor’s identity or authority to make the request.</p>
                        
                        <h3><FontAwesomeIcon icon={faClock} className="policy-icon" />Response Timing and Format</h3>
                        <p>We endeavor to respond to a verifiable consumer request within forty-five (45) days of its receipt. If we require more time (up to 90 days), we will inform you of the reason and extension period in writing.</p>
                        <p>If you have an account with us, we will deliver our written response to that account. If you do not have an account with us, we will deliver our written response by mail or electronically, at your option.</p>
                        <p>Any disclosures we provide will only cover the 12-month period preceding the verifiable consumer request’s receipt. The response we provide will also explain the reasons we cannot comply with a request, if applicable. For data portability requests, we will select a format to provide your personal information that is readily useable and should allow you to transmit the information from one entity to another entity without hindrance.</p>
                        <p>We do not charge a fee to process or respond to your verifiable consumer request unless it is excessive, repetitive, or manifestly unfounded. If we determine that the request warrants a fee, we will tell you why we made that decision and provide you with a cost estimate before completing your request.</p>

                        <h3><FontAwesomeIcon icon={faUsers} className="policy-icon" />Non-Discrimination</h3>
                        <p>We will not discriminate against you for exercising any of your CCPA rights. Unless permitted by the CCPA, we will not:</p>
                        <ul className='policy-list'>
                            <li><span>Deny you goods or services.</span></li>
                            <li><span>Charge you different prices or rates for goods or services, including through granting discounts or other benefits, or imposing penalties.</span></li>
                            <li><span>Provide you a different level or quality of goods or services.</span></li>
                            <li><span>Suggest that you may receive a different price or rate for goods or services or a different level or quality of goods or services.</span></li>
                        </ul>
                        <p>However, we may offer you certain financial incentives permitted by the CCPA that can result in different prices, rates, or quality levels. Any CCPA-permitted financial incentive we offer will reasonably relate to your personal information’s value and contain written terms that describe the program’s material aspects. Participation in a financial incentive program requires your prior opt in consent, which you may revoke at any time.</p>
                        
                        <h3><FontAwesomeIcon icon={faMapMarkerAlt} className="policy-icon" />Other California Privacy Rights</h3>
                        <p>California’s "Shine the Light" law (Civil Code Section § 1798.83) permits users of our Websites that are California residents to request certain information regarding our disclosure of personal information to third parties for their direct marketing purposes. To make such a request, please send an email to <a>info@botguru.ai</a></p>
                        <h3><FontAwesomeIcon icon={faFileContract} className="policy-icon" />Changes to Our Privacy Notice</h3>
                        <p>We reserve the right to amend this privacy Notice at our discretion and at any time. When we make changes to this privacy Notice, we will post the updated Notice on the Websites and update the Notice’s effective date. Your continued use of our Websites following the posting of changes constitutes your acceptance of such changes.</p>


                        <h3><FontAwesomeIcon icon={faHeadset} className="policy-icon" />Contact Information</h3>
                        <p>If you have any questions or comments about this Notice, the ways in which we collect and use your information described above, your choices and rights regarding such use, or wish to exercise your rights under California law, please do not hesitate to contact us at:</p>
                        <ul className='policy-list'>
                        <li><strong>Phone</strong> <span><a> +1 925-209-6049</a></span></li>
                        <li><strong>Email</strong> <span><a>info@botguru.ai </a></span></li>   
                        </ul>
                    </div>
                </div>
            </div>
        );
    };

    export default PrivacyPolicy;
