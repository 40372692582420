import React, { useState, useEffect } from "react";
import './ResumeGuruPage.css';
import ResumeModal from "./ResumeModal";
import useAuthService from '../../services/AuthService';
import ResumePreview from './ResumePreview';
import { jsPDF } from "jspdf";
import LoadingScreen from "./JDLoadingScreen";
import { useAuthContext } from "../Auth/AuthContext";
import Tour from 'reactour'; // Import the Tour component
import config from "../../config.json";
import Notification from "./Notification";
import { useNavigate } from "react-router-dom";
import { Info, MoveRight, Sparkles, Trophy, Zap } from "lucide-react";



const ResumeGuruPage = () => {

    const [resumeModal, setresumeModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selectedResume, setSelectedResume] = useState(null);
    const navigate = useNavigate();
    const [resumes, setResumes] = useState([]);
    const [showPreview, setShowPreview] = useState(false);
    const { userObjId, newUser,userAnalytics } = useAuthContext(); // Get userObjId and newUser from context
    const [showNotification, setShowNotification] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState('');
    const [showTourBtn, setShowTourBtn]=useState(false);


    // Add new state at the top with other statesconst [isViewMode, setIsViewMode] = useState(false);
    
    const [isViewMode, setIsViewMode] = useState(false);
    const [isTourOpen, setIsTourOpen] = useState(false); // Initialize as false
    const [currentStep, setCurrentStep] = useState(0); // State to track current tour step

    const tourSteps = [
        {
            selector: '.upload-resume-button',
            content: 'Click here to upload your resume.',
        },
        {
            selector: '.create-resume-button',
            content: 'Click here to create a new resume.',
        },
        // {
        //     selector: '.resume-card .file-type',
        //     content: 'Click here to edit the resume.',
        // },
        // {
        //     selector: '.resume-card .action-btn.download',
        //     content: 'Click here to download the resume.',
        // },
    ];

    const handleNext = () => {
        setCurrentStep((prev) => Math.min(prev + 1, tourSteps.length - 1));
    };

    const handlePrev = () => {
        setCurrentStep((prev) => Math.max(prev - 1, 0));
    };

    const handleElementClick = (e, normalFunction) => {
        if (isTourOpen) {
            if (e) e.preventDefault();
            handleNext();
        } else {
            normalFunction();
        }
    };

    const fetchResumes = async () => {
        setLoading(true);
        try {
            const response = await fetch(`${config.restAPI}/getResumes/`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    user_obj_id: userObjId,
                }),
            });

            if (!response.ok) {
                throw new Error(`Failed to fetch resumes. Status: ${response.status}`);
            }

            const data = await response.json();
            console.log("Fetched Resumes:", data.resumes);    
            const sortedResumes = data.resumes.sort((a, b) => {
                const aDate = new Date(a.created_at);
                const bDate = new Date(b.created_at);
                return bDate - aDate; // Latest first (descending order)
            });
    
            console.log("Sorted Resumes by created_at:", sortedResumes);
            setResumes(sortedResumes || []); 
        } catch (error) {
            console.error("Error fetching resumes:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchResumes();
    }, []);

    useEffect(() => {
        if (newUser === 'Y') {
            console.log('New user detected, showing initial tour guide');
            setShowTourBtn(true);
        } else {
            console.log('Tour guide disabled because:', {
                newUser
            });
            setIsTourOpen(false);
        }
    }, [newUser]);

    const handleViewResume = (resumeData) => {
        const formattedResume = {
            message: {
                first_name: resumeData.resume.personalDetails.firstName,
                last_name: resumeData.resume.personalDetails.lastName,
                email: resumeData.resume.personalDetails.email,
                phone_number: resumeData.resume.personalDetails.phoneNumber,
                linkedin_url: resumeData.resume.personalDetails.linkedIn,
                website_url: resumeData.resume.personalDetails.website,
                professional_summary: resumeData.resume.personalDetails.summary,
                skills: resumeData.resume.skills,
                experience: resumeData.resume.experiences.map(exp => ({
                    job_role: exp.jobTitle,
                    company_name: exp.companyName,
                    start_date: exp.startDate,
                    end_date: exp.endDate,
                    responsibilities: exp.description ? exp.description.split('\n') : [],
                    city_state: exp.location,
                    country: exp.country,
                    skills: exp.skills || [],
                    domain: exp.domain
                })),
                education: resumeData.resume.education.map(edu => ({
                    school_university: edu.school,
                    degree: edu.degree,
                    start_date: edu.startDate,
                    end_date: edu.endDate,
                    gpa: edu.gpa,
                    city_state: edu.city_state,
                    country: edu.country,
                    additionalDetails: edu.additionalDetails
                }))
            }
        };
        setSelectedResume(formattedResume);
        setShowPreview(true);
    };
    
    
    const handleDownloadResume = (resumeData) => {
        if (!resumeData?.resume) return;
        const resume = resumeData.resume;
        
        const pdf = new jsPDF();
        let yPos = 20;
        const margin = 20;
        const pageWidth = pdf.internal.pageSize.width;
    
        // Helper function for page breaks
        const checkPageBreak = (requiredSpace = 40) => {
            if (yPos > pdf.internal.pageSize.height - requiredSpace) {
                pdf.addPage();
                yPos = 20;
            }
        };

        const addHorizontalLine = () => {
            pdf.setDrawColor(200, 200, 200);
            pdf.setLineWidth(0.3);
            pdf.line(margin, yPos, pageWidth - margin, yPos);
            yPos += 10;
        };
    
        // Personal Details Section
        pdf.setFont("helvetica", "bold");
        pdf.setFontSize(24);
        const fullName = `${resume.personalDetails.firstName} ${resume.personalDetails.lastName}`;
        const nameWidth = pdf.getStringUnitWidth(fullName) * pdf.getFontSize() / pdf.internal.scaleFactor;
        pdf.text(fullName, (pageWidth - nameWidth) / 2, yPos);
        yPos += 8;
    
        // Contact Info
        pdf.setFontSize(11);
        pdf.setFont("helvetica", "normal");
        const contactInfo = [];
        if (resume.personalDetails.email) contactInfo.push(resume.personalDetails.email);
        if (resume.personalDetails.phoneNumber) contactInfo.push(resume.personalDetails.phoneNumber);
        const contactText = contactInfo.join(" | ");
        const contactWidth = pdf.getStringUnitWidth(contactText) * pdf.getFontSize() / pdf.internal.scaleFactor;
        pdf.text(contactText, (pageWidth - contactWidth) / 2, yPos);
        yPos += 5;
    
        // Social Links
        if (resume.personalDetails.linkedIn || resume.personalDetails.website) {
            const socialLinks = [];
            if (resume.personalDetails.linkedIn) socialLinks.push(resume.personalDetails.linkedIn);
            if (resume.personalDetails.website) socialLinks.push(resume.personalDetails.website);
            const socialText = socialLinks.join(" | ");
            const socialWidth = pdf.getStringUnitWidth(socialText) * pdf.getFontSize() / pdf.internal.scaleFactor;
            pdf.text(socialText, (pageWidth - socialWidth) / 2, yPos);
            yPos += 15;
        }
        yPos += 5;
        // Professional Summary
        if (resume.personalDetails.summary) {
            pdf.setFont("helvetica", "bold");
            pdf.setFontSize(12);
            pdf.text("PROFESSIONAL SUMMARY", margin, yPos);
            yPos += 8;
    
            pdf.setFont("helvetica", "normal");
            pdf.setFontSize(11);
            const summaryLines = pdf.splitTextToSize(resume.personalDetails.summary, pageWidth - 2 * margin);
            summaryLines.forEach(line => {
                checkPageBreak();
                pdf.text(line, margin, yPos);
                yPos += 6;
            });
            // yPos += 15;
            addHorizontalLine();
        }
    
        // Experience Section
        if (resume.experiences?.length) {
            checkPageBreak();
            pdf.setFont("helvetica", "bold");
            pdf.setFontSize(12);
            pdf.text("EXPERIENCE", margin, yPos);
            yPos += 6;
    
            resume.experiences.forEach(exp => {
                checkPageBreak();
                yPos += 3;
                 // Company Name and Date on same line
                 if (exp.companyName || (exp.startDate || exp.endDate)) {
                    pdf.setFont("helvetica", "bold");
                    pdf.setFontSize(11);
                    if(exp.jobTitle){
                    pdf.text(exp.companyName, margin, yPos);
                    }
                    if (exp.startDate || exp.endDate) {
                        const dateText = `${exp.startDate} - ${exp.endDate}`;
                        const dateWidth = pdf.getTextWidth(dateText);
                        pdf.text(dateText, pageWidth - margin - dateWidth, yPos);
                    }
                    yPos += 6;
                }
                // Job Title and Location
                if (exp.jobTitle || exp.location || exp.country) {
                    pdf.setFont("helvetica", "italic");
                    if (exp.jobTitle) {
                        pdf.text(exp.jobTitle, margin, yPos);
                    }
                    if (exp.location || exp.country) {
                        const locationText = [exp.location, exp.country].filter(Boolean).join(", ");
                        const locationWidth = pdf.getTextWidth(locationText);
                        pdf.text(locationText, pageWidth - margin - locationWidth, yPos);
                    }
                    yPos += 6;
                }
               
                // Responsibilities
                if (exp.description) {
                    yPos += 4;
                    pdf.setFont("helvetica", "normal");
                    const responsibilities = exp.description.split('\n');
                    responsibilities.forEach(resp => {
                        checkPageBreak();
                        const formattedResp = resp.startsWith('•') ? resp : `• ${resp}`;
                        const lines = pdf.splitTextToSize(formattedResp, pageWidth - 2 * margin - 5);                        lines.forEach(line => {
                            pdf.text(line, margin + 2, yPos);
                            yPos += 6;
                        });
                        // yPos += 4;
                    });
                }
                // yPos += 10;
            });
            addHorizontalLine();
        }
    
        // Skills Section
        if (resume.skills?.length) {
            checkPageBreak();
            pdf.setFont("helvetica", "bold");
            pdf.setFontSize(12);
            pdf.text("SKILLS", margin, yPos);
            yPos += 10;
    
            const skillsPerRow = 3;
            const skillWidth = (pageWidth - 1 * margin) / skillsPerRow;
            
            pdf.setFont("helvetica", "normal");
            pdf.setFontSize(11);
            
            for (let i = 0; i < resume.skills.length; i += skillsPerRow) {
                checkPageBreak();
                const rowSkills = resume.skills.slice(i, i + skillsPerRow);
                rowSkills.forEach((skill, index) => {
                    pdf.text(`• ${skill}`, margin + (index * skillWidth), yPos);
                });
                yPos += 8;
            }
            // yPos += 10;
            addHorizontalLine();
        }
    
        // Education Section
        if (resume.education?.length) {
            checkPageBreak();
            pdf.setFont("helvetica", "bold");
            pdf.setFontSize(12);
            pdf.text("Education", margin, yPos);
            yPos += 10;
    
            resume.education.forEach(edu => {
                checkPageBreak();
                pdf.setFont("helvetica", "bold");
                pdf.setFontSize(12);
                pdf.text(edu.school, margin, yPos);
                yPos += 6;
    
                pdf.setFont("helvetica", "italic");
                pdf.setFontSize(11);
                pdf.text(edu.degree, margin, yPos);
    
                const dateText = `${edu.startDate} - ${edu.endDate}`;
                const dateWidth = pdf.getStringUnitWidth(dateText) * pdf.getFontSize() / pdf.internal.scaleFactor;
                pdf.text(dateText, pageWidth - margin - dateWidth, yPos);
                
                if (edu.gpa) {
                    yPos += 6;
                    pdf.setFont("helvetica", "normal");
                    pdf.text(`GPA: ${edu.gpa}`, margin, yPos);
                }
                   // Add Additional Details
        if (edu.additionalDetails) {
            yPos += 6;
            pdf.setFont("helvetica", "normal");
            pdf.setFontSize(11);
            const detailLines = pdf.splitTextToSize(edu.additionalDetails, pageWidth - 2 * margin);
            detailLines.forEach(line => {
                checkPageBreak();
                pdf.text(line, margin, yPos);
                yPos += 6;
            });
        }



                yPos += 15;
            });
        }
    
        // Save the PDF
        const fileName = `${resume.personalDetails.firstName}_${resume.personalDetails.lastName}_Resume.pdf`;
        pdf.save(fileName);
        setIsTourOpen(false); // Close the tour when the user clicks on the download resume button
    };

    const handleEditResume = (_id) => {
        // Find the resume with the given _id
        console.log('Editing resume with id:', _id); // Debug log
        const selected = resumes.find(resumeData => resumeData._id === _id);
        console.log(selected)
        if (!selected) {
            console.error(`Resume with id ${_id} not found`);
            return;
        }
        // Format the data as needed for ResumeModal
        const formattedResume = {
            message: {
                first_name: selected.resume.personalDetails.firstName,
                res_obj_id: _id,
                resumeId: selected.resume_id,
                last_name: selected.resume.personalDetails.lastName,
                email: selected.resume.personalDetails.email,
                phone_number: selected.resume.personalDetails.phoneNumber,
                linkedin_url: selected.resume.personalDetails.linkedIn,
                website_url: selected.resume.personalDetails.website,
                professional_summary: selected.resume.personalDetails.summary,
                skills: selected.resume.skills,
                experience: selected.resume.experiences.map(exp => ({
                    job_role: exp.jobTitle,
                    company_name: exp.companyName,
                    start_date: exp.startDate,
                    end_date: exp.endDate,
                    responsibilities: exp.description ? exp.description.split('\n') : [],
                    city_state: exp.location,
                    country: exp.country,
                    skills: exp.skills || [],
                    domain: exp.domain
                })),
                education: selected.resume.education.map(edu => ({
                    school_university: edu.school,
                    degree: edu.degree,
                    start_date: edu.startDate,
                    end_date: edu.endDate,
                    gpa: edu.gpa,
                    city_state: edu.city_state,
                    country: edu.country,
                    additionalDetails: edu.additionalDetails
                }))
            }
        };
    
        setSelectedResume(formattedResume);
        setresumeModal(true);
        setIsTourOpen(false); // Close the tour when the user clicks on the edit button
    };
    

const authService = useAuthService();
    const websocketUrlForFileUpload = "wss://o834xau6qe.execute-api.us-west-1.amazonaws.com/production/"; // WebSocket for File upload

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (!file) {
      console.error("No file selected.");
      return;
    }

    console.log("File selected:", file);
    handleFileUpload(file);
    event.target.value = null; // Clear the input after handling
  };

  const handleFileUpload = (file) => {
    setLoading(true);
    setresumeModal(true);
    console.log("Initializing WebSocket for Resume Upload...");
    const websocketInstance = new WebSocket(websocketUrlForFileUpload);

    websocketInstance.onopen = () => {
      console.log("WebSocket connection established for Resume Upload.");
      const message = { action: "botguru_sendmessage", message: "get data" };
      sendWebSocketMessageWithRetry(websocketInstance, message);
    };

    websocketInstance.onmessage = (event) => handleWebSocketMessage(event, file);

    websocketInstance.onerror = (error) => {
      console.error("WebSocket error for Resume Upload:", error);
    };

    websocketInstance.onclose = () => {
      console.log("WebSocket connection closed for Resume Upload.");
    };
  };

  const handleWebSocketMessage = async (event, file) => {
    if (!event.data || event.data.trim() === "") {
        console.warn("Empty WebSocket message received.");
        return;
    }

    try {
        const data = JSON.parse(event.data);
        console.log("WebSocket message received:", data);
        setLoading(true);
        
        if (data.connectionid) {
            // Step 1: Call authService to extract resume data
            const response = await authService
                .resumeExtractor({
                    file,
                    connectionId: data.connectionid,
                    user_obj_id: userObjId,
                });

            console.log("Resume extraction initiated successfully:", response);

            // Wait for the full resume data in subsequent WebSocket messages
        } else if (data.message) {

            const experienceSkills = (data.message.experience || [])
            .flatMap(exp => exp.skills || []) // Extract skills from each experience
            .filter((skill, index, self) => self.indexOf(skill) === index); // Remove duplicates
            // Step 2: Full resume data received
            const formattedResume = {
              message: {
                  first_name: data.message.first_name || '',
                  last_name: data.message.last_name || '',
                  email: data.message.email || '',
                  phone_number: data.message.phone_number || '',
                  linkedin_url: data.message.linkedin_url || '',
                  website_url: data.message.website_url || '',
                  professional_summary: data.message.professional_summary || '',
                  skills: [...new Set([...(data.message.skills || []), ...experienceSkills])], // Merge extracted skills
                  experience: data.message.experience || [],
                  education: data.message.education || []
              }
          };

          setSelectedResume(formattedResume); // Set the formatted data
          setLoading(false);
          setresumeModal(true);
        }
    } catch (error) {
        console.error("Error handling WebSocket message:", error);
        setNotificationMessage(error?.data.error);
        setShowNotification(true);
        setLoading(false);
        setresumeModal(false);
    }
};

  const sendWebSocketMessageWithRetry = (websocketInstance, message, retries = 3, interval = 1000) => {
    let attempts = 0;

    const sendMessage = () => {
      if (websocketInstance.readyState === WebSocket.OPEN) {
        websocketInstance.send(JSON.stringify(message));
        console.log("WebSocket message sent:", message);
      } else if (attempts < retries) {
        attempts++;
        console.log(`WebSocket not ready. Retrying (${attempts}/${retries})...`);
        setTimeout(sendMessage, interval);
      } else {
        console.error("Failed to send WebSocket message after multiple attempts.");
      }
    };

    sendMessage();
  };

  const parseDate = (dateStr) => {
    if (!dateStr) return null;
    if (dateStr === 'Present') return new Date();
    
    const [month, year] = dateStr.split(' ');
    const monthIndex = new Date(Date.parse(month + " 1, 2000")).getMonth();
    return new Date(parseInt(year), monthIndex);
};

const calculateTotalExperience = (experiences) => {
    if (!experiences || experiences.length === 0) return 'No experience';

    let totalMonths = 0;
    
    experiences.forEach(exp => {
        const startDate = parseDate(exp.startDate);
        const endDate = exp.endDate === 'Present' ? new Date() : parseDate(exp.endDate);
        
        if (startDate && endDate) {
            const months = (endDate.getFullYear() - startDate.getFullYear()) * 12 + 
                          (endDate.getMonth() - startDate.getMonth());
            totalMonths += months;
        }
    });

    const years = Math.round(totalMonths / 12); // Round to nearest year
    return `${years}+ years`;
};


    const closeresumeModal = () => setresumeModal(false);

    const truncateJobTitle = (jobTitle) => {
        const maxLength = 28;
        if (jobTitle.length > maxLength) {
            return `${jobTitle.substring(0, maxLength - 3)}...`; // Truncate and add ellipsis
        }
        return jobTitle; // Return original if within limits
    };

    const handleUploadResumeClick = (e) => {
        handleElementClick(e, () => {
            document.getElementById('fileInput').click();
            setIsTourOpen(false); // Close the tour when the user clicks on the upload resume button
        });
    };

    const handleCreateResumeClick = (e) => {
        handleElementClick(e, () => {
            setSelectedResume(null);
            setresumeModal(true);
            setIsTourOpen(false); // Close the tour when the user clicks on the create new resume button
        });
    };

    const handleNewResumeClick = (e) => {
        handleElementClick(e, () => {
            setSelectedResume(null);
            setresumeModal(true);
            if (newUser === 'Y') {
                setIsTourOpen(false); // Only close tour if it was open (new user)
            }
        });
    };

    // Calculate the total sessions available by summing prepbot and mock_bot totals
    const totalSessions = (userAnalytics?.prepbot_total || 0) + (userAnalytics?.mockbot_total || 0);
    const usedSessions = (userAnalytics?.prepbot_used || 0) + (userAnalytics?.mockbot_used || 0);

    // Determine if the user has available sessions
    const hasSessions = totalSessions > usedSessions;

    return (
        <>
            <div className="resume-guru-page" style={{position:"relative"}}>
                <div className="hero-section">
                    <div className="hero-content">
                        <div className="hero-left">
                            <h1 className="hero-title">
                                Your Personalized Resume Bot
                            </h1>
                            <div className="hero-description">
                                <span>Create professional resumes in minutes! Use our AI-powered platform to customize templates and highlight your skills. Generate standout resumes with perfect formatting and ensure success using ResumeBot!</span>
                            </div>
                        </div>
                    </div>
                </div>
                {showTourBtn && <div className="show-resume-tour-btn" onClick={()=>setIsTourOpen(true)}>
                            <Info />
                            <span>View Tour</span>
                    </div>}

                <div className="resume-cards-container">

                    {/* Upload Resume Card */}
                    {hasSessions ?(
                    <div className="resume-card upload-card">
                        <div className="resume-user-info">
                            <i className="fas fa-cloud-upload-alt user-icon"></i>
                            <span className="name">Upload Resume</span>
                        </div>
                        <div className="upload-content">
                            <i className="fas fa-file-upload feature-icon"></i>
                            <div className="job-title">Import existing resume</div>
                            <p className="upload-description">
                                Supported formats: PDF, DOCX (Max 5 pages)
                            </p>
                        </div>
                        <div className="card-footer">
                            <input
                                id="fileInput"
                                type="file"
                                accept=".docx,.pdf"
                                style={{ display: 'none' }}
                                onChange={handleFileChange}
                            />
                            <button 
                                className="action-btn upload-btn upload-resume-button"
                                onClick={handleUploadResumeClick}
                            >
                                <i className="fas fa-cloud-upload-alt"></i>
                                Upload Resume
                            </button>
                        </div>
                    </div>
                    ):(
                        <div className="resume-card resume-invalid-card">
                        <div className="prep-session-card-header">Resume Upload</div>
                        <div className="prep-session-card-logo">
                            <div className="sub-container">
                                <Sparkles />
                                <p>Unlock the resume upload</p>
                            </div>
                        </div>
                        <div className="prep-session-card-features">
                            <p className="prep-session-card-feature">
                                <Zap className="prep-session-card-icon"/>
                               Upload your resume</p>
                            <p className="prep-session-card-feature">
                                <Trophy className="prep-session-card-icon"/>
                                Personalized feedbacks</p>
                        </div>
                        <button 
                        className="create-session-button" 
                        onClick={() => navigate("/pricing", { state: { activeTabName: 'Expert Resume Builder' } })}
                    >
                        Unlock Premium
                        <MoveRight />
                    </button>
                    </div>
                    )}

                    {/* Create New Resume Card */}
                    <div className="resume-card create-card">
                        <div className="resume-user-info">
                            <i className="fas fa-plus-circle user-icon"></i>
                            <span className="name">Create New Resume</span>
                        </div>
                        <div className="create-content">
                            <i className="fas fa-file-alt feature-icon"></i>
                            <div className="job-title">Start from scratch</div>
                            <p className="create-description">
                                Choose from multiple templates and create your perfect resume
                            </p>
                        </div>
                        <div className="card-footer">
                            <button 
                                className="action-btn create-btn create-resume-button" 
                                onClick={handleNewResumeClick}
                            >
                                <i className="fas fa-plus"></i>
                                Create New Resume
                            </button>
                        </div>
                    </div>
                    

                    {/* Static Resume Cards */}
                    {resumes.map((resumeData) => {
                         const resume = resumeData.resume; 
                         const fullName = `${resume.personalDetails.firstName} ${resume.personalDetails.lastName}`.trim();
                         return (
                        <div className="resume-card" key={resumeData._id}>
                            <div className="resume-user-info">
                            {/* <i className="fas fa-barcode resume-icon"></i>                             */}
                            <span className="resume-id-badge">
                            {/* <i className="fas fa-bolt id-icon"></i> */}
                            <i className="fas fa-star id-icon"></i>
                            <span className="id-number">{resumeData.resume_id}</span>
                            </span>    
                            <span className="file-type" onClick={(e) => handleElementClick(e, () => handleEditResume(resumeData._id))}>
                                <i className="fas fa-pen"></i>
                            </span>
                            </div>
                            <div className="job-title">
                                {truncateJobTitle(resume.experiences?.[0]?.jobTitle || 'No Job Title')}
                            </div>
                            <div className="info-row">
                                <div className="info-item">
                                    <span className="info-label">Last Updated</span>
                                    <div className="info-value">
                                        <i className="far fa-clock"></i>
                                        {new Date(resumeData.last_updated).toLocaleDateString('en-US', { day:'numeric',year: 'numeric', month: 'short' })}
                                    </div>
                                </div>
                                <div className="info-item">
                                    <span className="info-label">Experience</span>
                                    <div className="info-value">
                                        <i className="fas fa-briefcase"></i>
                                        {calculateTotalExperience(resume.experiences)}
                                    </div>
                                </div>
                            </div>
                            <div className="skills-section">
                                <span className="skills-label">Key Skills</span>
                                <div className="skills-list">
                                {resume.skills?.slice(0, 4).map((skill, index) => (
                                    <span key={index} className="skill-tag">
                                        {skill}
                                    </span>
                                ))}
                                </div>
                            </div>
                            <div className="card-footer">
                            {/* <div className="resume-id">ID: {resume.personalDetails.resumeId}</div> */}
                            <div className="actions">
                                    <button 
                                        className="action-btn"
                                        onClick={(e) => handleElementClick(e, () => handleViewResume(resumeData))}
                                    >
                                        <i className="fas fa-eye"></i>
                                        View
                                    </button>
                                    <button 
                                        className="action-btn download"
                                        onClick={(e) => handleElementClick(e, () => handleDownloadResume(resumeData))}
                                    >
                                    <i className="fas fa-download"></i>
                                    Download
                                    </button>
                                </div>
                            </div>
                        </div>
                         );
                    })}
                    
                </div>
                {/* {loading && (
                    <div className="loading-overlay">
                        <div className="spinner"></div>
                        <p>Loading, please wait...</p>
                    </div>
                )} */}

                {resumeModal && (
                    <ResumeModal 
                        isOpen={resumeModal} 
                        resumeData={selectedResume} 
                        closeModal={closeresumeModal} 
                        onResumeSubmit={() => {
                            setresumeModal(false);
                            fetchResumes();
                        }}
                        isLoading={loading}
                    />
                )}
                
                {showPreview && (
                    <ResumePreview 
                        resumeData={selectedResume}
                        closeModal={() => setShowPreview(false)}
                    />
                )}
                 {showNotification && (
                <Notification 
                    message={notificationMessage}
                    onClose={() => setShowNotification(false)}
                />
            )}
            </div>
            <Tour
                steps={tourSteps}
                isOpen={isTourOpen}
                onRequestClose={() => setIsTourOpen(false)}
                getCurrentStep={(curr) => setCurrentStep(curr)}
                showNumber={true}
                showButtons={true}
                showNavigation={true}
                disableInteraction={false}
                disableKeyboardNavigation={true}
                showNavigationNumber={true}
                badgeContent={(curr, tot) => `${curr} of ${tot}`}
                accentColor="#BE05FA"
                styles={{
                    options: {
                        backgroundColor: '#0e1428',
                        borderColor: '#a86cda',
                        textColor: '#ffffff',
                        arrowColor: '#a86cda',
                    },
                    badge: (base) => ({
                        ...base,
                        backgroundColor: '#a86cda',
                        padding: '4px 8px',
                        borderRadius: '4px',
                        fontSize: '14px',
                        marginTop: '10px',
                        color: '#ffffff',
                    }),
                    navigation: (base) => ({
                        ...base,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        padding: '10px 20px',
                    }),
                    navigationNumber: (base) => ({
                        ...base,
                        fontSize: '14px',
                        color: '#ffffff',
                        margin: '0 10px',
                    }),
                }}
                nextButton={
                    <button 
                        onClick={handleNext} 
                        style={{ borderRadius: '20px', cursor: currentStep === tourSteps.length - 1 ? 'not-allowed' : 'pointer' }} 
                        disabled={currentStep === tourSteps.length - 1}
                    >
                        Next
                    </button>
                }
                prevButton={
                    <button 
                        onClick={handlePrev} 
                        style={{ borderRadius: '20px', cursor: currentStep === 0 ? 'not-allowed' : 'pointer' }} 
                        disabled={currentStep === 0}
                    >
                        Previous
                    </button>
                }
                lastStepNextButton={
                    <button 
                        onClick={() => setIsTourOpen(false)}
                        style={{ 
                            borderRadius: '20px',
                            cursor: 'pointer'
                        }}
                    >
                        End Tour!
                    </button>
                }
            />
            {/* <span className="upload-resume-button" onClick={(e) => handleElementClick(e, () => {
                // Your normal function here
            })}>
                <i className="fas fa-upload"></i>
            </span> */}
        </>
    );
};

export default ResumeGuruPage;
