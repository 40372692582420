import { Navigate } from 'react-router-dom';
import { useAuth } from '../../useAuth'

export const ProtectedRoute = ({ children }) => {
  const { isAuthenticated, isLoading } = useAuth();
  
  if (isLoading) {
    return <div>Loading...</div>; // Or a proper loading spinner component
  }
  
  if (!isAuthenticated) {
    return <Navigate to="/" replace />;
  }
  else{

  }
  
  return children;
};