import React, { useEffect } from "react";

const LoadTransitionPage = ({ url, setIsLoading }) => {
    useEffect(() => {
        // Listener for messages from the iframe
        const handleMessage = (event) => {
            if (event.data === "operationComplete") {
                console.log("operationComplete received from iframe");
                setIsLoading(false); // Set loading to false when the operation is complete
            }
        };

        window.addEventListener("message", handleMessage);

        // Cleanup the event listener when the component unmounts
        return () => {
            window.removeEventListener("message", handleMessage);
        };
    }, [setIsLoading]);

    return (
        <div style={{ position: "fixed", top: 0, left: 0, width: "100%", height: "100%", zIndex: 9999, backgroundColor: "rgba(0, 0, 0, 0.5)" }}>
            {url ? (
                <iframe
                    src={url}
                    style={{
                        width: "100%",
                        height: "100%",
                        border: "none",
                    }}
                    allow="camera; microphone; fullscreen" // Allow necessary permissions
                    title="Load Transition"
                />
            ) : (
                <p style={{ color: "#fff", textAlign: "center", marginTop: "20px" }}>Loading...</p>
            )}
            {/*<button*/}
            {/*    style={{*/}
            {/*        position: "absolute",*/}
            {/*        bottom: "10px",*/}
            {/*        left: "10px",*/}
            {/*        padding: "10px 20px",*/}
            {/*        backgroundColor: "#f00",*/}
            {/*        color: "#fff",*/}
            {/*        border: "none",*/}
            {/*        borderRadius: "5px",*/}
            {/*        cursor: "pointer",*/}
            {/*        zIndex: 10000,*/}
            {/*    }}*/}
            {/*    onClick={() => setIsLoading(false)} // Manually close the iframe if needed*/}
            {/*>*/}
            {/*    Close*/}
            {/*</button>*/}
        </div>
    );
};

export default LoadTransitionPage;



// import React from "react";
// import "./LoadTransitionPage.css";
//
// const LoadTransitionPage = () => {
//     return (
//         <div className="iframe-overlay">
//             <iframe
//                 src="http://localhost:5173" // Replace with your desired URL
//                 style={{
//                     width: "100%",
//                     height: "100%",
//                     border: "none",
//                 }}
//                 allow="camera; microphone; fullscreen" // Allow necessary permissions
//                 title="Fullscreen Iframe"
//             />
//             <button
//                 className="close-button"
//                 onClick={() => window.location.href = "https://flower.botguru.ai/landingPage"} // Replace with your redirect URL
//             >
//                 Close
//             </button>
//         </div>
//     );
// };
//
// export default LoadTransitionPage;




// import React, { useState } from "react";
// import Loader from "./Loader";
// import "./LoadTransitionPage.css";
//
// const LoadTransitionPage = () => {
//     const [showLoader, setShowLoader] = useState(false);
//
//     const handleClick = () => {
//         setShowLoader(true);
//     };
//
//     return (
//         <div className="load-transition-page">
//             {showLoader && (
//                 <div className="loader-overlay">
//                     <Loader />
//                 </div>
//             )}
//             {!showLoader && (
//                 <div className="bookshelf-container">
//                     <div className="bookshelf">
//                         <div className="click-me-message" onClick={handleClick}>
//                             Click me !!!
//                         </div>
//                     </div>
//                 </div>
//             )}
//         </div>
//     );
// };
//
// export default LoadTransitionPage;
