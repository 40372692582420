import React,{useState} from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const ResumeEducation = ({ formEducationData, onFormEducationChange,index,fieldErrors ,updateFieldErrors }) => {

  const handleChange = (e) => {
    const { name, value } = e.target;
    onFormEducationChange({
        ...formEducationData,
        [name]: value,
      }); 
    if (fieldErrors[`education${index}_${name}`]) {
      updateFieldErrors(prevErrors => ({
        ...prevErrors,
        [`education${index}_${name}`]: undefined
      }));
    }
  };


  return (
    <div>
      <div className="row">
        <div className="col">
          <div className="input-div">
            <div className="label">
              <span>School/University</span>
              <span className={`required-asterisk ${fieldErrors[`education${index}_school`]? 'error-text' : ''}`}>*</span>
            </div>
            <input
              className={`resume-input ${fieldErrors[`education${index}_school`] ? 'error-border' : ''}`}
              name="school"
              placeholder="California State University"
              type="text"
              value={formEducationData.school || ''}
              onChange={handleChange}
            />
              {fieldErrors?.[`education${index}_school`] && (
              <span className="resume-error-message">{fieldErrors[`education${index}_school`]}</span>
            )}
          </div>
        </div>
        <div className="col">
          <div className="input-div">
            <div className="label">
              <span>Degree</span>
              <span className={`required-asterisk ${fieldErrors[`education${index}_degree`]? 'error-text' : ''}`}>*</span>
            </div>
            <input
              className={`resume-input ${fieldErrors[`education${index}_degree`] ? 'error-border' : ''}`}
              name="degree"
              placeholder="Masters in Computer Science"
              type="text"
              value={formEducationData.degree}
              onChange={handleChange}
            />
            {fieldErrors?.[`education${index}_degree`] && (
              <span className="resume-error-message">{fieldErrors[`education${index}_degree`]}</span>
            )}
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <div className="input-div">
            <div className='start-end-date'>
            <div className='exp-date'>
                <div className="label">
                  <span>Start Date</span>
                  <span className={`required-asterisk ${fieldErrors[`education${index}_startDate`]? 'error-text' : ''}`}>*</span>
                </div>
                <DatePicker
                  className={`datepicker ss ${fieldErrors[`education${index}_startDate`] ? 'error-border' : ''}`}
                  selected={formEducationData.startDate}
                  placeholderText="MM/YYYY"
                                  dateFormat="MMM yyyy"
                                  showMonthYearPicker
                  onChange={(date) =>{
                    const updatedFormData = { ...formEducationData, startDate: date };
                    onFormEducationChange(updatedFormData);
                
                    updateFieldErrors(prevErrors => {
                      const newErrors = { ...prevErrors };
                      
                      // Clear start date error when a valid date is selected
                      delete newErrors[`education${index}_startDate`];
                
                      // Check if start date is after end date
                      if (updatedFormData.endDate && date > updatedFormData.endDate) {
                        newErrors[`education${index}_date`] = "Start Date cannot be after End Date";
                      } else {
                        delete newErrors[`education${index}_date`]; // Remove the error if the dates are valid
                      }
                
                      return newErrors;
                    });
                  }}
                />
                 {fieldErrors?.[`education${index}_startDate`] && (
              <span className="resume-error-message">{fieldErrors[`education${index}_startDate`]}</span>
            )}
              {fieldErrors[`education${index}_date`] && (
                <span className="resume-error-message">{fieldErrors[`education${index}_date`]}</span>
              )}
             </div>
             <div className='exp-date'>
                <div className="label">
                  <span>End Date</span>
                  <span className={`required-asterisk ${fieldErrors[`education${index}_endDate`]? 'error-text' : ''}`}>*</span>
                </div>
              <DatePicker
                className={`datepicker ss ${fieldErrors[`education${index}_endDate`] ? 'error-border' : ''}`}
                selected={formEducationData.endDate}
                placeholderText="MM/YYYY"
                                dateFormat="MMM yyyy"
                                showMonthYearPicker
                onChange={(date) =>{
                  const updatedFormData = { ...formEducationData, endDate: date };
                  onFormEducationChange(updatedFormData);
              
                  updateFieldErrors(prevErrors => {
                    const newErrors = { ...prevErrors };
                    
                    // Clear end date error when a valid date is selected
                    delete newErrors[`education${index}_endDate`];
              
                    // Check if start date is after end date
                    if (updatedFormData.startDate && updatedFormData.startDate > date) {
                      newErrors[`education${index}_date`] = "Start Date cannot be after End Date";
                    } else {
                      delete newErrors[`education${index}_date`]; // Remove the error if the dates are valid
                    }
              
                    return newErrors;
                  });
                }}
              /> 
              {fieldErrors?.[`education${index}_endDate`] && (
              <span className="resume-error-message">{fieldErrors[`education${index}_endDate`]}</span>
              )}
              </div>
            </div>
           

          </div>
        </div>
        <div className="col">
          <div className="input-div">
            <div className="label">
              <span>GPA</span>
            </div>
            <input
              className="resume-input"
              name="gpa"
              placeholder="3.5 / 4"
              type="text"
              value={formEducationData.gpa}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="input-div">
          <div className="label">
            <span>Additional Details</span>
          </div>
          <textarea
          className={`text-area ${fieldErrors[`education${index}_additionalDetails`] ? 'error-border' : ''}`}

            name="additionalDetails"
            value={formEducationData.additionalDetails}
            onChange={handleChange}
          />
          {fieldErrors?.[`education${index}_additionalDetails`] && (
              <span className="resume-error-message">{fieldErrors[`education${index}_additionalDetails`]}</span>
              )}
        </div>
      </div>
    </div>
  );
};

export default ResumeEducation;
