import React, { useEffect, useRef, useState } from 'react';
// import { Link } from 'react-router-dom';
import { Canvas } from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';
import { Loader } from 'lucide-react';
import ThreeDModelViewer from './Components/ThreeDModelViewer';
import ThreeDScreen2 from './Components/ThreeDScreen2';
import './App.css';
import logo from './botgurulogo.png';
import RingLoader from "react-spinners/RingLoader";
// import CourseCard from './Components/CourseCard';
import config from './config.json';
import PrepGuru from './Components/PrepGuru';
import MockGuru from './Components/MockGuru';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import ResumeGuru from './Components/ResumeGuru';
import PricingPlan from './Components/Pricing';
import Testimonials from './Components/Testimonials';
import LoginModal from './Components/LoginModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComments } from '@fortawesome/free-solid-svg-icons';
// import { contain, cover } from 'three/src/extras/TextureUtils.js';
import { CircleX, Rocket } from 'lucide-react';
// import { faPlay } from '@fortawesome/free-regular-svg-icons';
 // Import partner logos
import ScrollingBanner from './Components/Utils/ScrollingBanner';
import { Objectives } from './Components/Utils/Objectives';
import { FAQAccordian } from './Components/Utils/FAQAccordian';
import { useAuthContext } from './Components/Auth/AuthContext';
import { useAuth } from './useAuth';
import { useNavigate } from 'react-router-dom';
// import CookiePolicyModal from './CookiePolicyModal';
import TermsAndConditionsModal from './TermsAndConditions';
import PrivacyPolicy from './PrivacyPolicy';
 
const Main = () => {
  const [isLogin,setIsLogin] = useState(false);
  const screenRefs = useRef([
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ]);
  const [isLoading, setIsLoading] = useState(true);
  const[startScreenTwoAnime, setStartScreenTwoAnime]=useState(false);
  const containerRef = useRef(null);

  const currentScreenRef = useRef(0);
  const [touchStartY, setTouchStartY] = useState(null);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [formData, setFormData] = useState({ name: '', email: '', phone: '', message: '' });
  const [emailError, setEmailError] = useState('');
  const [messageError, setMessageError] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const chatWidgetRef = useRef(null);

  const [isPrepGuruVisible, setIsPrepGuruVisible] = useState(false);
  const [isMockGuruVisible, setIsMockGuruVisible] = useState(false);
  const [navigateToHolder, setNavigateToHolder]=useState({path:"/landing", props:{}});

  const [lastScrollY, setLastScrollY] = useState(window.scrollY);
  const navigate=useNavigate();
  
  const {fetchPricingData}=useAuthContext();

  useEffect(()=>{
    fetchPricingData();
  },[]);

  const[isTAModalOpen,setIsTAModalOpen]=useState(false);
  const[isPrivacyModalOpen,setIsPrivacyModalOpen]=useState(false);


  const openTAMModal=()=>setIsTAModalOpen(true);
  const closeTAMeModal = () => setIsTAModalOpen(false);

  const openPrivacyModal=()=>setIsPrivacyModalOpen(true);
  const closePrivacyMeModal=()=>setIsPrivacyModalOpen(false);


  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const elementId = entry.target.id;
          const visiblePercentage = entry.intersectionRatio * 100;
  
          if (elementId === "prepGuru" && visiblePercentage >= 15) {
            setIsPrepGuruVisible((prev) => prev || true); // Ensure it runs only once
          } else if (elementId === "mockGuru" && visiblePercentage >= 15) {
            setIsMockGuruVisible((prev) => prev || true); // Ensure it runs only once
          }
        });
      },
      {
        threshold: [0, 0.15, 0.3, 0.5, 0.7, 0.85, 1],
      }
    );
    if (screenRefs.current && screenRefs.current[2]?.current) {
      observer.observe(screenRefs.current[2]?.current);
    }
    if (screenRefs.current && screenRefs.current[3]?.current) {
      observer.observe(screenRefs.current[3]?.current);
    }
    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0); // Initialize scroll position
  }, []);

  // to handle smooth scrolling
  const handleScrollToScreen = (index) => {
    const targetScreen = screenRefs.current[index]?.current;
    if (targetScreen) {
      currentScreenRef.current = index;
      targetScreen.scrollIntoView({ behavior: 'smooth' });
    }
    if (index === 1) {
      setStartScreenTwoAnime(true);
    }
    else{
      setStartScreenTwoAnime(false);
    }
  };

  let lastScrollTime = 0;
  const throttleScroll = 800; // Throttle duration in ms

  // Unified scroll handler for wheel, touch, and key events
  const handleScroll = (direction) => {
    if ( isTAModalOpen || isPrivacyModalOpen||isLogin) return; // Prevent scrolling when modal is open
    const now = Date.now();
    if (now - lastScrollTime < throttleScroll) return;

    const newIndex = currentScreenRef.current + direction;
    if (newIndex >= 0 && newIndex < screenRefs.current.length) {
      handleScrollToScreen(newIndex);
    }
    lastScrollTime = now;
  };

  const SCROLL_THRESHOLD = 50; // Set to a value that filters out minor scrolls

  const handleWheel = (event) => {
    if(event.target.classList.contains("objective") || event.target.parentNode.classList.contains("objectives")){
      return;
    }
    if ( isTAModalOpen || isPrivacyModalOpen||isLogin) return; // Prevent scrolling

    event.preventDefault();
    // Only trigger scrolling if the absolute value of deltaY exceeds the threshold
    if (Math.abs(event.deltaY) > SCROLL_THRESHOLD) {
      handleScroll(event.deltaY > 0 ? 1 : -1);
    }
  };
  const handleKeyDown = (event) => {
    if ( isTAModalOpen || isPrivacyModalOpen||isLogin) return; // Prevent scrolling

    if (event.key === 'ArrowDown') handleScroll(1);
    else if (event.key === 'ArrowUp') handleScroll(-1);
  };
  const { isAuthenticated } = useAuth();
    // Function to open the modal
    const openModal = (path,props={}) => {
      console.log("authenticated", isAuthenticated)
      if(isAuthenticated){
        navigate(path ?? '/landingPage',props);
      }else{
        setNavigateToHolder({path:path, props:props ?? {}}); //saving path to reroute after login
        setIsLogin(true);
      }
    };

    // Function to close the modal
    const closeModal = () => {
      setIsLogin(false);
    };

  const handleTouchStart = (event) => {
    if(event.target.classList.contains("objective") || event.target.parentNode.classList.contains("objectives")){
      return;
    }
    if ( isTAModalOpen || isPrivacyModalOpen||isLogin) return; // Prevent scrolling

    setTouchStartY(event.touches[0].clientY);
  }
  const handleTouchEnd = (event) => {
    if(event.target.classList.contains("objective") || event.target.parentNode.classList.contains("objectives")){
      return;
    }
    if ( isTAModalOpen || isPrivacyModalOpen||isLogin) return; // Prevent scrolling

    if (touchStartY === null) return;
    const swipeDistance = touchStartY - event.changedTouches[0].clientY;
    handleScroll(swipeDistance > 50 ? 1 : swipeDistance < -50 ? -1 : 0);
    setTouchStartY(null);
  };

  const handleTouchMove = (event) => {
    if(event.target.classList.contains("objective") || event.target.parentNode.classList.contains("objectives")){
      return;
    }
    if ( isTAModalOpen || isPrivacyModalOpen||isLogin) return; // Prevent scrolling

    if (!touchStartY) return;
    
    const touchY = event.touches[0].clientY;
    const diff = touchStartY - touchY;
    
    if (Math.abs(diff) > 50) { // Threshold for swipe
      handleScroll(diff > 0 ? 1 : -1);
      setTouchStartY(null);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'message') {
      if (value.length > 1000) {
        setMessageError('Message cannot exceed 1000 characters');
        return;
      } else {
        setMessageError('');
      }
    }
    if ((name === 'name' || name === 'email') && value.length > 100) {
      return; // Prevent input if character limit is exceeded
    }
    setFormData({ ...formData, [name]: value });
  };

  const [supportTicketStatus, setSupportTicketStatus] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitted(true);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)) {
      console.log("formData", formData);
      setEmailError('Invalid email format');
      return;
    } else {
      setEmailError('');
    }
    if(formData.message.length>1000){
      setMessageError("Message should be less than 1000 characters");
      return;
    }else{
      setMessageError("");
    }
    // Handle form submission logic here
    let timer;
    fetch(`${config.restAPI}/logSupportTicket/`, {
      method: 'POST',
      body:JSON.stringify(formData),
    })
    .then((response)=>{
      if(response.ok){
        console.log(response)
        setSupportTicketStatus("Ticket submitted successfully");
      }
    })
    .catch((error)=>{
      console.error(error)
      setSupportTicketStatus("Ticket submission failed");
      
    })
    .finally(()=>{
      setFormData({ name: '', email: '', phone: '', message: '' });
      setIsSubmitted(false);
      timer=setTimeout(() => {
        setSupportTicketStatus("")
        setIsChatOpen(!isChatOpen)
      }, 2000);
    })
    return ()=>{
      clearTimeout(timer);
    }
  };

  const handleClickOutside = (event) => {
    if (chatWidgetRef.current && !chatWidgetRef.current.contains(event.target)) {
      setIsChatOpen(false);
    }
  };

  useEffect(() => {
    if (isChatOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isChatOpen]);

  useEffect(() => {
    const addEventListeners = () => {
      window.addEventListener('wheel', handleWheel);
      window.addEventListener('keydown', handleKeyDown);
      window.addEventListener('touchstart', handleTouchStart);
      window.addEventListener('touchend', handleTouchEnd);
      window.addEventListener('touchmove', handleTouchMove);
    };

    const removeEventListeners = () => {
      window.removeEventListener('wheel', handleWheel);
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('touchstart', handleTouchStart);
      window.removeEventListener('touchend', handleTouchEnd);
      window.removeEventListener('touchmove', handleTouchMove);
    };

    if (!isTAModalOpen && !isPrivacyModalOpen && !isLogin && !isChatOpen) {
      addEventListeners();
    } else {
      removeEventListeners();
    }

    return () => {
      removeEventListeners();
    };
  }, [isTAModalOpen, isPrivacyModalOpen, isLogin, touchStartY, isChatOpen]);

  const textData = [
    { label: 'Name:', value: 'Botguru' },
    { label: 'Trained', value: '10k+ people' },
    { label: 'Sucess Ratio', value: '92%' }
  ];
  const videoRef = useRef(null);

  useEffect(() => {
    // Try to play the video when the component mounts
    if (videoRef.current) {
      videoRef.current.play().catch((error) => {
        console.error('Autoplay failed:', error);
      });
    }
  }, []);
  
  const [ThreeDModelLoaded, setThreeDModelLoaded] = useState(false);

  const handleModelLoad = (loaded) => {
    if (loaded) {
      setIsLoading(false);
    }
  };



  useEffect(() => {
      handleModelLoad(true);
  },[]);


  return (
    <div className="app-wrapper" ref={containerRef}>
      {/* Login Modal */}
      <LoginModal isLogin={isLogin} closeModal={closeModal} nextPath={navigateToHolder}/>
      
      {isLoading && (
        <div className="loading-screen">
          <RingLoader color="#BE05FA" size={100} />
        </div>
      )}


      {/* Header */}
      <div className='home-header-container'>
      <div className="header">
        <img className="homepage-logo" src={logo} alt="Logo" onClick={() => handleScrollToScreen(0)}/>
        <nav className="nav-menu">
          {/* <button className = "main-menu" href="/">Home</button> */}
          <div className="dropdown">
            <button  className="main-menu dropdown-toggle">
              Services
              <span className="dropdown-icon">►</span>
            </button>

            <div className="dropdown-menu">
            <button onClick={() => handleScrollToScreen(2)}>
              <FontAwesomeIcon className ="dropdown-icons" icon={faEnvelope}></FontAwesomeIcon>
              PrepBot</button>
            <hr className="custom-line" />
            <button onClick={() => handleScrollToScreen(3)}>
            <FontAwesomeIcon className ="dropdown-icons" icon={faEnvelope}></FontAwesomeIcon>MockBot</button>
            <hr className="custom-line" />
            <button onClick={() => handleScrollToScreen(4)}> <FontAwesomeIcon className ="dropdown-icons" icon={faEnvelope}></FontAwesomeIcon>ResumeExpert</button>
            </div>
          </div>
          <button className={"main-menu"}  onClick={() => handleScrollToScreen(2)}>PrepBot</button>
          <button className={"main-menu"}   onClick={() => handleScrollToScreen(3)}>MockBot</button>
          <button className={"main-menu"}  onClick={() => handleScrollToScreen(4)}>ResumeExpert</button>
          <button className={"main-menu"}  onClick={() => handleScrollToScreen(4)}>JDExpert</button>
          <button className={"main-menu"}  onClick={() => handleScrollToScreen(4)}>CareerExpert</button>
          {/* <button className="main-menu">FAANG-Guru</button> */}
          <button className="main-menu" onClick={() => handleScrollToScreen(5)}>Pricing</button>
        </nav>
        <nav className='loginbutton'>
          <button className="btn btn-primary" onClick={() => openModal('/landingPage')}>  
            SIGN IN<img className = "play" src={'play.svg'} alt="play"></img></button>
        </nav>
      </div>
      </div>

      {/* All Screen Sections */}
      <div className="screen screen1" ref={screenRefs.current[0]}>
        <div className='content'>
          <div className="content-2d">
          <div className="container">
              <div className="text-wrapper">
                <h1 className="three-d-text1">
      
                  <b className="three-d-text">
                    {" Conquer Interview Anxiety with "}
                    <span>
                      BotGuru!
                    </span>
                    <br></br>
                  </b>
                  <b className='success-text'>
                    { " Success Starts Here... "}
                  </b>
                      <Rocket className='rocket-icon'/>
                </h1>
                <p className="p-three-d-text">
                Ace your next interview with BotGuru! Our AI tools, PrepBot and MockBot, offer personalized coaching to boost your confidence. 
                Whether chasing your dream job or switching careers, we've got you covered for any Job role. 
                Unlock your potential and start your success journey today!</p>
                <div className="video-card">
                  <div className="video-wrapper">
                      <video
                        ref={videoRef}
                        src={`${config.backendAPI}BotGuru.mp4`}
                        autoPlay
                        loop
                        muted
                        playsInline
                        preload="auto"
                        controls
                      ></video>
                  </div>
                </div>
                <button className="btn btn-primary" onClick={() => openModal('/landingPage')}>Get Started</button>
                <ScrollingBanner />
              </div>
            </div>
          </div>

          {/* To render 3d content of screen1 */}
          <div className="content-3d">
                  <Canvas
                    style={{ pointerEvents: "auto", objectFit: "contain" }}
                    onCreated={({ gl }) => {
                      gl.domElement.addEventListener("webglcontextlost", (event) => {
                        event.preventDefault();
                        console.warn("WebGL context lost in screen1! Attempting recovery...");
                      });
                    }}
                    gl={{ powerPreference: "high-performance", antialias: true, preserveDrawingBuffer: true }}
                  >
                    <ambientLight intensity={0.5} />
                    <pointLight position={[10, 10, 10]} />
                    <ThreeDModelViewer onModelLoad={setThreeDModelLoaded} />
                    <OrbitControls enableZoom={false} minZoom={1} maxZoom={1} />
                  </Canvas>
                  {
                    (ThreeDModelLoaded===false) && <div className="ThreedModel-shimmer" >
                      <Loader className="loader-icon"/>
                    </div>
                  }
          </div>  

        </div>
      </div> 

      {/* To render 2d content of screen2 */}
      <div className="screen screen2" ref={screenRefs.current[1]}>
        <div className="screen2-content-2d">
        {/* To render profile and svg of details of botguru. einstein image and details */}
          <div className="profile">
            <svg viewBox="0 0 150 175" width="30%" height="100%" preserveAspectRatio="xMaxYMax meet"> 
              <defs>
                <clipPath id="polygon-clip">
                  <polygon points="85,170 5,170 5,50 15,40 15,10 25,0 80,0 140,0 150,10 150,160 140,170"/>
                </clipPath>
              </defs>
              <image
                href="botguruBG.png"
                width="100%"
                height="100%"
                clip-path="url(#polygon-clip)"
                preserveAspectRatio="xMaxYMax slice"
              />
              <polygon
                points="85,170 5,170 5,50 15,40 15,10 25,0 80,0 140,0 150,10 150,160 140,170"
                fill="none"
                stroke="black"
                stroke-width="2"
              />
            </svg>

            <svg viewBox="0 12 450 100" width="80%" height="100%" preserveAspectRatio="xMinYMax meet" className='profile-details'> 
              <polygon points="0,95 105,95 115,85 415,85 425,65 425,35 425,20 415,10 55,10 45,0 0,0" fill="none" stroke="white"/>
              {textData.map((item, index) => (
                <g key={index} transform={`translate(${10 + index * 140},50)`}>
                  <text className="text">{item.label}</text>
                  <text className="subtext" y="20">{item.value}</text>
                </g>
              ))}
            </svg>
          </div>

          {/* <div>        
              <h2>Services</h2>
          </div> */}
         
          {/* calling the cards display of all the services */}
          {/* <div className="course-card-container">
            <CourseCard animationControl={startScreenTwoAnime} />  
          </div> */}
          <div className='objectives-container'>
            <Objectives />
          </div>
        </div>

        {/* To render 3d content of screen2 */}
        {/* <div className="screen2-content-3d"> */}
        <div className="content-3d">
        <Canvas
            style={{ pointerEvents: "auto", objectFit: "contain" }}
            onCreated={({ gl }) => {
              gl.domElement.addEventListener("webglcontextlost", (event) => {
                event.preventDefault();
                console.warn("WebGL context lost in screen2! Attempting recovery...");
              });
            }}
            gl={{ powerPreference: "high-performance", antialias: true, preserveDrawingBuffer: true }}
          >
            <ambientLight intensity={0.5} />
            <pointLight position={[10, 10, 10]} />
            <ThreeDScreen2  animationControl={startScreenTwoAnime} />
            <OrbitControls enableZoom={false} minZoom={1} maxZoom={1}/>
          </Canvas>
        </div>
      </div>
      <div className="screen screen3" ref={screenRefs.current[2]} id="prepGuru">
        <PrepGuru isPrepGuruVisible={isPrepGuruVisible} getStarted={openModal}></PrepGuru>
      </div>
      <div className="screen screen4" ref={screenRefs.current[3]} id = "mockGuru">
        <MockGuru isMockGuruVisible={isMockGuruVisible} getStarted={openModal}></MockGuru>
      </div>
      <div className="screen screen5" ref={screenRefs.current[4]} id='resumeGuru'>
        <ResumeGuru></ResumeGuru>
      </div>
      <div className="screen screen8" ref={screenRefs.current[5]} id='pricing'>
        <PricingPlan showLogin={openModal}></PricingPlan>
      </div>
      <div className="screen screen9" ref={screenRefs.current[6]} id='testimonials'>
        <Testimonials></Testimonials>
      </div>
      <div className="screen screen10" ref={screenRefs.current[7]} id='footer'>
        <footer className="footer">
          <div className='FAQ-container'>
            <h2>Frequently Asked Questions</h2>
            <FAQAccordian />
          </div>
          <div className="footer-content">
            <div className="footer-section">
              <div className="footer-logo">
                <img src={logo} alt="Guru Careers" />
                <p>Success Starts Here...
                  <Rocket className='rocket-icon'/>
                </p>
                <p>
                  Unlocking potential, one career at a time. Join thousands of
                  professionals who've elevated their careers with our expert
                  guidance.
                </p>
                <div className="social-icons">
                  <a href="#"><i className="fab fa-facebook"></i></a>
                  <a href="#"><i className="fab fa-twitter"></i></a>
                  <a href="#"><i className="fab fa-linkedin"></i></a>
                  <a href="#"><i className="fab fa-instagram"></i></a>
                </div>
              </div>
            </div>
            <div className="footer-section">
              <h3>Our Services</h3>
              <ul>
                <li><a href="#prepGuru" onClick={(e) => { e.preventDefault(); handleScrollToScreen(2); }}>PrepBot</a></li>
                <li><a href="#mockGuru" onClick={(e) => { e.preventDefault(); handleScrollToScreen(3); }}>MockBot</a></li>
                <li><a href="#resumeGuru" onClick={(e) => { e.preventDefault(); handleScrollToScreen(4); }}>ResumeExpert</a></li>
                <li><a href="#jdGuru" onClick={(e) => { e.preventDefault(); handleScrollToScreen(4); }}>JDExpert</a></li>
                <li><a href="#careerGuru" onClick={(e) => { e.preventDefault(); handleScrollToScreen(4); }}>CareerExpert</a></li>
              </ul>
            </div>
            <div className="footer-section">
              <h3>Quick Links</h3>
              <ul>
                <li><a href="#" onClick={(e) => { e.preventDefault(); handleScrollToScreen(0); }}>Home</a></li>
                <li><a href='#' onClick={(e) => { e.preventDefault(); handleScrollToScreen(5); }}>Pricing Plans</a></li>
                <li><a href="#" onClick={(e) => { e.preventDefault(); handleScrollToScreen(6); }}>Success Stories</a></li>
              </ul>
            </div>
            <div className="footer-section">
              <h3>Get in Touch</h3>
              <ul className="contact-info">
                <li>
                  <i className="fas fa-map-marker-alt"></i>
                  6210 Stoneridge Mall Rd, Pleasanton, CA 94588
                </li>
                <li>
                  <i className="fas fa-phone"></i>
                  +1 (925) 398 8280
                </li>
                <li>
                  <i className="fas fa-envelope"></i>
                  info@botguru.ai
                </li>
              </ul>
            </div>
          </div>
          <div className="footer-bottom">
            <div className="copyright">
              © 2025 BotGuru. All rights reserved.
            </div>
            <div className="footer-links">
              <a href="#" onClick={(e) => { e.preventDefault(); openPrivacyModal(); }} >Privacy Policy</a>
              <a href="#" onClick={(e) => { e.preventDefault(); openTAMModal(); }} >Terms of Service</a>
              {/* <a href="#" onClick={(e) => { e.preventDefault(); openCookieModal(); }} >Cookie Policy</a> */}
            </div>
          </div>
        </footer>
        {/* <CookiePolicyModal isOpen={isCookieModalOpen} onClose={closeCookieModal} /> */}
        <TermsAndConditionsModal  isOpen={isTAModalOpen} onClose={closeTAMeModal}/>
        <PrivacyPolicy isOpen={isPrivacyModalOpen}onClose={closePrivacyMeModal}/>
      </div>

      {!isChatOpen &&
      <button className='chat-widget'
        aria-label="Open chat"
        onClick={()=>setIsChatOpen(!isChatOpen)}>
        <FontAwesomeIcon icon={faComments} />
      </button>}

      {isChatOpen && 
      (
        <div className="chat-widget-container" ref={chatWidgetRef}>
          <div className="chat-widget-header">
            <button onClick={()=>setIsChatOpen(!isChatOpen)} className='close-form-btn'>
              <CircleX />
            </button>
            <h3 className='form-title'>Contact Us</h3>
            <p className="form-subtitle">Get in touch with our team</p>
            {supportTicketStatus.length>0 && <span className={`ticket-status-${supportTicketStatus.includes("success") ? "success" : "rejected"}`}>{supportTicketStatus}</span>}
          </div>
          <div className="chat-widget-body">
            <form className="chat-form" onSubmit={handleSubmit}>
              <div className="chat-form-group">
                <label className="chat-form-label" htmlFor="name">Name *</label>
                <input
                  className="chat-form-input"
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  maxLength="100"
                  required
                />
              </div>
              <div className="chat-form-group">
                <label className="chat-form-label" htmlFor="email">Email *</label>
                <input
                  className="chat-form-input"
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  maxLength="100"
                  required
                />
                {isSubmitted && emailError && <span style={{ color: 'red' }}>{emailError}</span>}
              </div>
              <div className="chat-form-group">
                <label className="chat-form-label" htmlFor="message">Message *</label>
                <textarea
                  className="chat-form-textarea"
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleInputChange}
                  maxLength="1000"
                  required
                ></textarea>
                {messageError && <span style={{ color: 'red' }}>{messageError}</span>}
              </div>
              <button type="submit" className="chat-form-button btn btn-primary" >Submit</button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Main;