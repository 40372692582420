import React, { useState,useEffect } from "react";
// import './ResumeGuruPage.css';
import './ResumeModal.css';
import ResumeExperiences from "./ResumeExperiences";
import ResumeEducation from "./ResumeEducation";
import useAuthService from '../../services/AuthService';
import { jsPDF } from "jspdf";
import ResumeLoadingScreen from "../LandingPages/ResumeLoadingScreen";
import { useAuthContext } from "../Auth/AuthContext";
import config from "../../config.json";

const ResumeModal = ({ isOpen, closeModal,resumeData, onResumeSubmit,isLoading}) => {
    const [skills, setSkills] = useState([]);
    const [skillInput, setSkillInput] = useState('');
    const [errorMessage, setErrorMessage] = useState(""); // State for managing error message
    const [experiences, setExperiences] =  useState([]);
    const [fieldErrors, setFieldErrors] = useState({});
    const updateFieldErrors = (newErrors) => {
        setFieldErrors(newErrors);
    };
    const { userObjId } = useAuthContext(); // Get userObjId from context
    

    const validateStep = () => {
        let errors = {};
    
        if (currentStep === 1) {
            if (!personalDetails.firstName.trim()) errors.firstName = "Enter First Name";
            else if (personalDetails.firstName.length > 50) {
                errors.firstName = "First Name cannot exceed 50 characters";
            }
            if (!personalDetails.lastName.trim()) errors.lastName = "Enter Last Name";
            else if (personalDetails.lastName.length > 50) {
                errors.lastName = "Last Name cannot exceed 50 characters";
            }
            if (!personalDetails.email.includes("@")) errors.email = "Enter a valid Email";
            else if (personalDetails.email.length > 50) {
                errors.email = "Email cannot exceed 50 characters";
            }
            if (!personalDetails.phoneNumber.match(/^\+?\d{7,15}$/))
                 errors.phoneNumber = "Enter a valid Phone Number";

            if (personalDetails.linkedIn.length > 100) {
                errors.linkedIn = "LinkedIn URL cannot exceed 100 characters";
            }
    
            if (personalDetails.website.length > 100) {
                errors.website = "Website URL cannot exceed 100 characters";
            }
        }

        if (currentStep === 2 && experiences.length > 0) {
            
            let totalSkillsCount = 0;
            // Validate only if experience is added
            experiences.forEach((exp, index) => {
                const { jobTitle, companyName, startDate, endDate, description,domain,skills} = exp.formData;
                totalSkillsCount += skills?.length || 0; // Count all skills

                
                if (!jobTitle?.trim()) 
                    errors[`experience${index}_jobTitle`] = "Enter Job Title";
                else if (jobTitle.length > 100) {
                    errors[`experience${index}_jobTitle`] = "Job Title cannot exceed 100 characters";
                }
                if (!companyName?.trim()) errors[`experience${index}_companyName`] = "Enter Company Name";
                else if (companyName.length > 100) {
                    errors[`experience${index}_companyName`] = "Company Name cannot exceed 100 characters";
                }
                if (!domain?.trim()) errors[`experience${index}_domain`] = "Enter Domain Name";
                else if (domain.length > 100) {
                    errors[`experience${index}_domain`] = "Domain Name cannot exceed 100 characters";
                }
                if (!startDate) errors[`experience${index}_startDate`] = "Enter Start Date";
                if (!exp.formData.currentlyWorking && !endDate) {
                    errors[`experience${index}_endDate`] = "Enter End Date";
                }
                if (endDate && startDate && new Date(startDate) > new Date(endDate)) {
                    errors[`experience${index}_date`] = "Start Date cannot be after End Date";
                }
                if(!skills || skills.length === 0)
                    errors[`experience${index}_skills`]  = "At least one skill is required in the Skills tab";
                 else {
                    const longSkills = skills.filter(skill => skill.length > 100);
                    if (longSkills.length > 0) {
                        errors[`experience${index}_skills`] = "Each skill must be 100 characters or less";
                    }
                }
                if (!description?.trim()) errors[`experience${index}_description`] = "Enter Description";
                else if (description.length > 3500) {
                    errors[`experience${index}_description`] = "Description cannot exceed 3500 characters";
                }
            });
            if (totalSkillsCount > 60) {
                errors.experienceSkillsLimit = "You can add up to 60 skills only across all experiences."; // Set error message
            }
        }

        if (currentStep === 3) {
            if (skills.length === 0) {
                errors.skills="At least one skill is required in the Skills tab";
            }
            if(skills.length>60)
            {
                errors.skills="You can add up to 60 skills only";
            }
            else {
                // Check if any skill is more than 15 characters
                const longSkills = skills.filter(skill => skill.length > 100);
                if (longSkills.length > 0) {
                    errors.skills = "Skill cannot exceed 100 characters ";
                }
            }
        }

        if (currentStep === 4 && education.length > 0) { // Validate only if education is added
            education.forEach((edu, index) => {
                const { school, degree, startDate, endDate,additionalDetails } = edu.formEducationData;
        
                if (!school?.trim()) errors[`education${index}_school`] = "Enter School/University";
                else if (school.length > 100) {
                    errors[`education${index}_school`] = "School Name cannot exceed 100 characters";
                }
                if (!degree?.trim()) errors[`education${index}_degree`] = "Enter Degree";
                else if (degree.length > 100) {
                    errors[`education${index}_degree`] = "Degree Name cannot exceed 100 characters";
                }
                if (!startDate) errors[`education${index}_startDate`] = "Enter Start Date";
                if (!endDate) errors[`education${index}_endDate`] = "Enter End Date";
                if (endDate && startDate && new Date(startDate) > new Date(endDate)) {
                    errors[`education${index}_date`] = "Start Date cannot be after End Date";
                }
                if (additionalDetails?.length > 3500) {
                    errors[`education${index}_additionalDetails`] = "Additional Details cannot exceed 100 characters";
                }
            });
        }
        if(currentStep===5){          
            if (customSection.title.length > 100) {
            errors.title = "Custom Title cannot exceed 100 characters";
            }
            if(customSection.content.length>100){
                errors.content = "* Custom Content cannot exceed 100 characters";
            }
        
        }

        if (currentStep === 6) {
            if (!personalDetails.summary.trim()) errors.summary = "Enter Professional Summary";
            else if (personalDetails.summary.length > 3500) {
                errors.summary = "Professional Summary cannot exceed 3500 characters";
            }
        }
    
        if (Object.keys(errors).length > 0) {
            setFieldErrors(errors);
            return false; // Prevent navigation
        }
    
        setFieldErrors({});
        return true; // Validation successful
    };

    const showErrorAlert = (message) => {
        return (
            <div className="error-modal">
                <div className="error-modal-content">
                    <strong>
                        <i className="fa fa-triangle-exclamation" aria-hidden="true"></i> {message}
                    </strong>
                    <button
                        onClick={() => setErrorMessage("")} // Clear the error message when the button is clicked
                        className="error-modal-close-btn"
                    >
                        OK
                    </button>
                </div>
            </div>
        );
    };

    const generateRandom5DigitNumber = () => {
        return Math.floor(10000 + Math.random() * 90000);
    };

    const [personalDetails, setPersonalDetails] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        linkedIn:'',
        website:'',
        summary:'',
        resumeId: ""
    });
    const [currentStep, setCurrentStep] = useState(1);
    const [isEditing, setIsEditing] = useState(false);
    const [resumeName, setResumeName] = useState("Resume Name");
    const authService = useAuthService();

    const formatDateToMonthYear = (date) => {
        if (!date) return '';
        const d = new Date(date);
        const now = new Date();
        
        // Always return the formatted date
        return d.toLocaleDateString('en-US', { month: 'short', year: 'numeric' });
    };

    const getDateRangeText = (startDate, endDate) => {
        const formatDateToMonthYear = (date) => {
            if (!date) return '';
            const d = new Date(date);
            const now = new Date();
            
            // Always return the formatted date
            return d.toLocaleDateString('en-US', { month: 'short', year: 'numeric' });
        };

        const isCurrentMonth = (date) => {
            if (!date) return false;
            const d = new Date(date);
            const now = new Date();
            return d.getMonth() === now.getMonth() && d.getFullYear() === now.getFullYear();
        };

        const start = startDate ? formatDateToMonthYear(startDate) : '';
        const end = endDate ? formatDateToMonthYear(endDate) : '';
        
        // If only start date exists, show just start date
        if (start && !end) {
            return start;
        }
        
        // If both dates exist
        if (start && end) {
            // If both dates are in current month, show the month/year
            if (isCurrentMonth(startDate) && isCurrentMonth(endDate)) {
                return start;
            }
            // If only end date is current month (and different from start), show "Present"
            if (isCurrentMonth(endDate) && !isCurrentMonth(startDate)) {
                return `${start} - Present`;
            }
            // If dates are same (but not current), show just start date
            if (start === end) {
                return start;
            }
            // Otherwise show the full range
            return `${start} - ${end}`;
        }
        
        return '';
    };

    const handleSubmit = async () => {
        if (!validateStep()) return; // Stop submission if validation fails
        const now = new Date();
        const date = now.toISOString().split("T")[0]; // Extract date in YYYY-MM-DD format
        const time = now.toTimeString().split(" ")[0]; // Extract time in HH:MM:SS format
      
        const resumeId = resumeData?.message?.resumeId || generateRandom5DigitNumber();        console.log("Generated resumeId:", resumeId);

        const payload = {
            // resume_id:resumeId,
            user_obj_id:userObjId,
            user_id: "email8@email.com", // Replace with dynamic user email if needed
            resume_name: "test.png",
            data: date,
            time: time,
            resumeId: resumeId,
            resume: {
                personalDetails: {
                    firstName: personalDetails.firstName,
                    lastName: personalDetails.lastName,
                    email: personalDetails.email,
                    phoneNumber: personalDetails.phoneNumber,
                    linkedIn: personalDetails.linkedIn,
                    website: personalDetails.website,
                    summary: personalDetails.summary,
                },
                experiences: experiences.map((experience) => ({
                    jobTitle: experience.formData.jobTitle || "",
                    companyName: experience.formData.companyName || "",
                    startDate: experience.formData.startDate
                    ? formatDateToMonthYear(experience.formData.startDate)
                    : null,
                    endDate: experience.formData.endDate
                    ? formatDateToMonthYear(experience.formData.endDate)
                    : null,
                    description: experience.formData.description || "",
                    skills: experience.formData.skills || [],
                    domain: experience.formData.domain || "",
                    location: experience.formData.location || "",
                    country: experience.formData.country || "",
                })),
                skills: skills || [],
                education: education.map((edu) => ({
                    school: edu.formEducationData.school || "",
                    degree: edu.formEducationData.degree || "",
                    startDate: edu.formEducationData.startDate
                    ? formatDateToMonthYear(edu.formEducationData.startDate)
                    : null,
                    endDate: edu.formEducationData.endDate
                    ? formatDateToMonthYear(edu.formEducationData.endDate)
                    : null,
                    gpa: edu.formEducationData.gpa || "",
                    city_state: edu.formEducationData.city_state || "",
                    country: edu.formEducationData.country || "",
                    additionalDetails: edu.formEducationData.additionalDetails || "",
                })),
            },
        };
        console.log("start one")
        console.log("end one")
        if (resumeData?.message?.res_obj_id){
            console.log("resume_obj_if added")
            payload['res_obj_id'] = resumeData.message.res_obj_id;
        }
        else{
            console.log("new resume")
        }
        try {
            const response = await authService.saveResume(payload); // Call the saveResume method
            console.log("Resume saved successfully:", response);

            // Trigger the callback to return to "Choose your Resume" page
            if (onResumeSubmit) onResumeSubmit();

            // Close the modal
            if (closeModal) closeModal();
        } catch (error) {
            console.error("Error submitting resume:", error);
            setErrorMessage("Failed to save resume. Please try again.");
        }
    };



    const isValidDate = (date) => {
        const parsedDate = new Date(date);
        return !isNaN(parsedDate.getTime());
    };


    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const normalizeAndValidatePhoneNumber = (phoneNumber) => {
        if (!phoneNumber) return ''; // Return empty if no phone number is provided

        // Remove unwanted characters (keep digits and `+` at the start)
        const normalized = phoneNumber.replace(/[^+\d]/g, '');

        // Validate the normalized phone number
        const phoneRegex = /^\+?\d{7,15}$/; // Allows optional `+`, 7-15 digits
        return phoneRegex.test(normalized) ? normalized : ''; // Return normalized if valid, else empty
    };



    useEffect(() => {
        if (resumeData) {
            const message = resumeData.message || {};

            // Populate personal details
            setPersonalDetails({
                firstName: message.first_name || '',
                lastName: message.last_name || '',
                email: isValidEmail(message.email) ? message.email : '',
                phoneNumber: normalizeAndValidatePhoneNumber(message.phone_number),
                linkedIn: message.linkedin_url || '',
                website: message.website_url || '',
                summary: message.professional_summary || '',
            });

            // Populate skills
            const experienceSkills = (message.experience || [])
            .flatMap(exp => exp.skills || []) // Extract skills from each experience
            .filter((skill, index, self) => self.indexOf(skill) === index); // Remove duplicates
        
            setSkills([...new Set([...(message.skills || []), ...experienceSkills])]); // Merge skills from experiences
            
            // Populate experiences
            const formattedExperiences = (message.experience || []).map((exp, index) => ({
                id: index + 1,
                formData: {
                    jobTitle: exp.job_role,
                    companyName: exp.company_name,
                    startDate: isValidDate(exp.start_date) ? new Date(exp.start_date) : null,
                    endDate: isValidDate(exp.end_date) ? new Date(exp.end_date) : null,
                    description: exp.responsibilities.join("\n"),
                    location: exp.city_state,
                    country: exp.country,
                    skills: exp.skills,
                    domain: exp.domain,
                },
            }));
            setExperiences(formattedExperiences);

            // Populate education
            const formattedEducation = (message.education || []).map((edu, index) => ({
                id: index + 1,
                formEducationData: {
                    school: edu.school_university,
                    degree: edu.degree,
                    startDate: isValidDate(edu.start_date) ? new Date(edu.start_date) : null,
                    endDate: isValidDate(edu.end_date) ? new Date(edu.end_date) : null,
                    gpa: edu.gpa,
                    city_state: edu.city_state,
                    country: edu.country,
                    additionalDetails: edu.additionalDetails || '' // Add this line
                },
            }));
            setEducation(formattedEducation);
        }
    }, [resumeData]);


    const handleIconClick = () => {
        setIsEditing(true); // Enable editing on icon click
    };

    const handleChange = (event) => {
        setResumeName(event.target.value); // Update the text while editing
    };

    const handleBlur = () => {
        setIsEditing(false); // Disable editing when input loses focus
    };


    const goToNextStep = () => {
        if (validateStep()) {
            if (currentStep < 6) {
                setCurrentStep(currentStep + 1);
            }}
        console.log(currentStep)
        if (currentStep === 2){
            console.log("valueset")
            const websocketUrlForProjectBuilder = "wss://o834xau6qe.execute-api.us-west-1.amazonaws.com/production/";

            const websocketInstance = new WebSocket(websocketUrlForProjectBuilder);
            websocketInstance.onopen = () => {
                console.log("WebSocket connection established for Project Generation");

                const initialMessage = { action: "botguru_sendmessage", message: "get data" };
                websocketInstance.send(JSON.stringify(initialMessage));
                console.log("Sent initial message for Project Generation:", initialMessage);
            };
            // websocketInstance.onmessage = (event) => {
            //     console.log("WebSocket message received:", event.data);
            // };
            websocketInstance.onmessage = async (event) => {
                try {
                    // Ensure the message is not empty
                    if (!event.data || event.data.trim() === "") {
                        console.warn("Received empty or irrelevant WebSocket message");
                        return;
                    }

                    // Attempt to parse the JSON
                    const data = JSON.parse(event.data);
                    console.log("WebSocket message received:", data);

                    // Handle messages with a connection ID
                    if (data.connectionid) {
                        console.log("Connection ID received:", data.connectionid);

                        // Prepare the payload
                        const payload = {
                            details: experiences.map((experience) => ({
                                jobRole: experience.formData.jobTitle || "N/A",
                                startDate: experience.formData.startDate?.toISOString().split("T")[0] || null,
                                endDate: experience.formData.endDate?.toISOString().split("T")[0] || null,
                                companyName: experience.formData.companyName || "N/A",
                                domain: experience.formData.domain || "N/A",
                                skills: experience.formData.skills?.join(", ") || "",
                            })),
                            connectionId: data.connectionid,
                            user_id: "naveen@email.com", // Replace with the actual user ID
                        };

                        // Send the payload to the API
                        try {
                            const apiUrl = `${config.restAPI}/resumeProfSummary/`;

                            const response = await fetch(apiUrl, {
                                method: "POST",
                                credentials:"include",
                                headers: {
                                    "Content-Type": "application/json",
                                },
                                body: JSON.stringify(payload),
                            });

                            if (!response.ok) {
                                throw new Error(`API error: ${response.status}`);
                            }

                            console.log("Payload sent successfully:", payload);
                        } catch (error) {
                            console.error("Error sending payload to API:", error);
                        }
                    }

                    // Handle messages with professional summary
                    else if (data.message) {
                        try {
                            const parsedMessage = JSON.parse(data.message); // Parse the inner JSON message
                            if (parsedMessage.professional_summary) {
                                console.log("Professional Summary received:", parsedMessage.professional_summary);

                                // Update the state with the professional summary
                                setPersonalDetails((prevDetails) => ({
                                    ...prevDetails,
                                    summary: parsedMessage.professional_summary,
                                }));
                            }
                            websocketInstance.close(1000, "Work completed"); // Close with a reason

                        } catch (innerError) {
                            console.error("Error parsing inner message:", innerError.message, "Raw message:", data.message);
                        }
                    }

                    // Handle unexpected structures
                    else {
                        console.warn("Unexpected WebSocket message structure:", data);
                    }

                } catch (error) {
                    console.error("Error parsing WebSocket message:", error.message, "Raw message:", event.data);
                }
            };

            websocketInstance.onerror = (error) => {
                console.error("WebSocket error:", error);
            };

            websocketInstance.onclose = (event) => {
                console.log(`WebSocket closed: Code = ${event.code}, Reason = ${event.reason}`);
            };

        }
    };

    const goToPreviousStep = () => {
        if (currentStep > 1) {
            setCurrentStep(currentStep - 1);
        }
    };


    // const handleFormChange = (id, updatedFormData) => {
    //     setExperiences((prevExperiences) =>
    //         prevExperiences.map((exp) =>
    //             exp.id === id ? { ...exp, formData: updatedFormData } : exp
    //         )
    //     );
    // };

    const handleFormChange = (id, updatedFormData) => {
        setExperiences((prevExperiences) => {
            const updatedExperiences = prevExperiences.map((exp) =>
                exp.id === id ? { ...exp, formData: updatedFormData } : exp
            );
            // Extract all unique skills from experiences
            const experienceSkills = updatedExperiences
                .flatMap((exp) => exp.formData.skills || [])
                .filter((skill, index, self) => self.indexOf(skill) === index); // Ensure uniqueness
    
              

            // Combine skills from experiences and the main skills array without duplicates
            setSkills((prevSkills) => {
                const combinedSkills = [...experienceSkills, ...prevSkills];
                const uniqueSkills = combinedSkills.filter(
                    (skill, index, self) => self.indexOf(skill) === index // Ensure uniqueness
                );
                return uniqueSkills;
            });
    
            return updatedExperiences;
        });
    };
    
    const removeSkillFromGlobalList = (skillToRemove) => {
        setSkills((prevSkills) => prevSkills.filter(skill => skill !== skillToRemove));
    };    
    
    

    const handleChangePersonalDetails = (event) => {
        const { name, value } = event.target;
        setPersonalDetails((prevDetails) => ({
            ...prevDetails,
            [name]: value,
        }));
        // Clear the error for the specific field when the user starts typing
        setFieldErrors((prevErrors) => ({
            ...prevErrors,
            [name]: '',
        }));
    };


    const handleSkillInputChange = (e) => {
        setSkillInput(e.target.value);
        // Clear the error message when the user types
    setFieldErrors(prevErrors => {
        const newErrors = { ...prevErrors };
        delete newErrors.skills; // Remove skills error
        return newErrors;
    });
    };

    const handleSkillAdd = (e) => {
        if (e.key === 'Enter' && skillInput.trim()) {
            const newSkill = skillInput.trim();

                    // 🔥 Count total skills across all experiences
        const totalSkills = experiences.reduce((count, exp) => count + (exp.formData.skills?.length || 0), 0);

        // 🔥 Prevent adding a skill if the total exceeds 20
        if (totalSkills > 60) {
            updateFieldErrors(prevErrors => ({
                ...prevErrors,
                skills: "You can add up to 60 skills only across all experiences."
            }));
            return; // Stop execution
        }

            // Determine if the skill is in all caps
            const isAllCaps = newSkill === newSkill.toUpperCase();
            // Format the skill only if it's not in all caps
            const formattedSkill = isAllCaps ? newSkill : newSkill.charAt(0).toUpperCase() + newSkill.slice(1).toLowerCase();
            // Check if the skill already exists (case-insensitive)
            const skillExists = skills.some(skill => skill.toLowerCase() === formattedSkill.toLowerCase());
            if (skillExists) {
                updateFieldErrors(prevErrors => ({
                    ...prevErrors,
                    skills: "Skill already added"
                }));
                return; // Stop further execution
            }

            if (formattedSkill.length > 100) {
                setFieldErrors(prevErrors => ({
                    ...prevErrors,
                    skills: "Each skill must be 100 characters or less"
                }));
                return; // Stop further execution
            }

            setSkills([...skills, formattedSkill]);
            setSkillInput(''); // Clear input field
        }
    };
    

    const handleSkillRemove = (skillToRemove) => {
        setSkills(skills.filter((skill) => skill !== skillToRemove));
        setExperiences((prevExperiences) => 
            prevExperiences.map((exp) => ({
                ...exp,
                formData: {
                    ...exp.formData,
                    skills: exp.formData.skills?.filter((skill) => skill !== skillToRemove) || [],
                },
            }))
        );
    };

    useEffect(() => {
        console.log("Updated Experiences:", experiences);
    }, [experiences]);
    const handleAddExperience = () => {
        setExperiences([
            ...experiences,
            { id: experiences.length + 1, formData: {} },
        ]);
    };
    const [education, setEducation] =  useState([]);;
    const handleEducationFormChange = (id, updatedEducationFormData) => {
        // Format bullet points for additionalDetails
        if ('additionalDetails' in updatedEducationFormData) {
            const lines = updatedEducationFormData.additionalDetails.split('\n');
            const formattedLines = lines.map(line => {
                const trimmedLine = line.trim();
                // Only add bullet if line has content and doesn't already start with a bullet
                if (trimmedLine && !trimmedLine.startsWith('•')) {
                    return `• ${trimmedLine}`;
                }
                // Return original line (with or without bullet)
                return line;
            });
            updatedEducationFormData.additionalDetails = formattedLines.join('\n');
        }

        setEducation((prevEducation) =>
            prevEducation.map((edu) =>
                edu.id === id ? { ...edu, formEducationData: updatedEducationFormData } : edu
            )
        );
    };
    useEffect(()=>{
        console.log(education)

    },[education])

    const handleDeleteExperience = (id) => {
        setExperiences(experiences.filter((experience) => experience.id !== id));
            // Clear errors related to experiences
    updateFieldErrors(prevErrors => {
        const newErrors = { ...prevErrors };
        Object.keys(newErrors).forEach(key => {
            if (key.startsWith('experience')) {
                delete newErrors[key];
            }
        });
        return newErrors;
    });
    };
    const handleAddEducation = () => {
        setEducation([...education,  { id: education.length + 1, formEducationData: {} }]);
    };
    const handleDeleteEducation = (id) => {
        setEducation(education.filter((education) => education.id !== id));
        updateFieldErrors(prevErrors => {
            const newErrors = { ...prevErrors };
            Object.keys(newErrors).forEach(key => {
                if (key.startsWith('education')) {
                    delete newErrors[key];
                }
            });
            return newErrors;
        });
    };

    const handleDownloadPDF = () => {
        // Initialize PDF
        const pdf = new jsPDF({
            unit: "pt",
            format: "a4"
        });
    
        // PDF dimensions
        const pageWidth = pdf.internal.pageSize.getWidth();
        const pageHeight = pdf.internal.pageSize.getHeight();
        const margin = 60;
        const usableWidth = pageWidth - (2 * margin);
    
        let yPos = 60; // Start a bit lower for better spacing
    
        // Helper functions
        const centerText = (text, y, fontSize, isColor = false) => {
            pdf.setFontSize(fontSize);
            const textWidth = pdf.getTextWidth(text);
            const xPos = (pageWidth - textWidth) / 2;
            
            if (isColor) {
                // Set the color to #2b1d4d (dark purple)
                pdf.setTextColor(43, 29, 77); // RGB values for #2b1d4d
            }
            
            pdf.text(text, xPos, y);
            
            // Reset color back to black for other text
            if (isColor) {
                pdf.setTextColor(0, 0, 0);
            }
        };
    
        const addHorizontalLine = () => {
            pdf.setDrawColor(200, 200, 200);
            pdf.setLineWidth(0.5);
            pdf.line(margin, yPos, pageWidth - margin, yPos);
            yPos += 15;
        };
    
        const addSectionTitle = (title) => {
            pdf.setFont("helvetica", "bold");
            pdf.setFontSize(12);
            pdf.text(title.toUpperCase(), margin, yPos);
            yPos += 20;
        };
    
        const addTextWithWrapping = (text, x, y, maxWidth, fontSize = 11) => {
            pdf.setFontSize(fontSize);
            const lines = pdf.splitTextToSize(text, maxWidth);
            pdf.text(lines, x, y);
            return (lines.length * (fontSize * 1.2)) + 5;
        };
    
        // Personal Details Section - Centered with specific styling
        // Name
        pdf.setFont("helvetica", "bold");
        pdf.setFontSize(24);
        const fullName = `${personalDetails.firstName} ${personalDetails.lastName}`.trim();
         const nameWidth = pdf.getStringUnitWidth(fullName) * pdf.getFontSize() / pdf.internal.scaleFactor;
        pdf.text(fullName, (pageWidth - nameWidth) / 2, yPos);
        yPos += 25;
    
        // Contact Information
        pdf.setFontSize(11);
        pdf.setFont("helvetica", "normal");
        const contactInfo = [];
        if (personalDetails.email) contactInfo.push(personalDetails.email);
        if (personalDetails.phoneNumber) contactInfo.push(personalDetails.phoneNumber);
        if (contactInfo.length) {
            centerText(contactInfo.join(" | "), yPos, 11);
            yPos += 15;
        }
    
        // Links
        const links = [];
        if (personalDetails.linkedIn) links.push(personalDetails.linkedIn);
        if (personalDetails.website) links.push(personalDetails.website);
        if (links.length) {
            centerText(links.join(" | "), yPos, 11);
            yPos += 15;
        }
    
        yPos += 10; // Add some space before the line
        // addHorizontalLine();
    
        // Rest of the sections remain the same...
        // Professional Summary
        if (personalDetails.summary) {
            addSectionTitle("PROFESSIONAL SUMMARY");
            pdf.setFont("helvetica", "normal");
            pdf.setFontSize(11);
            const summaryLines = pdf.splitTextToSize(personalDetails.summary, pageWidth - 2 * margin);
            summaryLines.forEach(line => {
                // checkPageBreak();
                pdf.text(line, margin, yPos);
                yPos += 18;
            });
        }
        // yPos += 1; 
        addHorizontalLine();
    
        // Experience Section
        if (experiences.length > 0) {
            if (yPos > pageHeight - 100) {
                pdf.addPage();
                yPos = margin;
            }
    
            addSectionTitle("EXPERIENCE");
    
            experiences.forEach((exp, index) => {
                if (yPos > pageHeight - 150) {
                    pdf.addPage();
                    yPos = margin;
                }
    
                // Company Name and Date on same line
                if (exp.formData.companyName) {
                    pdf.setFont("helvetica", "bold");
                    pdf.setFontSize(11);
                    pdf.text(exp.formData.companyName, margin, yPos);
    
                    if (exp.formData.startDate || exp.formData.endDate) {
                        const dateText = getDateRangeText(exp.formData.startDate, exp.formData.endDate);
                        const dateWidth = pdf.getTextWidth(dateText);
                        pdf.text(dateText, pageWidth - margin - dateWidth, yPos);
                    }
                    yPos += 20;
                }
    
                // Job Title and Location
                if (exp.formData.jobTitle || exp.formData.location || exp.formData.country) {
                    pdf.setFont("helvetica", "italic");
                    if (exp.formData.jobTitle) {
                        pdf.text(exp.formData.jobTitle, margin, yPos);
                    }
    
                    if (exp.formData.location || exp.formData.country) {
                        const locationText = [exp.formData.location, exp.formData.country].filter(Boolean).join(", ");
                        const locationWidth = pdf.getTextWidth(locationText);
                        pdf.text(locationText, pageWidth - margin - locationWidth, yPos);
                    }
                    yPos += 20;
                }
    
                // Description
                if (exp.formData.description) {
                    pdf.setFont("helvetica", "normal");
                    const descriptionLines = exp.formData.description
                        .split('\n')
                        .map(line => line.trim())
                        .filter(Boolean);
                
    
                    descriptionLines.forEach(line => {
                        if (yPos > pageHeight - 40) {
                            pdf.addPage();
                            yPos = margin;
                        }
                        const formattedLine = line.startsWith('•') ? line : `• ${line}`;
                        const height = addTextWithWrapping(formattedLine, margin + (line.startsWith('•') ? 10 : 0), yPos, usableWidth - 15);
                        yPos += height;
                        yPos += 4;
                    });
                }
    
                // yPos += 25;
            });
            addHorizontalLine();
        }
    
        // Skills Section
        if (skills.length > 0) {
            if (yPos > pageHeight - 100) {
                pdf.addPage();
                yPos = margin;
            }
    
            addSectionTitle("SKILLS");
            yPos += 10;
    
            const skillsPerRow = 3;
            const skillWidth = (usableWidth - (margin * (skillsPerRow - 1))) / skillsPerRow;
            pdf.setFont("helvetica", "normal");
            pdf.setFontSize(11);

            for (let i = 0; i < skills.length; i += skillsPerRow) {
                if (yPos > pageHeight - 40) {
                    pdf.addPage();
                    yPos = margin;
                }
                const rowSkills = skills.slice(i, i + skillsPerRow);
                rowSkills.forEach((skill, index) => {
                    const xPos = margin + (index * (skillWidth + 50));
                    pdf.setFont("helvetica", "normal");
                    pdf.text(`• ${skill}`, xPos, yPos);
                });
    
                yPos += 20;
            }
            addHorizontalLine();
        }
    
        // Education Section
        if (education.length > 0) {
            if (yPos > pageHeight - 100) {
                pdf.addPage();
                yPos = margin;
            }
    
            addSectionTitle("EDUCATION");
    
            education.forEach((edu) => {
                if (yPos > pageHeight - 150) {
                    pdf.addPage();
                    yPos = margin;
                }
    
                if (edu.formEducationData.school) {
                    pdf.setFont("helvetica", "bold");
                    pdf.text(edu.formEducationData.school, margin, yPos);
    
                    if (edu.formEducationData.startDate || edu.formEducationData.endDate) {
                        const dateText = getDateRangeText(edu.formEducationData.startDate, edu.formEducationData.endDate);
                        const dateWidth = pdf.getTextWidth(dateText);
                        pdf.text(dateText, pageWidth - margin - dateWidth, yPos);
                    }
                    yPos += 20;
                }
    
                if (edu.formEducationData.degree || edu.formEducationData.gpa) {
                    pdf.setFont("helvetica", "italic");
                    const degreeGpaText = [
                        edu.formEducationData.degree,
                        edu.formEducationData.gpa ? `GPA: ${edu.formEducationData.gpa}` : null
                    ].filter(Boolean).join(" | ");
                    pdf.text(degreeGpaText, margin, yPos);
                    yPos += 20;
                }
                if (edu.formEducationData.additionalDetails) {
                    pdf.setFont("helvetica", "normal");
                    const details = edu.formEducationData.additionalDetails.split('\n');
                    details.forEach(detail => {
                        if (yPos > pageHeight - 40) {
                            pdf.addPage();
                            yPos = margin;
                        }
                        const height = addTextWithWrapping(detail, margin + (detail.startsWith('•') ? 10 : 0), yPos, usableWidth - 15);
                        yPos += height;
                    });
                }
                yPos += 10;

            });


            // addHorizontalLine();
        }
        if (customSection.content) {
            if (yPos > pageHeight - 100) {
                pdf.addPage();
                yPos = margin;
            }
    
            addSectionTitle(customSection.title.toUpperCase());
            pdf.setFont("helvetica", "normal");
    
            const customContent = customSection.content.split('\n');
            customContent.forEach(line => {
                if (yPos > pageHeight - 40) {
                    pdf.addPage();
                    yPos = margin;
                }
                const height = addTextWithWrapping(line, margin + (line.startsWith('•') ? 10 : 0), yPos, usableWidth - 15);
                yPos += height;
            });
            addHorizontalLine();
        }
        // Save the PDF
        const fileName = `${fullName}_Resume.pdf`;
        pdf.save(fileName);
        // pdf.save("resume.pdf");
    };

    const [customSection, setCustomSection] = useState({
        title: 'CUSTOM SECTION',
        content: ''
    });

    const handleCustomSectionChange = (e) => {
        const { name, value } = e.target;
        
        if (name === 'content') {
            // Split into lines and process each line
            const lines = value.split('\n');
            const formattedLines = lines.map(line => {
                const trimmedLine = line.trim();
                // Only add bullet if line has content and doesn't already start with a bullet
                if (trimmedLine && !trimmedLine.startsWith('•')) {
                    return `• ${trimmedLine}`;
                }
                // Return original line (with or without bullet)
                return line;
            });
    
            setCustomSection(prev => ({
                ...prev,
                content: formattedLines.join('\n')
            }));
        } else {
            setCustomSection(prev => ({
                ...prev,
                [name]: value
            }));
        }
    };
const isEmptyPreview = !personalDetails.firstName &&
    !personalDetails.lastName &&
    !personalDetails.email &&
    !personalDetails.phoneNumber &&
    !personalDetails.linkedIn &&
    !personalDetails.website &&
    skills.length === 0 &&
    experiences.length === 0 &&
    education.length === 0 &&
    !customSection.content;

console.log("Is empty preview?", isEmptyPreview);



    if (!isOpen) return null;
    return (
        <div className="modal-overlay">
        {isLoading ? (
  <ResumeLoadingScreen/>
 ) : (
        <div className="resume-modal-content">
            {/* Add the alert box here */}
            {errorMessage && showErrorAlert(errorMessage)}
            <button className="resume-close-button" onClick={closeModal}>
                &times;
            </button>
            <div className="steps-header">
                
                <div className="stepper">

                    <div className={`step ${currentStep >= 1 ? 'active' : ''} ${currentStep > 1 ? 'completed' : ''}` }>
                        <div className="circle">
                            <i className="fas fa-user"></i>
                        </div>
                        <span>Personal Details</span>
                    </div>
                    <div className="step-line"></div>
                    <div className={`step ${currentStep >= 2 ? 'active' : ''} ${currentStep > 2 ? 'completed' : ''}`}>
                        <div className="circle">
                            <i className="fas fa-briefcase"></i>
                        </div>
                        <span>Experience</span>
                    </div>
                    <div className="step-line"></div>
                    <div className={`step ${currentStep >= 3 ? 'active' : ''} ${currentStep > 3 ? 'completed' : ''}`}>
                        <div className="circle">
                            <i className="fas fa-cogs"></i>
                        </div>
                        <span>Skills</span>
                    </div>
                    <div className="step-line"></div>
                    <div className={`step ${currentStep >= 4 ? 'active' : ''} ${currentStep > 4 ? 'completed' : ''}`}>
                        <div className="circle">
                            <i className="fas fa-graduation-cap"></i>
                        </div>
                        <span>Education</span>
                    </div>
                    <div className="step-line"></div>
                    <div className={`step ${currentStep >= 5 ? 'active' : ''} ${currentStep > 5 ? 'completed' : ''}`}>
                        <div className="circle">
                            <i className="fas fa-edit"></i>
                        </div>
                        <span>Custom Section</span>
                    </div>
                    <div className="step-line"></div>
                    <div className={`step ${currentStep >= 6 ? 'active' : ''} ${currentStep > 6 ? 'completed' : ''}`}>
                        <div className="circle">
                            <i className="fas fa-file-alt"></i>
                        </div>
                        <span>Professional Summary</span>
                    </div>
                </div>
            </div>
                
            <div className="content-wrapper">
                <div className='resume-form'>
                    <div className="main-div">
                        <div className="resume-name">
                            {isEditing ? (
                                <input
                                    type="text"
                                    value={resumeName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    autoFocus // Focus on input immediately
                                    className="edit-input"
                                />
                            ) : (
                                <>
                                    {resumeName} <i className="fa fa-pen" onClick={handleIconClick}></i>
                                </>
                            )}
                        </div>
                        {currentStep === 1 && (
                            <div className='sub-div'>
                                {/* <h4>PERSONAL DETAILS</h4> */}

                                <div className="row">
                                    <div className="col">
                                        <div className="input-div">
                                            <div className="label">
                                                <span>First Name</span>
                                                <span className={`required-asterisk ${fieldErrors.firstName ? 'error-text' : ''}`}>*</span>
                                            </div>
                                            <input
                                                className={`resume-input ${fieldErrors.firstName ? 'error-border' : ''}`}
                                                placeholder="John"
                                                type="text"
                                                name="firstName"
                                                value={personalDetails.firstName}
                                                onChange={handleChangePersonalDetails}
                                                />
                                                {fieldErrors.firstName && <span className="resume-error-message">{fieldErrors.firstName}</span>}
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="input-div">
                                            <div className="label">
                                                <span>Last Name</span>
                                                <span className={`required-asterisk ${fieldErrors.lastName ? 'error-text' : ''}`}>*</span>
                                            </div>
                                            <input 
                                                className={`resume-input ${fieldErrors.lastName ? 'error-border' : ''}`}
                                                placeholder="Doe" 
                                                type="text" 
                                                name="lastName"
                                                value={personalDetails.lastName} onChange={handleChangePersonalDetails}
                                            />
                                            {fieldErrors.lastName && <span className="resume-error-message">{fieldErrors.lastName}</span>}
                                        </div>
                                    </div>
                                </div>

                                <div className = "row">
                                    <div className="col">
                                        <div className="input-div">
                                            <div className="label">
                                                <span>Email </span>
                                                <span className={`required-asterisk ${fieldErrors.email ? 'error-text' : ''}`}>*</span>
                                            </div>
                                            <input 
                                                className={`resume-input ${fieldErrors.email ? 'error-border' : ''}`}
                                                placeholder='johndoe@gmail.com' 
                                                type="text" name="email"
                                                value={personalDetails.email} 
                                                onChange={handleChangePersonalDetails}
                                            />
                                            {fieldErrors.email && <span className="resume-error-message">{fieldErrors.email}</span>}
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="input-div">
                                            <div className="label">
                                                <span>Phonenumber </span>
                                                <span className={`required-asterisk ${fieldErrors.phoneNumber ? 'error-text' : ''}`}>*</span>
                                            </div>
                                            <input
                                                className={`resume-input ${fieldErrors.phoneNumber ? 'error-border' : ''}`}
                                                type="text" name="phoneNumber" 
                                                placeholder="123 456 6789"
                                                value={personalDetails.phoneNumber} 
                                                onChange={handleChangePersonalDetails}
                                            />
                                            {fieldErrors.phoneNumber && 
                                            <span className="resume-error-message">{fieldErrors.phoneNumber}</span>}
                                        </div>
                                    </div>
                                </div>
                                <div className = "row">
                                    <div className="col">
                                        <div className="input-div">
                                            <div className="label">
                                                <span>LinkedIn </span>
                                            </div>
                                            <input className={`resume-input ${fieldErrors.linkedIn ? 'error-border' : ''}`} type="text" placeholder='https://linkedin.com' name='linkedIn'
                                                   value={personalDetails.linkedIn} onChange={handleChangePersonalDetails}/>
                                            {fieldErrors.linkedIn && 
                                            <span className="resume-error-message">{fieldErrors.linkedIn}</span>}
                                        </div>
                                    </div>
                                    <div className="col">
                                        <div className="input-div">
                                            <div className="label">
                                                <span>Website </span>
                                            </div>
                                            <input className={`resume-input ${fieldErrors.website ? 'error-border' : ''}`}type="text"  placeholder='https://website.com' name='website'
                                                   value={personalDetails.website} onChange={handleChangePersonalDetails} />
                                            {fieldErrors.website && 
                                            <span className="resume-error-message">{fieldErrors.website}</span>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {currentStep === 2 && (
                            <div className='sub-div'>
                                {/* <h4>EXPERIENCE</h4> */}
                                {experiences.map((experience, index) => (
                                    <div key={experience.id} className="experience-container">
                                        <div className="experience-header">
                                            {/* <h4>EXPERIENCE {index + 1}</h4> */}
                                            <button
                                                className="delete-button"
                                                onClick={() => handleDeleteExperience(experience.id)}
                                            >
                                                <i className="fa fa-trash" aria-hidden="true"></i>
                                            </button>
                                        </div>
                                        <ResumeExperiences
                                            index={index}
                                            formData={experience.formData}
                                            onFormChange={(updatedFormData) =>
                                                handleFormChange(experience.id, updatedFormData)
                                            }
                                            fieldErrors={fieldErrors}  // Pass fieldErrors as a prop
                                            updateFieldErrors={updateFieldErrors}
                                            removeSkillFromGlobalList={removeSkillFromGlobalList}
                                            experiences={experiences} // 🔥 Pass the experiences array
                                        />
                                    </div>
                                ))}
                                {fieldErrors.experienceSkillsLimit && (
                                    <span className="resume-error-message">{fieldErrors.experienceSkillsLimit}</span>
                                )}

                                <span className="experience-note">Note: Add upto 4 Experiences</span>
                                <button className='experience' onClick={handleAddExperience} disabled={experiences.length>=4}>+ Add an experience</button>
                            </div>
                        )}
                        {currentStep === 3 && (
                            <div className='sub-div'>
                                {/* <h4>SKILLS</h4> */}
                                <div className="row">
                                    <div className="input-div">
                                        <div className="label">
                                            <span>Skills</span>
                                            <span className={`required-asterisk ${fieldErrors.skills ? 'error-text' : ''}`}>*</span>
                                        </div>
                                        <input
                                            type="text"
                                            className={`resume-skills ${fieldErrors.skills ? 'error-border' : ''}`}
                                            value={skillInput}
                                            onChange={handleSkillInputChange}
                                            onKeyDown={handleSkillAdd}
                                            placeholder="Enter skills and press Enter"
                                        />
                                        {fieldErrors.skills && 
                                            <span className="resume-error-message">{fieldErrors.skills}</span>
                                        }
                                        {(skills.length > 0 || skillInput.trim()) && (
                                            <div className="resume-skills-container">
                                                {[...(skills || []), skillInput.trim() || '']
                                            .filter(Boolean) // Ensure no empty strings are displayed
                                            .map((skill, index) => (
                                                <button 
                                                key={index} 
                                                className={`resume-skill-button ${skill === skillInput.trim() ? 'pending-skill' : ''}`}
                                                >
                                                <span className="skill-text">{skill}</span>
                                                {skill !== skillInput.trim() && (
                                                    <span className="remove-skill" onClick={() => handleSkillRemove(skill)}>
                                                    &times;
                                                    </span>
                                                )}
                                                </button>
                                            ))}

                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                        {currentStep === 4 && (
                            <div className='sub-div'>
                                {/* <h4>EDUCATION</h4> */}
                                {education.map((educations, index) => (
                                    <div key={educations.id} className="experience-container">
                                        <div className="experience-header">
                                            {/* <h4>EDUCATION {index + 1}</h4> */}
                                            <button
                                                className="delete-button"
                                                onClick={() => handleDeleteEducation(educations.id)}
                                            >
                                                <i className="fa fa-trash" aria-hidden="true"></i>
                                            </button>
                                        </div>
                                        <ResumeEducation
                                            index={index}
                                            formEducationData={educations.formEducationData}
                                            onFormEducationChange={(updatedEducationFormData) =>
                                                handleEducationFormChange(educations.id, updatedEducationFormData)
                                            }
                                            fieldErrors={fieldErrors}
                                            updateFieldErrors={updateFieldErrors}
                                        />
                                    </div>
                                ))}

                                <button className='experience' onClick={handleAddEducation} disabled={experiences.length >= 4}>+ Add Education</button>
                            </div>
                        )}
                        {currentStep === 5 && (
                            <div className='sub-div'>
                                <div className="row">
                                    <div className="input-div">
                                        <div className="label">
                                            <span>Section Title</span>
                                        </div>
                                        <input 
                                            className={`resume-input ${fieldErrors.title ? 'error-border' : ''}`}
                                            name="title"
                                            value={customSection.title}
                                            onChange={handleCustomSectionChange}
                                            placeholder="Enter section title"
                                        />
                                        {fieldErrors.title && 
                                            <span className="resume-error-message">{fieldErrors.title}</span>}
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="input-div">
                                        <div className="label">
                                            <span>Content</span>
                                        <div>
                                        {fieldErrors.content && 
                                        <span className="resume-error-message">{fieldErrors.content}</span>}
                                    </div>
                                        </div>
                                        
                                        <textarea 
                                            className={`text-area ${fieldErrors.content ? 'error-border' : ''}`}
                                            name="content"
                                            value={customSection.content}
                                            onChange={handleCustomSectionChange}
                                            placeholder="Enter content"
                                        ></textarea>
                                        
                                    </div>
                                </div>
                            </div>
                        )}
                        {currentStep === 6 && (

                            <div className='sub-div'>
                                {/* <h4>PROFESSIONAL SUMMARY</h4> */}
                                <div className="row">
                                    <div className="input-div">
                                        <div className="label">
                                            <span>Professional Summary</span>
                                            <span className={`required-asterisk ${fieldErrors.summary ? 'error-text' : ''}`}>*</span>
                                        </div>
                                        <textarea 
                                            className={`text-area ${fieldErrors.summary ? 'error-border' : ''}`}
                                            name='summary'
                                            value={personalDetails.summary} 
                                            onChange={handleChangePersonalDetails}>
                                        </textarea>
                                        {fieldErrors.summary && 
                                            <span className="resume-error-message">{fieldErrors.summary}</span>
                                        }
                                    </div>
                                </div>
                            </div>
                        )}
                        <div className="navigation-buttons">
                            {currentStep > 1 && <button onClick={goToPreviousStep}>Previous</button>}
                            {currentStep < 6 && <button onClick={goToNextStep}>Next</button>}
                            {currentStep === 6 && <button onClick={handleSubmit}>Submit</button>}
                        </div>
                    </div>
                </div>
                <div className="template">
                    <div className="download-resume-icon">
                        <button onClick={handleDownloadPDF} className="download-btn">Download PDF</button>
                    </div>
                    
                    <div className="new-preview-body">
                        <div className="resume-template">
                        {/* Personal Details Section */}
                        {isEmptyPreview ? (
                        // Empty Preview State (Default View)
                        <div className="empty-preview-state">
                            <div className="empty-preview-content">
                                {[...Array(11)].map((_, index) => (
                                    <div
                                        key={index}
                                        className="empty-preview-line"
                                    ></div>
                                ))}
                            </div>
                        </div>
                    ) : (
                        <>
                            <div className="new-preview-section new-personal-details">
                            <h1 className="new-preview-name">
                            {personalDetails.firstName} {personalDetails.lastName}
                            </h1>
                            <div className="preview-contact-info">
                                {personalDetails.email && (
                                    <span><i className="far fa-envelope"></i> {personalDetails.email}</span>
                                )}
                                {personalDetails.phoneNumber && (
                                    <span><i className="fas fa-phone"></i> {personalDetails.phoneNumber}</span>
                                )}
                            </div>
                            <div className="preview-social-links">
                                {personalDetails.linkedIn && (
                                    <span><i className="fab fa-linkedin"></i> {personalDetails.linkedIn}</span>
                                )}
                                {personalDetails.website && (
                                    <span><i className="fas fa-globe"></i> {personalDetails.website}</span>
                                )}
                            </div>
                            </div>

                            {/* Professional Summary Section */}
                            {personalDetails.summary && (
                            <div className="new-preview-section">
                                <h3 className="new-preview-section-title">Professional Summary</h3>
                                {personalDetails.summary && (
                                <div className="preview-section-content">
                                    <p>{personalDetails.summary}</p>
                                </div>
                                )}
                            </div>
                            )}

                            {/* Experience Section */}
                            {experiences.length > 0 && (
                            <div className="new-preview-section">
                                <h3 className="new-preview-section-title">Experience</h3>
                                <div className="preview-section-content">
                                    {experiences.map((exp, index) => (
                                        <div key={index} className="new-preview-experience-item">
                                            <div className="new-preview-experience-header">
                                                <div className="new-preview-experience-title">
                                                    <h4>{exp.formData.jobTitle}</h4>
                                                    <span className="new-preview-company">{exp.formData.companyName}</span>
                                                </div>
                                                <div className="new-preview-experience-period">
                                                    <span> {exp.formData.startDate ? exp.formData.startDate.toLocaleDateString('en-US', { year: 'numeric', month: 'short' }) : ''} - 
                                                    {exp.formData.currentlyWorking 
                                                            ? "Current"  // Show "Current" if checked
                                                            : exp.formData.endDate 
                                                            ? exp.formData.endDate.toLocaleDateString('en-US', { year: 'numeric', month: 'short' }) 
                                                            : ''
                                                        }                                                    </span>
                                                    {(exp.formData.location || exp.formData.country) && (
                                                        <span className="new-preview-location">
                                                            <i className="fas fa-map-marker-alt"></i>

                                                            {exp.formData.location && exp.country
                                                                ? `${exp.formData.location}, ${exp.formData.country}`
                                                                : exp.formData.location || exp.formData.country}
                                                        </span>
                                                    )}
                                                </div>
                                            </div>
                                            <ul className="new-preview-responsibilities">
                                                {/* {exp.formData.description?.map((resp, idx) => (
                                                    <li key={idx}>{resp}</li>
                                                ))} */}
                                                {Array.isArray(exp.formData.description) ? (
                                                    exp.formData.description.map((resp, idx) => (
                                                        <li key={idx}>{resp.replace(/^[•\s]+/, '')}</li>
                                                    ))
                                                ) : (
                                                    // If description is a string, split it into an array by line breaks
                                                    exp.formData.description?.split('\n')
                                                    .filter(line => line.trim()) // Remove empty lines
                                                    .map((resp, idx) => (
                                                        <li key={idx}>{resp.replace(/^[•\s]+/, '')}</li>    
                                                    ))
                                                )}
                                            </ul>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            )}

                            {/* Skills Section */}
                            {skills.length > 0 && (
                            <div className="new-preview-section">
                                <h3 className="new-preview-section-title">Skills</h3>
                                <div className="new-preview-skills-container">
                                {skills.map((skill, index) => (
                                <span key={index} className="new-preview-skill-tag">
                                    {skill}
                                </span>
                                ))}
                                </div>
                            </div>
                             )}

                            {/* Education Section */}
                            {education.length > 0 && (
                            <div className="new-preview-section">
                                <h3 className="new-preview-section-title">Education</h3>
                                <div className="preview-section-content">
                                    {education.map((edu, index) => (
                                        <div key={index} className="new-preview-education-item">
                                            <div className="new-preview-education-header">
                                                <div className="new-preview-education-title">
                                                    <h4>{edu.formEducationData.school}</h4>
                                                    <span className="new-preview-degree">{edu.formEducationData.degree}</span>
                                                </div>
                                                <div className="new-preview-education-period">
                                                    <span>{edu.formEducationData.startDate ? edu.formEducationData.startDate.toLocaleDateString('en-US', { year: 'numeric', month: 'short' }) : ''} - {edu.formEducationData.endDate ? edu.formEducationData.endDate.toLocaleDateString('en-US', { year: 'numeric', month: 'short' }) : ''}</span>
                                                    {edu.formEducationData.gpa && (
                                                        <span className="new-preview-gpa">GPA: {edu.formEducationData.gpa}</span>
                                                    )}
                                                </div>
                                            </div>
                                            {edu.formEducationData.additionalDetails && (
                                                <p className="new-preview-education-details"
                                                dangerouslySetInnerHTML={{
                                                    __html: edu.formEducationData.additionalDetails
                                                        .replace(/\n/g, '<br/>') // Handle line breaks
                                                        .replace(/^\s*•/gm, '<br/>•') // Ensure bullet points start on a new line
                                                }}
                                            />
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </div>
                            )}
                            {customSection.content && (
                            <div className="new-preview-section">
                                
                                <h3 className="new-preview-section-title">{customSection.title}</h3>
                                
                                <div className="preview-section-content">
                                    <p
                                        dangerouslySetInnerHTML={{
                                            __html: customSection.content
                                                .replace(/\n/g, '<br/>') // Handle line breaks
                                                .replace(/^\s*•/gm, '<br/>•') // Ensure bullet points start on a new line
                                        }}
                                    />
                                </div>
                            </div>
                            )}
                        </>
                        )}
                        </div>
                    </div> 

                </div>
            </div>
        </div>    
 )}
    </div>
   
);
};

export default ResumeModal;
