import { Star } from "lucide-react";

export const TestimonialCard = ({ name, role, company, content, rating, image }) => {
    return (
      <div className="testimonial-card">
        <div className="testimonial-card-header">
          <img
            src={image}
            alt={name}
            className="testimonals-image"
          />
          <div className="user-info">
            <h3 className="user-name">{name}</h3>
            <p className="user-role">{role}</p>
            <p className="user-company">{company}</p>
          </div>
        </div>
        <div className="rating">
          {[...Array(5)].map((_, i) => (
            <Star
              key={i}
              className={`star ${i < Math.ceil(rating) ? 'filled' : ''}`}
            />
          ))}
        </div>
        <p className="testimonial-content">{content}</p>
      </div>
    );
  };