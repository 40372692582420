import React, { useEffect } from 'react';
import './Notification.css';

const Notification = ({ message, onClose }) => {
    useEffect(() => {
        const timer = setTimeout(onClose, 5000); // Auto-close after 5 seconds
        return () => clearTimeout(timer); // Cleanup timer on unmount
    }, [onClose]);

    return (
        <div className="notification show">
            <i className="fas fa-exclamation-triangle" style={{ marginRight: '5px', color:'red' }}></i> {/* Add the icon here */}
            <span>{message}</span>
            <div className="notification-progress-bar"></div>
            <button onClick={onClose} className="close-btn">&times;</button>
        </div>
    );
};

export default Notification;