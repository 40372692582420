import React, { useEffect, useState } from 'react';
import { Rocket, Mail, CheckCircle, CreditCard, Bot, Brain } from 'lucide-react';

const data={
    "prepSteps" : [
        { icon: Rocket, label: 'Start' , description: 'Click Login'},
        { icon: Mail, label: 'Email', description: 'Enter email'},
        { icon: CheckCircle, label: 'Verify', description: 'Confirm identity'},
        { icon: CreditCard, label: 'Subscribe', description: 'Choose plan'},
        { icon: Bot, label: 'Begin', description: 'Start Prep'},
    ],
    "mockSteps" : [
        { icon: Rocket, label: 'Start' , description: 'Click Login'},
        { icon: Mail, label: 'Email', description: 'Enter email'},
        { icon: CheckCircle, label: 'Verify', description: 'Confirm identity'},
        { icon: CreditCard, label: 'Subscribe', description: 'Choose plan'},
        { icon: Brain, label: 'Begin', description: 'Start Mock'},
    ]
}

export const ProgressBar = ({stepsData}) =>{
    const [currentStep, setCurrentStep]= useState(0);
    const [progress, setProgress] = useState(0);
    useEffect(()=>{
        const animationTime= setTimeout(()=>{
            if(currentStep<steps.length-1){
                setCurrentStep(prev => prev + 1);
                setProgress((currentStep + 1 ) *  (100 / (steps.length-1)));
            }
        }, 1000);
        return ()=>clearTimeout(animationTime)
    }, [currentStep]);
    var steps=[];
    if(stepsData==="prepSteps"){
        steps=data.prepSteps;
    }else{
        steps=data.mockSteps;
    }
    return (
        <div className='progress-container'>
            <div className='progress-bar'>
                <div 
                    className='progress-line'
                    style={{width: `${progress}%`}}>
                </div>
                {steps.map((step, index) => {
                    const Icon = step.icon;
                    return (
                        <div 
                        key={step.label} 
                        className={`step-container ${index <= currentStep ? 'active' : ''}`}
                        style={{ left: `${(index * 100) / (steps.length - 1)}%` }}
                        >
                        <div className="icon-container">
                            <Icon className="icon" />
                        </div>
                        <div className='label-container'>
                            <span className="step-label">{step.label}</span>
                            <span className="step-description">{step.description}</span>
                        </div>
                        </div>
                    );
                })}
            </div>
        </div>
    )
}