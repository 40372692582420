import React, { useState, useEffect, useRef } from 'react';
import { 
  useStripe, 
  useElements, 
  PaymentElement, 
  AddressElement,
} from '@stripe/react-stripe-js';
import { useNavigate, useLocation } from 'react-router-dom';
import './PaymentForm.css';
import SuccessModal from './LandingPages/SuccessModal';
import { ArrowLeft } from 'lucide-react';

const PaymentForm = ({subscribePlan}) => {
  const location = useLocation();
  const { amount, planDetails } = location.state || { amount: 0, planDetails: {} };
  const amountInDollars = (amount / 100).toFixed(2);
  const sessionType= planDetails?.session_type.slice(0, 1).toUpperCase() + planDetails?.session_type.slice(1);
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  console.log("plan details in payment form", planDetails);
  
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showPromoInput, setShowPromoInput] = useState(false);
  const [cardholderName, setCardholderName] = useState('');
  const promoRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (promoRef.current && !promoRef.current.contains(event.target)) {
        setShowPromoInput(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      if (!stripe || !elements) {
        throw new Error('Stripe is not loaded');
      }

      const addressElement = elements.getElement(AddressElement);
      const { value: billingAddressData } = await addressElement.getValue();

      const { error: paymentError, paymentIntent } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          payment_method_data: {
            billing_details: {
              name: cardholderName,
              address: {
                line1: billingAddressData.address.line1,
                line2: billingAddressData.address.line2,
                city: billingAddressData.address.city,
                state: billingAddressData.address.state,
                postal_code: billingAddressData.address.postal_code,
                country: billingAddressData.address.country,
              },
            },
          },
          return_url: 'http://localhost:3000/payment-success',
        },
        redirect: 'if_required',
      });

      if (paymentError) {
        throw new Error(paymentError.message);
      }
        // Log the payment intent details
        // console.log('Payment successful:', paymentIntent);
        let response= await subscribePlan({"subscription_id":planDetails.id,payment_details: paymentIntent,sessions:planDetails?.sessions });
        if(response.ok){
          setShowModal(true);
        }else{
          setError("Payment failed");
        }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };
  console.log("session type", planDetails?.session_type);
  return (
    <div className="payment-page">
      <div className="payment-content">
        {/* Left Section: Configuration Details */}
        <div className="payment-details">
          <div className='payment-back-btn'>
            <button onClick={() => navigate(-1)} className='back-btn'>
            <ArrowLeft />
            <span>Back</span>
            </button>
          </div>
          <div className="paymentPage-logo">
            <img src="https://botguru.ai/static/media/botgurulogo.ebbcf644d1784a3848d5.png" alt="BotGuru Logo" />
          </div>
          <div className="subscription-heading">
            <h2>Subscription Summary</h2>
            {
              (planDetails?.plan_type==="Starter") 
              ? (
                <div className='subscription-details'>
                <h4 className="plan-type">{planDetails?.plan_type} Plan -  
                  <span> {sessionType}</span>
                </h4>
                <p className="plan-detail">${amountInDollars} per {planDetails?.session_type==="resume" ? ` ${planDetails?.sessions ?? 10} AI Generated Resumes` : (planDetails?.session_type ==="jd" ? `${planDetails?.sessions ?? 10} AI Generated Job Descriptions` : "Session" )}</p>
                </div>
              )
              :(
                <div className='subscription-details'>
                <h4 className="plan-type">{planDetails?.plan_type} Plan -  
                  <span> {sessionType}</span>
                </h4>
                  <p className="plan-detail">${amountInDollars} - {planDetails?.sessions} {planDetails?.session_type==="resume" ? "AI Generated Resumes" : (planDetails?.session_type ==="jd" ? "AI Generated Job Descriptions" : "Sessions" )}</p>
                </div>
              )
            }
          </div>
          <div className="subtotal">
            <span>Subtotal</span>
            <span>${amountInDollars}</span>
          </div>
          <div className="promo-code" ref={promoRef}>
            {!showPromoInput ? (
              <button 
                onClick={() => setShowPromoInput(true)}
                className="promo-button"
              >
                Add Promotional Code
              </button>
            ) : (
              <div className="promo-input">
                <input type="text" placeholder="Add Promotional Code" />
              </div>
            )}
          </div>
          <div className="tax">
            <span>Tax</span>
            <span>$0.00</span>
          </div>
          <div className="total-due">
            <span>Total</span>
            <span>${amountInDollars}</span>
          </div>
        </div>

        {/* Right Section: Payment Form */}
        <form onSubmit={handleSubmit} className="modern-payment-form">
          <div className="form-container">
            <div className="payment-details-section">
              <div className="section-title">
                <i className="fas fa-user"></i>
                <h2>Payment Details</h2>
              </div>
              <div className="form-group">
                <label htmlFor="full-name">
                  <i className="far fa-user"></i>
                  Cardholder Name
                </label>
                <input
                  id="full-name"
                  type="text"
                  placeholder="Enter your full name"
                  value={cardholderName}
                  onChange={(e) => setCardholderName(e.target.value)}
                  className="modern-input"
                  required
                />
              </div>

              <div className="form-group">
                <PaymentElement />
              </div>

              <div className="form-group">
                <AddressElement
                  options={{
                    mode: 'billing',
                    display: {
                      name: 'full',
                      organization: 'never',
                    },
                    layout: {
                      type: 'accordion',
                      defaultCollapsed: false,
                      radios: 'hidden',
                      spacedAccordionItems: false,
                    },
                    styling: {
                      layout: {
                        gap: '1rem',
                      },
                      fields: {
                        country: { grid: 'col-span-12' },
                        line1: { grid: 'col-span-12' },
                        line2: { grid: 'col-span-12' },
                        city: { grid: 'col-span-6' },
                        state: { grid: 'col-span-3' },
                        postalCode: { grid: 'col-span-3' },
                      },
                    },
                  }}
                />
              </div>
            </div>
          </div>

          {/* Payment Action */}
          <div className="payment-action">
            <button 
              className="modern-pay-button" 
              type="submit" 
              disabled={!stripe || loading}
            >
              {loading ? (
                <div className="loading-spinner">
                  <div className="spinner"></div>
                  <span>Processing...</span>
                </div>
              ) : (
                <>
                  <i className="fas fa-lock"></i>
                  <span>Pay Securely ${amountInDollars}</span>
                </>
              )}
            </button>
          </div>

          {/* Error Message */}
          {error && (
            <div className="error-message">
              <i className="fas fa-exclamation-circle"></i>
              {error}
            </div>
          )}
        </form>
      </div>

      {showModal && (
        <SuccessModal 
          onClose={() => setShowModal(false)} 
          navigate={navigate} 
          planType={sessionType} 
        />
      )}
    </div>
  );
};

export default PaymentForm;


