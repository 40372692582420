import { Check } from "lucide-react"
import '../Pricing.css';
export const FreePlan = ({data, selectPlan})=>{
    return (
        <div className="tabData freePlan">
            <div className="content">
                <span className="free-trial-tag">Try out!</span>
                <div className="pricing-section">
                    <h5 className="plan-title">Free Trial !</h5>
                    <span className="price">$0.00</span>
                    <div className="session-info">
                            <span className="">15 Mins session</span>
                            <span>Get a preview of the complete PrepBot 60-minutes session and discover how it can elevate your skills and help you ace the interview</span>
                    </div>
                </div>  
            <div className="session-features free">
            {data.hasOwnProperty("features") && data.features.map((feature, index) => (
                <div key={index}>
                    <Check className="check" strokeWidth={3}/>
                    <span>{feature}</span>
                </div>
            ))}
            </div>
            </div>
            <button 
                className="choose-planBtn"
                onClick={() => selectPlan(data.price,{"plan_type":"free", "id":data._id, "session_type":data.session_type})}
            >Choose Plan</button>
        </div>
    )
}
