import React, { useState, useEffect } from "react";
import './JDGuruPage.css';
import JdDetailsModal from './JDDetailsModal';
import JDEditableModal from './JDEditableModal';
import JDCreateModal from "./JDCreateModal";
import useAuthService from '../../services/AuthService';
import { jsPDF } from "jspdf";
import { useAuthContext } from "../Auth/AuthContext";
import Tour from 'reactour';
import config from "../../config.json";
import { Info, TriangleAlert } from "lucide-react";
import Notification from "./Notification";
import { useNavigate } from "react-router-dom";
import { MoveRight, Sparkles, Trophy, Zap } from "lucide-react";



const JDGuruPage = () => {
    const authService = useAuthService();
    const websocketUrlForFileUpload = "wss://o834xau6qe.execute-api.us-west-1.amazonaws.com/production/";
    const { userObjId, newUser,userAnalytics } = useAuthContext(); // Get userObjId and newUser from context
    const [showNotification, setShowNotification] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState('');
    const [showTourBtn, setShowTourBtn]=useState(false);
    const navigate = useNavigate();
    // Generate random number function
    const generateRandom5DigitNumber = () => {
        return Math.floor(10000 + Math.random() * 90000);
    };

    // States
    const [formData, setFormData] = useState({
        "job_role": "",
        company: "",
        job_type: "",
        job_description: {
            about_us: "",
            job_overview: "",
            key_responsibilities: "",
            required_qualification_experience: "",
            preferred_skills: ""
        },
        experience: "",
        salary: "",
        location: "",
        date: new Date().toISOString().split("T")[0],
        time: new Date().toTimeString().split(" ")[0],
        jd_id: generateRandom5DigitNumber()
    });
    const [isModalOpen, setModalOpen] = useState(false);
    const [isEditModalOpen, setEditModalOpen] = useState(false);
    const [selectedJdId, setSelectedJdId] = useState(null);
    const [isCreateModalOpen, setCreateModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [jdData, setJdData] = useState([]);
    const [isTourOpen, setIsTourOpen] = useState(false); // State to control the tour
    const [currentStep, setCurrentStep] = useState(0); // State to track current tour step
    const [isUploading, setIsUploading] = useState(false);
    const [uploadError, setUploadError]= useState(false);

    console.log("User Data:", newUser);

    useEffect(() => {
        if (newUser === 'Y') {
            console.log('New user detected, showing initial tour guide');
            setShowTourBtn(true);
            // setIsTourOpen(true);
        } else {
            console.log('Tour guide disabled because:', {
                newUser
            });
            setIsTourOpen(false);
        }
    }, [newUser]);

    // useEffect(()=>{
    //     let timer;
    //     if(uploadError){
    //         timer=setTimeout(() => {
    //             setUploadError(false);
    //         }, 10000);
    //     }
    //     return () => clearTimeout(timer);
    // }, [uploadError])

    const handleNext = () => {
        setCurrentStep((prev) => Math.min(prev + 1, tourSteps.length - 1));
    };

    const handlePrev = () => {
        setCurrentStep((prev) => Math.max(prev - 1, 0));
    };

    const handleElementClick = (e, normalFunction) => {
        if (isTourOpen) {
            if (e) e.preventDefault();
            handleNext();
        } else {
            normalFunction();
        }
    };

    const tourSteps = [
        {
            selector: '.upload-jd-button',
            content: 'Click here to upload your job description.'
        },
        {
            selector: '.create-jd-button',
            content: 'Click here to create a new job description.'
        },
        // {
        //     selector: '.jd-file-type',
        //     content: 'Click here to edit the job description.'
        // },
        // {
        //     selector: '.action-btn.download',
        //     content: 'Click here to download the job description.'
        // },
    ];

    // Fetch job roles
    const fetchJobRoles = async () => {
        setLoading(true);
        try {
            const response = await fetch(`${config.restAPI}/getJobs/`, {
                method: "POST",
                credentials:"include",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ user_obj_id:userObjId }),
            });

            if (!response.ok) {
                throw new Error(`Failed to fetch job roles. Status: ${response.status}`);
            }

            const data = await response.json();
            const sortedData = data.job_descriptions.sort((a, b) => {
                const aDate = new Date(a.created_at);
                const bDate = new Date(b.created_at);
                return bDate - aDate; // Latest first
            });
    
            console.log("Sorted JDs by created_at:", sortedData);
            setJdData(sortedData || []);
        } catch (error) {
            console.error("Error fetching job roles:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchJobRoles();
    }, []);

    const handleDownloadPDF = (data) => {
        handleElementClick(null, () => {
            if (!data) return;
            const pdf = new jsPDF();

            const cleanText = (text) => {
                if (!text) return '';
                // Remove numbers followed by dot/bracket at start of line
                return text.replace(/^\d+[\.)]\s*/gm, '');
            };
            
            // Set up PDF styling
            pdf.setTextColor(0, 0, 0); // Black text
            pdf.setFillColor(255, 255, 255); // White background
            
            // Helper function to ensure string values
            const toString = (value) => {
                if (value === null || value === undefined) return 'Not Specified';
                return String(value);
            };
            
            // Company Name - Centered, Bold, Underlined
            pdf.setFontSize(18);
            pdf.setFont(undefined, 'bold');
            const companyName = toString(data.company);
            const companyNameWidth = pdf.getStringUnitWidth(companyName) * pdf.internal.getFontSize() / pdf.internal.scaleFactor;
            const centerX = (pdf.internal.pageSize.width - companyNameWidth) / 2;
            pdf.text(companyName, centerX, 20);
            pdf.setFont(undefined, 'normal');
            pdf.setLineWidth(0.5);
            pdf.line(centerX, 22, centerX + companyNameWidth, 22); // Underline
            pdf.setFontSize(12);
            
            // Date - Right Aligned
            const currentDate = new Date().toLocaleDateString();
            pdf.text(`Date: ${currentDate}`, pdf.internal.pageSize.width - 20, 30, { align: 'right' });

            // Job Details
            pdf.setFontSize(12);
            const titleX = 20;
            const valueX = 70;
            
            // Helper function for adding fields
            const addField = (label, value, y) => {
                pdf.setFont(undefined, 'bold');
                pdf.setFontSize(12);
                pdf.text(label, titleX, y);
                pdf.setFont(undefined, 'normal');
                pdf.setFontSize(10);
                pdf.text(toString(value), valueX, y);
            };

            // Add job details fields
            addField('Job Role:', data.job_role, 40);
            addField('Location:', data.location, 50);
            addField('Experience:', data.experience, 60);
            addField('Salary:', data.salary, 70);
            addField('Job Type:', data.job_type, 80);
            addField('JD ID:', data.jd_id, 90);

            // Job Description Sections
            let yPos = 110;
            const pageHeight = pdf.internal.pageSize.height;

            // Function to check for page break
            const checkPageBreak = () => {
                if (yPos >= pageHeight - 20) {
                    pdf.addPage();
                    yPos = 20;
                }
            };

            // Helper function for adding sections
            const addSection = (title, content) => {
                if (content) {
                    checkPageBreak();
                    pdf.setFontSize(14);
                    pdf.setFont(undefined, 'bold');
                    pdf.setFontSize(12);
                    pdf.text(title, 20, yPos);
                    pdf.setFont(undefined, 'normal');
                    pdf.setFontSize(10);
                    yPos += 10;

                    const lines = pdf.splitTextToSize(toString(content), 170);
                    lines.forEach(line => {
                        checkPageBreak();
                        pdf.text(line, 20, yPos);
                        yPos += 6;
                    });
                    yPos += 10;
                }
            };

            // Add job description sections
            if (data.job_description) {
                addSection('About Us:', data.job_description.about_us);
                addSection('Job Overview:', data.job_description.job_overview);
                
                // Key Responsibilities with bullet points
                // Key Responsibilities
        if (data.job_description.key_responsibilities) {
            checkPageBreak();
            pdf.setFontSize(12);
            pdf.setFont(undefined, 'bold');
            pdf.text('Key Responsibilities:', 20, yPos);
            pdf.setFont(undefined, 'normal');
            pdf.setFontSize(10);
            yPos += 10;

            const responsibilities = cleanText(data.job_description.key_responsibilities).split('\n');
            responsibilities.forEach(resp => {
                if (resp.trim()) {
                    const lines = pdf.splitTextToSize(resp.trim(), 160);
                    lines.forEach((line, index) => {
                        checkPageBreak();
                        pdf.text(line, 25, yPos);
                        yPos += 6;
                    });
                }
            });
            yPos += 10;
        }

        // Qualifications
        if (data.job_description.required_qualification_experience) {
            checkPageBreak();
            pdf.setFontSize(12);
            pdf.setFont(undefined, 'bold');
            pdf.text('Required Qualifications/Experience:', 20, yPos);
            pdf.setFont(undefined, 'normal');
            pdf.setFontSize(10);
            yPos += 10;

            const qualifications = cleanText(data.job_description.required_qualification_experience).split('\n');
            qualifications.forEach(qual => {
                if (qual.trim()) {
                    const lines = pdf.splitTextToSize(qual.trim(), 160);
                    lines.forEach((line, index) => {
                        checkPageBreak();
                        pdf.text(line, 25, yPos);
                        yPos += 6;
                    });
                }
            });
            yPos += 10;
        }

        // Preferred Skills
        if (data.job_description.preferred_skills) {
            checkPageBreak();
            pdf.setFontSize(12);
            pdf.setFont(undefined, 'bold');
            pdf.text('Preferred Skills:', 20, yPos);
            pdf.setFont(undefined, 'normal');
            pdf.setFontSize(10);
            yPos += 10;

            const skills = cleanText(data.job_description.preferred_skills).split('\n');
            skills.forEach(skill => {
                if (skill.trim()) {
                    const lines = pdf.splitTextToSize(skill.trim(), 160);
                    lines.forEach((line, index) => {
                        checkPageBreak();
                        pdf.text(line, 25, yPos);
                        yPos += 6;
                    });
                }
            });
        }

            }

            // Footer
            pdf.setFontSize(10);
            pdf.setTextColor(128, 128, 128);
            pdf.text('Generated by BotGuru', 20, pdf.internal.pageSize.height - 10);
            
            // Save the PDF
            const fileName = `JD_${data.job_role.replace(/\s+/g, '_')}_${new Date().toISOString().split('T')[0]}.pdf`;
            pdf.save(fileName);
            setIsTourOpen(false); // Close the tour when the user clicks on the download button
        });
    };

    const handleFileChange = (event) => {
        console.log("handleFileChange triggered");  // Debug log 1
        
        const file = event.target.files[0];
        console.log("Files object:", event.target.files);  // Debug log 2
        
        if (!file) {
            console.error("No file selected.");
            return;
        }
    
        console.log("File selected:", file);  // This is your existing log
        handleFileUpload(file);
        
        console.log("About to reset input value");  // Debug log 3
        event.target.value = null;
        console.log("Input value after reset:", event.target.value);  // Debug log 4
        handleElementClick(event, () => {
            const file = event.target.files[0];
            if (!file) {
                console.error("No file selected.");
                return;
            }

            console.log("File selected:", file);
            handleFileUpload(file);
            event.target.value = null; // Reset file input
        });
    };

    const handleFileUpload = (file) => {

        setIsUploading(true); // Set only when uploading a file
        setCreateModalOpen(true);

        console.log("Initializing WebSocket for File Upload...");
        const websocketInstance = new WebSocket(websocketUrlForFileUpload);

        websocketInstance.onopen = () => {
            console.log("WebSocket connection established for File Upload.");
            const message = { action: "botguru_sendmessage", message: "get data" };
            sendWebSocketMessageWithRetry(websocketInstance, message);
        };

        websocketInstance.onmessage = (event) => handleWebSocketMessageForFileUpload(event, file);

        websocketInstance.onerror = (error) => {
            console.error("WebSocket error for File Upload:", error);
        };

        websocketInstance.onclose = () => {
            console.log("WebSocket connection closed for File Upload.");
        };
    };

    const sendWebSocketMessageWithRetry = (websocketInstance, message, retries = 3, interval = 1000) => {
        let attempts = 0;

        const sendMessage = () => {
            if (websocketInstance.readyState === WebSocket.OPEN) {
                websocketInstance.send(JSON.stringify(message));
                console.log("WebSocket message sent:", message);
            } else if (attempts < retries) {
                attempts++;
                console.log(`WebSocket not ready. Retrying (${attempts}/${retries})...`);
                setTimeout(sendMessage, interval);
            } else {
                console.error("Failed to send WebSocket message after multiple attempts.");
            }
        };

        sendMessage();
    };

    const handleWebSocketMessageForFileUpload = async (event, file) => {
        try {
            const data = JSON.parse(event.data);
            console.log("WebSocket message received:", data);

            if (data.connectionid) {
                try {
                    await authService.jobDescriptionExtractor({
                        file,
                        connectionId: data.connectionid,
                        user_obj_id:userObjId,
                    });
                } catch (error) {
                    console.error("Error uploading file:", error);
                    setNotificationMessage(error?.data.error);
                    setShowNotification(true);
                    setLoading(false);
                    setCreateModalOpen(false);
                }
            }

            if (data.message) {
                try {
                    const parsedMessage = JSON.parse(data.message);
                    console.log("Parsed Message:", parsedMessage);
                    // Update form data
                    setFormData(prev => ({
                        ...prev,
                        "job_role": parsedMessage.job_role || prev["job_role"],
                        company: parsedMessage.company || prev.company,
                        job_type: parsedMessage.job_type || prev.job_type,
                        experience: parsedMessage.job_description.experience || prev.experience,
                        salary: parsedMessage.job_description.salary || prev.salary,
                        location: parsedMessage.job_description.location || prev.location,  
                        job_description: {
                            ...prev.job_description,
                            about_us: parsedMessage.job_description.about_us || prev.job_description.about_us,
                            job_overview: parsedMessage.job_description.job_overview || prev.job_description.job_overview,
                            key_responsibilities: parsedMessage.job_description.key_responsibilities || prev.job_description.key_responsibilities,
                            required_qualification_experience: parsedMessage.job_description.required_qualification_experience || prev.job_description.required_qualification_experience,
                            preferred_skills: parsedMessage.job_description.preferred_skills || prev.job_description.preferred_skills
                        },
                        jd_id: generateRandom5DigitNumber()
                    }));
                    setIsUploading(false); // Ensure reset when closed
                } catch (parseError) {
                    console.error("Error parsing message:", parseError);
                }
            }
        } catch (error) {
            console.error("Error handling WebSocket message:", error);
        }
    };



    const openModal = (_id) => {
        setSelectedJdId(_id);
        setModalOpen(true);
    };

    const openEditModal = (_id) => {
        setSelectedJdId(_id);
        setEditModalOpen(true);
        setIsTourOpen(false); // Close the tour when the user clicks on the edit button
    };


    const truncateJobRole = (jobRole) => {
        const maxLength = 25;
        const words = jobRole.split(' ');
    
        if (jobRole.length > maxLength || words.length > 2) {
            return `${jobRole.substring(0, maxLength - 3)}...`; // Truncate and add ellipsis
        }
        return jobRole; // Return original if within limits
    };
    const truncateCompany = (company) => {
        const maxLength = 10;
        const words = company.split(' ');
    
        if (company.length > maxLength || words.length > 2) {
            return `${company.substring(0, maxLength - 3)}...`; // Truncate and add ellipsis
        }
        return company; // Return original if within limits
    };
    const truncateJobType = (job_type) => {
        const maxLength = 10;
        const words = job_type.split(' ');
    
        if (job_type.length > maxLength || words.length > 2) {
            return `${job_type.substring(0, maxLength - 3)}...`; // Truncate and add ellipsis
        }
        return job_type; // Return original if within limits
    };
    
    const handleUploadJDClick = () => {
        handleElementClick(null, () => {
            document.getElementById('fileInput').click();
            if (newUser === 'Y') {
                setIsTourOpen(false); // Only close tour if it was open (new user)
            }
        });
    };

    const handleCreateJDClick = () => {
        handleElementClick(null, () => {
            setCreateModalOpen(true);
            if (newUser === 'Y') {
                setIsTourOpen(false); // Only close tour if it was open (new user)
            }
        });
    };

    const renderCard = (data) => {
        return (
            <div className="jd-guru-card" key={data._id}>
                        <div className="jd-user-info">
                        {/* <i className="fas fa-barcode resume-icon"></i>                             */}
                        <span className="resume-id-badge">
                        {/* <i className="fas fa-bolt id-icon"></i> */}
                        <i className="fas fa-star id-icon"></i>
                        <span className="jd-id-number">{data.jd_id}</span>
                        </span>    

                        <span className="jd-file-type" onClick={(e) => handleElementClick(e, () => {
                            e.stopPropagation(); // Prevent event bubbling
                            openEditModal(data._id);
                        })}>
                            <i className="fas fa-pen"></i>
                        </span>
                        </div>

                        <div className="jd-job-title">
                            {truncateJobRole(data.job_role)}
                        </div>
                        <div className="jd-info-row">
                            <div className="jd-info-item">
                                <span className="jd-info-label">Last Updated</span>
                                <div className="jd-info-value">
                                    <i className="far fa-clock"></i>
                                    {new Date(data.created_at).toLocaleDateString('en-US', { day:'numeric',year: 'numeric', month: 'short' })}
                                </div>
                            </div>
                            <div className="jd-info-item">
                                <span className="jd-info-label">Company</span>
                                <div className="jd-info-value">
                                    <i className="fas fa-briefcase"></i>
                                    {truncateCompany(data.company)||'-'}
                                </div>
                            </div>
                            <div className="jd-info-item">
                                <span className="jd-info-label">Job Type</span>
                                <div className="jd-info-value">
                                    <i className="fas fa-briefcase"></i>
                                    {truncateJobType(data.job_type)||'-'}
                                </div>
                            </div>
                            <div className="jd-info-item">
                                <span className="jd-info-label">Experience</span>
                                <div className="jd-info-value">
                                    <i className="fas fa-briefcase"></i>
                                    {data.experience||'-'}
                                </div>
                            </div>

                        </div>
                        <div className="card-footer">
                        {/* <div className="resume-id">ID: {resume.personalDetails.resumeId}</div> */}
                        <div className="actions">
                                <button 
                                    className="action-btn"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        openModal(data._id);
                                    }}
                                >
                                    <i className="fas fa-eye"></i>
                                    View
                                </button>
                                <button 
                                    className="action-btn download"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleDownloadPDF(data);
                                    }}
                                >
                                <i className="fas fa-download"></i>
                                Download
                                </button>
                            </div>
                        </div>

                {/* <div className="jd-guru-top">
                <div className="jd-guru-role-edit">
                <h3>{truncateJobRole(data.job_role) || "Role Title"}</h3>
                <i 
                    className="fas fa-pen jd-guru-edit-icon" 
                    onClick={(e) => {
                    e.stopPropagation(); // Prevent event bubbling
                    openEditModal(data._id);
                    }}
                ></i>
                </div>
                <div className="jd-guru-company">
                        <span className="jd-guru-company-badge">{truncateCompany(data.company) || "Company Name"}</span>
                        <div className="jd-guru-type">
                            {truncateJobType(data.job_type) || "Full Time"}
                        </div>
                    </div>
                </div>

                <div className="jd-guru-details">
                    <div className="jd-guru-detail-item">
                        <i className="fas fa-map-marker-alt"></i>
                        {data.location || "-"}
                    </div>
                    <div className="jd-guru-detail-item">
                        <i className="fas fa-briefcase"></i>
                        {data.experience || "-"}
                    </div>
                    <div className="jd-guru-detail-item">
                        <i className="fas fa-dollar-sign"></i>
                        {data.salary || "-"}
                    </div>
                </div>

                <div className="jd-guru-actions">
                    <button 
                        className="jd-action-btn preview-jd"
                        onClick={(e) => {
                            e.stopPropagation();
                            openModal(data._id);
                        }}
                    >
                        <i className="fas fa-eye"></i>
                        Preview
                    </button>
                    <button 
                        className="jd-action-btn download-jd" onClick={(e) => {
                            e.stopPropagation();
                            handleDownloadPDF(data);
                        }}
                    >
                        <i className="fas fa-download"></i>
                        Download
                    </button>
                </div> */}
            </div>
        );
    }; 

    const NewJDCard = () => (
        <div className="resume-card create-card" onClick={handleCreateJDClick}>
            <div className="resume-user-info">
            <i className="fas fa-plus-circle user-icon"></i>
            <span className="name">Create New JD</span>
                    </div>
                    <div className="create-content">
                    <i className="fas fa-file-alt feature-icon"></i>
                    <div className="job-title">Create New JD</div>
                        <p className="create-description">
                        Start creating a new job description
                        </p>
                    </div>
                    <div className="card-footer">
                        <button 
                    className="action-btn create-btn create-jd-button">
                         <i className="fas fa-cloud-upload-alt"></i>
                    Create New JD
                </button>
            </div>
        </div>
    );

    // Calculate the total sessions available by summing prepbot and mock_bot totals
    const totalSessions = (userAnalytics?.prepbot_total || 0) + (userAnalytics?.mockbot_total || 0);
    const usedSessions = (userAnalytics?.prepbot_used || 0) + (userAnalytics?.mockbot_used || 0);

    // Determine if the user has available sessions
    const hasSessions = totalSessions > usedSessions;

    const UploadJDCard = () => (
        
        <div className="resume-card upload-card" onClick={handleUploadJDClick}>
           <div className="resume-user-info">
                        <i className="fas fa-cloud-upload-alt user-icon"></i>
                        <span className="name">Upload JD</span>
                    </div>
                    <div className="upload-content">
                        <i className="fas fa-file-upload feature-icon"></i>
                        <div className="job-title">Import existing JD</div>
                        <p className="upload-description">
                            Supported formats: PDF, DOCX (Max 5 pages)
                        </p>
                    </div>
                    <div className="card-footer">
                        <input
                            id="fileInput"
                            type="file"
                            accept=".docx,.pdf"
                            style={{ display: 'none' }}
                            onChange={handleFileChange}
                        />
                        <button 
                            className="action-btn upload-btn upload-jd-button"
                            // onClick={handleUploadJDClick}
                        >
                            <i className="fas fa-cloud-upload-alt"></i>
                            Upload JD
                        </button>
             </div>
         </div>
    );

    const InvalidCard = () => (
        <div className="resume-card resume-invalid-card">
            <div className="prep-session-card-header">JD Upload</div>
            <div className="prep-session-card-logo">
                <div className="sub-container">
                    <Sparkles />
                    <p>Unlock the JD upload</p>
                </div>
            </div>
            <div className="prep-session-card-features">
                <p className="prep-session-card-feature">
                    <Zap className="prep-session-card-icon"/>
                    Upload your JD
                </p>
                <p className="prep-session-card-feature">
                    <Trophy className="prep-session-card-icon"/>
                    Personalized feedbacks
                </p>
            </div>
            <button 
                className="create-session-button" 
                onClick={() => navigate("/pricing", { state: { activeTabName: 'AI JD Generator' } })}
            >
                Unlock Premium
                <MoveRight />
            </button>
        </div>
    );

    return (
        <div className="jd-guru-page" style={{position:"relative"}}>
            <div className="hero-section">
                <div className="hero-content">
                    <div className="hero-left">
                        <h1 className="hero-title">
                            Your Personalized JD Bot
                        </h1>
                        <div className="hero-description">
                            <span>Create clear, engaging job descriptions in minutes! Use our AI-powered platform to customize templates and highlight essential skills. 
                            Generate professional JDs with perfect formatting and ensure consistency using JDExpert!</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="jd-guru-container" style={{position:"relative"}}>
                {/* {loading ? (
                    <p>Loading...</p>
                ) : ( */}
                    <>
                        {hasSessions ? <UploadJDCard /> : <InvalidCard />}
                        <NewJDCard />
                        {jdData.map((jd) => renderCard(jd))}
                    </>
                {/* )} */}
            {showTourBtn && <div className="show-jd-tour-btn" onClick={()=>setIsTourOpen(true)}>
                            <Info />
                            <span>View Tour</span>
            </div>}
            </div>
            <JdDetailsModal 
                isOpen={isModalOpen} 
                closeModal={() => setModalOpen(false)} 
                jdId={selectedJdId} 
                refreshJDList={fetchJobRoles}
                jdData={jdData} 
            />
            <JDEditableModal 
                isOpen={isEditModalOpen} 
                closeModal={() => setEditModalOpen(false)} 
                jdId={selectedJdId} 
                refreshJDList={fetchJobRoles}
                jdData={jdData}
            />
            <JDCreateModal
                isOpen={isCreateModalOpen}
                closeModal={() => setCreateModalOpen(false)}
                refreshJDList={fetchJobRoles}
                initialFormData={formData} 
                isLoading={isUploading}
            />
            {showNotification && (
                <Notification 
                    message={notificationMessage}
                    onClose={() => setShowNotification(false)}
                />
            )}
            <Tour
                steps={tourSteps}
                isOpen={isTourOpen}
                onRequestClose={() => setIsTourOpen(false)}
                getCurrentStep={(curr) => setCurrentStep(curr)}
                showNumber={true}
                showButtons={true}
                showNavigation={true}
                disableInteraction={false}
                disableKeyboardNavigation={true}
                showNavigationNumber={true}
                badgeContent={(curr, tot) => `${curr} of ${tot}`}
                accentColor="#BE05FA"
                styles={{
                    options: {
                        backgroundColor: '#0e1428',
                        borderColor: '#a86cda',
                        textColor: '#ffffff',
                        arrowColor: '#a86cda',
                    },
                    badge: (base) => ({
                        ...base,
                        backgroundColor: '#a86cda',
                        padding: '4px 8px',
                        borderRadius: '4px',
                        fontSize: '14px',
                        marginTop: '10px',
                        color: '#ffffff',
                    }),
                    navigation: (base) => ({
                        ...base,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        padding: '10px 20px',
                    }),
                    navigationNumber: (base) => ({
                        ...base,
                        fontSize: '14px',
                        color: '#ffffff',
                        margin: '0 10px',
                    }),
                }}
                nextButton={
                    <button 
                        onClick={handleNext} 
                        style={{ borderRadius: '20px', cursor: currentStep === tourSteps.length - 1 ? 'not-allowed' : 'pointer' }} 
                        disabled={currentStep === tourSteps.length - 1}
                    >
                        Next
                    </button>
                }
                prevButton={
                    <button 
                        onClick={handlePrev} 
                        style={{ borderRadius: '20px', cursor: currentStep === 0 ? 'not-allowed' : 'pointer' }} 
                        disabled={currentStep === 0}
                    >
                        Previous
                    </button>
                }
                lastStepNextButton={
                    <button 
                        onClick={() => setIsTourOpen(false)}
                        style={{ 
                            borderRadius: '20px',
                            cursor: 'pointer'
                        }}
                    >
                        End Tour!
                    </button>
                }
            />
        </div>
    );
};

export default JDGuruPage;