import React, {useState,useEffect}from "react";
import "./MyAccountModal.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faSpinner,faUser
} from '@fortawesome/free-solid-svg-icons';
import { useAuthContext } from '../Auth/AuthContext';
import { CircleCheckBig, Crown, Gift, Goal, Rocket, Sliders } from "lucide-react";
import { useNavigate } from "react-router-dom";
import config from "../../config.json";

function formatPhoneNumber(phoneNumber) {
  const cleaned = ('' + phoneNumber).replace(/\D/g, '');

  // Adjust regex to handle country code if necessary
  const match = cleaned.match(/^(\d{1,3})?(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    const countryCode = match[1] ? `+${match[1]} ` : '';
    return `${countryCode}(${match[2]}) ${match[3]}-${match[4]}`;
  }

  return phoneNumber;
}

const MyAccountModal = ({handleSignOut}) => {
  const {userFirstName,userObjId, profileData, setProfileData } = useAuthContext(); 
    const [skills, setSkills] = useState(profileData.skills || []);
    const [skillInput, setSkillInput] = useState('');
    const [isEditingSkills, setIsEditingSkills] = useState(false);
    const [tempSkills, setTempSkills] = useState([...skills]); 

    const [interestedDomains, setInterestedDomains] = useState(profileData?.target_roles || []);
    const [domainInput, setDomainInput] = useState('');
    const [isEditingDomains, setIsEditingDomains] = useState(false);
    const [tempDomains, setTempDomains] = useState([...interestedDomains]);

    const [profileImage, setProfileImage] = useState(profileData?.profile_image); 
    const [isUploading, setIsUploading] = useState(false);
    const [tempImageUrl, setTempImageUrl] = useState(null);
    const [imageLoading, setImageLoading] = useState(false);
    const [skillError, setSkillError] = useState(""); // Store validation error message
    const [domainError, setDomainError] = useState(""); // Store validation error message
    const [isEditingProfile, setIsEditingProfile] = useState(false);
    const [editedName, setEditedName] = useState( `${profileData.first_name} ${profileData.last_name}`);
    const [editedPhone, setEditedPhone] = useState(profileData.phone_number);
    const [showDeleteIcon, setShowDeleteIcon] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [imageFlag, setImageFlag] = useState("N");

    const [localProfileData, setLocalProfileData] = useState({...profileData});



    const navigate=useNavigate();
    const formattedPhoneNumber = formatPhoneNumber(localProfileData.phone_number);



    useEffect(() => {
      // Update local profile data whenever profileData changes
      setLocalProfileData({...profileData});
      setEditedName(`${profileData.first_name} ${profileData.last_name}`);
      setEditedPhone(profileData.phone_number);
      setSkills(profileData.skills || []);
      setTempSkills(profileData.skills || []);
      setInterestedDomains(profileData.target_roles || []);
      setTempDomains(profileData.target_roles || []);
    }, [profileData]);
  

           // Function to preload image
           const preloadImage = (url) => {
            return new Promise((resolve, reject) => {
                const img = new Image();
                img.src = url;
                img.onload = () => resolve(url);
                img.onerror = () => reject(new Error('Image load failed'));
            });
        };
    
        useEffect(() => {
            if (profileData.profile_image) {
                setImageLoading(true);
                preloadImage(profileData.profile_image)
                    .then((loadedUrl) => {
                        setProfileImage(loadedUrl);
                    })
                    .catch(() => {
                        setProfileImage(null);
                    })
                    .finally(() => {
                        setImageLoading(false);
                    });
            }
        }, [profileData.profile_image]);
    
    
        // Preload newly uploaded image
            useEffect(() => {
              if (tempImageUrl) {
                  setImageLoading(true);
                  preloadImage(tempImageUrl)
                      .then((loadedUrl) => {
                          setProfileImage(loadedUrl);
                          setTempImageUrl(null);
                      })
                      .catch(() => {
                          setProfileImage(null);
                      })
                      .finally(() => {
                          setImageLoading(false);
                      });
              }
          }, [tempImageUrl]);
    
      const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedImage(file);
            setProfileImage(URL.createObjectURL(file));
            setImageFlag("Y");
        }
    };
    
    const handleImageDelete = () => {
        setProfileImage(null);
        setSelectedImage(null);
        setImageFlag("N");
    };
      
        const triggerFileInput = () => {
          document.getElementById('fileInput').click();
      };


      const toggleEditModes = () => {
        const newEditState = !isEditingSkills || !isEditingDomains;
        setIsEditingSkills(newEditState);
        setIsEditingProfile(newEditState);
        setIsEditingDomains(newEditState);
        setTempSkills([...skills]);
        setTempDomains([...interestedDomains]);
        setShowDeleteIcon(newEditState);
    };
  
  const handleProfileUpdate = async () => {
    const [firstName, lastName] = editedName.split(" ");
    const formData = new FormData();
    formData.append("user_obj_id", userObjId);
    formData.append("first_name", firstName || "");
    formData.append("last_name", lastName || "");
    formData.append("phone_number", editedPhone || "");
    formData.append("skills", tempSkills.length > 0 ? tempSkills.join(",") : "");
    formData.append("target_roles", tempDomains.length > 0 ? tempDomains.join(",") : "");
    if (selectedImage) {
      formData.append("image", selectedImage);
  } else if (!profileImage) {
      formData.append("image_flag", "N"); // Only set when image is deleted or does not exist
  }

  
    try {
        const response = await fetch(`${config.restAPI}/updateUserProfile/`, {
            method: "POST",
            credentials: "include",
            body: formData,
        });
        if (!response.ok) throw new Error("Profile update failed");
        const data = await response.json();
  // Update the local state first before updating the context
  const updatedLocalProfileData = {
    ...profileData,
    first_name: firstName || "",
    last_name: lastName || "",
    phone_number: editedPhone || "",
    skills: tempSkills,
    target_roles: tempDomains,
    profile_image: data.profile_image || profileData.profile_image,
    // Preserve other fields that might be returned from the API
    ...data
  };
  
  // Update local data for immediate display
  setLocalProfileData(updatedLocalProfileData);
  
  // Update skills and domains state
  setSkills(tempSkills);
  setInterestedDomains(tempDomains);
  
  // Update the context (this may trigger a re-render later)
  setProfileData(updatedLocalProfileData);
        setIsEditingProfile(false);
        setIsEditingSkills(false);
        setIsEditingDomains(false);
        setShowDeleteIcon(false);
    } catch (error) {
        console.error("Error updating profile:", error);
    }
  };
  

    const handleSkillInputChange = (e) => {
      setSkillInput(e.target.value);
      if (e.target.value.trim() === "") {
        setSkillError("");
    }
    };

    const handleSkillAdd = (e) => {
      if (e.key === 'Enter' && skillInput.trim()) {
          let newSkill = skillInput.trim();
  
                 // Only change the first letter to uppercase if it is lowercase
        if (newSkill.charAt(0) === newSkill.charAt(0).toLowerCase()) {
          newSkill = newSkill.charAt(0).toUpperCase() + newSkill.slice(1);
      }
  
          // Check if the skill already exists (case-insensitive)
          const skillExists = tempSkills.some(skill => skill.toLowerCase() === newSkill.toLowerCase());
          if (skillExists) {
            setSkillError("  * Skill already added");
              return;
          }
  
          // Prevent adding more than 20 skills
          if (tempSkills.length >= 20) {
            setSkillError(" * More than 20 skills cannot be added");
              return;
          }
  
          // Ensure the skill length does not exceed 100 characters
          if (newSkill.length > 100) {
            setSkillError(" * Each skill must be 100 characters or less");
              return;
          }
          setSkillError("");
          setTempSkills([...tempSkills, newSkill]);
          setSkillInput('');
      }
  };
  

    const handleSkillRemove = (skillToRemove) => {
      setTempSkills(tempSkills.filter(skill => skill !== skillToRemove));
    };

    const handleDomainInputChange = (e) => {
      setDomainInput(e.target.value);
          // Remove error when input is empty
      if (e.target.value.trim() === "") {
          setDomainError("");
      }
  };

  const handleDomainAdd = (e) => {
      if (e.key === 'Enter' && domainInput.trim()) {

        let newDomain = domainInput.trim();

        // ✅ Only capitalize the first letter if it's lowercase (leave the rest unchanged)
        if (newDomain.charAt(0) === newDomain.charAt(0).toLowerCase()) {
          newDomain = newDomain.charAt(0).toUpperCase() + newDomain.slice(1);
        }

        // Check if the skill already exists (case-insensitive)
        const domainExists = tempDomains.some(domain => domain.toLowerCase() === newDomain.toLowerCase());
        if (domainExists) {
          setDomainError("Domain already added");
            return;
        }

        // Prevent adding more than 20 skills
        if (tempDomains.length >= 20) {
          setDomainError("More than 20 Domains cannot be added");
            return;
        }

        // Ensure the skill length does not exceed 100 characters
        if (newDomain.length > 50) {
          setDomainError("Each Domain must be 100 characters or less");
            return;
        }

          setDomainError("");
          setTempDomains([...tempDomains, newDomain]);
          setDomainInput('');

      }
  };

  const handleDomainRemove = (domainToRemove) => {
      setTempDomains(prevDomains => prevDomains.filter(domain => domain !== domainToRemove));
  };

  

  const renderSubscriptionDetails = (details) => {
        return (
        <div className="subscription-box">
          <div className="plan-header">
            {
              Object.entries({"prepbot":"PrepBot", "mockbot":"MockBot", "resume":"ResumeExpert", "jd":"JDExpert"}).map(([key,value], index)=>{
                if(key===details?.session_type){
                  return (<p className="session-name"> {value}</p>)
                }
              })
            }
              <span> - </span>
              <p className="plan-type"> {details.plan_type} Plan {
              details.plan_type==="free-trial" ? <Gift />
              : details.plan_type==="starter" ? <Rocket /> : <Goal />
              }
            </p>
              
          </div>
          <div className="plan-details">
            <div className="plan-info">
            {details.status==="active" && <p className="status active">{details?.status.slice(0,1).toUpperCase()+details?.status.slice(1)}</p>}
                  <p className="plan-price">${details.price}</p>
                  { (details.session_type==="prepbot") || (details.session_type==="mockbot")
                      ? <p className="sessions-count">{details?.sessions} {details?.sessions===1 ? "session" : "sessions"}</p>
                      : (details.session_type==="resume") ? <p className="sessions-count">{details?.resume} Resumes</p>
                      : <p className="sessions-count">{details?.jd} Job Descriptions</p>
                  }
            </div> 
          </div>
        </div>
        )
    };


    const renderTags = (tags) => (
      <div className="tags">
          {tags.map((tag, index) => <span key={index}>{tag}</span>)}
      </div>
    );

  return (
    <div className="account-modal-content">
      <div className="profile-header">
        <div className="profile-section">
          <div className="user-profile-image">
            <div className="camera-icon-container">
                        {(isUploading || imageLoading) ? (
                            <div className="loading-spinner-container">
                                <FontAwesomeIcon 
                                    icon={faSpinner} 
                                    spin 
                                    className="img-loading-spinner" 
                                />
                            </div>        
                        ) : (
                            <>
                            {profileImage ? (
                                <img 
                                    src={profileImage}  
                                    className="profile-image" 
                                    onError={(e) => {
                                    e.target.onerror = null; 
                                    setProfileImage(null); 
                                    }} 
                                  />
                                  ) : (
                                  <FontAwesomeIcon 
                                    icon={faUser} 
                                    className="default-user-icon" 
                                  />
                                  )}
                                   {showDeleteIcon && profileImage && (
                                     <div className="delete-icon" onClick={handleImageDelete}>
                            <i className="fas fa-trash delete-icon"></i>
                            </div>
                        )}
                        {isEditingProfile &&(
                                <div className="camera-icon" onClick={triggerFileInput}>
                                    <i className="fas fa-camera"></i>
                                </div>
                        )}
                            </>
                        )}
                        <input
                            type="file"
                            id="fileInput"
                            style={{ display: 'none' }}
                            onChange={handleFileChange}
                            disabled={isUploading || imageLoading}
                        />
                        </div>
          </div>
          <div className="myaccount-info">
          {isEditingProfile ? (
                        <input 
                            type="text" 
                            value={editedName} 
                            className="user-skills"
                            onChange={(e) => setEditedName(e.target.value)}
                        />
                    ) : (
                        <h2>{localProfileData.first_name} {localProfileData.last_name}</h2>
                    )}
              <p><i className="fas fa-envelope"></i> {localProfileData.email}</p>
              {isEditingProfile ? (
                        <input 
                            type="text" 
                            value={editedPhone} 
                            className="user-skills"
                            onChange={(e) => setEditedPhone(e.target.value)}
                        />
                    ) : (
                        <p><i className="fas fa-phone"></i> {formattedPhoneNumber}</p>
                    )}
          </div>
        </div>
        <div className="profile-options">
        {isEditingProfile && <button className="skills-save-button" onClick={handleProfileUpdate}>Save</button>}
          <i className="fas fa-edit"  onClick={toggleEditModes} style={{cursor:"pointer"}}></i>
          <i className="fas fa-sign-out sign-out-icon" onClick={handleSignOut} style={{cursor:"pointer"}}></i>
        </div>
      </div>
<div className="profile-details-section">
      <div className="user-profile-details">
        <div className="info-box">
          <div className="skills-info-header">
          <div className="skills-title">
              <h3><i className="fas fa-code"></i> Skills </h3>
              {skillError && <span className="account-skill-error">{skillError}</span>} {/* Error beside h3 */}
          </div>
            {/* {isEditingSkills && (
            <button onClick={() => saveProfileChanges("skills")} className="skills-save-button">Save</button>
            )} */}
          </div>
          {isEditingSkills ? (
          <div className="skill-content-box">
            <input
              type="text"
              className="user-skills"
              value={skillInput}
              onChange={handleSkillInputChange}
              onKeyDown={handleSkillAdd}
              placeholder="Enter skills and press Enter"
            />
            {tempSkills.length > 0 && (
            <div className="user-skills-container">
              {tempSkills.map((skill, index) => (
                <button key={index} className="user-skill-button">
                  <span className="user-skill-text">{skill}</span>
                  <span className="user-remove-skill" onClick={() => handleSkillRemove(skill)}> &times; </span>
                </button>
              ))}
            </div>
          )}
          </div>
            ) : (
               renderTags(skills)
            )}
        </div>
        <div className="info-box">
          <div className="skills-info-header">
            <div className="skills-title">
            <h3><i className="fas fa-book-open"></i> Interested Job Roles</h3>
              {domainError && <span className="account-skill-error">{domainError}</span>} {/* Error beside h3 */}
          </div>
            {/* {isEditingDomains && (
              <button onClick={() => saveProfileChanges("domains")} className="skills-save-button">Save</button>
            )} */}
          </div>
          {isEditingDomains ? (
          <div className="skill-content-box">
            <input
              type="text"
              className="user-skills"
              value={domainInput}
              onChange={handleDomainInputChange}
              onKeyDown={handleDomainAdd}
              placeholder="Enter skills and press Enter"
            />
            {tempDomains.length > 0 && (
            <div className="user-skills-container">
              {tempDomains.map((domain, index) => (
                <button key={index} className="user-skill-button">
                  <span className="user-skill-text">{domain}</span>
                  <span className="user-remove-skill" onClick={() => handleDomainRemove(domain)}> &times; </span>
                </button>
              ))}
            </div>
          )}
          </div>
            ) : (
               renderTags(interestedDomains)
            )}
        </div>
      </div>
      {!isEditingProfile && (
      <div className="active-subscriptions">
        <div className="subscription-title">
          Active Subscriptions
          <button className="purchase-plan"
            onClick={()=>navigate("/pricing")}
            > Add Sessions
            {/* <Crown /> */}
            <Crown />
          </button>
          </div>
        <div className="subscription-section"> 
          {
            localProfileData.subscriptions && localProfileData?.subscriptions.map((subscription, index)=>{
              return (
                  renderSubscriptionDetails(subscription)
              )})
          } 
        </div>
      </div>
      )}
      </div>
   </div>
  );
};

export default MyAccountModal;