import React, { useState, useEffect } from "react";
import "./JDEditableModal.css";
import useAuthService from  '../../services/AuthService'
import { jsPDF } from "jspdf";
import JDLoadingScreen from '../LandingPages/JDLoadingScreen'
import { useAuthContext } from "../Auth/AuthContext";


const JDCreateModal = ({ isOpen, closeModal, refreshJDList,initialFormData,isLoading }) => {
    const authService = useAuthService();
    const now = new Date();
    const date = now.toISOString().split("T")[0]; // Extract date in YYYY-MM-DD format
    const time = now.toTimeString().split(" ")[0]; // Extract time in HH:MM:SS format
    const generateRandom5DigitNumber = () => {
        return Math.floor(10000 + Math.random() * 90000);
    };

    const { userObjId } = useAuthContext(); // Get userObjId from context

    const [formData, setFormData] = useState(initialFormData||{
        "job_role": "",
        company: "",
        job_type: "",
        job_description: {
            about_us: "",
            job_overview: "",
            preferred_skills: "",
            key_responsibilities: "",
            required_qualification_experience: ""
        },
        experience: "",
        salary: "",
        currency: "USD", // Add currency to formData
        location: "",
        date: date,
        time: time,
        jd_id: generateRandom5DigitNumber()
    });




    const [selectedFile, setSelectedFile] = useState(null); // State for selected file
    const [errorMessage, setErrorMessage] = useState(""); // State for error message
    const [loading, setLoading] = useState(false);
    const [fieldErrors, setFieldErrors] = useState({});
    const [aiGenerating, setAiGenerating] = useState(false); // State for AI generation loading
    const [aiGenerateError, setAiGenerateError]=useState("");

    useEffect(()=>{
        let timer;
        if(aiGenerateError.length){
            timer=setTimeout(()=>{
                setAiGenerateError("");
            }, 5000);
        }
        return () => clearTimeout(timer);
    }, [aiGenerateError])
    

    useEffect(() => {
        if (!isOpen) {
            // Clear field errors when the modal is closed
            setFieldErrors({});
        }
    }, [isOpen]);


    const websocketUrlForJDGen = "wss://o834xau6qe.execute-api.us-west-1.amazonaws.com/production/"; // WebSocket for Job Description generation
    const websocketUrlForFileUpload = "wss://o834xau6qe.execute-api.us-west-1.amazonaws.com/production/"; // WebSocket for File upload
    const user_id = "email8@email.com";

    const handleInputChange = (field, value) => {
        setFormData((prevData) => {
            if (['about_us', 'job_overview', 'preferred_skills', 'key_responsibilities', 'required_qualification_experience'].includes(field)) {
                return {
                    ...prevData,
                    job_description: {
                        ...prevData.job_description,
                        [field]: value
                    }
                };
            }
            return {
                ...prevData,
                [field]: value
            };
        });
    };

    useEffect(() => {
        if (isOpen && initialFormData) {
            setFormData(initialFormData);
        }
    }, [isOpen, initialFormData]);


    console.log("form data", formData);
    const validateForAIGenerate = () => {
        const { "job_role": jobRole, company: company, job_type: jobType, job_description } = formData;
        let errors = {};
    
        if (!jobRole) errors.jobRole = "Enter the Job/Role";
        else if (jobRole.length > 100) {
            errors.jobRole = "Job/Role cannot exceed 100 characters";
        }

        if (!company) errors.company = "Enter the Company";
        else if (company.length > 100) {
            errors.company = "Company cannot exceed 100 characters";
        }
       
        if (!jobType || jobType === "Select Job Type") errors.jobType = "Select the Job Type";


        if (job_description.key_responsibilities.length > 5000) {
            errors.keyResponsibilities = "Key Responsibilities cannot exceed 5000 characters";
        }

        if (job_description.about_us && job_description.about_us.length > 800) {
            errors.aboutUs = "About Us cannot exceed 800 characters";
        }
    
        if (job_description.job_overview && job_description.job_overview.length > 800) {
            errors.jobOverview = "Job Overview cannot exceed 800 characters";
        }
    
        if (job_description.preferred_skills && job_description.preferred_skills.length > 1000) {
            errors.preferredSkills = "Preferred Skills cannot exceed 1000 characters";
        }
    
        if (job_description.required_qualification_experience && job_description.required_qualification_experience.length > 1400) {
            errors.requiredQualificationExperience = "Required Qualifications/Experience cannot exceed 1400 characters";
        }  

        setFieldErrors(errors);
    
        return Object.keys(errors).length === 0;
    };

    // Validation for Save button
    const validateForSave = () => {
        const { "job_role": jobRole, company: company, job_type: jobType, job_description } = formData;
        let errors = {};
    
        if (!jobRole) errors.jobRole = "Enter the Job/Role";
        else if (jobRole.length > 100) {
            errors.jobRole = "Job/Role cannot exceed 100 characters";
        }

        if (!company) errors.company = "Enter the Company";
        else if (company.length > 100) {
            errors.company = "Company cannot exceed 100 characters";
        }

        if (!jobType || jobType === "Select Job Type") errors.jobType = "Select the Job Type";
        else if (jobType.length > 100) {
            errors.jobType = "Job Type cannot exceed 100 characters";
        }

        if (!job_description.key_responsibilities || !job_description.key_responsibilities.toString().trim()) {
            errors.keyResponsibilities = "Enter Key Responsibilities";
        }
        else if (job_description.key_responsibilities.length > 6000) {
            errors.keyResponsibilities = "Key Responsibilities cannot exceed 6000 characters";
        }

        if (job_description.about_us && job_description.about_us.length > 3000) {
            errors.aboutUs = "About Us cannot exceed 3000 characters";
        }
    
        if (job_description.job_overview && job_description.job_overview.length > 3000) {
            errors.jobOverview = "Job Overview cannot exceed 3000 characters";
        }
    
        if (job_description.preferred_skills && job_description.preferred_skills.length > 3000) {
            errors.preferredSkills = "Preferred Skills cannot exceed 3000 characters";
        }
    
        if (job_description.required_qualification_experience && job_description.required_qualification_experience.length > 3000) {
            errors.requiredQualificationExperience = "Required Qualifications/Experience cannot exceed 3000 characters";
        }    
    
        setFieldErrors(errors);
    
        return Object.keys(errors).length === 0;
    };

    const handleSubmit = async () => {
        if (!validateForSave()) {
            return; // Show the modal and stop submission
        }
        try {
            // Log the job details being saved
            console.log("Saving Job Details:", formData);
            // Prepare the payload for the API request
            const payload = {
                user_obj_id:userObjId,
                user_id: "email8@email.com",  // Replace with dynamic user ID if needed
                "job_role": formData["job_role"],
                company: formData["company"],
                job_type: formData["job_type"],
                job_description: formData.job_description,
                date: formData["date"],
                time: formData["time"],
                experience: formData.experience,
                salary: formData.salary,
                currency:formData.currency,
                location: formData.location,
                jd_id: generateRandom5DigitNumber()// Sending the job details
            };

            // Call the save API (you should ensure the API is correctly set up in your AuthService)
            const response = await authService.saveJobDetails(payload);

            // Log the response after the job details are saved
            console.log("Job Details Saved:", response);

            // Handle the response (you can show a success message or handle errors accordingly)
            if (response.error) {
                console.error("Error saving job details:", response.error);
                alert("Failed to save job details. Please try again.");
            } else {
                // alert("Job details saved successfully.");
                if (refreshJDList) {
                    await refreshJDList(); // Refresh JD list in PrepLaunchPod
                }
            }

            // Close the modal after submission
            closeModal();
        } catch (error) {
            // Log any errors that occur during the save process
            console.error("Error saving job details:", error);
            alert("An error occurred. Please try again.");
        }
    };

    useEffect(() => {
        if (isOpen) {
            setFormData({
                "job_role": "",
                company: "",
                job_type: "",
                 job_description: {
                about_us: "",
                job_overview: "",
                preferred_skills: "",
                key_responsibilities: "",
                required_qualification_experience: ""
                    },
                experience: "", 
                salary: "",
                location: "",
                date: new Date().toISOString().split("T")[0],
                time: new Date().toTimeString().split(" ")[0],
                jd_id: Math.floor(10000 + Math.random() * 90000),
            });
        }
    }, [isOpen]);


    const handleWebSocketMessageForJDGen = async (event) => {
        try {
            const data = JSON.parse(event.data);
            console.log("Message received:", data);

            const formatWithBullets = (text) => {
                if (!text) return '';
                return text
                    .split('\n')
                    .map(line => line.trim())
                    .map(line => line.replace(/^[\d.)\-–—]+\s*/, '• ')) // Replace numbers, dots, parentheses, and hyphens with bullets
                    .join('\n');
            };

            if (data.connectionid) {
                // Send connection ID to the backend
                await authService.JdAiGenerate({
                    job_role: formData["job_role"],
                    company_name: formData.company,
                    connectionId: data.connectionid,
                    bot_type:"jd_ai_generate",
                    job_details: {
                        about_us: formData.about_us || '',
                        job_overview: formData.job_overview || '',
                        preferred_skills: formData.preferred_skills || '',
                        key_responsibilities: formData.key_responsibilities || '',
                        required_qualification_experience: formData.required_qualification_experience || ''
                    },
                    user_obj_id: userObjId ,
                }).then(()=>{
                    console.log("Job description generated");
                })
                .catch(()=>{
                    console.log("Job description not generated");
                    setAiGenerating(false);
                    setAiGenerateError("AI JD Generate failed, Re-try!")
                })

            }


            // Extract specific fields and ensure they are in the right format
            if (data.message) {
                console.log("Message content:", data.message);

                const { about_us, job_overview, key_responsibilities, required_qualification_experience,preferred_skills } = data.message;

                // // Concatenate the values of the selected fields into a single string
                // let description = '';
                // if (about_us) description += `ABOUT US:\n${about_us}\n\n`;
                // if (job_overview) description += `Job Overview:\n ${job_overview}\n\n`;
                // if(preferred_skills)description += `Preferred Skills:\n ${preferred_skills}\n\n`;
                // if (key_responsibilities) description += `Key Responsibilities:\n ${key_responsibilities}\n\n`;
                // if (required_qualification_experience) description += `Required Qualifications/Experience:\n ${required_qualification_experience}\n\n`;

                // // Update the state with the extracted and formatted job description
                // setFormData((prevData) => ({
                //     ...prevData,
                //     job_description: description.trim(), // Clean up any trailing spaces or newlines
                // }));
                // Update the state with individual fields
    setFormData((prevData) => ({
        ...prevData,
        job_description: {
             
        about_us: about_us || '',
        job_overview: job_overview || '',
        preferred_skills: formatWithBullets(preferred_skills) || '',
        key_responsibilities: formatWithBullets(key_responsibilities) || '',
        required_qualification_experience: formatWithBullets(required_qualification_experience) || '',
        }
    }));
    setAiGenerating(false); // Start AI generation loading
}

        } catch (error) {
            console.error("Error processing Job Description WebSocket message:", error);
        }
    };

    const handleAIGenerate = () => {
        if (!validateForAIGenerate()) {
            return;
        }

        console.log("AI Generate button clicked");
        setAiGenerating(true); // Start AI generation loading
        // Debugging log
        console.log("Establishing a new WebSocket connection for Job Description Generation.");
        const websocketInstance = new WebSocket(websocketUrlForJDGen);

        websocketInstance.onopen = () => {
            console.log("WebSocket connection established for Job Description Generation");

            const initialMessage = { action: "botguru_sendmessage", message: "get data" };
            websocketInstance.send(JSON.stringify(initialMessage));
            console.log("Sent initial message for Job Description Generation:", initialMessage);
        };

        websocketInstance.onmessage = (event) => {
            handleWebSocketMessageForJDGen(event);
        };

        websocketInstance.onerror = (error) => {
            console.error("WebSocket error for Job Description Generation:", error);
            setAiGenerating(false); // Stop AI generation loading
        };

        websocketInstance.onclose = () => {
            console.log("WebSocket connection for Job Description Generation closed");
        };

    };

    const handleDownloadPDF = () => {
        if (!formData) return;
        const pdf = new jsPDF();
        
        // Set up PDF styling
        pdf.setTextColor(0, 0, 0);
        pdf.setFillColor(255, 255, 255);
        
        // Company Name - Centered, Bold, Underlined
        pdf.setFontSize(18);
        pdf.setFont(undefined, 'bold');
        const companyName = String(formData.company || '');;
        const companyNameWidth = pdf.getStringUnitWidth(companyName) * pdf.internal.getFontSize() / pdf.internal.scaleFactor;
        const centerX = (pdf.internal.pageSize.width - companyNameWidth) / 2;
        pdf.text(companyName, centerX, 20);
        pdf.setFont(undefined, 'normal');
        pdf.setLineWidth(0.5);
        pdf.line(centerX, 22, centerX + companyNameWidth, 22);
        
        // Date - Right Aligned
        const currentDate = new Date().toLocaleDateString();
        pdf.setFontSize(12);
        pdf.text(`Date: ${currentDate}`, pdf.internal.pageSize.width - 20, 30, { align: 'right' });

        // Job Details
        const titleX = 20;
        const valueX = 70;
        
        const addField = (label, value, y) => {
            pdf.setFontSize(14);
            pdf.setFont(undefined, 'bold');
            pdf.setFontSize(12);
            pdf.text(label, titleX, y);
            pdf.setFont(undefined, 'normal');
            pdf.setFontSize(10);
            pdf.text(String(value || 'Not Specified'), valueX, y);  // Convert value to string
        };

        addField('Job Role:', formData.job_role, 40);
        addField('Location:', formData.location, 50);
        addField('Experience:', formData.experience, 60);
        addField('Salary:', formData.salary, 70);
        addField('Job Type:', formData.job_type, 80);
        addField('JD ID:', formData.jd_id, 90);

        let yPos = 110;
        const pageHeight = pdf.internal.pageSize.height;

        const checkPageBreak = () => {
            if (yPos >= pageHeight - 20) {
                pdf.addPage();
                yPos = 20;
            }
        };

        // About Us
        if (formData.job_description?.about_us) {
            checkPageBreak();
            pdf.setFontSize(14);
            pdf.setFont(undefined, 'bold');
            pdf.setFontSize(12);
            pdf.text('About Us:', 20, yPos);
            pdf.setFont(undefined, 'normal');
            pdf.setFontSize(10);
            yPos += 10;

            const lines = pdf.splitTextToSize(String(formData.job_description.about_us), 170);            lines.forEach(line => {
                checkPageBreak();
                pdf.text(line, 20, yPos);
                yPos += 6;
            });
            yPos += 10;
        }

        // Job Overview
        if (formData.job_description?.job_overview) {
            checkPageBreak();
            pdf.setFontSize(14);
            pdf.setFont(undefined, 'bold');
            pdf.setFontSize(12);
            pdf.text('Job Overview:', 20, yPos);
            pdf.setFont(undefined, 'normal');
            pdf.setFontSize(10);
            yPos += 10;

            const lines = pdf.splitTextToSize(String(formData.job_description.job_overview), 170);
            lines.forEach(line => {
                checkPageBreak();
                pdf.text(line, 20, yPos);
                yPos += 6;
            });
            yPos += 10;
        }

        // Key Responsibilities
        if (formData.job_description?.key_responsibilities) {
            checkPageBreak();
            pdf.setFontSize(14);
            pdf.setFont(undefined, 'bold');
            pdf.setFontSize(12);
            pdf.text('Key Responsibilities:', 20, yPos);
            pdf.setFont(undefined, 'normal');
            pdf.setFontSize(10);
            yPos += 10;

            const responsibilities = String(formData.job_description.key_responsibilities).split('\n');
            responsibilities.forEach(resp => {
                if (resp.trim()) {
                    const lines = pdf.splitTextToSize(resp.trim(), 160);
                    lines.forEach(line => {
                        checkPageBreak();
                        pdf.text(line, 25, yPos);
                        yPos += 6;
                    });
                }
            });
            yPos += 10;
        }

        // Required Qualifications
        if (formData.job_description?.required_qualification_experience) {
            checkPageBreak();
            pdf.setFontSize(14);
            pdf.setFont(undefined, 'bold');
            pdf.setFontSize(12);
            pdf.text('Required Qualifications/Experience:', 20, yPos);
            pdf.setFont(undefined, 'normal');
            pdf.setFontSize(10);
            yPos += 10;

            const qualifications = String(formData.job_description.required_qualification_experience).split('\n');
            qualifications.forEach(qual => {
                if (qual.trim()) {
                    const lines = pdf.splitTextToSize(qual.trim(), 160);
                    lines.forEach(line => {
                        checkPageBreak();
                        pdf.text(line, 25, yPos);
                        yPos += 6;
                    });
                }
            });
            yPos += 10;
        }

        // Preferred Skills
        if (formData.job_description?.preferred_skills) {
            checkPageBreak();
            pdf.setFontSize(14);
            pdf.setFont(undefined, 'bold');
            pdf.setFontSize(12);
            pdf.text('Preferred Skills:', 20, yPos);
            pdf.setFont(undefined, 'normal');
            pdf.setFontSize(10);
            yPos += 10;

            const skills = String(formData.job_description.preferred_skills).split('\n');
            skills.forEach(skill => {
                if (skill.trim()) {
                        const lines = pdf.splitTextToSize(skill.trim(), 160);
                    lines.forEach((line, index) => {
                        checkPageBreak();
                        pdf.text(line, 25, yPos);
                        yPos += 6;
                    });
                }
            });
        }

        // Footer
        pdf.setFontSize(10);
        pdf.setTextColor(128, 128, 128);
        pdf.text('Generated by BotGuru', 20, pdf.internal.pageSize.height - 10);
        
        // Save the PDF
        const fileName = `JD_${String(formData.job_role).replace(/\s+/g, '_')}_${new Date().toISOString().split('T')[0]}.pdf`;
        pdf.save(fileName);
    };

    const EmptyPreviewState = () => (
        <div className="empty-preview-state">
            <div className="empty-preview-content">
                <div className="empty-preview-line"></div>
                <div className="empty-preview-line"></div>
                <div className="empty-preview-line"></div>
                <div className="empty-preview-line"></div>
                <div className="empty-preview-line"></div>
                <div className="empty-preview-line"></div>
                <div className="empty-preview-line"></div>
                <div className="empty-preview-line"></div>
                <div className="empty-preview-line"></div>
                <div className="empty-preview-line"></div>
                <div className="empty-preview-line"></div>
            </div>
        </div>
    );

    const PreviewContent = () => {
        const hasAnyData = formData.job_role || formData.job_type || formData.company || formData.job_description.about_us || formData.job_description.job_overview || formData.job_description.key_responsibilities || formData.job_description.required_qualification_experience || formData.job_description.preferred_skills;
        
        if (!hasAnyData) {
            return <EmptyPreviewState />;
        }

        return (
            <>
            <div className="preview-company-header">
                    <h1 className="company-name">{formData.company}</h1>
                    <div className="current-date">Date: {new Date().toLocaleDateString()}</div>
                </div>
                <div className="preview-row">
                    {formData.job_role && (
                        <div className="preview-item">
                            <h3>Job/Role:</h3>
                            <p>{formData.job_role}</p>
                        </div>
                    )}
                    {formData.job_type && (
                        <div className="preview-item">
                            <h3>Job Type:</h3>
                            <p>{formData.job_type}</p>
                        </div>
                    )}
                    {formData.experience && (
                        <div className="preview-item">
                            <h3>Experience:</h3>
                            <p>{formData.experience}</p>
                        </div>
                    )}  
                    {formData.salary && (
                        <div className="preview-item">
                            <h3>Salary:</h3>
                            <p>{`${formData.salary} ${formData.currency||'USD'}`}</p>
                        </div>
                    )}  
                    {formData.location && (
                        <div className="preview-item">
                            <h3>Location:</h3>
                            <p>{formData.location}</p>
                        </div>
                    )}
                </div>
                    {formData.job_description.about_us && (
                        <div className="preview-item">
                            <h3>About Us:</h3>
                            <p>{formData.job_description.about_us}</p>
                        </div>
                    )}
                    {formData.job_description.job_overview && (
                        <div className="preview-item">
                            <h3>Job Overview:</h3>
                            <p>{formData.job_description.job_overview}</p>
                        </div>
                    )}
                    {formData.job_description.key_responsibilities && (
                        <div className="preview-item">
                            <h3>Key Responsibilities:</h3>
                            <p>{formData.job_description.key_responsibilities}</p>
                        </div>
                    )}
                    {formData.job_description.required_qualification_experience && (
                        <div className="preview-item">
                            <h3>Required Qualifications/Experience:</h3>
                            <p>{formData.job_description.required_qualification_experience}</p>
                        </div>
                    )}
                    {formData.job_description.preferred_skills && (
                        <div className="preview-item">
                            <h3>Preferred Skills:</h3>
                            <p>{formData.job_description.preferred_skills}</p>
                        </div>
                    )}

            </>
        );
    };

    

    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
             {isLoading ? (
       <JDLoadingScreen/>
      ) : (
            <div className="jd-editable-modal-content">
                
                <button className="jd-close-icon" onClick={closeModal}>
                    &times;
                </button>
                <div className="split-container">
                <div className="form-side">
                    <div className="createjd-header">
                        <h2>Create New Job/Role</h2>
                    </div>

                    <div className="jd-editable-modal-details">
                        {/* Error message section */}
{/* 
                        {errorMessage && (
                            <div className="error-modal">
                                <div className="error-modal-content">

                                    <strong> <i className="fa fa-triangle-exclamation" aria-hidden="true"></i>{errorMessage}</strong>
                                    <button onClick={() => setErrorMessage("")} className="error-modal-close-btn">
                                        OK
                                    </button>
                                </div>
                            </div>
                        )} */}

                        <div className="side-by-side-fields">
                            <div className="field-container">
                                <div className="job-label">
                                    <h3>Job/Role:</h3>
                                    <span className={`required-asterisk ${fieldErrors.jobRole ? 'error-text' : ''}`}>*</span>
                                </div>
                                <input
                                    type="text"
                                    value={formData["job_role"] || ""}
                                    placeholder="Software Engineer"
                                    onChange={(e) => handleInputChange("job_role", e.target.value)}
                                    className={fieldErrors.jobRole ? 'error-border' : ''}
                                />
                                {fieldErrors.jobRole && 
                                            <span className="jd-error-message">{fieldErrors.jobRole}</span>}
                            </div>
                            <div className="field-container">
                            <div className="job-label">
                                <h3>Job Type:</h3>
                                <span className={`required-asterisk ${fieldErrors.jobType ? 'error-text' : ''}`}>*</span>
                                </div>
                                <select
                                    value={formData.job_type || ""}
                                    onChange={(e) => handleInputChange("job_type", e.target.value)}
                                    className={fieldErrors.jobType ? 'error-border' : ''}
                                >
                                    <option value="Select Job Type">Select Job Type</option>
                                    <option value="Full Time">Full Time</option>
                                    <option value="Contract">Contract</option>
                                </select>
                                {fieldErrors.jobType && 
                                            <span className="jd-error-message">{fieldErrors.jobType}</span>}
                            </div>
                            <div className="field-container">
                            <div className="job-label">
                                <h3>Company:</h3>
                                <span className={`required-asterisk ${fieldErrors.company ? 'error-text' : ''}`}>*</span>
                            </div>
                                <input
                                    type="text"
                                    value={formData.company || ""}
                                    placeholder="Apple"
                                    onChange={(e) => handleInputChange("company", e.target.value)}
                                    className={fieldErrors.company ? 'error-border' : ''}
                                />
                                {fieldErrors.company && 
                                            <span className="jd-error-message">{fieldErrors.company}</span>}
                            </div>
                            <div className="field-container">
                                <div className="job-label">
                                    <h3>Experience:</h3>
                                </div>
                                <input
                                    type="text"
                                    value={formData.experience || ""}
                                    placeholder="2-5 years"
                                    onChange={(e) => handleInputChange("experience", e.target.value)}
                                />
                            </div>
                            <div className="field-container">
                                <div className="job-label">
                                    <h3>Salary:</h3>
                                </div>
                            <div className="input-with-dropdown">
                                <input
                                type="text"
                                value={formData.salary || ""}
                                placeholder="2000"
                                onChange={(e) => handleInputChange("salary", e.target.value)}
                                />
                                <select
                                value={formData.currency || "USD"} // Default to USD
                                onChange={(e) => handleInputChange("currency", e.target.value)}
                                className="currency-dropdown"
                                >
                                <option value="USD">USD</option>
                                <option value="EUR">EUR</option>
                                <option value="GBP">GBP</option>
                                <option value="INR">INR</option>
                                <option value="AUD">AUD</option>
                                </select>
                            </div>
                            </div>



                            <div className="field-container">
                            <div className="job-label">
                                <h3>Location:</h3>
                            </div>
                                <input
                                    type="text"
                                    value={formData.location || ""}
                                    placeholder="California"
                                    onChange={(e) => handleInputChange("location", e.target.value)}
                                />
                            </div>
                        </div>
                        {/* <div className="job-description">
                            <h3 className="JD">Job Description:</h3>
                            <button className="ai-generate-button" onClick={handleAIGenerate}>
                                <i className="fas fa-wand-magic-sparkles"></i>&nbsp;AI Generate
                            </button>
                        </div>
                        <div className="jd-editable-modal-field">
                            {loading && (
                                <div className="loading-overlay">
                                    <div className="spinner"></div>
                                    <p>Loading, please wait...</p>
                                </div>
                            )}
                            <textarea
                                value={loading ? "Generating Job Description..." : formData.job_description || ""}
                                onChange={(e) => handleInputChange("job_description", e.target.value)}
                                rows="5"
                                disabled={loading}
                            />
                        </div> */}
                        <div className="jd-sections-container">
                            <div className="jd-section">
                                <h3>
                                    <span>About Us:</span>
                                    {aiGenerateError.length ? <span className="ai-generate-error">{aiGenerateError}</span> :""}
                                    <button className="ai-generate-button" onClick={handleAIGenerate} disabled={aiGenerating}>
                                    {aiGenerating ? (
                                    <>
                                       AI Generating <i className="fas fa-spinner fa-spin"></i>
                                    </>
                                ) : (
                                    <>
                                        <i className="fas fa-wand-magic-sparkles"></i> AI Generate
                                    </>
                                )}
                                    </button>
                                </h3>
                                <textarea
                                    value={formData.job_description.about_us}
                                    onChange={(e) => handleInputChange("about_us", e.target.value)}
                                    placeholder="Enter company description..."
                                    className={fieldErrors.aboutUs ? 'error-border' : ''} 
                                />
                                {fieldErrors.aboutUs && 
                                <span className="jd-error-message">{fieldErrors.aboutUs}</span>}
                            </div>
                            <div className="jd-section">
                                <h3>Job Overview:</h3>
                                <textarea
                                    value={formData.job_description.job_overview}
                                    onChange={(e) => handleInputChange("job_overview", e.target.value)}
                                    placeholder="Enter job overview..."
                                    className={fieldErrors.jobOverview ? 'error-border' : ''}
                                />
                                {fieldErrors.jobOverview && 
                                <span className="jd-error-message">{fieldErrors.jobOverview}</span>}
                            </div>
                            <div className="jd-section">
                            <div className="job-label">
                                <h3>Key Responsibilities:</h3>
                                <span className={`required-asterisk ${fieldErrors.keyResponsibilities ? 'error-text' : ''}`}>*</span>

                            </div>
                                <textarea
                                    value={formData.job_description.key_responsibilities}
                                    onChange={(e) => handleInputChange("key_responsibilities", e.target.value)}
                                    placeholder="Enter key responsibilities..."
                                    className={fieldErrors.keyResponsibilities ? 'error-border' : ''}
                                />
                                {fieldErrors.keyResponsibilities && 
                                <span className="jd-error-message">{fieldErrors.keyResponsibilities}</span>}
                            </div>
                            <div className="jd-section">
                                <h3>Required Qualifications/Experience:</h3>
                                <textarea
                                    value={formData.job_description.required_qualification_experience}
                                    onChange={(e) => handleInputChange("required_qualification_experience", e.target.value)}
                                    placeholder="Enter required qualifications..."
                                    className={fieldErrors.requiredQualificationExperience ? 'error-border' : ''}
                                />
                                {fieldErrors.requiredQualificationExperience && 
                                <span className="jd-error-message">{fieldErrors.requiredQualificationExperience}</span>}
                            </div>
                            <div className="jd-section">
                                <h3>Preferred Skills:</h3>
                                <textarea
                                    value={formData.job_description.preferred_skills}
                                    onChange={(e) => handleInputChange("preferred_skills", e.target.value)}
                                    placeholder="Enter preferred skills..."
                                    className={fieldErrors.preferredSkills ? 'error-border' : ''}
                                />
                                 {fieldErrors.preferredSkills && 
                                <span className="jd-error-message">{fieldErrors.preferredSkills}</span>}
                            </div>
                        </div>

                    </div>
                    <div className="jd-save-btn-container">
                        <button className="jd-save-btn" onClick={handleSubmit}>
                            Save
                        </button>
                    </div>

                </div>

                <div className="preview-side">
              <div className="preview-header">
                <h2>Preview</h2>
                <button 
                    className="download-pdf-btn"
                    onClick={handleDownloadPDF}
                >
                <i className="fas fa-download"></i>
                Download PDF
                </button>
              </div>
              <div className="preview-content">
              <PreviewContent />
              </div>
            </div>
          </div>
        </div>
            )}
        </div>
    );
};

export default JDCreateModal;
