import React from "react";
import "./DataCard.css";

const DataCard = () => {
    return (
        <div className="datacard">
            <div className="datacard-content">
                <h1>Thanks for completing the session!!!</h1>

                <h2>Whats Next!!!</h2>
                <p>
                    Well sit back and relax while Botguru makes your personalized <strong>score report</strong> to
                    enhance your skills and give you tailored guidance to your need through out your preparation
                    journey, Botguru will analyse your performance to provide you with your progression of knowledge and
                    skills throughout the sessions. Let's see what the report provides-
                </p>
                <p>
                    <strong>Deep Personalized Analysis:</strong> Every session as analyzed to generate a score report
                    that shows how you performed in that session and what you learned or can present better than before.
                    📹🎤
                </p>

                <h3>How It Works:</h3>
                <ul>
                    <li>
                        <strong>What to Expect:</strong> Each report has the questions asked during the session, your summarised answer,
                        the bots answers and feedback provided and recommendations that will guide you to prepare better
                        and tell you your immediate next steps.
                    </li>
                    <li>
                        <strong>Visulizations:</strong> We believe in the fact that what gets calculated and visualized
                        gets accomplished. That is why we provide you with indept visulization over different
                        performance metrics observed throughout the sessions, these visualizations help you better gauge
                        your progression across sessions.
                    </li>
                    <li>
                        <strong>What to Expect After reading the report:</strong> The report is designed to not only
                        show you your performance but to provide you with helpful insights and answers that if
                        considered properly can lead to better interview performance and lead to your dream job!!.
                    </li>
                </ul>

                <p>Are you ready to elevate your skills? Go and read the report! 🚀</p>
                <p> </p>
                <p> </p>

            </div>
        </div>
    );
};

export default DataCard;
