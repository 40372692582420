import React, { useState, useEffect } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { useLocation, useNavigate } from "react-router-dom";
import { loadStripe } from '@stripe/stripe-js';
import PaymentForm from './Components/PaymentForm';
import './Components/PaymentForm.css';
import { Rocket, X } from 'lucide-react';
import config from './config.json';

const stripePromise = loadStripe('pk_test_51QhgoVDwNduYvFaM4IiEakufyhhSEEF4QYvTfn4jnmUv0LyNCTMFyBGbQBvjV7giYz0obo3rC6C6Jratj0PVN6k300RkSVrrFY');
// 'pk_test_51QhgoVDwNduYvFaM4IiEakufyhhSEEF4QYvTfn4jnmUv0LyNCTMFyBGbQBvjV7giYz0obo3rC6C6Jratj0PVN6k300RkSVrrFY');
const PaymentPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { amount, planDetails } = location.state || { amount: 0, planDetails: {} };
  const [clientSecret, setClientSecret] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [errorMessage, setErrorMessage]=useState("");
  const [showFreePlan, setShowFreePlan]=useState(false);
  const userObjectID= localStorage.getItem("userObjId");

  const subscribePlan= async (body)=>{
    setShowFreePlan(false);
    try{
      let response= await fetch(`${config.restAPI}/activatePlan/`, {
        method:"POST",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({...body, user_obj_id:userObjectID}),
      })
      if(response.ok){
        if(planDetails.plan_type==="free"){
          navigate("/landingPage");
        }else{
          return response;
        }
      }else{
        console.log("response not ok", response);
        setErrorMessage("Already activated your free trial");
        setShowFreePlan(true);
      }
    }
    catch(err){
      console.log(err);
    }
  }

  useEffect(()=>{
    let timer;
    if(errorMessage){
      timer=setTimeout(()=>{
        setErrorMessage("");
        navigate(-1);
      }, 4000);
    }
    return ()=>{
      if(timer){
        clearTimeout(timer);
      }
    }
  },[errorMessage]);


  useEffect(() => {
    let isMounted = true;
    let controller = new AbortController();

    const fetchClientSecret = async () => {
      try {
        setIsLoading(true);
        setError(null);

        // Check if we already have a client secret in sessionStorage
        const storedSecret = sessionStorage.getItem('stripe_client_secret');
        if (storedSecret) {
          setClientSecret(storedSecret);
          setIsLoading(false);
          return;
        }

        const response = await fetch(`${config.restAPI}/paymentCheckout/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ amount }),
          signal: controller.signal
        });

        if (!response.ok) {
          throw new Error('Failed to fetch client secret');
        }

        const data = await response.json();

        if (isMounted) {
          setClientSecret(data.client_secret);
          // Store the client secret in sessionStorage
          sessionStorage.setItem('stripe_client_secret', data.client_secret);
        }
      } catch (err) {
        if (err.name === 'AbortError') {
          return;
        }
        if (isMounted) {
          setError(err.message);
        }
      } finally {
        if (isMounted) {
          setIsLoading(false);
        }
      }
    };

    // Only fetch if we don't have a client secret and amount is valid
    if (!clientSecret && amount > 0) {
      fetchClientSecret();
    }
    else if(!amount>0){
      setIsLoading(false);
      setShowFreePlan(true);
    }
    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [amount, clientSecret]);

  // Clear the client secret from sessionStorage when leaving the page
  useEffect(() => {
    return () => {
      sessionStorage.removeItem('stripe_client_secret');
    };
  }, []);

  const appearance = {
    theme: 'night',
    variables: {
      colorPrimary: '#BE05FA',
      colorBackground: '#1A1F36',
      colorText: '#FFFFFF',
      colorDanger: '#FF5252',
      fontFamily: 'system-ui, -apple-system, "Segoe UI", Roboto, sans-serif',
      spacingUnit: '4px',
      borderRadius: '8px',
    },
    rules: {
      '.Input': {
        border: '1px solid rgba(255, 255, 255, 0.1)',
        boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1)',
        transition: 'all 0.2s ease',
      },
      '.Input:focus': {
        border: '1px solid #BE05FA',
        boxShadow: '0 0 0 1px #BE05FA',
      },
      '.Label': {
        color: '#8792A2',
        fontSize: '14px',
      },
      '.Tab': {
        border: '1px solid rgba(255, 255, 255, 0.1)',
        boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1)',
      },
      '.Tab--selected': {
        backgroundColor: '#BE05FA',
        color: 'white',
      },
    },
  };

  if (error) {
    return (
      <div className="payment-page">
        <div className="error-message">
          Error loading payment form: {error}
        </div>
      </div>
    );
  }

  if (isLoading || !clientSecret) {
    return (
      <div className="payment-page">
        {showFreePlan ? (
          <div className="overlay">
            <div className='freePlan-popup'>
           {errorMessage && <p className='error-message'>{errorMessage}</p>}
              <div className="popup-header">
                <h2>Activate Your Free Trial</h2>
                <button className="close-btn" onClick={() => navigate(-1)}>
                  <X size={24} />
                </button>
              </div>
              <div className='popup-content'>
                <p>Start your journey with our free trial plan and explore all the features!</p>
              </div>
              <div className='popup-buttons'>
                <button className="cancel-btn" onClick={() => navigate(-1)}>Cancel</button>
                <button className="activate-btn" onClick={() => subscribePlan({"subscription_id":planDetails.id})}>
                  Activate Now
                  <Rocket size={16} />
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className="loading-component">
            <div className="loader"></div>
          </div>
        )}
      </div>
    );
  }
  return (
    <div className="payment-page">
      <Elements stripe={stripePromise} options={{ clientSecret, appearance }}>
        <PaymentForm subscribePlan={subscribePlan}/>
      </Elements>
    </div>
  );
};
export default PaymentPage;
