import React,{useState} from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import useAuthService from '../../services/AuthService';
import './ResumeModal.css';
import { useAuthContext } from '../Auth/AuthContext';

const ResumeExperiences = ({ formData, onFormChange ,fieldErrors, index,updateFieldErrors,removeSkillFromGlobalList,experiences  }) => {
    const authService = useAuthService();
    const [loading, setLoading] = useState(false);
    const {userObjId}=useAuthContext();

    console.log(formData)
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        onFormChange({
            ...formData,
            [name]: value,
        });
        if (fieldErrors[`experience${index}_${name}`]) {
            updateFieldErrors(prevErrors => ({
                ...prevErrors,
                [`experience${index}_${name}`]: undefined
            }));
        }
    };

        const handleSkillInputChange = (e) => {
            const newSkillInput = e.target.value;
    
            // Update the form data with the new skill input
            onFormChange({
                ...formData,
                skillInput: newSkillInput,
            });
    
            // Clear the skill error if it exists
            if (fieldErrors[`experience${index}_skills`]) {
                updateFieldErrors(prevErrors => ({
                    ...prevErrors,
                    [`experience${index}_skills`]: undefined
                }));
            }
        };

        const websocketUrlForProjectBuilder = "wss://o834xau6qe.execute-api.us-west-1.amazonaws.com/production/";
        const user_id = "email@email.com";

        const handleSkillAdd = (e) => {
            if (e.key === 'Enter' && formData.skillInput?.trim()) {
                e.preventDefault();
                const newSkill = formData.skillInput.trim();

                const skills = formData.skills || [];

                const totalSkills = experiences.reduce((count, exp) => count + (exp.formData.skills?.length || 0), 0);


                if (totalSkills > 60) {
                    updateFieldErrors(prevErrors => ({
                        ...prevErrors,
                        [`experience${index}_skills`]: "You can add up to 60 skills only across all experiences."
                    }));
                    return;
                }

                const isAllCaps = newSkill === newSkill.toUpperCase();

                const formattedSkill = isAllCaps ? newSkill : newSkill.charAt(0).toUpperCase() + newSkill.slice(1).toLowerCase();
                const skillExists =  skills.some(skill => skill.toLowerCase() === formattedSkill.toLowerCase());

            // Check if the skill already exists
            if (skillExists) {
                updateFieldErrors(prevErrors => ({
                    ...prevErrors,
                    [`experience${index}_skills`]: "Skill already added"
                }));
                return; // Stop further execution
            }

            if (formattedSkill.length > 100) {
                updateFieldErrors(prevErrors => ({
                    ...prevErrors,
                    [`experience${index}_skills`]: "Each skill must be 100 characters or less"
                }));
                return; // Stop further execution
            }
                onFormChange({
                    ...formData,
                    skills: [...(formData.skills || []), formattedSkill],
                    skillInput: '', // Clear input field
                });
                updateFieldErrors(prevErrors => ({
                    ...prevErrors,
                    [`experience${index}_skills`]: undefined // Clear error for skills
                }));
            }
        };

        const handleSkillRemove = (skillToRemove) => {
            const updatedSkills = (formData.skills || []).filter(skill => skill !== skillToRemove);

            onFormChange({
                ...formData,
                skills: updatedSkills,
            });
            // 🔥 Ensure validation triggers when no skills remain
        updateFieldErrors(prevErrors => {
            const newErrors = { ...prevErrors };
            if (updatedSkills.length === 0) {
                newErrors[`experience${index}_skills`] = "At least one skill is required in the Skills tab";
            } else {
                delete newErrors[`experience${index}_skills`]; // Remove error if skills exist
            }
            return newErrors;
        });
            removeSkillFromGlobalList(skillToRemove);
        };

    const bullet = '\u2022';
    const bulletWithSpace = `${bullet} `;
    const enterKeyCode = 13;

    const handleDescriptionInput = (event) => {
        const { keyCode, target } = event;
        const { selectionStart, value } = target;
        if (fieldErrors[`experience${index}_description`]) {
            updateFieldErrors(prevErrors => ({
                ...prevErrors,
                [`experience${index}_description`]: undefined
            }));
        }    

        if (keyCode === enterKeyCode) {
            event.preventDefault();
            const beforeCursor = value.substring(0, selectionStart);
            const afterCursor = value.substring(selectionStart);
            const newValue = `${beforeCursor}\n${bulletWithSpace}${afterCursor}`;

            onFormChange({
                ...formData,
                description: newValue,
            });

            setTimeout(() => {
                target.selectionStart = target.selectionEnd = selectionStart + bulletWithSpace.length + 1;
            }, 0);
        } else if (value.length === 0 || (value.length === 1 && value !== bulletWithSpace)) {
            onFormChange({
                ...formData,
                description: bulletWithSpace,
            });
        } else {
            onFormChange({
                ...formData,
                description: value,
            });
        }
    };

    const handleWebSocketMessageForProjectBuilder = async (event) => {
        try {
            const data = JSON.parse(event.data);
            console.log("Message received:", data);

            if (data.connectionid) {
                authService.resumeProjectBuilder({
                    company_name: formData.companyName || "Company Name",
                    job_role: formData.jobTitle || "Software Engineer",
                    domain: formData.domain || "Software",
                    skills: formData.skills?.join(", ") || "JavaScript, React",
                    description: formData.description || "Project description",
                    connectionId: data.connectionid,
                    user_obj_id:userObjId,
                    bot_type:"resume_ai_generate"
                });
            }

            if (data.message) {
                console.log("Message content:", data.message);

                const {
                    project_overview,
                    key_technologies,
                    project_outcomes,
                    roles_responsibilities,
                } = data.message;

                let description = '';

                if (project_overview) {
                    description += `Project Overview: ${project_overview}\n`;
                }
                if (key_technologies) {
                    description += `Key Technologies: ${key_technologies}\n`;
                }
                if (project_outcomes) {
                    description += `Project Outcomes: ${project_outcomes}\n`;
                }
                if (roles_responsibilities && Array.isArray(roles_responsibilities)) {
                    const formattedRoles = roles_responsibilities
                        .map((role) => `\u2022 ${role}`)
                        .join('\n');
                    description =`${formattedRoles}`;
                }

                console.log("Final description before update:", description);

                onFormChange({
                    ...formData,
                    ProjectDescription: description.trim(),
                    description: description.trim(), // Update formData.description
                });
                setLoading(false);  
            }
        } catch (error) {
            console.error("Error processing Project Builder WebSocket message:", error);
        }
    };

    const handleProjectGenerate = () => {
        setLoading(true);
        console.log("Establishing a new WebSocket connection for Project Generation.");

        const websocketInstance = new WebSocket(websocketUrlForProjectBuilder);

        websocketInstance.onopen = () => {
            console.log("WebSocket connection established for Project Generation");

            const initialMessage = { action: "botguru_sendmessage", message: "get data" };
            websocketInstance.send(JSON.stringify(initialMessage));
            console.log("Sent initial message for Project Generation:", initialMessage);
        };

        websocketInstance.onmessage = handleWebSocketMessageForProjectBuilder;

        websocketInstance.onerror = (error) => {
            console.error("WebSocket error for Project Generation:", error);
            setLoading(false);
        };

        websocketInstance.onclose = () => {
            console.log("WebSocket connection for Project Generation closed");
        };
    };

    return (
        <div>
            <div className="row">
                <div className="col">
                    <div className="input-div">
                        <div className="label">
                            <span>Company Name</span>
                            <span className={`required-asterisk ${fieldErrors[`experience${index}_companyName`]? 'error-text' : ''}`}>*</span>
                        </div>
                        <input
                            className={`resume-input ${fieldErrors[`experience${index}_companyName`] ? 'error-border' : ''}`}
                            placeholder="Flexon Technologies"
                            type="text"
                            name="companyName"
                            value={formData.companyName || ''}
                            onChange={handleInputChange}
                        />
                        {fieldErrors?.[`experience${index}_companyName`] && (
              <span className="resume-error-message">{fieldErrors[`experience${index}_companyName`]}</span>
            )}
                    </div>
                </div>
                <div className="col">
                    <div className="input-div">
                        <div className="label">
                            <span>Job Title</span>
                            <span className={`required-asterisk ${fieldErrors[`experience${index}_jobTitle`]? 'error-text' : ''}`}>*</span>
                        </div>
                        <input
                            className={`resume-input ${fieldErrors[`experience${index}_jobTitle`] ? 'error-border' : ''}`}
                            placeholder="Software Developer"
                            type="text"
                            name="jobTitle"
                            value={formData.jobTitle || ''}
                            onChange={handleInputChange}
                        />
                          {fieldErrors?.[`experience${index}_jobTitle`] && (
              <span className="resume-error-message">{fieldErrors[`experience${index}_jobTitle`]}</span>
            )}
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <div className="input-div">
                        <div className="label">
                            <span>Domain</span>
                            <span className={`required-asterisk ${fieldErrors[`experience${index}_domain`]? 'error-text' : ''}`}>*</span>
                        </div>
                        <input
                            className={`resume-input ${fieldErrors[`experience${index}_domain`] ? 'error-border' : ''}`}
                            placeholder="Technical"
                            type="text"
                            name="domain"
                            value={formData.domain || ''}
                            onChange={handleInputChange}
                        />
                          {fieldErrors?.[`experience${index}_domain`] && (
              <span className="resume-error-message">{fieldErrors[`experience${index}_domain`]}</span>
            )}
                    </div>
                </div>
                <div className="col">
                    <div className="input-div">
                       
                        <div className="start-end-date">
                            <div className='exp-date'>
                                <div className="label">
                                    <span>Start Date</span>
                                    <span className={`required-asterisk ${fieldErrors[`experience${index}_startDate`]? 'error-text' : ''}`}>*</span>
                                </div>
                                <DatePicker
                                        className={`datepicker ss ${fieldErrors[`experience${index}_startDate`] &&[`experience${index}_date`] ? 'error-border' : ''}`}
                                        selected={formData.startDate}
                                        placeholderText="MM/YYYY"
                                        dateFormat="MMM yyyy"
                                        showMonthYearPicker
                                        onChange={(date) =>{
                                            const updatedFormData = { ...formData, startDate: date };
                                            onFormChange(updatedFormData);
                                       
                                            updateFieldErrors(prevErrors => {
                                              const newErrors = { ...prevErrors };
                                       
                                              // Clear start date error when selecting a valid date
                                              delete newErrors[`experience${index}_startDate`];
                                       
                                              // Validate if start date is after end date
                                              if (updatedFormData.endDate && date > updatedFormData.endDate) {
                                                newErrors[`experience${index}_date`] = "Start Date cannot be after End Date";
                                              } else {
                                                delete newErrors[`experience${index}_date`]; // Remove the error if dates are valid
                                              }
                                       
                                              return newErrors;
                                            });
                                          }}
                                />
                                                                                        {fieldErrors?.[`experience${index}_startDate`] && (
                        <span className="resume-error-message">{fieldErrors[`experience${index}_startDate`]}</span>
                        )}
                            </div>
                            <div className='exp-date'>
                                <div className="label">
                                    <span>End Date</span>
                                    <span className={`required-asterisk ${fieldErrors[`experience${index}_endDate`]? 'error-text' : ''}`}>*</span>
                                </div>
                                <DatePicker
                                        className={`datepicker ss ${fieldErrors[`experience${index}_endDate`] &&[`experience${index}_date`] ? 'error-border' : ''}`}
                                        selected={formData.endDate}
                                        onChange={(date) =>
                                            {
                                                const updatedFormData = { ...formData, endDate: date };
                                                onFormChange(updatedFormData);
                                           
                                                updateFieldErrors(prevErrors => {
                                                  const newErrors = { ...prevErrors };
                                           
                                                  // Clear end date error when selecting a valid date
                                                  delete newErrors[`experience${index}_endDate`];
                                           
                                                  // Validate if start date is after end date
                                                  if (updatedFormData.startDate && updatedFormData.startDate > date) {
                                                    newErrors[`experience${index}_date`] = "Start Date cannot be after End Date";
                                                  } else {
                                                    delete newErrors[`experience${index}_date`]; // Remove error if valid
                                                  }
                                           
                                                  return newErrors;
                                                });
                                              }}
                                           
                                        placeholderText="MM/YYYY"
                                        dateFormat="MMM yyyy"
                                        showMonthYearPicker
                                        disabled={formData.currentlyWorking}  // Disable if checkbox is checked
                                />

                        {fieldErrors?.[`experience${index}_startDate`] && (
                        <span className="resume-error-message">{fieldErrors[`experience${index}_endDate`]}</span>
                        )}
                            </div>
                        </div>
                         {/* Checkbox for "I currently work here" */}
                        <div className="currently-working">
                            <input
                                type="checkbox"
                                id={`currentlyWorking-${formData.id}`}  
                                checked={formData.currentlyWorking || false}
                                onChange={(e) => {
                                    const isChecked = e.target.checked;
                                    onFormChange({
                                        ...formData,
                                        currentlyWorking: isChecked,
                                        endDate: isChecked ? new Date() : null  // Set current date when checked
                                    });
                                }}
                            />
                            <label htmlFor={`currentlyWorking-${formData.id}`}>I currently work here</label>
                        </div>

                        {fieldErrors?.[`experience${index}_date`] && (
                        <span className="resume-error-message">{fieldErrors[`experience${index}_date`]}</span>
                        )}
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="input-div">
                    <div className="label">
                        <span>Skills</span>
                        <span className={`required-asterisk ${fieldErrors[`experience${index}_skills`]? 'error-text' : ''}`}>*</span>
                        </div>
                    <input
                        type="text"
                        className={`resume-skills ${fieldErrors[`experience${index}_skills`] ? 'error-border' : ''}`}
                        value={formData.skillInput || ''}
                        onChange={handleSkillInputChange}
                        onKeyDown={handleSkillAdd}
                        placeholder="Enter skills and press Enter"
                    />
                    {fieldErrors?.[`experience${index}_skills`] &&
                                            <span className="resume-error-message">{fieldErrors[`experience${index}_skills`]}</span>
                    }
                    {(formData.skills?.length > 0 || formData.skillInput?.trim()) && (

                        <div className="resume-skills-container">
                        {[...(formData.skills || []), formData.skillInput?.trim() || '']
                        .filter(Boolean) // Ensure empty strings are not displayed
                        .map((skill, index) => (
                            <button 
                            key={index} 
                            className={`resume-skill-button ${skill === formData.skillInput?.trim() ? 'pending-skill' : ''}`}
                            >
                            {skill}
                            {skill !== formData.skillInput?.trim() && (
                                <span className="remove-skill" onClick={() => handleSkillRemove(skill)}>
                                &times;
                                </span>
                            )}
                            </button>
                        ))}
                        </div>
                    )}
                </div>
            </div>
            <div className="row">
                <div className="input-div" style={{ position: "relative" }}>
                    <div className="label expirence-description">
                        <span>Description</span>
                        <span className={`required-asterisk ${fieldErrors[`experience${index}_description`]? 'error-text' : ''}`}>*</span>
                        {fieldErrors?.[`experience${index}_description`] && (
                        <span className="resume-error-message">{fieldErrors[`experience${index}_description`]}</span>
                        )}
                    </div>
                    {loading && (
                    <div className="resumedes-loading-overlay">
                        <div className="spinner"></div>
                        <p>Loading, please wait...</p>
                    </div>
                     )}
                      <div className="textarea-container">
                      <button className="project-ai-generate-button" onClick={handleProjectGenerate}disabled={loading}>
                            {loading ? (
                                    <>
                                       AI Generating <i className="fas fa-spinner fa-spin"></i>
                                    </>
                                ) : (
                                    <>
                                        <i className="fas fa-wand-magic-sparkles"></i> AI Generate
                                    </>
                                )}
                        </button>
                    <textarea
                        className={`text-area ${fieldErrors[`experience${index}_description`] ? 'error-border' : ''}`}
                        name="description"
                        value={loading ? "Generating Project Description..." :formData.description || ''}
                        onChange={(e) => onFormChange({ ...formData, description: e.target.value })}
                        onKeyDown={handleDescriptionInput}
                        placeholder="Enter Roles and Responsibilities"
                        disabled={loading}
                    />

                   
                    </div>
                   
                </div>
            </div>
        </div>
    );
};

export default ResumeExperiences;