import React, { useMemo } from 'react';
import './Testimonials.css';
import Data from './data/testimonial.json';
import { TestimonialCard } from './TestimonialCard';

const Testimonials = () => {
  const firstHalf = useMemo(() => Data.testimonials.slice(0, Math.ceil(Data.testimonials.length / 2)), []);
  const secondHalf = useMemo(() => Data.testimonials.slice(Math.ceil(Data.testimonials.length / 2)), []);

  return (
    <div className="testimonials-section">
      <div className="testimonials-container">
        <div className="testimonials-header">
          <h2 className="testimonials-title">What Our Users Say</h2>
          <p className="testimonials-subtitle">
            Join thousands of successful professionals who transformed their careers
          </p>
          <div className="testimonials-stats">
            <span className="stats-number">1,000+</span>
            <span>success stories and counting</span>
          </div>
        </div>

        <div className='data-container'>
          <div className="scroll-container scroll-left">
            <div className="scroll-content">
              {[...firstHalf, ...firstHalf].map((testimonial, idx) => (
                <TestimonialCard key={idx} {...testimonial} />
              ))}
            </div>
          </div>

          <div className="scroll-container scroll-right">
            <div className="scroll-content">
              {[...secondHalf, ...secondHalf].map((testimonial, idx) => (
                <TestimonialCard key={idx} {...testimonial} />
              ))}
            </div>
          </div>
        </div>
      </div>
      </div>
  );
};

export default Testimonials;
