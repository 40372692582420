//index.js
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter,Routes, Route } from 'react-router-dom';
import DashboardLayout from './Components/Dashboard/DashboardLayout'; 
import MainPage from './Components/Dashboard/MainPage';
import PrepGuruMain from './Components/Dashboard/PrepGuruMain';
import MockGuruMain from './Components/Dashboard/MockGuruMain';
import ResumeGuruMain from './Components/Dashboard/ResumeGuruMain';
import JDGuruMain from './Components/Dashboard/JDGuruMain';
import CareerGuruMain from './Components/Dashboard/CareerGuruMain';
import Dashboard from './Components/Dashboard/Dashboard';
import { ChoosePlan } from './ChoosePlan';
import MainLandingPage from './Components/LandingPages/MainLandingPage';
import PricingPlan from './Components/Pricing';
import PaymentPage from './PaymentPage';
import ExitTransitionPage from "./Components/LandingPages/exitpage/ExitTransitionPage";
import { ProtectedRoute } from './Components/Auth/ProtectedRoute';
import { AuthProvider } from './Components/Auth/AuthContext';  // Import Auth Context



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <AuthProvider> {/* 🔹 Wrap the entire app in AuthProvider */}
    <BrowserRouter>
    <Routes>
        {/* Root route without a header */}
         <Route path="/" element={<App />} />
        <Route path="/landingPage" element={<ProtectedRoute><MainLandingPage /></ProtectedRoute>} />
        <Route path="/exit" element={<ProtectedRoute><ExitTransitionPage /></ProtectedRoute>} />
        <Route path="/pricing" element={<ProtectedRoute><ChoosePlan /></ProtectedRoute>} /> 
        <Route path="/payment" element={<ProtectedRoute><PaymentPage/></ProtectedRoute>} /> 

        {/* <Route path="/landingPage" element={<MainLandingPage />} />
        <Route path="/exit" element={<ExitTransitionPage />} />
        <Route path="/pricing" element={<ChoosePlan />} /> 
        <Route path="/payment" element={<PaymentPage/>} />   */}


        {/* <Route element={<DashboardLayout />}>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/prepgurumain" element={<PrepGuruMain />} />
          <Route path="/mockgurumain" element={<MockGuruMain />} />
          <Route path="/resumegurumain" element={<ResumeGuruMain />} />
          <Route path="/jdgurumain" element={<JDGuruMain />} />
          <Route path="/careergurumain" element={<CareerGuruMain />} />
        </Route> */}
      </Routes>
    </BrowserRouter>
    </AuthProvider>
  </React.StrictMode>
);
 
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();