// import React from "react";
// import "./LoadTransitionPage.css";
//
// const LoadTransitionPage = () => {
//     return (
//         <div className="bookshelf-container">
//             <div className="bookshelf"></div>
//         </div>
//     );
// };
//
// export default LoadTransitionPage;
// import React, { useState } from "react";
// import "./LoadTransitionPage.css";
//
// const LoadTransitionPage = ({ setIsLoading }) => {
//
//     const handleClick = () => {
//         setIsLoading(false); // Close the loading screen
//     };
//
//     return (
//
//             <div className="bookshelf-container">
//                 <div className="bookshelf">
//                     <div className="click-me-message" onClick={handleClick}>
//                         Click me !!!!
//                     </div>
//                 </div>
//             </div>
//
//     );
// };
//
// export default LoadTransitionPage;
import React, { useState } from "react";
import Loader from "./Loader";
import "./LoadTransitionPage.css";

const ExitTransitionPage = () => {
    const [showLoader, setShowLoader] = useState(false);

    const handleClick = () => {
        setShowLoader(true);
    };

    return (
        <div className="load-transition-page">
            {showLoader && (
                <div className="loader-overlay">
                    <Loader />
                </div>
            )}
            {!showLoader && (
                <div className="bookshelf-container">
                    <div className="bookshelf">
                        <div className="click-me-message" onClick={handleClick}>
                            Click me !!!
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ExitTransitionPage;
