import React from 'react';
import './PolicyModal.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faFileContract,faUserShield,faShieldAlt,faGlobeAmericas,faHandshakeSlash,faClipboardList,faPhoneAlt,faBalanceScale,faPuzzlePiece, faGlobe,faFileAlt,faHandshake,faFileSignature,faCopyright,faUserCheck, faCircleInfo,faBell,faDollarSign, faEnvelope, faGavel, faSyncAlt, faBan, faUserSecret, faTrafficLight, faUserTimes, faExclamationTriangle, faRobot, faCircle } from '@fortawesome/free-solid-svg-icons';

const TermsConditionsModal = ({ isOpen, onClose }) => {
    if (!isOpen) return null;

    return (
        <div className="policy-modal-overlay" onClick={onClose}>
            <div className="policy-modal-content" onClick={(e) => e.stopPropagation()}>
                <button className="policy-close-button" onClick={onClose}>
                    <FontAwesomeIcon icon={faTimes} />
                </button>
                <div className="policy-modal-header">
                    <h2><FontAwesomeIcon icon={faFileContract} className="policy-icon" />TERMS OF USE</h2>
                    <p className="effective-date">Last Updated: <strong>February 26th , 2025</strong></p>
                </div>
                <div className="policy-modal-body">
                    <p>PLEASE READ THESE TERMS OF USE (THE “AGREEMENT” OR THE “TERMS OF USE” OR “TERMS”) CAREFULLY BEFORE USING THE SERVICES OFFERED BY Flexon Technologies Inc, A DELAWARE Incorporation (THE “Company”). THIS AGREEMENT SETS FORTH THE LEGALLY BINDING TERMS FOR YOUR USE OF THE SERVICES OFFERED BY Company DIRECTLY OR THROUGH BotGuru.ai OR ALL OTHER SITES OWNED AND OPERATED BY Company THAT REDIRECT TO BotGuru.ai AND ALL SUBDOMAINS (COLLECTIVELY, THE “SITE” OR “WEBSITE” OR “WEBSITES”), AND THE SERVICES OFFERED BY Company (TOGETHER WITH THE SITE, COLLECTIVELY, THE “SERVICE” OR “SERVICES”).</p>
                    <p>BY CLICKING ON THE ACCEPTANCE TAB THAT LINKS TO THIS AGREEMENT, OR OTHERWISE USING THE SERVICES, YOU AGREE YOU HAVE THE AUTHORITY TO ENTER INTO THIS AGREEMENT, AND FURTHER AGREE TO BE BOUND BY THIS AGREEMENT, THE PRIVACY POLICY, AND ALL OTHER RULES, POLICIES, AND PROCEDURES THAT MAY BE PUBLISHED BY Company FROM TIME TO TIME ON THE SITE.</p>
                    <p>The word “You” or “Your” shall mean the individual and entity that accepts these Terms of Use by clicking on the “I Accept” button.</p>

                    <h3><FontAwesomeIcon icon={faGlobe} className="policy-icon" /> USING THE SITE, THE APP, AND THE SERVICES</h3>
                    <p>You may be able to view portions of the Site without registering with Company as a registered user. However, in order to access certain password-restricted areas of the Site and to use certain Services offered on and through the Site, you must register and share an email and receive a OTP(One-time Password) for every time you login. Once you have submitted your account registration information, we will have the right to approve or reject the requested registration, in our sole discretion. Please note that the Services available to you may also vary depending on various factors, including the type of subscription you may register for.</p>
                    <p>You are responsible for maintaining the confidentiality of your OTP, and you are responsible for all activities that occur using your OTP. You agree not to share your OTP, let others access or use your OTP, or do anything else that might jeopardize the security of your account. You agree to notify us, if you are aware of any unauthorized use of your OTP on the Site, or if you know of any other breach of security in relation to the Site.</p>
                    <p>All the information that you provide when registering for an account and otherwise through the Site must be accurate, complete, and up to date. You may change, correct, or remove certain information from your account by logging into your account at the Site and making the desired changes to your account information.</p>
                    <p>All Services are provided on a user basis and accounts may be tracked for compliance. If it is determined that unapproved users other than those provided to us are using Services, then we may, at our sole discretion, terminate the account upon notice to the user's email address on record.</p>
                    <p>You agree to use the Services in accordance with the terms set forth herein. The Site may not be used to mine information from users. You acknowledge that we collect data and information regarding individuals seeking mental and physical transformative services. You agree that we may disclose the identity of such arrangements, and other data and information regarding those efforts and that such information is not protected by any privilege, including medical. WE DISCLAIM AND EXCLUDE ANY LIABILITY TO YOU IN CONNECTION WITH SUCH ACTIVITIES.</p>
                    <p>The Services are subject to storage and usage restrictions which we may change from time to time.</p>
                    <h3><FontAwesomeIcon icon={faEnvelope} className="policy-icon" /> ELECTRONIC COMMUNICATIONS.</h3>
                    <p>By using the Site and/or the Services, you consent to receiving electronic communications from us. These electronic communications may include information about Services and features of the Site, notices about applicable fees and charges, transactional information, and other information concerning or related to the Site and/or Services. These electronic communications are part of your relationship with us. You agree that any notices, agreements, disclosures, or other communications that we send you electronically will satisfy any legal communication requirements, including that such communications be in writing.</p>
                    
                    <h3><FontAwesomeIcon icon={faUserShield} className="policy-icon" /> PRIVACY POLICY</h3>
                    <p>Please review our Privacy Policy, which is available at botguru.ai (the “Privacy Policy”) and which explains how we treat the personal information that we collect about you through the Site. You agree that we may treat your personal information in accordance with our Privacy, which is incorporated by reference into these Terms.</p>
                    
                    <h3><FontAwesomeIcon icon={faFileAlt} className="policy-icon" /> USER CONTENT</h3>
                    <p>You are responsible for the information, opinions, messages, comments, photos, videos, graphics, sounds, and other content or material that you submit, upload, post, or otherwise make available on or through the Site and/or the Services ("User Content"). You may not upload, post, or otherwise make available on the Site any material protected by copyright, trademark, or any other proprietary right without the express permission of the owner of such copyright, trademark, or other proprietary right owned by a third party, and the burden of determining whether any material is protected by any such right is on you. You shall be solely liable for any damage resulting from any infringement of copyrights, trademarks, proprietary rights, violation of contract, privacy or publicity rights, or any other harm resulting from your User Content. You have full responsibility for your User Content, including its legality, reliability, and appropriateness.</p>
                    <p>Unless otherwise explicitly stated herein or in the Privacy Policy, you agree that any User Content submitted, uploaded, posted, or otherwise made available by you in connection with your use of the Site and/or Services is provided on a non-proprietary and non-confidential basis. You hereby grant to US a non-exclusive, perpetual, irrevocable, royalty-free, fully paid-up, worldwide license (including the right to sublicense through multiple tiers) to use, reproduce, process, adapt, publicly perform, publicly display, modify, prepare compilations and derivative works of, publish, transmit and distribute your User Content, or any portion thereof, and to publish, transmit, distribute, and sell compilations and derivative works of your User Content, in any form, medium, or distribution method now known or hereafter existing, known, or developed. We may modify or adapt your User Content in order to transmit, display, or distribute them over computer networks and in various media and/or make changes to your User Content as necessary to conform and adapt them to any requirements or limitations of any networks, devices, services, or media. You agree to pay for all royalties, fees, damages, and any other monies owing any person by reason of any User Content posted by you to or through the Site. When you provide User Content you agree that such User Content shall not be in violation of the Unauthorized Activities paragraph below. Those prohibitions do not require us to monitor, police, or remove any User Content or other information submitted by you or any other user.</p>
                    
                    <h3><FontAwesomeIcon icon={faBan} className="policy-icon" /> UNAUTHORIZED ACTIVITIES</h3>
                    <p>When using the Site and/or the Services, you agree not to:</p>
                    <ul  className="policy-list">
                        <li><span>Defame, abuse, harass, stalk, threaten, or otherwise violate the legal rights (such as rights of privacy and publicity) of others.</span></li>
                        <li><span>Use racially, ethnically, or otherwise offensive language.</span> </li>
                        <li><span>Discuss or incite illegal activity.</span> </li>
                        <li><span>Use explicit/obscene language or solicit/post sexually explicit images (actual or simulated).</span> </li>
                        <li><span>Post anything that exploits children or minors or that depicts cruelty to animals.</span> </li>
                        <li><span> Use any false or inaccurate information for purposes of registering as a user of the Site.</span> </li>
                        <li><span>Delete or revise any material or other information of any other user or the Site, or otherwise alter the opinions or comments posted by others on the Site.</span> </li>
                        <li><span>Disseminate any unsolicited or unauthorized advertising, promotional materials, 'junk mail', 'spam', 'chain letters', 'pyramid schemes', or any other form of such solicitation.</span></li>    
                        <li><span>Use any robot, spider, scraper, or other automated means to access the Site.</span></li>
                        <li><span>Use the Site or the Services for any commercial or pecuniary purpose.</span></li>
                        <li><span>Use the Site in any manner that is harmful to others.</span></li>
                        <li><span>Attempt to modify, adapt, translate, decipher, decompile, disassemble, or reverse engineer any of the software comprising or in any way making up a part of the Site or the Services.</span></li>
                        <li><span>Reproduce, duplicate, copy, sell, resell, or otherwise exploit for any commercial purposes, any portion of, use of, or access to the Site.</span></li>
                        <li><span>Remove any copyright, trademark, or other proprietary rights notice from the Site or materials originating from the Site.</span></li>
                        <li><span>Use the Site in a manner that intentionally or unintentionally violates any applicable local, state, national, or international law, or in a manner that is inappropriate, harmful, or offensive.</span></li>
                        <li><span>Take any action that imposes an unreasonable or disproportionately large load on our infrastructure or may otherwise harm, disrupt, or overburden the Site.</span></li>
                    </ul>
                    <p>This list of prohibitions provides examples and is not complete or exclusive. We reserve the right to (a) terminate access to your account, your ability to post to the Site (or use the Services), and (b) refuse, delete, or remove any User Content, with or without cause and with or without notice, for any reason or no reason, or for any action that we determine, in our sole discretion, is inappropriate or disruptive to the Site or to any other user of the Site and/or Services. We may report to law enforcement authorities any actions that may be illegal, and any reports it receives of such conduct. When legally required or at our discretion, we will cooperate with law enforcement agencies in any investigation of alleged illegal activity on the Site or on the Internet.</p> 
                    <p>Unauthorized use of any Materials or Third-Party Content contained on the Site may violate certain laws and regulations. You agree to indemnify and hold us and our officers, directors, employees, affiliates, agents, licensors, and business partners harmless from and against any and all costs, damages, liabilities, and expenses (including attorneys' fees and costs of defense) we or any other indemnified party suffers in relation to, arising from, or for the purpose of avoiding any claim or demand from a third party that your use of the Site or the use of the Site by any person using your user name and/or password (violates any applicable law or regulation, or the copyrights, trademark rights, or other rights of any third party</p>
                    <h3><FontAwesomeIcon icon={faUserCheck} className="policy-icon" /> ELIGIBILITY</h3> 
                   <p>You must be at least the age of majority in the jurisdiction in which you reside in order to use the Site and the Services. By using the Site or the Services, you represent, acknowledge, and agree that you are at least the age of majority in the jurisdiction where you reside and have the right, authority, and capacity to agree to and abide by these Terms. You also represent that you will use the Site and Services in a manner consistent with any and all applicable laws and regulations. Not all products and services are available in all geographic areas. Your eligibility for particular products and services is subject to final determination by Company. We may terminate these Terms and your use of the Site and Services without notice if we, in our sole discretion, believe that you are not at least the age of majority in the jurisdiction where you reside, or have otherwise violated the Terms.</p>
                    
                    <h3><FontAwesomeIcon icon={faCircleInfo} className="policy-icon" /> DISCLAIMER OF WARRANTY</h3>
                    <p>To the fullest extent permissible by law, the Services are provided “AS IS” and WITHOUT WARRANTY OF ANY KIND. YOU ASSUME THE RISK OF ANY AND ALL DAMAGE OR LOSS FROM THE USE OF THE SERVICES. TO THE MAXIMUM EXTENT PERMITTED BY LAW, Company EXPRESSLY DISCLAIMS ANY AND ALL WARRANTIES, EXPRESS OR IMPLIED, REGARDING THE SERVICES, INCLUDING, BUT NOT LIMITED TO, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT. YOUR USE OF THE SERVICE IS SOLELY AT YOUR OWN RISK.</p>

                    
                    <h3><FontAwesomeIcon icon={faFileSignature } className="policy-icon" /> CONTENT AND LICENSES</h3>
                    <p>You agree that the Service contains Content provided by Company and may be protected by copyrights, trademarks, service marks, patents, trade secrets, or other rights and laws. Users shall abide by and maintain all copyright and other legal notices, information, and restrictions contained in any Content accessed through the Service.</p>
                    
                    <h3><FontAwesomeIcon icon={faCopyright} className="policy-icon" /> COPYRIGHT DISPUTE POLICY</h3>
                    <p>Under the Digital Millenium Copyright Act (the “DMCA”), online service providers such as Company have the right, but not the obligation, to remove material that allegedly violates someone’s copyright. We respect others’ intellectual property rights, and we reserve the right to delete or disable Content alleged to be infringing, and to terminate the accounts of repeat alleged infringers.</p>
                    
                    <h3><FontAwesomeIcon icon={faShieldAlt} className="policy-icon" /> PROPRIETARY RIGHT</h3>
                    <p>Company owns and retains all proprietary rights in the Site, its logo, the Service, and all material and information received from the Users (“Content”). The Site contains the copyrighted material,trademarks, and other proprietary information of Company. Users may not alter, modify, publish, distribute, publicly display, or sell any Content or other such proprietary information, or otherwise copy or transmit any Content or other such proprietary information without the express written permission of Company.</p>
                    
                    <h3><FontAwesomeIcon icon={faSyncAlt} className="policy-icon" /> CHANGES AND TERMINATION</h3>
                    <p>The Services are offered at our sole discretion. We reserve the right, in our sole discretion and at any time: (a) to terminate or suspend the Services, in whole or in part; and (b) to add, delete, or otherwise change any of the Terms and Conditions, including changes to fees, charges, and discounts. We may change the Terms and Conditions without prior notice to you, but the current version of the Terms and Conditions will be posted on the Site. By continuing to participate in the Services following any change in the Terms and Conditions, you are accepting the changes to the Terms and Conditions.</p>
                    <p>In addition, we reserve the right to terminate your participation in the Services: (a) in the event of any abuse or fraud, and/or any violation of the Terms and Conditions; or (b) for your failure to meet eligibility requirements. We reserve the right, in our sole discretion, to determine whether termination or disqualification is appropriate. We reserve the right to deny access to your account or account history in the event your account is terminated. These rights are in addition to any other legal or equitable remedy that may be available to us under applicable law.</p>
                    <p>You may terminate your participation in the Services at any time by contacting us on the Site or through your App.</p>
                    <h3><FontAwesomeIcon icon={faHandshake} className="policy-icon" /> INDEMNIFICATION</h3>
                    <p>By using the Services and agreeing to this Agreement, you agree to defend, indemnify, and hold harmless Company, its affiliates, and each of its affiliates’ directors, officers, employees, managers, agents, contractors, partners, attorneys, and suppliers from all liabilities, claims, and expenses, including reasonable attorneys’ fees, that arise from or relate to the use or misuse of, or access to, the Service, the violation of any law, rule or regulation, or infringement by you, or any third party using your account.</p>
                    <h3><FontAwesomeIcon icon={faBalanceScale} className="policy-icon" /> LIMITATION OF LIABILITY</h3>
                    <ul  className="policy-list">
                        <li><span>DATA LOSS;</span></li>
                        <li><span>ERRORS, MISTAKES, OR INACCURACIES OF CONTENT;</span> </li>
                        <li><span>DEFAMATORY, OFFENSIVE, OR ILLEGAL CONDUCT OF ANY THIRD PARTY;</span> </li>
                        <li><span>PERSONAL INJURY OR PROPERTY DAMAGE OF ANY NATURE WHATSOEVER, RESULTING FROM THE YOUR USE OF THE SERVICES;</span> </li>
                        <li><span>UNAUTHORIZED ACCESS TO OR USE OF Company SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN;</span> </li>
                        <li><span>LOSSES CAUSED BY ANY UNAUTHORIZED USE OF YOUR ACCOUNT;</span> </li>
                        <li><span>INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM Company SERVICES, ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE, WHICH MAY BE TRANSMITTED TO OR THROUGH Company SERVICES BY ANY THIRD PARTY, WHETHER BASED ON WARRANTY, CONTRACT, TORT, OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT Company IS ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.</span> </li>
                    </ul>
                    <p>Electronic Communication Privacy Act Notice (18usc 2701-2711): Company makes no guarantee of confidentiality or privacy of any communication or information transmitted on the Site or any website linked to the Site. Company will not be liable for the privacy of email addresses, registration and identification information, communications, confidential or trade-secret information, or any other Content stored on Company equipment, transmitted over networks accessed by the Site, or otherwise connected with your use of the Service.</p>
                    <p>THE FOREGOING LIMITATION OF LIABILITY SHALL APPLY TO THE FULLEST EXTENT PERMITTED BY THE JURISDICTION IN WHICH YOU ARE LOCATED.</p>
                    <h3><FontAwesomeIcon icon={faGlobeAmericas} className="policy-icon" /> LOCAL LAWS; EXPORT CONTROL</h3>
                    <p>We control and operate the Site from our headquarters in the United States of America, and the Services may not be appropriate or available for use in other locations. If you use the Site outside the United States of America, you are responsible for following applicable local laws.</p>
                    <h3><FontAwesomeIcon icon={faGavel} className="policy-icon" /> GOVERNING LAW – ARBITRATION; CLASS ACTION WAIVER</h3>
                    <p>All provisions shall be construed in accordance with and governed by the laws of the United States and the State of Michigan, without reference to their rules regarding conflicts of law. All disputes arising out of or related to the provisions herein and/or the use of the Site, the Content, or the Services shall be resolved in a court of competent jurisdiction located in Alameda County, California. You consent to the personal jurisdiction of the courts in Michigan and agree that Michigan is the sole venue for any dispute. To the fullest extent allowed by law, neither you, nor any other user of the Site, can be a class representative, class member, or otherwise participate in a class, consolidated, or representative proceeding without having complied with the opt-out requirements above.</p>
                    <h3><FontAwesomeIcon icon={faBell} className="policy-icon" /> ELECTRONIC DELIVERY, NOTICE POLICY, CONSENT</h3>
                    <p>By using the Services, you consent to receive from Company all communications including notices, agreements, legally required disclosures, or other information in connection with the Services (collectively, “Contract Notices”) electronically. Company may provide the electronic Contract Notices by posting them on the Site or directly emailing you to an email address it provides in the registration process.</p>
                    <p>Your use of electronic signatures to sign documents legally binds it in the same manner as if it had manually signed such documents. The use of electronic versions of documents fully satisfies any requirement that such documents be provided to you in writing. If you sign electronically, you represent that you have the ability to access and retain a record of such documents. You agree that you are responsible for understanding these documents and agree to conduct business by electronic means. You are obligated to review the Site periodically for changes and modifications and agree not to contest the admissibility or enforceability the Site’s electronically stored copy of this Agreement in any proceeding arising out of this Agreement.</p>
                    <h3><FontAwesomeIcon icon={faPuzzlePiece} className="policy-icon" /> INTEGRATION AND SEVERABILITY; ASSIGNABILITY; WAIVER</h3>
                    <p>These Terms of Use, together with the Privacy Policy and Services Agreement, and any other legal notices published by Company on the Site, shall constitute the entire agreement between you and Company concerning the Services and supersedes all prior or contemporaneous communications, agreements, and understandings between Company and you with respect to the subject matter hereof. These Terms of Use shall not be assignable by the you, either in whole or in part. Company reserves the right to assign its rights and obligations under these Terms without restriction. The failure of Company to enforce any right or provision of these Terms of Use will not constitute a waiver of future enforcement of that right or provision. The waiver of such right or provision may only become effective upon written agreement signed by a duly authorized representative of Company. The section titles in this Agreement and the Privacy Policy are for convenience only and have no legal or contractual effect. If, for any reason, any provision of these Terms, or a portion thereof, shall be unenforceable, that provision shall be enforced to the maximum extent permissible so as to affect the intent of these Terms, and the remainder of these Terms shall continue in full force and effect. If any provision of the Terms shall be unlawful, void, or for any reason unenforceable, then that provision will be deemed severable from the Terms and will not affect the validity and enforceability of any remaining provisions. A printed version of these Terms shall be admissible in judicial or administrative proceedings.</p>
                    <h3><FontAwesomeIcon icon={faHandshakeSlash} className="policy-icon" /> FORCE MAJEURE; NO PARTNERSHIP; NO THIRD-PARTY BENEFICIARIES</h3>
                    <p>Company shall not be liable for any failure to perform its obligations hereunder where the failure results from any cause beyond Company reasonable control, including, without limitation, mechanical, electronic, or communications failure or degradation. No agency, partnership, joint venture, or employment relationship is created as a result of the Terms of Use and neither party has any authority of any kind to bind the other in any respect. Except as expressly provided in these Terms of Use, there shall be no third-party beneficiaries to the Terms of Use</p>
                    <h3><FontAwesomeIcon icon={faClipboardList} className="policy-icon" /> NOTICE PROCESS</h3>
                    <p>All notices contemplated herein shall be sent to the Company by sending the notice to 6210 Stoneridge Mall Rd suite 210, Pleasanton, CA 94588, Company shall send notices to you at the email address registered by you. A Notice is deemed to have been given when sent.</p>
                    <h3><FontAwesomeIcon icon={faPhoneAlt} className="policy-icon" /> CONTACT US</h3>
                    <p>If you have any questions about these Terms or otherwise need to contact us for any reason, you can reach us at <a>info@botguru.ai</a></p>
                </div>
            </div>
        </div>
    );
};

export default TermsConditionsModal;