import React, { useState } from 'react';
import './CareerGuruPage.css';

const CareerGuruPage = () => {
    const [messages, setMessages] = useState([]);
    const [input, setInput] = useState('');

    const handleSend = () => {
        if (input.trim()) {
            setMessages([...messages, { text: input, sender: 'user' }]);
            // Simulate bot response (replace with actual API call)
            setTimeout(() => {
                const botResponse = getBotResponse(input); // Placeholder function
                setMessages([...messages, { text: input, sender: 'user' }, { text: botResponse, sender: 'bot' }]);
                setInput('');
            }, 500); // Simulate a slight delay
        }
    };

    // Placeholder function - replace with your actual bot logic
    const getBotResponse = (userInput) => {
        //  Your AI logic here to generate a response based on userInput
        return "This is a placeholder bot response.  Replace this with your AI integration.";
    };


    return (
        <div className="career-guru-page">
            <div className="chat-header">
                <h1>AI Career Assistant</h1>
                <p>Ask me anything about your career journey!</p>
            </div>
            <div className="chat-container">
                <div className="messages">
                    {messages.map((msg, index) => (
                        <div key={index} className={`message ${msg.sender}`}>
                            {msg.text}
                        </div>
                    ))}
                </div>
                <div className="input-container">
                    <input
                        type="text"
                        value={input}
                        onChange={(e) => setInput(e.target.value)}
                        placeholder="Type your question..."
                    />
                    <button onClick={handleSend}>Send</button>
                </div>
            </div>
        </div>
    );
};

export default CareerGuruPage; 