
import PricingPlan from "./Components/Pricing"
import './App.css';
import { CircleArrowRight } from "lucide-react";
import { useNavigate } from "react-router-dom";

export const ChoosePlan=()=>{
    const navigate= useNavigate();
    return (
        <div className="ChoosePlan-container">
            <button className="skip-subscription"
                onClick={() => navigate("/landingPage")} >
                Home
                <CircleArrowRight />
            </button>
            <PricingPlan />
        </div>
    )
}