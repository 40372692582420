import React,{useState,useEffect} from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import './MainLandingPage.css';
import logo from '../../botgurulogo.png';
import PrepGuruPage from './PrepGuruPage';
import MockGuruPage from './MockGuruPage';
import ResumeGuruPage from './ResumeGuruPage';
import JDGuruPage from './JDGuruPage';
import CareerGuruPage from './CareerGuruPage';
import Analytics from './Analytics';
import ProfileSection from './ProfileSection';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './MyAccountModal.css';
import { useAuthContext } from '../Auth/AuthContext';
import config from "../../config.json";



import { 
    faUser, 
    faCheckCircle, 
    faCalendarAlt, 
    faDollarSign,
    faClipboardList
  } from '@fortawesome/free-solid-svg-icons';
import MyAccountModal from './MyAccountModal';
import { Circle, CircleX, LogOut } from 'lucide-react';
import useAuthService from '../../services/AuthService';





const MainLandingPage = () => {
    const location = useLocation();
    const [activeButton, setActiveButton] = useState(location.state?.activeButton || 'Analytics');
    const [activeBot, setActiveBot] = useState(location.state?.activeBot || 'prep');
    const [activeView, setActiveView] = useState("Analytics"); // Track the current view (button or Account)
    const [previousView, setPreviousView] = useState(null); // To track the view before opening account details
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const {userObjId, profileData, setProfileData, userAnalytics, setUserAnalytics, onLogout} = useAuthContext(); 
    const [logoutPopUp, setLogoutPopUp] = useState(false);
    const navigate=useNavigate("");
    const authService=useAuthService();




    useEffect(() => {
        if (location.state?.activeButton) {
            setActiveButton(location.state.activeButton);
            setActiveView(location.state.activeButton); // Ensure activeView updates
        }
    }, [location.state]);


    // useEffect(() => {
    //     if (location.state?.activeButton) {
    //       setActiveButton(location.state.activeButton);
    //     }
    //   }, [location.state]);


    // Add this useEffect to handle clicks outside the menu
    useEffect(() => {
        const handleClickOutside = (event) => {
            // Get both menu and burger button elements
            const menuElement = document.querySelector('.landing-services');
            const burgerButton = document.querySelector('.burger-menu-btn');
            
            // Check if click is outside both menu and burger button
            if (menuElement && burgerButton && 
                !menuElement.contains(event.target) && 
                !burgerButton.contains(event.target)) {
                setIsMenuOpen(false);
            }
        };

        // Add event listener to document
        document.addEventListener('click', handleClickOutside);

        // Cleanup
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []); // Empty dependency array since we want this to run once

    // Add this to close menu when a menu item is clicked
    const handleButtonClick = (buttonName) => {
        setActiveButton(buttonName);
        setActiveView(buttonName);
        setIsMenuOpen(false); // Close menu after selection
    };

    const openAccountDetails = () => {
        setPreviousView(activeView); // Save the current view before switching
        setActiveView("Account");
        setActiveButton('');
        // Switch view to Account details
    };
    
    const closeAccountDetails = () => {
        setActiveView(previousView); // Restore the previous view
        setPreviousView(null); // Clear the previous view
      };

      const handleLogoClick = () => {
        setActiveButton('Analytics');
        setActiveView('Analytics');
    };

    const handleLogout = async () => {
        try {
            const response = await authService.logout( { 
                credentials: 'include'
            });
    
            if (response && response.isAuthenticated === false) {
                setLogoutPopUp(false);
                console.log("Logout successful");
                localStorage.removeItem("userObjId");
                onLogout();
                navigate("/");
            } else {
                console.error("Logout failed:", response);
                
            }
        } catch (error) {
            console.error("Error during logout:", error);
        }
    };

    const toggleMenu = (e) => {
        e.stopPropagation(); // Prevent event from bubbling up
        setIsMenuOpen(!isMenuOpen);
    };

    useEffect(() => {
        const fetchProfileDetails = async () => {
            try {
                setProfileData({})
                const response = await fetch(`${config.restAPI}/getProfileDetails/`, {
                    method: "POST",
                    credentials:"include",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({ user_obj_id:userObjId }),
                });
    
                if (!response.ok) {
                    throw new Error("Failed to fetch profile details");
                }
    
                const data = await response.json();
                setProfileData(data.response);
            } catch (error) {
                console.error("Error fetching profile details:", error);
            }
        };
    
        fetchProfileDetails();
    }, [userObjId]);

    useEffect(() => {
        const fetchAnalyticsDetails = async () => {
            try {
                setUserAnalytics({})
                const response = await fetch(`${config.restAPI}/getuserAnalytics/`, {
                    method: "POST",
                    credentials:"include",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({ user_obj_id:userObjId }),
                });
    
                if (!response.ok) {
                    throw new Error("Failed to fetch profile details");
                }
                const data = await response.json();
                // console.log("Analytics data:", data);
                setUserAnalytics(data.response);
            } catch (error) {
                console.error("Error fetching profile details:", error);
            }
        };
    
        fetchAnalyticsDetails();
    }, [userObjId]);
    
    

    return (
        <div className='main-landing-page'>
            <div className='left-landing-page'>
                <button className='burger-menu-btn' onClick={toggleMenu}></button>
                <div>
                    <img className='logo' 
                        src={logo} 
                        alt={logo} 
                        style={{ pointerEvents: 'none' }}></img>
                </div>
                <div 
                    className={`landing-services ${isMenuOpen ? 'menu-open' : ''}`}
                    onClick={(e) => e.stopPropagation()} // Prevent clicks inside menu from closing it
                >
                    <button
                        className={activeButton === 'Analytics' ? 'active' : ''}
                        onClick={() => handleButtonClick('Analytics')}
                    >
                        <img src="/prepguruicon.png" alt="Logo" />
                        Analytics
                    </button>
                    <button
                        className={activeButton === 'PrepGuru' ? 'active' : ''}
                        onClick={() => handleButtonClick('PrepGuru')}
                    >
                        <img src="/prepguruicon.png" alt="Logo" />
                        PrepBot
                    </button>
                    <button
                        className={activeButton === 'MockGuru' ? 'active' : ''}
                        onClick={() => handleButtonClick('MockGuru')}
                    >
                        <img src="/mockguruicon.png" alt="Logo" />
                        MockBot
                    </button>
                    <button
                        className={activeButton === 'ResumeGuru' ? 'active' : ''}
                        onClick={() => handleButtonClick('ResumeGuru')}
                    >
                        <img src="/JDguruicon.png" alt="Logo" />
                        ResumeExpert
                    </button>
                    <button
                        className={activeButton === 'JDGuru' ? 'active' : ''}
                        onClick={() => handleButtonClick('JDGuru')}
                    >
                        <img src="/JDguruicon.png" alt="Logo" />
                        JDExpert
                    </button>
                </div>
                <div className='nav-footer'>
                    <ProfileSection openAccountDetails={openAccountDetails}  userName={ `${profileData.first_name} ${profileData.last_name}`} setLogoutPopUp={setLogoutPopUp}/>
                    <div className="version-info">
                        Version 1.0.0
                    </div>
                </div>
            </div>

            <div className="right-landing-page">
                
                {activeView  === 'Analytics' && <Analytics  activeBot={activeBot} analyticsData={userAnalytics} ></Analytics> }
                {activeView  === 'PrepGuru' && <PrepGuruPage ></PrepGuruPage>}
                {activeView  === 'MockGuru' && <MockGuruPage></MockGuruPage>}
                {activeView ==='ResumeGuru' && <ResumeGuruPage></ResumeGuruPage>}
                {activeView ==='JDGuru' && <JDGuruPage></JDGuruPage>}
                {activeView ==='CareerGuru' && <CareerGuruPage></CareerGuruPage>}
                {activeView === "Account" && <MyAccountModal handleSignOut={()=>setLogoutPopUp(true)}></MyAccountModal>}
            </div>
            {logoutPopUp ? <div className='logout-confirm-overlay' onClick={(e)=>{
                if(e.target.classList.contains("logout-confirm-overlay")){
                    setLogoutPopUp(false);
                }
            }}>
                <div className='logout-confirm-model'>
                    <div className='logout-cancel' onClick={()=>setLogoutPopUp(false)}>
                        <CircleX />
                    </div>
                    <div className='logout-model-header'>
                        <LogOut />
                        <div>
                            <h4>Confirm Logout</h4>
                            <span>Are you sure you want to log out? You'll need to sign in again to access your account.</span>
                        </div>
                    </div>
                    <div className='logout-buttons'>
                        <button className='logout-cancel-btn' onClick={()=>setLogoutPopUp(false)}>Cancel</button>
                        <button className='confirm-logout-btn' onClick={()=>handleLogout()}>Logout</button>
                    </div>
                </div>
            </div> : " "}
         </div>
    );
};

export default MainLandingPage;