import React, { useState,useEffect} from "react";
import './CompletedDetailsModal.css';
import { useAuthContext } from "../Auth/AuthContext";
import config from "../../config.json";
 
const CompletedDetailsModal = ({ isOpen, closeModal, cardData }) => {
  const [reportUrl, setReportUrl] = useState("");
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const { userObjId} = useAuthContext(); // Get userObjId and newUser from context


  function formatDateToLocal(dateString) {
    const date = new Date(dateString);
  
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.getFullYear();
    return ` ${month} ${day}, ${year}`;

  }

  const fetchReport = async (type,reportFullName) => {
    try {
      const response = await fetch(`${config.restAPI}/getReports/`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          bucket_name: "botguru-resumes",
          user_obj_id: userObjId,
          session_obj_id: reportFullName,
        }),
      });

      const data = await response.json();
      console.log(data.html_url)
      if (type === "view" && data?.html_url) {
       
        setReportUrl(data.html_url);
        setIsViewModalOpen(true);  // Ensure this is set after updating reportUrl
      } else if (type === "download" && data?.pdf_url) {
        window.open(data.pdf_url);
      } else {
        console.error("URL not found in response:", data);
      }
    } catch (error) {
      console.error("Error fetching report:", error);
    }
  };

  const closeViewModal = () => {
    setIsViewModalOpen(false);
    setReportUrl("");
  };


  if (!isOpen || !cardData) return null;
  const latestSession = cardData.sessions[0] || {};
  const totalDuration = cardData.sessions.reduce((total, session) => total + parseInt(session.duration || 0), 0);
  const validSessions = cardData.sessions.filter(session => session.duration !== undefined && session.duration !== null);
  const averageScore = validSessions.length > 0
  ? Math.round(validSessions.reduce((total, session) => total + parseInt(session.score || 0), 0) / validSessions.length)
  : 0; // Default to 0 if no valid sessions


  return (
    <>
    <div className="modal-overlay">
      <div className="completed-modal-content" onClick={(e) => e.stopPropagation()}>
        <button className="completed-close-button" onClick={closeModal}>
          &times;
        </button>
 
        <div className="modal-header">
          <div className="header-icon">
            <i className="fas fa-clock"></i>
          </div>
          <div className="session-count">
          <h2>{cardData.job_role}</h2>
          </div>
        </div>

        <div className="stats-container">
          <div className="stat-box">
            <div className="stat-label">
              <span className="stat-label-text">Latest Score</span>
              <i className="fas fa-medal"></i>
            </div>
            <div className="stat-content">
              <div className="stat-value">{cardData.latest_score}%</div>
              {cardData.total_sessions >=2 && (
              <div className={`stat-improvement ${cardData.improvement_score < 0 ? 'negative-score' : ''}`}>
                  {cardData.improvement_score}%
              </div>
              )}
            </div>
          </div>
          <div className="stat-box">
            <div className="stat-label">
              <span className="stat-label-text">Average Score</span>
              <i className="fas fa-chart-line"></i>
            </div>
            <div className="stat-content">
              <div className="stat-value">{averageScore}%</div>
            </div>
          </div>
          <div className="stat-box">
            <div className="stat-label">
              <span className="stat-label-text">Total Duration</span>
              <i className="fas fa-clock"></i>
            </div>
            <div className="stat-content">
              <div className="stat-value">{totalDuration} mins</div>
            </div>
          </div>
          <div className="stat-box">
            <div className="stat-label">
              <span className="stat-label-text">Sessions</span>
              <i className="fas fa-list-check"></i>
            </div>
            <div className="stat-content">
              <div className="stat-value">{cardData.sessions.filter(session => session.duration !== undefined && session.duration !== null).length}</div>
            </div>
          </div>
        </div>
 
        <div className="timeline-section">
          <h3>Session Timeline</h3>
          <div className="timeline-container">
            {cardData.sessions
            .filter(session => session.duration !== undefined && session.duration !== null)
            .reverse()
            .map((session, index,filteredSessions) => (
              <div className="timeline-item" key={index}>
                <div className="timeline-marker"></div>
                <div className="timeline-content">
                  <div className="session-header">
                    <h4>Session {filteredSessions.length - index}</h4>
                    <span className="session-date">{formatDateToLocal(session.session_created_at)}</span>
                  </div>
                  <div className="session-stats">
                    <span className="duration">
                      <i className="far fa-clock"></i> {session.duration} mins
                    </span>
                    <span className="session-score">
                      <i className="fas fa-chart-line"></i> Score: {session.score}%
                    </span>
                  </div>
                  <div className="report-conatiner">

                  <button
                        className="download-report-btn"
                        onClick={() => fetchReport("view",session.report_full_name)}
                      >
                        <i className="fas fa-eye"></i> View Session Report
                      </button>
                      <button
                        className="download-report-btn"
                        onClick={() => fetchReport("download", session.report_full_name)}
                      >
                        <i className="fas fa-download"></i> Download Session Report
                      </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        </div>
        
      </div>

{isViewModalOpen && (
  <div className="modal-overlay">
   
    <div className="view-modal-content">
       <button className="view-close-button" onClick={closeViewModal}>
        &times;
    </button>
      <iframe
        src={reportUrl}
        width="900vw"
        height="550px"
        frameBorder="0"
      />
    </div>

  </div>
)}
</>
      
  );
};
 
export default CompletedDetailsModal;