import React from "react";
import { useSelector } from "react-redux";
import config from '../config.json';

const useAuthService = () => {
  // const userSession = useSelector((state) => state.AuthReducer?.userSession || {});

  const makeRequest = async (url, method, body = null, checkNoContent = true) => {
    try {
      const options = {
        method,
        credentials: 'include',
      };
      // Check if the body is FormData
      if (body instanceof FormData) {
        options.body = body; // Directly assign FormData
        options.headers = {
          Accept: 'application/json', // Optional
        };
      } else if (body) {
        options.body = JSON.stringify(body);
        options.headers = {
          'Content-Type': 'application/json',
        };
      }

      const response = await fetch(url, options);

      if (checkNoContent && response.status === 204) {
        console.log("No content received.");
        return [];
      }
      if(!response.ok){
        let errData=await response.json()
        console.log("message in makeRequest", errData);
        let error=new Error('Network response failed');
        error.data=errData;
        throw error;
      }
      return await response.json();
    } catch (error) {
      console.error(`Error in ${method} request to ${url}:`, error);
      throw error;
    }
  };


  // const signup = (email) => {
  //   const url = `${config.restAPI}/signin/`;
  //   return makeRequest(url, 'POST', { recipient_email: email });
  // };
  const signup = (email, ipAddress,fingerprint) => {
    const url = `${config.restAPI}/signin/`;

    // Create the payload with both email and IP address
    const payload = {
        recipient_email: email,  // Email
        ip_address: ipAddress,
        fingerprint:fingerprint     // IP Address
    };

    return makeRequest(url, 'POST', payload);
};
  const verifySignin = (email,otp_code) => {
    const url = `${config.restAPI}/verifySignin/`;
    return makeRequest(url, 'POST',{email:email,otp_code:otp_code});
  };

  const googlesignup = (email,credential,source) => {
    const url = `${config.restAPI}/thirdPartyAppLogin/`;
    return makeRequest(url, 'POST',{email:email,credential:credential,source:source});
  };

  const logout =()=>{
    const url = `${config.restAPI}/logout/`;
    return makeRequest(url, 'POST');
  }

  const saveUserDetails = (details) =>{
    console.log(details)
    const url = `${config.restAPI}/saveUserDetails/`;
    return makeRequest(url, 'POST',details);
  }

  const JdAiGenerate = ({job_role,company_name,connectionId,job_details, user_obj_id, bot_type }) => {
    console.log(job_role,company_name,connectionId,job_details, user_obj_id)
    const url=`${config.restAPI}/jobDescriptionGen/`;
    return makeRequest(url,'POST',{job_role,company_name,connectionId,job_details, user_obj_id, bot_type})
  }

  const jobDescriptionExtractor = ({ file, connectionId, user_obj_id }) => {
    const url = `${config.restAPI}/jobDescriptionExtractor/`;
    const formData = new FormData();
    formData.append('file', file);
    formData.append('connectionId', connectionId);
    formData.append('user_obj_id', user_obj_id);
    formData.append("bot_type", "jd_extractor")
    return makeRequest(url, 'POST', formData, { 'Accept': 'application/json' },true);
  };


  const saveJobDetails = (details) => {
    const url = `${config.restAPI}/SaveJob/`; // Update with the actual API endpoint
    return makeRequest(url, 'POST', details);
  };

  const resumeProjectBuilder = ({ company_name, job_role, domain, skills, description, connectionId, user_obj_id , bot_type}) => {
    console.log(job_role, company_name, connectionId, skills, user_obj_id);
    const url = `${config.restAPI}/resumeProjectBuilder/`;
    return makeRequest(url, 'POST', { company_name, job_role, domain, skills, description, connectionId, user_obj_id, bot_type });
  };

  const saveResume = (resumeDetails) => {
    const url = `${config.restAPI}/saveResume/`; // Update the base API URL in config.json if necessary
    return makeRequest(url, 'POST', resumeDetails);
  };


  const resumeExtractor = ({ file, connectionId, user_obj_id }) => {
    const url = `${config.restAPI}/resumeExtractor/`;
    const formData = new FormData();
    formData.append('file', file);
    formData.append('connectionId', connectionId);
    formData.append('user_obj_id', user_obj_id);
    formData.append("bot_type", "resume_extractor")

    return makeRequest(url, 'POST', formData, { 'Accept': 'application/json' },true);
  };





  return {
    signup,
    verifySignin,
    saveResume,
    saveUserDetails,
    JdAiGenerate,
    jobDescriptionExtractor,
    saveJobDetails,
    resumeProjectBuilder,
    resumeExtractor,
    googlesignup,
    logout
  };
};

export default useAuthService;
