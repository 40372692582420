import React, { useEffect, useRef, useState } from 'react';
import './Component.css';
import config from '../config.json';
import { ProgressBar } from './Utils/ProgressBar';
import { ArrowRight, CheckCircle2 } from 'lucide-react';

const features = [
    {
      id: 1,
      title: 'AI Interview MockBot',
      description: 'Dive into real-time, simulated interviews after sharpening your skills with PrepBot. '
    },
    {
      id: 2,
      title: 'Realistic Scenarios',
      description: 'GPractice mock interviews where MockBot monitors your presentation skills for authentic preparation.'
    },
    {
      id: 3,
      title: 'Instant Feedback',
      description: 'Receive constructive insights on your behavior, strategy, strengths, and weaknesses, along with actionable tips for improvement after each session.'
    },
    {
      id: 4,
      title: 'Master Your Craft',
      description: ' Reinforce your learning with repeat sessions and track your progress on the path to success!'
    }
  ];
  const FeatureItem = ({ title, description }) => (
    <div className="feature-item">
        <CheckCircle2 className='feature-logo'/>
      <div className="feature-content">
        <h3 className="feature-title">{title}</h3>
        <p className="feature-description">{description}</p>
      </div>
    </div>
  );

 
const MockGuru = ({isMockGuruVisible, getStarted}) => {
  const videoRef = useRef(null);
 
  return (
    <div className="page">
        <div className='row-1'>
            <div className='row-1-container'>
                <h1>MockBot</h1>
                <h2>Practice to perfection!</h2>
            </div>
        </div>
        <div className='row-2'>
            <div className='row-2-container'>
                <div className="feature-list">
                {features.map((feature) => (
                    <FeatureItem
                    key={feature.id}
                    title={feature.title}
                    description={feature.description}
                    />
                ))}
                </div>
                <div className="videoPlayer">
                    <div>
                        <video
                        ref={videoRef}
                        src={`${config.backendAPI}MockGuru.mp4`}
                        autoPlay
                        loop
                        muted
                        playsInline
                        controls
                        style={{ borderRadius: '10px' }}
                        loading="lazy"
                    ></video>
                    </div>
                        <button className="subscribe-btn" onClick={()=>getStarted('/landingPage')}>Get started</button>
                </div>
            </div>
        </div>
        <div className='row-3'>
          {/* <p className='steps-title'>Steps To Start <ArrowRight /></p> */}
            {isMockGuruVisible && <ProgressBar stepsData="mockSteps" />}
        </div>
    </div>
 
  );
};
 
export default MockGuru;